import React from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from '../../ConfirmationModal'
import { INITIAL } from '../reportStatuses'
import { getSentryErrorMessage } from '../../../utils/sentry'

const ErrorMessageModal = props => {
  const errorMessage = getSentryErrorMessage(props.error)
  if (!errorMessage) return null
  return (
    <ConfirmationModal
      title=""
      subtitle={errorMessage}
      isOpen={props.isOpen && props.reportStatus === INITIAL}
      actionButtons={[
        {
          label: 'IGNORE',
          color: 'secondary',
          handler: props.closeIssueReportModalFromSentry,
          isDisabled: false,
        },
        {
          label: 'REPORT',
          color: 'primary',
          handler: props.openForm,
          isDisabled: false,
        },
      ]}
    />
  )
}

ErrorMessageModal.propTypes = {
  error: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeIssueReportModalFromSentry: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  reportStatus: PropTypes.number.isRequired,
}

ErrorMessageModal.defaultProps = {
  error: {},
}

export default ErrorMessageModal
