import _ from 'lodash'
import moment from 'moment'
import eventUtils from '../../utils/eventUtils'
import timeUtils from '../../utils/timeUtils'

import { convertDateToLocalTimezone, getDefaultSyncDateRangeISOs } from '../../server/utils/date'

const LAST_MONTH_RANGE = 1
const FIRST_MONTH_RANGE = 2

export const isInSyncedRange = date => {
  const { startISO, endISO } = getDefaultSyncDateRangeISOs()
  return moment(date).isBetween(startISO, endISO)
}

const lastDayOfMonthLimit = () => {
  const lastMonthLimit = timeUtils.addMonths(new Date(), LAST_MONTH_RANGE)
  return new Date(lastMonthLimit.getFullYear(), lastMonthLimit.getMonth() + 1, 0)
}

export const isNextMonthOutOfRange = (date, lastMonthRange) => {
  const monthRange = lastMonthRange || LAST_MONTH_RANGE
  const lastMonthLimit = moment().add(monthRange, 'months')
  return moment(date).isSameOrAfter(lastMonthLimit, 'month')
}

const isNextWeekOutOfRange = date => {
  const startOfWeekLimit = moment(lastDayOfMonthLimit()).startOf('week')
  const startOfWeek = moment(date).startOf('week')
  return moment(startOfWeekLimit).isSame(startOfWeek)
}

const isNextDayOutOfRange = date => moment(lastDayOfMonthLimit()).isSame(date)

const firstDayOfFirstMonthLimit = () => {
  const firstMonthLimit = timeUtils.substractMonths(new Date(), FIRST_MONTH_RANGE)
  return new Date(firstMonthLimit.getFullYear(), firstMonthLimit.getMonth())
}

export const isPreviousMonthOutOfRange = (date, firstMonthRange) => {
  const monthRange = firstMonthRange || FIRST_MONTH_RANGE
  const convertedDate = convertDateToLocalTimezone(date)
  const firstMonthLimit = moment().subtract(monthRange, 'months')
  return moment(convertedDate).isSameOrBefore(firstMonthLimit, 'month')
}

const isPreviousWeekOutOfRange = date => {
  const startOfWeekLimit = moment(firstDayOfFirstMonthLimit()).startOf('week')
  const startOfWeek = moment(date).startOf('week')
  return moment(startOfWeekLimit).isSame(startOfWeek)
}

const isPreviousDayOutOfRange = date => moment(firstDayOfFirstMonthLimit()).isSame(date)

export const shouldHideArrows = (isDayViewPeriodSelected, selectedDate) => {
  const previousDayOutOfRange = isPreviousDayOutOfRange(selectedDate)
  const previousWeekOutOfRange = isPreviousWeekOutOfRange(selectedDate)
  const nextDayOutOfRange = isNextDayOutOfRange(selectedDate)
  const nextWeekOutOfRange = isNextWeekOutOfRange(selectedDate)
  const shouldHideLeftArrow =
    (isDayViewPeriodSelected && previousDayOutOfRange) ||
    (!isDayViewPeriodSelected && previousWeekOutOfRange)
  const shouldHideRightArrow =
    (isDayViewPeriodSelected && nextDayOutOfRange) ||
    (!isDayViewPeriodSelected && nextWeekOutOfRange)
  return { shouldHideLeftArrow, shouldHideRightArrow }
}

/**
 * @typedef {Object} EntriesInformation
 * @property {Object} selectedDate
 * @property {Object[]} selectedDayEntries
 */

/**
 * @typedef {import('moment').Moment} Moment
 */

/**
 * @typedef {Object} Gap
 * @property {Moment} start
 * @property {Moment} end
 */

/**
 * Returns a free gap object given the timeentries for a date
 * @param {EntriesInformation} param
 * @returns {Gap} gap
 */

export const getFreeGap = ({ selectedDate, selectedDayEntries }) => {
  const dayTimeEntries = selectedDayEntries
  const today = moment(selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ')

  const dayBusyTime = eventUtils.getDayBusyTime(dayTimeEntries)
  const todayEightAM = moment(today)
    .set({ h: 8 })
    .toISOString()
  const halfPastEightAM = moment(today)
    .set({ h: 8, m: 30 })
    .toISOString()

  const isEightAmFree = dayBusyTime.every(busySlot => {
    return !timeUtils.momentIsBetweenExclusiveEnd(todayEightAM, busySlot.start, busySlot.end)
  })

  if (isEightAmFree) {
    return {
      start: todayEightAM,
      end: halfPastEightAM,
    }
  }

  const dayFreeTime = eventUtils.getDayFreeTime(dayTimeEntries, selectedDate)

  const closestFreeGaps = dayFreeTime
    .filter(freeSlot => moment(freeSlot.end).diff(moment(freeSlot.start), 'minutes') >= 30)
    .filter(freeSlot => moment(freeSlot.start).isSameOrAfter(todayEightAM))

  const closestFreeGap = _.head(closestFreeGaps)

  const start = _.get(closestFreeGap, 'start') ? moment(_.get(closestFreeGap, 'start')) : null

  if (!start) return null
  const halfHourBeforeStart = moment(closestFreeGap.start).add(30, 'minutes')
  const endOfDay = moment(selectedDate).endOf('day')
  const end = timeUtils.momentIsAfter(endOfDay, halfHourBeforeStart)
    ? endOfDay
    : halfHourBeforeStart

  return { start, end }
}

export const getEventsFromEntriesInfo = ({
  startISO,
  endISO,
  exchangeEvents,
  timeEntries,
  localEntries,
}) => {
  const entityFilter = timeUtils.doesEntityOccurInside({ startISO, endISO })
  const uiExchangeEvents = exchangeEvents.filter(entityFilter)
  const entries = [...timeEntries, ...localEntries.map(e => e.data)]
  const uiEntries = entries.filter(entityFilter)
  const areAllEventsAdded = uiExchangeEvents.every(exchangeEvent => {
    const existAnEntryCreatedByEvent = uiEntries.some(
      entry => entry.referenceId === exchangeEvent.id,
    )
    return existAnEntryCreatedByEvent
  })
  return {
    uiExchangeEvents,
    areAllEventsAdded,
  }
}

export const isEntryStartDateEqualToDate = (entry, date) => {
  const entryDate = entry.originalEntryDate || entry.entryDate
  if (!entryDate) return false

  let dateString = ''
  if (typeof date === 'object') {
    dateString = date.toISOString()
  } else dateString = date

  const selectedDateString = moment(dateString).format('YYYY-MM-DD')
  const entryStartDate = moment(entryDate).utc().format('YYYY-MM-DD')
  return selectedDateString === entryStartDate
}
