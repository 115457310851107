import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const DelegateConfirmationModelWrapper = styled.h1``

export const Header1 = styled.h2`
  margin-bottom: 15px;
`

export const InfoPara = styled.p`
  margin-bottom: 15px;
`

export const DelegatePermission = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-bottom: 1px solid lightgray;
`

export const DelegatePermissionName = styled.span`
  color: black;
  font-size: 13px;
  flex: 1;
`
export const DelegateAccessModelContentWrapper = styled.div`
  color: gray;
`

export const DelegateAccessModelButton = styled(Button)`
  height: 100%;
  width: 100%;
`
