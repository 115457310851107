import _ from 'lodash'
import moment from '../utils/moment'
import { convertDateToLocalTimezone, parsePTOStartStopDate } from '../utils/date'
import { FUNNEL_ENTRIES_RESOURCE_ID } from '../../constants/events'

export const getDefaultHeaders = () => {
  return {
    'content-type': 'application/json',
  }
}

export const urlifyQuery = query => {
  return _.chain(query)
    .thru(compact)
    .map((value, key) => {
      const compactValue = maybeCompactArray(value)
      return `${key}=${compactValue}`
    })
    .join('&')
    .value()
}

export const compact = value => {
  return _.chain(value)
    .omitBy(e => !e)
    .value()
}

export const maybeCompactArray = maybeArray => {
  if (!Array.isArray(maybeArray)) return maybeArray
  return _.reject(maybeArray, _.isNil)
}

// @TODO cleanup how we handle arranging entries into the middle when not from journal.
export const addEventDataToEntries = entries => {
  const entriesGroupedByDay = _.groupBy(entries, 'originalEntryDate')
  const result = []

  _.each(entriesGroupedByDay, (entriesForDay, day) => {
    // Remove UTC timezone offset
    const nonUTCDay = convertDateToLocalTimezone(day)

    const timesheetDuration = _.chain(entriesForDay)
      .map('originalDurationInHours')
      .sum()
      .value()

    let totalHours = (24 - timesheetDuration) / 2
    _.each(entriesForDay, entry => {
      let start
      let end

      if (_.isNil(entry.start) || _.isNil(entry.end)) {
        start = moment(nonUTCDay)
          .startOf('day')
          .add(totalHours, 'hours')
          .toLocalString()
        totalHours += entry.originalDurationInHours
        end = moment(nonUTCDay)
          .startOf('day')
          .add(totalHours, 'hours')
          .toLocalString()
      } else {
        start = convertDateToLocalTimezone(entry.start)
        end = convertDateToLocalTimezone(entry.end)
      }

      const newEvent = _.assign(entry, {
        start,
        end,
        resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
      })
      result.push(newEvent)
    })
  })

  return result
}

export const addEventDataToEntry = entry => {
  const start = convertDateToLocalTimezone(entry.start)
  const end = convertDateToLocalTimezone(entry.end)

  return _.assign(entry, {
    start,
    end,
    resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
  })
}

export const addEventDataToPTO = entries => {
  return _.map(entries, entry => {
    const { start, end } = parsePTOStartStopDate(entry)

    return {
      ...entry,
      start,
      end,
      resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
      type: 'timeOffEntry',
    }
  })
}

export const convertPTOCodesToProjects = codes => {
  return _.map(codes, code => {
    return {
      ...code,
      projectType: 'pto',
    }
  })
}

export const convertTimesheetToLocalDate = timesheets => {
  return _.map(timesheets, timesheet => {
    return {
      ...timesheet,
      startDate: convertDateToLocalTimezone(timesheet.startDate),
      endDate: convertDateToLocalTimezone(timesheet.endDate),
    }
  })
}

export const DATA_LIMIT_PER_REQUEST = 100
export const AUTH_ERROR = 403
