import _ from 'lodash'
import eventUtils from '../../utils/eventUtils'

export const isEntryValid = entry => {
  if (_.isNil(entry.originalProjectId)) return false

  const durationInHoursNumber = parseFloat(entry.originalDurationInHours)
  const isDurationValid =
    !_.isNaN(durationInHoursNumber) && !isInvalidDurationTime(durationInHoursNumber)

  return isDurationValid && !eventUtils.isEntryInvoiced(entry)
}

export const isInvalidDurationTime = entry => {
  if (!entry) return true
  return entry.originalDurationInHours >= 24 || entry.originalDurationInHours <= 0
}
