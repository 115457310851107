import gql from 'graphql-tag'

export const UPDATE_TIMESHEET_APPROVAL_STATUS = gql`
  mutation($repliconId: String!, $approvalStatus: String!) {
    updateApprovalStatus(repliconId: $repliconId, approvalStatus: $approvalStatus) {
      _id
      deleted
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
    }
  }
`

export const UPDATE_TIMESHEET_APPROVAL_STATUS_AS_DELEGATE = gql`
  mutation($repliconId: String!, $approvalStatus: String!, $delegateId: String!) {
    updateApprovalStatus_asDelegate(
      repliconId: $repliconId
      approvalStatus: $approvalStatus
      delegateId: $delegateId
    ) {
      _id
      deleted
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
    }
  }
`
