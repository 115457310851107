import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

export const MasterButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: '14px',
    minWidth: '100px',
    margin: '0 0 20px 0',
    float: 'right',
  },
}))(Button)

export const Totals = styled.div`
  font-size: 14px;
  padding: 15px 6px;
  float: left;
`

export const TotalNumber = styled.span`
  font-weight: bold;
`

export const Expandables = styled.div`
  clear: both;
  overflow: scroll;
  & > div:not(:last-child) {
    margin: 0 0 5px 0;
  }
`
