import _ from 'lodash'
import moment from '../../utils/moment'
import { getStartEndISOForTheDay, convertDateToLocalTimezone } from '../../utils/date'
import { TimesheeIsAlreadyApprovedError } from '../../utils/errorClasses'
import Timesheet from '../../models/timesheet'
import UnhandledEntry from '../../models/unhandledEntry'
import { APPROVED } from '../../../constants/timesheetStatus'
import { log } from '../../../utils/logger'
import { convertTimesheetToLocalDate } from '../../funnel/funnelUtils'

import * as timesheetService from '../../../entities/timesheet/service'

const getNewTimeEntryEvent = data => {
  return {
    ...data,
    start: isAlreadyUTC(data.start) ? data.start : moment(data.start).toLocalStringAsUTC(),
    end: isAlreadyUTC(data.end) ? data.end : moment(data.end).toLocalStringAsUTC(),
  }
}

const isAlreadyUTC = date => {
  return _.endsWith(date, 'Z')
}

export const getUpdatedTimeEntryEvent = data => {
  const timeEntryEvent = getNewTimeEntryEvent(data)
  return _.merge({}, timeEntryEvent, {
    _id: data._id,
    originalDurationInHours: data.originalDurationInHours,
    originalProjectId: data.originalProjectId,
    originalComment: data.comment,
  })
}

const getTimesheet = async ({ client, eventData, selectedDelegateId }) => {
  const { startISO, endISO } = getStartEndISOForTheDay(convertDateToLocalTimezone(eventData.start))
  const localTimesheet = await Timesheet.get({
    startDate: convertDateToLocalTimezone(startISO),
  })

  if (!_.isNil(localTimesheet)) return localTimesheet

  const databaseTimesheets = await timesheetService.fetchTimesheets({
    client,
    startDate: startISO,
    endDate: endISO,
    shouldCreateIfNotExist: true,
    selectedDelegateId,
  })

  if (_.isNil(databaseTimesheets) || _.isEmpty(databaseTimesheets))
    throw new Error('Can not create entry without timesheet')

  const timesheets = convertTimesheetToLocalDate(databaseTimesheets)
  const [timesheet] = timesheets
  await Timesheet.add(timesheet)
  return timesheet
}

export const transformDataToValidEntry = async ({ client, eventData, selectedDelegateId }) => {
  const timesheet = await getTimesheet({ client, eventData, selectedDelegateId })
  const entryEvent = getNewTimeEntryEvent(eventData)

  if (timesheet.approvalStatus === APPROVED) {
    log(`Timesheet is already approved. Creating unhandled entry:`, entryEvent)
    await UnhandledEntry.add(entryEvent)
    throw new TimesheeIsAlreadyApprovedError()
  }
  const entry = { ...entryEvent, repliconTimesheetId: timesheet.repliconId }
  return entry
}
