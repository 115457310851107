import moment from 'moment'
import 'moment-duration-format'
import _ from 'lodash'
import { convertDateToLocalTimezone } from '../server/utils/date'

export default {
  getSelectedDayWeekDays(d) {
    const firstWeekDay = this.getFirstDayOfWeek(d)
    const weekDaysQuantity = 7
    const selectedDayWeekDays = []
    _.times(weekDaysQuantity, i => {
      const day = this.addDays(firstWeekDay, i)
      selectedDayWeekDays.push(day)
    })
    return selectedDayWeekDays
  },

  getFirstDayOfWeek(d) {
    const clonedDate = moment(d).clone()
    const startOfWeek = new Date(moment(clonedDate).startOf('isoWeek'))

    return startOfWeek
  },

  getFullMonth(d) {
    const month = d.getMonth()
    switch (month) {
      default:
        return 'Unknown'
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'
    }
  },

  getFullDayOfWeek(day) {
    switch (day) {
      default:
        return 'Unknown'
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
    }
  },

  getShortDayOfWeek(day) {
    switch (day) {
      default:
        return 'Unknown'
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
    }
  },

  getFullYear(date) {
    return date.getFullYear()
  },

  clone(d) {
    return new Date(d.getTime())
  },

  cloneAsDate(d) {
    const clonedDate = this.clone(d)
    clonedDate.setHours(0, 0, 0, 0)
    return clonedDate
  },

  addDays(d, days) {
    const newDate = this.clone(d)
    newDate.setDate(d.getDate() + days)
    return newDate
  },

  substractDays(d, days) {
    const newDate = this.clone(d)
    newDate.setDate(d.getDate() - days)
    return newDate
  },

  addWeeks(d, weeks) {
    const newDate = this.clone(d)
    newDate.setDate(newDate.getDate() + weeks * 7)
    return newDate
  },

  addMonths(d, months) {
    const newDate = this.clone(d)
    newDate.setDate(1) // first
    newDate.setMonth(d.getMonth() + months)
    return newDate
  },

  substractMonths(d, months) {
    const newDate = this.clone(d)
    newDate.setDate(1)
    newDate.setMonth(d.getMonth() - months)
    return newDate
  },

  addYears(d, years) {
    const newDate = this.clone(d)
    newDate.setFullYear(d.getFullYear() + years)
    return newDate
  },

  setDay(d, day) {
    const newDate = this.clone(d)
    newDate.setDate(day)
    return newDate
  },

  setMonth(d, month) {
    const newDate = this.clone(d)
    newDate.setMonth(month)
    return newDate
  },

  setYear(d, year) {
    const newDate = this.clone(d)
    newDate.setFullYear(year)
    return newDate
  },

  setHours(d, hours) {
    const newDate = this.clone(d)
    newDate.setHours(hours)
    return newDate
  },

  setHoursWithoutMinSec(d, hours) {
    const newDate = this.clone(d)
    newDate.setHours(hours, 0, 0)
    return newDate
  },

  setMinutes(d, minutes) {
    const newDate = new Date(d.getTime() + minutes * 60000)
    return newDate
  },

  isBetween(date, a, b) {
    return date >= a && date <= b
  },

  momentIsBetween(date, a, b) {
    return moment(date).isBetween(a, b)
  },

  momentIsAfter(a, b) {
    return moment(b).isAfter(a)
  },

  momentInclusiveIsBetween(date, a, b) {
    return moment(date).isBetween(a, b) || moment(date).isSame(a) || moment(date).isSame(b)
  },

  momentIsSame(a, b) {
    return moment(b).isSame(a)
  },

  momentIsBetweenExclusiveEnd(date, a, b) {
    return moment(date).isBetween(a, b) || moment(date).isSame(a)
  },

  daysBetween(a, b) {
    return Math.floor((a - b) / 86400000)
  },

  areSameDay(a, b) {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    )
  },

  areSameMonth(a, b) {
    return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth()
  },

  isSecondBetweenFirst(a, b) {
    return a.start.getTime() <= b.start.getTime() && a.end.getTime() >= b.end.getTime()
  },

  addMinutes(d, min) {
    const newDate = this.clone(d)
    return moment(newDate)
      .add(min, 'minutes')
      .toDate()
  },

  areSameWeek(a, b) {
    let aParam = a
    let bParam = b
    // check within 7 days
    if (this.daysBetween(aParam, b) < 7) {
      aParam = this.clone(aParam)
      aParam.setDate(aParam.getDate() - aParam.getDay()) // first day of week
      bParam = this.clone(bParam)
      bParam.setDate(bParam.getDate() - bParam.getDay()) // first day of week
      return aParam.getDate() === bParam.getDate()
    }
    return false
  },

  toggleTimeMode(d) {
    const newDate = this.clone(d)
    const hours = newDate.getHours()

    newDate.setHours(hours - (hours > 12 ? -12 : 12))
    return newDate
  },

  formatTime(date, format, delimiter = null) {
    let hours = date.getHours()
    let mins = date.getMinutes().toString()

    if (format === 'ampm') {
      const isAM = hours < 12
      const additional = isAM ? ' AM' : ' PM'

      hours %= 12
      hours = (hours || 12).toString()
      if (mins.length < 2) mins = `0${mins}`

      return delimiter
        ? `${hours}${delimiter}${mins}${additional}`
        : `${hours}:${mins}${additional}`
    }

    hours = hours.toString()
    if (hours.length < 2) hours = `0${hours}`
    if (mins.length < 2) mins = `0${mins}`
    return `${hours}:${mins}`
  },

  prettyFormatDate(date, today = new Date()) {
    let prefix = this.getFullDayOfWeek(date.getDay())
    if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
      if (date.getDate() === today.getDate()) {
        prefix = 'Today'
      } else if (date.getDate() - 1 === today.getDate()) {
        prefix = 'Tomorrow'
      }
    }

    return `${prefix} ${date.getMonth() + 1}/${date.getDate()}/${String(date.getFullYear()).substr(
      2,
    )}`
  },

  correctTodayHours(date = new Date()) {
    const dateNow = new Date()
    return this.areSameDay(date, dateNow) ? dateNow : date
  },

  getDurationInMinutes(start, end) {
    const endTime = moment(end)
    const startTime = moment(start)
    return endTime.diff(startTime, 'minutes')
  },

  convertMinToHours(durationInMinutes) {
    return (durationInMinutes / 60).toFixed(1)
  },

  getWeekRange(date = moment()) {
    return {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    }
  },

  getWeekDays(weekStart) {
    const days = [weekStart]
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate(),
      )
    }
    return days
  },

  formatDateToYYYYMMDD(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
      date.getDate(),
    ).padStart(2, '0')}`
  },

  getPrettyDuration(durationInHours, format = '') {
    // format with empty string means miliseconds
    return moment.duration(durationInHours, format).format('H[h]m[m]')
  },

  doesTimePeriodContainsAnother(innerRange, outsideRange) {
    const isStartDateInRange = moment(innerRange.startISO).isBetween(
      outsideRange.startISO,
      outsideRange.endISO,
    )
    const isEndDateInRange = moment(innerRange.endISO).isBetween(
      outsideRange.startISO,
      outsideRange.endISO,
    )
    return isStartDateInRange && isEndDateInRange
  },

  standardTimeFormat(date) {
    return moment(date).format('hh:mm A')
  },

  fullPrettyDateFormat(dateString) {
    return moment(dateString).format('MMMM Do YYYY, h:mm:ss a')
  },

  doesEntityOccurInside({ startISO, endISO }) {
    const { momentIsAfter, momentIsSame } = this
    return function isBetween({ start, end }) {
      const localStartISO = convertDateToLocalTimezone(startISO)
      const localStart = convertDateToLocalTimezone(start)
      const goodStart =
        momentIsAfter(localStartISO, localStart) || momentIsSame(localStartISO, localStart)

      const localEnd = convertDateToLocalTimezone(end)
      const localEndISO = convertDateToLocalTimezone(endISO)
      const goodEnd = momentIsAfter(localEnd, localEndISO) || momentIsSame(localEnd, localEndISO)

      return goodStart && goodEnd
    }
  },

  getTimeUnitsBetweenDates(earliestDate, latestDate) {
    const start = moment(earliestDate)
    const end = moment(latestDate)

    const diffInMiliseconds = end.diff(start)
    const duration = moment.duration(diffInMiliseconds)

    return {
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      miliseconds: diffInMiliseconds,
    }
  },

  getMonthDifference(currentDate) {
    const diff = moment(currentDate).diff(moment(), 'months', true)
    return Math.abs(Math.ceil(diff))
  },
}
