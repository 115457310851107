import Mousetrap from 'mousetrap'

export const onKeyPress = ({ dismissEntryBox, submit, setDurationPicker }) => {
  Mousetrap.bind('space', ({ target: { id } }) => {
    if (id === 'duration') setDurationPicker()
  })
  Mousetrap.bind('enter', async event => {
    const { id, value } = event.target
    if (id === 'textarea' || id === 'duration') {
      event.preventDefault()
      await submit()
      dismissEntryBox()
      return
    }
    if (id === 'project_selector' && value !== '') {
      await submit()
    }
  })
  Mousetrap.bind('ctrl+enter', () => {
    submit()
  })
  Mousetrap.bind('escape', () => {
    dismissEntryBox()
  })
  Mousetrap.bind('shift+enter', event => {
    event.preventDefault()
  })
}
