import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import {
  EmptyEntryBoxTextContainer,
  EmptyEntryBoxTimeRangeContainer,
  EntryBoxContainer,
  EntryBoxIconContainer,
} from './EntryBoxPreviewStyle'
import timeUtils from '../../../utils/timeUtils'

const getTimeRange = (start, end) => {
  return `${timeUtils.formatTime(start, 'ampm')} - ${timeUtils.formatTime(end, 'ampm')}`
}

const EmptyEntryBoxPreview = ({ end, onCreateTimeEntry, shift, start, tabIndex }) => {
  const onTimeEntryCreate = () => {
    onCreateTimeEntry(start, timeUtils.addMinutes(start, shift))
  }

  return (
    <EntryBoxContainer
      id={`emptyEntryBoxPreview_tabindex_${tabIndex}`}
      tabIndex={tabIndex}
      onKeyPressCapture={onTimeEntryCreate}
      onClick={onTimeEntryCreate}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} sm={5} md={4}>
          <EmptyEntryBoxTimeRangeContainer>
            {getTimeRange(start, end)}
          </EmptyEntryBoxTimeRangeContainer>
        </Grid>
        <Grid item xs={8} sm={7} md={8}>
          <EmptyEntryBoxTextContainer>No time entries</EmptyEntryBoxTextContainer>
        </Grid>
      </Grid>
      <EntryBoxIconContainer>
        <AddCircle style={{ fontSize: 18, color: '#666' }} />
      </EntryBoxIconContainer>
    </EntryBoxContainer>
  )
}

EmptyEntryBoxPreview.propTypes = {
  end: PropTypes.object.isRequired,
  onCreateTimeEntry: PropTypes.func.isRequired,
  shift: PropTypes.number.isRequired,
  start: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
}

export default EmptyEntryBoxPreview
