import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import eventUtils from '../../../../utils/eventUtils'
import { getHeaderTitle } from '../../../../utils/entriesUtils'
import {
  EventContainer,
  EventDuration,
  TimelineViewEventTitle,
  TimelineViewEventHeader,
} from '../TimelineViewEventStyle'
import CustomTooltip from '../../../../components/CustomTooltip'
import { getTooltipDates, getTooltipComment } from '../utils'

class TimelineViewTimeOffEntryEvent extends React.PureComponent {
  containerRef = React.createRef()

  state = {
    containerHeight: 0,
  }

  componentDidMount() {
    if (this.isContainerHeightAvailable()) {
      this.updateContainerHeight()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isContainerHeightAvailable()) {
      if (this.containerRef.current.clientHeight !== prevState.containerHeight) {
        this.updateContainerHeight()
      }
    }
  }

  isContainerHeightAvailable = () => {
    return !!this.containerRef.current
  }

  updateContainerHeight() {
    this.setState({
      containerHeight: this.containerRef.current.clientHeight,
    })
  }

  render() {
    const { timeOffEntry } = this.props
    const { hours, minutes } = eventUtils.getTimeEntryDurationUnits(timeOffEntry)
    const entryHeaderTitle = getHeaderTitle(timeOffEntry, [], this.props.timeOffCodes)
    const tooltipText = (
      <div>
        <h4>{getTooltipDates(timeOffEntry)}</h4>
        <p>{getTooltipComment(timeOffEntry)}</p>
      </div>
    )
    return (
      <CustomTooltip
        title={tooltipText}
        isTimeline
        width="37vw"
        textAlign="left"
        minHeight="80px"
        padding="15px 10px"
        transition={Fade}
      >
        <EventContainer tabIndex={0} ref={this.containerRef}>
          <TimelineViewEventHeader>
            <TimelineViewEventTitle
              id="entry-header-title"
              data-testid={`timeline.calendarTimeOffEntry.${timeOffEntry._id}`}
            >
              {entryHeaderTitle}
            </TimelineViewEventTitle>
            <EventDuration id="entry-duration">{`${hours} h ${minutes} min`}</EventDuration>
          </TimelineViewEventHeader>
        </EventContainer>
      </CustomTooltip>
    )
  }
}

TimelineViewTimeOffEntryEvent.propTypes = {
  timeOffEntry: PropTypes.object.isRequired,
  timeOffCodes: PropTypes.array.isRequired,
}

export default TimelineViewTimeOffEntryEvent
