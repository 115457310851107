import moment from 'moment'

export const shouldShowNotificationNow = reminderSettings => {
  if (!reminderSettings) return false

  const {
    weekDays,
    timeFrom: reminderTimeFrom,
    timeTo: reminderTimeTo,
    periodInMinutes,
    shouldRemind,
  } = reminderSettings

  if (!shouldRemind) return false

  const actualDate = moment()
  const shouldBeShownToday = weekDays[actualDate.format('dddd').toLowerCase()]
  if (!shouldBeShownToday) return false

  const momentDateTimeFrom = moment(reminderTimeFrom)
  const momentDateTimeTo = moment(reminderTimeTo)

  const momentTodayTimeFrom = moment()
    .set('hour', momentDateTimeFrom.hour())
    .set('minute', momentDateTimeFrom.minute())
  const momentTodayTimeTo = moment()
    .set('hour', momentDateTimeTo.hour())
    .set('minute', momentDateTimeTo.minute())

  const elapsedMinutesSinceTimeFrom = actualDate.diff(momentTodayTimeFrom, 'minutes')

  const isInPeriod =
    actualDate.diff(momentTodayTimeFrom, 'minutes') >= 0 &&
    actualDate.diff(momentTodayTimeTo, 'minutes') <= 0
  if (!isInPeriod) return false

  const shouldBeShownThisMinute = elapsedMinutesSinceTimeFrom % periodInMinutes === 0
  if (!shouldBeShownThisMinute) return false

  return true
}
