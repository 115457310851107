import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Checkbox } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import { VALID_PTOS } from '../../../../constants/projects'
import InputAutoSuggestion from './InputAutoSuggestion'
import { ProjectContainer } from '../../ListView/EntryBoxEditStyle'

import * as projectUtils from '../../../../utils/projectUtils'

import { mapStateToProps } from './store'
import eventUtils from '../../../../utils/eventUtils'

const ProjectSelector = ({
  classes,
  projects,
  entry,
  projectError,
  handleFavoriteProjectSelection,
  userSettings,
  mostRecentlyUsedProjectIds,
  onSelectSuggestion,
  handleSetDefaultProject,
  handleClearDefaultProject,
  clearCurrentEditableEntryProject,
  commentTextFieldRef,
  isConnected,
  assignments,
  focusCommentTextField,
  person,
}) => {
  const currentProject = eventUtils.findProjectObject(entry, projects, null)
  const projectObject = eventUtils.findProjectObject(entry, projects, userSettings.defaultProjectId)
  const isProjectPTO = projectUtils.isPTOCodeProject(projectObject)
  const isProjectEmpty = _.isEmpty(projectObject) || _.isEmpty(entry.originalProjectId)
  const hasDefault =
    !!entry.originalProjectId &&
    !!userSettings.defaultProjectId &&
    userSettings.defaultProjectId === entry.originalProjectId
  const defaultButtonText = hasDefault
    ? 'Stop using project as default'
    : 'Use this project as default'
  const validPTOsIds = _.map(VALID_PTOS, pto => pto.repliconId)
  const projectsWithValidTimeOffCodes = _.filter(projects, project => {
    return (
      project.isOpenForTimeEntries &&
      (!projectUtils.isPTOCodeProject(project) || _.includes(validPTOsIds, project.repliconId))
    )
  })

  // if user's position or user's employeeType are Contractor - Expert, only show projects that they are assigned to
  const isExpert =
    _.get(person, 'position') === 'Contractor - Expert' ||
    _.get(person, 'employeeType', '').includes('Contractor - Expert')

  const allSuggestions = _.sortBy(projectsWithValidTimeOffCodes, 'name')
  const expertSuggestions = _.filter(allSuggestions, project => {
    return _.includes(_.map(assignments, 'projectId'), project._id)
  })
  const suggestions = isExpert ? expertSuggestions : allSuggestions

  const isDefaultProjectButtonDisabled = isProjectEmpty || isProjectPTO

  const handleDefaultProjectButtonClick = () => {
    if (hasDefault) {
      handleClearDefaultProject()
      return
    }

    if (!entry.originalProjectId) return

    handleSetDefaultProject(entry.originalProjectId)
  }

  return (
    <ProjectContainer>
      <InputAutoSuggestion
        InputProps={{
          classes: { input: classes.inputAutoSuggestions },
        }}
        endAdornment={
          <Tooltip
            placement="top"
            title={defaultButtonText}
            classes={{ tooltip: classes.tooltipText, popper: classes.popper }}
          >
            {!isDefaultProjectButtonDisabled ? (
              <Checkbox
                classes={{ root: classes.rootCheckbox }}
                color="primary"
                tabIndex="-1"
                onChange={handleDefaultProjectButtonClick}
                checked={hasDefault}
              />
            ) : (
              <div />
            )}
          </Tooltip>
        }
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ className: 'mousetrap', id: 'project_selector' }}
        inputLabelProps={{ classes: { root: classes.labelText } }}
        suggestions={suggestions}
        fieldsToSearch={['projectCode', 'name']}
        name="Project"
        placeholder="Search for a Project"
        onSelectSuggestion={onSelectSuggestion}
        initialValue={_.get(projectObject, 'name', '')}
        currentValue={_.get(currentProject, 'name', '')}
        error={projectError}
        onFavoriteSuggestionSelected={handleFavoriteProjectSelection}
        userSettings={userSettings}
        assignments={assignments}
        mostRecentlyUsedProjectIds={mostRecentlyUsedProjectIds}
        clearCurrentEditableEntryProject={clearCurrentEditableEntryProject}
        commentTextFieldRef={commentTextFieldRef}
        entry={entry}
        isConnected={isConnected}
        focusCommentTextField={focusCommentTextField}
      />
    </ProjectContainer>
  )
}

ProjectSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  entry: PropTypes.object.isRequired,
  projectError: PropTypes.bool.isRequired,
  handleFavoriteProjectSelection: PropTypes.func.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  handleSetDefaultProject: PropTypes.func.isRequired,
  handleClearDefaultProject: PropTypes.func.isRequired,
  clearCurrentEditableEntryProject: PropTypes.func.isRequired,
  commentTextFieldRef: PropTypes.object.isRequired,
  isConnected: PropTypes.bool.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  assignments: PropTypes.array.isRequired,
  focusCommentTextField: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(ProjectSelector)
