import React, { PureComponent } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import timeUtils from '../../../../utils/timeUtils'
import {
  DatePickerWrapper,
  PeriodSwitcherContainer,
  styles,
  DateRange,
  CustomToggle,
  DatePickerContainer,
  ArrowLeft,
  ArrowRight,
} from './style'

class Selector extends PureComponent {
  handleChange = (event, viewSelected) => {
    if (viewSelected === 'PROJECT') return this.props.setProjectView()
    if (viewSelected === 'SELF') return this.props.setSelfView()
    if (viewSelected === 'PARTNER') return this.props.setPartnerView()
  }

  renderCurrentDate = selectedDate => {
    return (
      <>
        <span className="week-day">{timeUtils.getShortDayOfWeek(selectedDate.getDay())} </span>
        <span className="month">{selectedDate.getMonth() + 1}/</span>
        <span className="days">{selectedDate.getDate()}/</span>
        <span className="year">{selectedDate.getFullYear()}</span>
      </>
    )
  }

  renderWeeklySelectedDate(selectedDate) {
    const startDate = timeUtils.getFirstDayOfWeek(selectedDate)
    const endDate = timeUtils.addDays(startDate, 6)
    return (
      <DateRange>
        {this.renderCurrentDate(startDate)} - {this.renderCurrentDate(endDate)}
      </DateRange>
    )
  }

  renderAssignmentTypeSelector = () => {
    const { isDisabledProjectView, isDisabledPartnerView, currentViewSelected } = this.props
    return (
      <PeriodSwitcherContainer>
        <ToggleButtonGroup value={currentViewSelected} onChange={this.handleChange} exclusive>
          <CustomToggle value="SELF">
            <span>Self</span>
          </CustomToggle>
          <CustomToggle value="PROJECT" disabled={isDisabledProjectView}>
            <span>Project</span>
          </CustomToggle>
          <CustomToggle value="PARTNER" disabled={isDisabledPartnerView}>
            <span>Partner</span>
          </CustomToggle>
        </ToggleButtonGroup>
      </PeriodSwitcherContainer>
    )
  }

  renderDatePicker() {
    const { currentWeek, goToNextWeek, goToPreviousWeek, isConnected } = this.props
    const shouldHideLeftArrow = !isConnected
    const shouldHideRightArrow = !isConnected

    return (
      <DatePickerContainer>
        <DatePickerWrapper data-testid="dateSelector">
          <ArrowLeft
            hide={shouldHideLeftArrow}
            onMouseDown={goToPreviousWeek}
            data-testid="dateSelector.previousWeek"
          >
            <ChevronLeftIcon style={{ fontSize: 24 }} />
          </ArrowLeft>
          {this.renderWeeklySelectedDate(currentWeek)}
          <ArrowRight
            hide={shouldHideRightArrow}
            onMouseDown={goToNextWeek}
            data-testid="dateSelector.nextWeek"
          >
            <ChevronRightIcon style={{ fontSize: 24 }} />
          </ArrowRight>
        </DatePickerWrapper>
      </DatePickerContainer>
    )
  }

  render() {
    return (
      <>
        {this.renderAssignmentTypeSelector()}
        {this.renderDatePicker()}
      </>
    )
  }
}
Selector.propTypes = {
  currentWeek: PropTypes.string.isRequired,
  currentViewSelected: PropTypes.string.isRequired,
  isDisabledProjectView: PropTypes.bool.isRequired,
  isDisabledPartnerView: PropTypes.bool.isRequired,
  setSelfView: PropTypes.func.isRequired,
  setProjectView: PropTypes.func.isRequired,
  setPartnerView: PropTypes.func.isRequired,
  goToNextWeek: PropTypes.func.isRequired,
  goToPreviousWeek: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Selector)
