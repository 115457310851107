import gql from 'graphql-tag'

export const GET_TIMESHEETS = gql`
  query($status: String, $startDate: String, $endDate: String, $shouldCreateIfNotExist: Boolean) {
    getTimesheets(
      status: $status
      startDate: $startDate
      endDate: $endDate
      shouldCreateIfNotExist: $shouldCreateIfNotExist
    ) {
      _id
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
    }
  }
`

export const GET_TIMESHEETS_AS_DELEGATE = gql`
  query(
    $status: String
    $startDate: String
    $endDate: String
    $shouldCreateIfNotExist: Boolean
    $delegateId: String!
  ) {
    getTimesheets_asDelegate(
      status: $status
      startDate: $startDate
      endDate: $endDate
      shouldCreateIfNotExist: $shouldCreateIfNotExist
      delegateId: $delegateId
    ) {
      _id
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
    }
  }
`
