import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

export const Container = styled.div`
  width: 100%;
  padding: 15px 0;
  overflow: scroll;
  display: flex;
  flex: 1;
`

export const ContainerGrid = styled(Grid)`
  padding: 15px 0;
  overflow: scroll;
`

export const TableContainer = styled(Grid)`
  flex: 1;
`

export const DayTableWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
`

export const WeekTableWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`
