import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@material-ui/core'
import { ContextMenuContainer } from './style'
import { isAnOperation, isADeleteOperation } from '../../models/entryOperation'

const initialMouseCoordinates = {
  mouseX: null,
  mouseY: null,
}

const ContextMenu = ({ actions, timeEntry, children, isLocked, isPlaceholder }) => {
  const [mouseCoordinates, setMouseCoordinates] = React.useState(initialMouseCoordinates)

  const handleClick = event => {
    event.preventDefault()
    if (isAnOperation(timeEntry) && isADeleteOperation(timeEntry)) return
    setMouseCoordinates({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })
  }

  const getAnchorPosition = () => {
    return mouseCoordinates.mouseY && mouseCoordinates.mouseX
      ? { top: mouseCoordinates.mouseY, left: mouseCoordinates.mouseX }
      : null
  }

  const handleClose = () => {
    setMouseCoordinates(initialMouseCoordinates)
  }

  const handleDeleteEntry = event => {
    event.stopPropagation()
    actions.deleteEntry(timeEntry)
    handleClose()
  }

  const handleDuplicateEntry = event => {
    event.stopPropagation()
    actions.duplicateEntry(timeEntry)
    handleClose()
  }

  if (isPlaceholder) return children

  return (
    <ContextMenuContainer onContextMenu={handleClick} isLocked={isLocked}>
      {children}
      <Menu
        keepMounted
        open={mouseCoordinates.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={getAnchorPosition()}
      >
        <MenuItem onClick={handleDeleteEntry}>Delete</MenuItem>
        <MenuItem onClick={handleDuplicateEntry}>Duplicate</MenuItem>
      </Menu>
    </ContextMenuContainer>
  )
}

ContextMenu.propTypes = {
  actions: PropTypes.object.isRequired,
  timeEntry: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool,
}

ContextMenu.defaultProps = {
  isPlaceholder: false,
}

export default ContextMenu
