import React from 'react'

import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Views, Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import classNames from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import withSizes from 'react-sizes'

import { log } from '../../../utils/logger'
import eventUtils from '../../../utils/eventUtils'
import {
  setEndWorkingDateTime,
  setStartWorkingDateTime,
  isZeroStartEndEvent,
  getDurationInHours,
} from '../../../server/utils/date'
import {
  EXCHANGE_EVENTS_RESOURCE_ID,
  EXCHANGE_EMAILS_RESOURCE_ID,
  FUNNEL_ENTRIES_RESOURCE_ID,
} from '../../../constants/events'
import { isProjectInternal } from '../../../utils/projectUtils'
import TimelineViewTimeOffEntry from './event/TimelineViewTimeOffEntryEvent'
import TimelineViewEvent from './event/TimelineViewEvent'
import TimelineViewTitle from './TimelineViewTitle'
import CalendarEvent from './CalendarEvent'
import TimelineViewEmailEvent from './event/TimelineViewEmailEvent'
import { addKeyDownListener, removeKeyDownListener } from '../../../utils/appUtils'
import timeUtils from '../../../utils/timeUtils'
import ContextMenu from '../../../components/contextMenu'
import { entryCreationKeyDown, entryListKeyDown, onKeyDownEvent } from './shortcuts'
import { isEntryLocked, getIsDroppableEvent } from './utils'

import {
  isAnOperation,
  getOperationStart,
  getOperationEnd,
  isADeleteOperation,
  getEntry,
} from '../../../models/entryOperation'
import { REFERENCE_TYPE } from '../../../constants/timeEntry'
import { breakpoints } from '../../../constants/breakpoints'
import HorizontalSplitter from './HorizontalSplitter'

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const dailyViewResourceMap = [
  { resourceId: FUNNEL_ENTRIES_RESOURCE_ID, resourceTitle: 'Timesheet' },
  { resourceId: EXCHANGE_EVENTS_RESOURCE_ID, resourceTitle: 'Calendar' },
  { resourceId: EXCHANGE_EMAILS_RESOURCE_ID, resourceTitle: 'Email' },
]
const weeklyViewResourceMap = [
  { resourceId: FUNNEL_ENTRIES_RESOURCE_ID, resourceTitle: 'Timesheet' },
]

const slotSettings = {
  true: {
    STEP_IN_MINUTES: 6,
    SLOTS_IN_GROUP: 5,
  },
  false: {
    STEP_IN_MINUTES: 20,
    SLOTS_IN_GROUP: 3,
  },
}

class TimelineView extends React.Component {
  state = {
    selectedEmail: null,
    selectedArea: {},
    basicSelectedSlot: {},
  }

  componentDidMount() {
    addKeyDownListener(this.handleCreateEntry)
  }

  componentWillUnmount() {
    removeKeyDownListener(this._handleOnKeyDownEvent)
    removeKeyDownListener(this.handleCreateEntry)
  }

  move = direction => {
    const { STEP_IN_MINUTES } = this.getSlotSettings()
    const shiftInMinutes = direction === 'down' ? STEP_IN_MINUTES : -STEP_IN_MINUTES
    this.setState({
      selectedArea: {
        start: this.getUpdatedSlotDateTime(shiftInMinutes, this.state.selectedArea.start),
        end: this.getUpdatedSlotDateTime(shiftInMinutes, this.state.selectedArea.end),
      },
      basicSelectedSlot: {
        start: this.getUpdatedSlotDateTime(shiftInMinutes, this.state.basicSelectedSlot.start),
        end: this.getUpdatedSlotDateTime(shiftInMinutes, this.state.basicSelectedSlot.end),
      },
    })
  }

  getUpdatedSlotDateTime = (minutes, slotDateTime) => {
    const dateTime = new Date(slotDateTime)
    dateTime.setMinutes(dateTime.getMinutes() + minutes)
    return dateTime
  }

  accumulateAndMoveDown = () => {
    const { selectedArea, basicSelectedSlot } = this.state
    const { STEP_IN_MINUTES } = this.getSlotSettings()
    const moveDownAboveBasicSelectedSlot =
      selectedArea.start.getTime() < basicSelectedSlot.start.getTime() &&
      selectedArea.end.getTime() === basicSelectedSlot.end.getTime()
    if (moveDownAboveBasicSelectedSlot) {
      selectedArea.start = this.getUpdatedSlotDateTime(STEP_IN_MINUTES, selectedArea.start)
    } else {
      selectedArea.end = this.getUpdatedSlotDateTime(STEP_IN_MINUTES, selectedArea.end)
    }
    this.setState({
      selectedArea,
    })
  }

  accumulateAndMoveUp = () => {
    const { selectedArea, basicSelectedSlot } = this.state
    const { STEP_IN_MINUTES } = this.getSlotSettings()
    const moveUpAboveBasicSelectedSlot =
      selectedArea.start.getTime() <= basicSelectedSlot.start.getTime() &&
      selectedArea.end.getTime() === basicSelectedSlot.end.getTime()
    if (moveUpAboveBasicSelectedSlot) {
      selectedArea.start = this.getUpdatedSlotDateTime(-STEP_IN_MINUTES, selectedArea.start)
    } else {
      selectedArea.end = this.getUpdatedSlotDateTime(-STEP_IN_MINUTES, selectedArea.end)
    }
    this.setState({
      selectedArea,
    })
  }

  getSlotSettings = () => {
    return slotSettings[this.props.isDayViewPeriodSelected]
  }

  deselectSlot = () => {
    // prevent component re rendering if slot already empty
    if (_.isEmpty(this.state.selectedArea)) return
    this.setState({
      selectedArea: {},
      basicSelectedSlot: {},
    })
  }

  selectSlot = selectedArea => {
    this.setState({
      selectedArea,
      basicSelectedSlot: _.assign({}, selectedArea),
    })
  }

  handleCreateEntry = () => {
    return entryCreationKeyDown({
      currentEditableEntry: this.props.currentEditableEntry,
      selectedDayEntries: this.props.selectedDayEntries,
      selectedDate: this.props.selectedDate,
      addCalendarPlaceholderEvent: this.props.addCalendarPlaceholderEvent,
      onEntrySubmit: this.props.onEntrySubmit,
      onEntryUpdate: this.props.onEntryUpdate,
      isDayViewPeriodSelected: this.props.isDayViewPeriodSelected,
      isTimesheetLocked: this.props.isTimesheetLocked,
      handleNoFreeGapModalOpen: this.props.handleNoFreeGapModalOpen,
    })
  }

  _handleOnKeyDownEvent = event => {
    return onKeyDownEvent({
      event,
      accumulateAndMoveDown: this.accumulateAndMoveDown,
      accumulateAndMoveUp: this.accumulateAndMoveUp,
      move: this.move,
      onTimeEntrySlotClick: this.props.onTimeEntrySlotClick,
      deselectSlot: this.deselectSlot,
      selectedArea: this.state.selectedArea,
    })
  }

  handleEntryKeyDown = entry => event => {
    entryListKeyDown({
      event,
      onEntryDelete: this.props.onEntryDelete,
      entry,
    })
  }

  _onNavigate = () => log('navigate')

  isLockedEvent = event => {
    if (eventUtils.isTimeOffEvent(event) && !this.props.isConnected) return true
    if (eventUtils.isCalendarOrEmailEvent(event))
      return _.hasIn(this.props.usedExchangeEvents, event.id)
    if (eventUtils.isFunnelEntry(event)) return this.props.isTimesheetLocked
    return false
  }

  eventPropGetter = event => {
    const isOperation = isAnOperation(event)
    const isTimeOffEvent = eventUtils.isTimeOffEvent(event)

    const eventType = isOperation ? 'offline' : event.source || event.eventSource || 'other'
    const projectObject = _.find(this.props.projects, {
      _id: isOperation ? getEntry(event).originalProjectId : event.originalProjectId,
    })

    // eslint-disable-next-line no-nested-ternary
    const isSelected = isTimeOffEvent
      ? false
      : isOperation
      ? _.get(event, 'data.sourceId') === _.get(this.props.currentEditableEntry, 'data.sourceId')
      : _.get(event, '_id') === _.get(this.props.currentEditableEntry, '_id')

    const classes = classNames({
      'is-deleted-entry': Boolean(isOperation && isADeleteOperation(event)),
      'rbc-event-internal': projectObject && isProjectInternal(projectObject),
      locked: this.isLockedEvent(event),
      'rbc-event-selected': isSelected,
      'rbc-event-pto': isTimeOffEvent,
      'email-event': eventUtils.isEmailEvent(event),
      'has-unassigned-project': eventUtils.isUnassignedEntryProject(
        isOperation ? event.data : event,
      ),
      'selected-project': Boolean(isOperation ? event.data.type : event.type),
      'is-placeholder': eventUtils.isPlaceholderEntry(isOperation ? event.data : event),
    })

    const className = `rbc-event-${eventType.toLowerCase()} ${classes}`

    return { className }
  }

  slotPropGetter = slotStart => {
    const formattedTime = timeUtils
      .formatTime(slotStart, 'ampm', '-')
      .split(' ')
      .join('')
    const { selectedArea } = this.state
    if (_.isEmpty(selectedArea)) {
      return {
        className: classNames({ [`${formattedTime}`]: true }),
      }
    }
    const { STEP_IN_MINUTES } = this.getSlotSettings()
    const slotEnd = timeUtils.setMinutes(slotStart, slotStart.getMinutes() + STEP_IN_MINUTES)
    const isSlotBetweenSelected = timeUtils.isSecondBetweenFirst(selectedArea, {
      start: slotStart,
      end: slotEnd,
    })
    if (!isSlotBetweenSelected) return
    const className = classNames({
      'selected-slot-start': selectedArea.start.getTime() === slotStart.getTime(),
      'selected-slot-middle': isSlotBetweenSelected,
      'selected-slot-end': selectedArea.end.getTime() === slotEnd.getTime(),
    })
    return { className }
  }

  onEventDrop = async args => {
    if (eventUtils.isTimeOffEvent(_.get(args, 'event'))) return false

    const isDroppableEvent = getIsDroppableEvent(args, this.props.timesheets)

    if (!isDroppableEvent) {
      this.props.showModal({
        title: 'MOVING ENTRIES',
        message: 'Entry can not be moved',
      })
      return
    }

    if (eventUtils.isEmailEvent(args.event)) {
      if (_.isNil(this.state.selectedEmail)) return
      this.props.onTimelineEventDrop(
        {
          ...args,
          event: {
            ...this.state.selectedEmail,
            referenceType: REFERENCE_TYPE.MAIL,
          },
        },
        this.props.timesheets,
      )
    } else {
      this.props.onTimelineEventDrop(
        {
          ...args,
          event: {
            ...args.event,
            referenceType: REFERENCE_TYPE.CALENDAR,
          },
        },
        this.props.timesheets,
      )
    }
  }

  resizeEvent = args => {
    if (eventUtils.isTimeOffEvent(_.get(args, 'event'))) return
    if (isEntryLocked(args.event, this.props.timesheets)) return
    this.props.onTimelineEventResize(args)
  }

  onEventSelected = selectedEntryEvent => () => {
    if (eventUtils.isTimeOffEvent(selectedEntryEvent)) return false
    this.deselectSlot()
    this.props.clearCurrentEditableEntry()
    this.props.onTimeEntryEventClick(selectedEntryEvent)
  }

  onEventDrag = event => {
    if (isEntryLocked(event, this.props.timesheets)) return false
    if (eventUtils.isEmailEvent(event)) {
      return !_.isNil(this.state.selectedEmail)
    }
    return true
  }

  getStartDateTime = event => {
    if (event.isFullDayEvent && isZeroStartEndEvent(event.start, event.end))
      return new Date(setStartWorkingDateTime(event.start))
    if (isAnOperation(event)) return getOperationStart(event)
    return event.start
  }

  getEndDateTime = event => {
    if (event.isFullDayEvent && isZeroStartEndEvent(event.start, event.end))
      return new Date(setEndWorkingDateTime(event.end))
    if (isAnOperation(event)) return getOperationEnd(event)
    return event.end
  }

  renderCalendarEvent = event => {
    return (
      <CalendarEvent
        event={event}
        projects={this.props.projects}
        handleCreateEntry={projectId => {
          this.props.addCalendarPlaceholderEvent({
            start: event.start.toISOString(),
            end: event.end.toISOString(),
            originalDurationInHours: getDurationInHours(
              event.start.toISOString(),
              event.end.toISOString(),
            ),
            comment: event.subject,
            projectId,
            referenceId: event.id,
            referenceType: REFERENCE_TYPE.CALENDAR,
          })
        }}
        setCurrentSelectedEvent={this.props.setCurrentSelectedEvent}
      />
    )
  }

  renderEmail = event => {
    const eventWithReference = { ...event, referenceType: REFERENCE_TYPE.MAIL }
    return (
      <TimelineViewEmailEvent
        event={eventWithReference}
        handleSetPageViewList={this.props.handleSetPageViewList}
        setEmail={this.setEmail}
        setCurrentSelectedEvent={this.props.setCurrentSelectedEvent}
        isLockedEvent={this.isLockedEvent}
      />
    )
  }

  renderTimeOffEntry = timeOffEntry => {
    return (
      <TimelineViewTimeOffEntry
        timeOffEntry={timeOffEntry}
        timeOffCodes={this.props.timeOffCodes}
      />
    )
  }

  renderTimeEntry = entryOrOperation => {
    return (
      <ContextMenu
        actions={this.props.getContextMenuActions()}
        timeEntry={entryOrOperation}
        isLocked={this.isLockedEvent(entryOrOperation)}
        isPlaceholder={eventUtils.isPlaceholderEntry(entryOrOperation)}
      >
        <TimelineViewEvent
          entry={entryOrOperation}
          projects={this.props.projects}
          timeOffCodes={this.props.timeOffCodes}
          handleEntryKeyDown={this.handleEntryKeyDown(entryOrOperation)}
          onDoubleClick={this.onEventSelected(entryOrOperation)}
          selectedDate={this.props.selectedDate}
        />
      </ContextMenu>
    )
  }

  renderTimelineViewTitle = ({ event: entry }) => {
    const isThisEntryLocked =
      (isEntryLocked(entry, this.props.timesheets) && !this.props.isTimesheetLocked) ||
      (eventUtils.isTimeOffEvent(entry) && !this.props.isConnected)

    return (
      <ContextMenu
        isLocked={isThisEntryLocked}
        actions={this.props.getContextMenuActions()}
        timeEntry={entry}
      >
        <TimelineViewTitle
          entry={entry}
          projects={this.props.projects}
          timeOffCodes={this.props.timeOffCodes}
          handleEntryKeyDown={this.handleEntryKeyDown(entry)}
          onDoubleClick={this.onEventSelected(entry)}
        />
      </ContextMenu>
    )
  }

  setEmail = email => {
    if (this.state.selectedEmail !== email) {
      this.setState({ selectedEmail: email })
    }
  }

  renderTimelineViewEvent = ({ event }) => {
    if (eventUtils.isCalendarEvent(event)) return this.renderCalendarEvent(event)
    if (eventUtils.isEmailEvent(event)) return this.renderEmail(event)
    if (eventUtils.isTimeOffEvent(event)) return this.renderTimeOffEntry(event)

    return this.renderTimeEntry(event)
  }

  onSlotSelected = slotInfo => {
    if (isEntryLocked({ startDate: slotInfo.start }, this.props.timesheets)) return

    if (_.isEmpty(this.props.currentEditableEntry) && slotInfo.action === 'click') {
      if (slotInfo.resourceId === FUNNEL_ENTRIES_RESOURCE_ID) {
        this.selectSlot(slotInfo)
        this.props.clearCurrentEditableEntry()
      } else {
        this.deselectSlot()
      }
    }

    if (slotInfo.action === 'doubleClick' || slotInfo.action === 'select') {
      this.props.onTimeEntrySlotClick(slotInfo)
      this.deselectSlot()
    }
    return false
  }

  onSelecting = range => !isEntryLocked({ startDate: range.start }, this.props.timesheets)

  getCalendarAndExchangeEvents = () => {
    const timeEntriesEvents = _.concat(this.props.timeEntries, this.props.unhandledEntries).filter(
      entity => entity._id !== _.get(this.props.currentEditableEntry, '_id'),
    )

    const entryOperationEvents = this.props.localEntries
      .map(operation => {
        return {
          ...operation,
          resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
        }
      })
      .filter(
        entryOperation =>
          _.get(entryOperation, 'data.sourceId') !==
          _.get(this.props.currentEditableEntry, 'data.sourceId'),
      )

    const transformedEmails = eventUtils.groupEmailsForTimeline(this.props.exchangeEmails)
    const shouldDisplayCurrentEntry =
      (this.props.isTimesheetSubmitted && this.props.currentEditableEntry._id === 'placeholder') ||
      (!this.props.isDayViewPeriodSelected &&
        this.props.currentEditableEntry.hideOnWeekPeriod === true) ||
      _.isEqual(this.props.currentEditableEntry, this.props.initialCurrentEditableEntry)
        ? {}
        : { ...this.props.currentEditableEntry, resourceId: FUNNEL_ENTRIES_RESOURCE_ID }

    return _.concat(
      timeEntriesEvents,
      entryOperationEvents,
      shouldDisplayCurrentEntry,
      this.props.exchangeEvents,
      transformedEmails,
      this.props.timeOffEntries,
    )
  }

  renderTimeline() {
    const resourceMap = this.props.isDayViewPeriodSelected
      ? dailyViewResourceMap
      : weeklyViewResourceMap
    const { SLOTS_IN_GROUP, STEP_IN_MINUTES } = this.getSlotSettings()
    return (
      <DragAndDropCalendar
        onSelecting={this.onSelecting}
        localizer={localizer}
        events={this.getCalendarAndExchangeEvents()}
        tooltipAccessor={() => null}
        startAccessor={this.getStartDateTime}
        scrollToTime={new Date(1970, 1, 1, 8)}
        endAccessor={this.getEndDateTime}
        timeslots={SLOTS_IN_GROUP}
        step={STEP_IN_MINUTES}
        defaultView={this.props.isDayViewPeriodSelected ? Views.DAY : Views.WEEK}
        view={this.props.isDayViewPeriodSelected ? Views.DAY : Views.WEEK}
        onView={() => {}}
        defaultDate={new Date()}
        date={this.props.selectedDate}
        onNavigate={this._onNavigate}
        toolbar={false}
        header={false}
        resources={resourceMap}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        onSelectSlot={this.onSlotSelected}
        selectable={!this.props.isTimesheetLocked}
        draggableAccessor={this.onEventDrag}
        onEventDrop={this.onEventDrop}
        resizable
        onEventResize={this.resizeEvent}
        components={{
          day: { event: this.renderTimelineViewEvent },
          week: { event: this.renderTimelineViewTitle },
        }}
        eventPropGetter={this.eventPropGetter}
        slotPropGetter={this.slotPropGetter}
        showMultiDayTimes
      />
    )
  }

  render() {
    const thirdColumn = this.props.isEmailSelected ? 'with-emails' : 'with-calendar'
    const viewClass = this.props.isDayViewPeriodSelected ? 'daily-view' : 'weekly-view'
    const className = `timeline ${thirdColumn} ${viewClass}`
    const height = this.props.show ? '100%' : '0%'

    if (this.props.isMobile && this.props.isDayViewPeriodSelected) {
      return (
        <HorizontalSplitter>
          <div id="timeline" data-testid="calendar.timeline" className={className}>
            {this.renderTimeline()}
          </div>
          {this.props.children}
        </HorizontalSplitter>
      )
    }

    return (
      <div id="timeline" data-testid="calendar.timeline" className={className} style={{ height }}>
        {this.renderTimeline()}
        <Hidden smDown>{this.props.children}</Hidden>
      </div>
    )
  }
}

TimelineView.propTypes = {
  timeEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  timeOffEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  unhandledEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  exchangeEvents: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  exchangeEmails: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  projects: PropTypes.array.isRequired,
  timeOffCodes: PropTypes.array.isRequired,
  usedExchangeEvents: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onTimeEntrySlotClick: PropTypes.func.isRequired,
  handleSetPageViewList: PropTypes.func.isRequired,
  onTimeEntryEventClick: PropTypes.func.isRequired,
  onTimelineEventResize: PropTypes.func.isRequired,
  onTimelineEventDrop: PropTypes.func.isRequired,
  isTimesheetLocked: PropTypes.bool.isRequired,
  selectedDate: PropTypes.object.isRequired,
  clearCurrentEditableEntry: PropTypes.func.isRequired,
  isEmailSelected: PropTypes.bool.isRequired,
  currentEditableEntry: PropTypes.object,
  getContextMenuActions: PropTypes.func.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  onEntrySubmit: PropTypes.func.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  selectedDayEntries: PropTypes.array.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  onEntryDelete: PropTypes.func.isRequired,
  timesheets: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
  handleNoFreeGapModalOpen: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
  initialCurrentEditableEntry: PropTypes.object,
  setCurrentSelectedEvent: PropTypes.func.isRequired,
}

TimelineView.defaultProps = {
  currentEditableEntry: null,
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakpoints.md,
})

export default withSizes(mapSizesToProps)(TimelineView)
