import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import {
  EntryBoxDurationContainer,
  EntryBoxProjectContainer,
  EntryBoxTimeRangeContainer,
  EntryBoxCommentContainer,
  ColoredEntryBoxContainer,
  EntryBoxPTOTitle,
  BillableStatusContainer,
} from './EntryBoxPreviewStyle'
import CustomTooltip from '../../../components/CustomTooltip'
import timeUtils from '../../../utils/timeUtils'
import { timesheet } from '../../../constants/billingOptions'
import eventUtils from '../../../utils/eventUtils'
import { isProjectInternal } from '../../../utils/projectUtils'
import { isAnOperation, isADeleteOperation } from '../../../models/entryOperation'
import { getTooltipTitle } from '../TimelineView/utils'

const getTimeRange = (start, end) =>
  `${timeUtils.formatTime(start, 'ampm')} - ${timeUtils.formatTime(end, 'ampm')}`

const getBillableStatus = entry =>
  entry.billable === timesheet.BILLABLE ? 'Billable' : 'Not Billable'

const getEntryTypeString = (entryOrAction, project) => {
  const entry = isAnOperation(entryOrAction) ? entryOrAction.data : entryOrAction
  const hasProjectSelected = entry.type
  if (isADeleteOperation(entryOrAction)) return 'deleted'
  if (eventUtils.isPlaceholderEntry(entry) && !hasProjectSelected) return 'placeholder'
  if (eventUtils.isUnassignedEntryProject(entry)) return 'unassigned'
  if (eventUtils.isTimeOffEvent(entry)) return 'timeOff'
  if (isProjectInternal(project)) return 'internal'
  return 'default'
}

const EntryBoxPreview = ({
  entry,
  onSelectEntryToEdit,
  tabIndex,
  projects,
  handleKeyDown,
  isLocked,
  isEntryBeingEdited,
}) => {
  const onClickOnEditIcon = useCallback(() => {
    onSelectEntryToEdit(entry)
  }, [entry, onSelectEntryToEdit])

  const project = eventUtils.findProjectObject(entry, projects)
  const isPTO = eventUtils.isTimeOffEvent(entry)
  const { hours, minutes } = eventUtils.getTimeEntryDurationUnits(entry)
  const entryToUse = isAnOperation(entry) ? entry.data : entry
  const isPlaceholder = eventUtils.isPlaceholderEntry(entryToUse)

  return (
    <CustomTooltip textAlign="left" title={getTooltipTitle(entryToUse)}>
      <ColoredEntryBoxContainer
        tabIndex={tabIndex}
        id={`entryBoxPreview_tabindex_${tabIndex}`}
        onKeyDown={event => handleKeyDown(event, entry)}
        entryType={getEntryTypeString(entry, project)}
        isOffline={isAnOperation(entry)}
        onDoubleClick={onClickOnEditIcon}
        className={isLocked ? 'locked' : ''}
        isEntryBeingEdited={isEntryBeingEdited}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} sm={5} md={4}>
            <EntryBoxTimeRangeContainer>
              {getTimeRange(entryToUse.start, entryToUse.end)}
            </EntryBoxTimeRangeContainer>
            <EntryBoxDurationContainer>{`${hours} h ${minutes} min`}</EntryBoxDurationContainer>
            {isPTO && <EntryBoxPTOTitle>PTO</EntryBoxPTOTitle>}
          </Grid>
          <Grid item xs={8} sm={7} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <EntryBoxProjectContainer isPlaceholder={!project.name}>
                  {project.name || 'Specify a project'}
                </EntryBoxProjectContainer>
              </Grid>
              <Grid item xs={12}>
                <BillableStatusContainer>{getBillableStatus(entryToUse)}</BillableStatusContainer>
              </Grid>
              <Grid item xs={12}>
                <EntryBoxCommentContainer isPlaceholder={!entryToUse.originalComment}>
                  <span>
                    {!isPlaceholder
                      ? entryToUse.originalComment
                      : entryToUse.originalComment || 'Add an entry here by typing a comment below'}
                  </span>
                </EntryBoxCommentContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ColoredEntryBoxContainer>
    </CustomTooltip>
  )
}
EntryBoxPreview.propTypes = {
  entry: PropTypes.object.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  projects: PropTypes.array.isRequired,
  onSelectEntryToEdit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isEntryBeingEdited: PropTypes.bool.isRequired,
}

export default memo(EntryBoxPreview)
