import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import EmailIcon from '@material-ui/icons/Email'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { StyledGrid, PermissionText } from '../styles'

const DelegateAccessProfileRow = props => {
  const { _id, fromFullName, permission } = props.delegateAccess
  return (
    <MenuItem
      onClick={() => {
        props.handleSelectedDelegateId(_id)
        props.anchorMenuClose()
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={1} md={1}>
          <input
            checked={_id === props.selectedDelegateId}
            readOnly
            name="delegateId"
            type="radio"
            value={_id}
          />
        </Grid>
        <Grid item xs={11} md={11}>
          <StyledGrid container spacing={0}>
            <Grid item xs={7} md={7}>
              {fromFullName}
            </Grid>
            <Grid item xs={1} md={1}>
              <EmailIcon color="disabled" fontSize="small" />
            </Grid>
            <Grid item xs={1} md={1}>
              <CalendarTodayIcon color="primary" fontSize="small" />
            </Grid>
            <Grid item xs={3} md={3}>
              <PermissionText>{permission}</PermissionText>
            </Grid>
          </StyledGrid>
        </Grid>
      </Grid>
    </MenuItem>
  )
}

DelegateAccessProfileRow.propTypes = {
  delegateAccess: PropTypes.object.isRequired,
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func,
  anchorMenuClose: PropTypes.func,
}

export default DelegateAccessProfileRow
