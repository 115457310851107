import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import Mousetrap from 'mousetrap'
import history from '../../utils/history'
import { AuthPageContainer, DraggableContainer, WelcomeText } from '../styles'

import {
  InitialSyncContainer,
  SyncingElement,
  SyncElementIcon,
  SyncNavigation,
  FailedSyncNavigation,
  styles,
} from './styles'
import ContinueWithoutSync from './ContinueWithoutSync'
import { mapStateToProps } from './store'

class WelcomeScreen extends React.Component {
  componentDidMount = () => {
    this.props.startInitialSync()
    Mousetrap.bind('enter', () => {
      if (!this.props.initialSync.done || !this.hasSyncFailed()) {
        this.props.setHasUserGotStarted(true)
      }
    })
  }

  componentWillUnmount = () => {
    Mousetrap.reset()
  }

  renderFirstPassedIcon = item => {
    if (item.inProgress) {
      return <CircularProgress size={24} className={this.props.classes.syncProgress} />
    }

    if (item.done) {
      return <CheckIcon fontSize="large" className={this.props.classes.syncIconDone} />
    }

    if (item.error) {
      return <ClearIcon fontSize="large" className={this.props.classes.syncIconError} />
    }

    return null
  }

  renderSyncStatusItem = itemKey => {
    const elementKeySyncStatus = `element-${itemKey}-sync-status`
    const item = this.props.initialSync.syncItems[itemKey]

    return (
      <SyncingElement key={itemKey}>
        <SyncElementIcon>{this.renderFirstPassedIcon(item)}</SyncElementIcon>
        <div id={elementKeySyncStatus}>{item.text}</div>
      </SyncingElement>
    )
  }

  renderSyncStatus = () => {
    const newSyncItems = { ...this.props.initialSync.syncItems }

    return (
      <InitialSyncContainer>
        {Object.keys(newSyncItems)
          .sort((a, b) => {
            if (a === 'events') return -1
            if (b === 'events') return 1
            if (a === 'timehseets') return 1
            if (b === 'timesheets') return -1
            return 1
          })
          .map(this.renderSyncStatusItem)}
      </InitialSyncContainer>
    )
  }

  handleContinueWithoutSync = () => {
    this.props.sendErrorMessage(
      `User ${this.props.userInfo.fullName} is having issues with the initial sync`,
    )
    this.props.setHasUserGotStarted(true)
  }

  hasSyncFailed = () => _.some(this.props.initialSync.syncItems, item => item.error)

  renderContinueWithoutSyncSection = () => {
    return (
      <ContinueWithoutSync
        initialSync={this.props.initialSync}
        handleContinueWithoutSync={this.handleContinueWithoutSync}
      />
    )
  }

  handleGetStarted = () => {
    history.push('/')
    this.props.setHasUserGotStarted()
  }

  renderSyncButtons = () => {
    if (!this.props.initialSync.done || !this.hasSyncFailed()) {
      return (
        <Button
          id="get-started"
          variant="contained"
          color="primary"
          size="large"
          disabled={this.props.initialSync.inProgress}
          onClick={this.handleGetStarted}
          data-testid="welcomePage.getStartedButton"
        >
          GET STARTED
        </Button>
      )
    }
    return (
      <FailedSyncNavigation>
        {this.props.numberOfInitialSyncRetries > 1 && this.renderContinueWithoutSyncSection()}
        <div className={this.props.classes.buttonContainer}>
          <Button
            id="try-again"
            variant="contained"
            color="primary"
            style={{
              display: 'block',
              margin: 'auto',
            }}
            size="large"
            onClick={this.props.handleClickRetry}
          >
            TRY AGAIN
          </Button>
        </div>
      </FailedSyncNavigation>
    )
  }

  render() {
    return (
      <DraggableContainer>
        <AuthPageContainer>
          <WelcomeText>
            <div>
              <h1>Welcome to Glimpse</h1>
            </div>
            <div>
              <h2 id="sync-message">
                Please wait while we sync your calendar, sent emails and timesheets
              </h2>
            </div>
          </WelcomeText>
          {this.renderSyncStatus()}
          <SyncNavigation>{this.renderSyncButtons()}</SyncNavigation>
        </AuthPageContainer>
      </DraggableContainer>
    )
  }
}

WelcomeScreen.propTypes = {
  userInfo: PropTypes.object.isRequired,
  setHasUserGotStarted: PropTypes.func.isRequired,
  handleClickRetry: PropTypes.func.isRequired,
  initialSync: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  numberOfInitialSyncRetries: PropTypes.number.isRequired,
  sendErrorMessage: PropTypes.func.isRequired,
  startInitialSync: PropTypes.func.isRequired,
}

export default compose(connect(mapStateToProps), withStyles(styles))(WelcomeScreen)
