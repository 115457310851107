import _ from 'lodash'

import config from '../../config'

// Entities
import * as timesheetService from '../../entities/timesheet/service'
import * as timeEntryService from '../../entities/timeEntry/service'
import * as timeOffService from '../../entities/timeOffEntry/service'
import * as projectsService from '../../entities/project/service'
import * as timeOffCodeService from '../../entities/timeOffCode/service'
import * as ReminderSettingsService from '../../entities/reminderSettings/service'
import { getDelegateAccessMine } from '../../entities/delegateAccess/service'
import * as userSettingsService from '../../entities/userSettings/service'
import * as eventService from '../../entities/outlook/events/service'
import * as messageService from '../../entities/outlook/messages/service'
import * as assignmentService from '../../entities/assignment/service'

// Models
import Timesheet from '../../server/models/timesheet'
import TimeEntry from '../../server/models/timeEntry'
import EntryAction from '../../server/models/entryAction'
import TimeOffEntry from '../../server/models/timeOffEntry'
import Project from '../../server/models/project'
import TimeOffCode from '../../server/models/timeOffCode'
import Email from '../../server/models/email'
import Event from '../../server/models/event'
import Assignment from '../../server/models/assignment'

// Utils
import eventUtils from '../../utils/eventUtils'
import { log, logErrorMessage } from '../../utils/logger'
import { getDiff } from '../../utils/diff'
import { getLastUsedProjectIds } from '../../server/utils/projects'
import {
  convertTimesheetToLocalDate,
  addEventDataToEntries,
  addEventDataToPTO,
} from '../../server/funnel/funnelUtils'
import { convertDateToLocalTimezone, getDefaultSyncDateRangeISOs } from '../../server/utils/date'

import { initialReminderSettings } from '../../App/constants'

const { IS_DELEGATE_ENABLED } = config

export const syncTimesheets = async ({
  selectedDelegateId,
  getUIRefreshTimePeriod,
  handleAppState,
  client,
}) => {
  try {
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod()

    const funnelTimesheets = await timesheetService.fetchTimesheets({
      client,
      onlyApproved: true,
      selectedDelegateId,
    })

    const funnelTimesheetsUI = await timesheetService.fetchTimesheets({
      client,
      startDate: startISOUI,
      endDate: endISOUI,
      shouldCreateIfNotExist: true,
      selectedDelegateId,
    })
    const allFunnelTimesheets = _.uniqBy(
      [...funnelTimesheets, ...funnelTimesheetsUI],
      timesheet => timesheet._id,
    )

    const timesheets = convertTimesheetToLocalDate(allFunnelTimesheets)

    const localTimesheets = await Timesheet.where('startDate')
      .between(
        convertDateToLocalTimezone(startISOUI),
        convertDateToLocalTimezone(endISOUI),
        true,
        true,
      )
      .or('approvalStatus')
      .equals('Approved')
      .toArray()

    const { create, update, remove, hasChanged } = getDiff({
      oldData: localTimesheets,
      newData: timesheets,
      uniqueId: '_id',
    })
    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)
    log(`ES → LS → Timesheets → C: ${sC} U: ${sU} D: ${sR}`)
    if (hasChanged) {
      handleAppState({ timesheets })

      log(`LS → UI → Timesheet → ${_.size(timesheets)}`)
      // THE SYNCING LOGIC IS TO COMPLEX, HAVE TO DO THIS
      const idsToDelete = [...remove, ...create].map(t => t._id)
      if (idsToDelete.length !== 0) await Timesheet.bulkDelete(idsToDelete)
      if (sC !== 0) await Timesheet.bulkAdd(create)
      if (sU !== 0) await Timesheet.bulkPut(update)
    } else log(`LS → UI → Timesheet → X`)
    return hasChanged
  } catch (e) {
    logErrorMessage(e.message, 'app/index.js > syncTimesheets > ')
  }
}

export const syncTimeEntries = async ({
  selectedDelegateId,
  getUIRefreshTimePeriod,
  handleAppState,
  userSettings,
  handleUpdateTimesheetData,
  client,
}) => {
  try {
    const { startISO, endISO } = getDefaultSyncDateRangeISOs()
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod()

    const localTimeEntries = await TimeEntry.where('originalEntryDate')
      .between(convertDateToLocalTimezone(startISO), convertDateToLocalTimezone(endISO), true, true)
      .or('originalEntryDate')
      .between(
        convertDateToLocalTimezone(startISOUI),
        convertDateToLocalTimezone(endISOUI),
        true,
        true,
      )
      .toArray()

    const funnelTimeEntries = await timeEntryService.fetchTimeEntriesInDateRange({
      client,
      startISO,
      endISO,
      selectedDelegateId,
    })
    const funnelTimeEntriesUI = await timeEntryService.fetchTimeEntriesInDateRange({
      client,
      startISO: startISOUI,
      endISO: endISOUI,
      selectedDelegateId,
    })
    const allFunnelEntries = _.uniqBy(
      [...funnelTimeEntries, ...funnelTimeEntriesUI],
      funnelEntry => funnelEntry._id,
    )

    const adoptedFunnelEntries = addEventDataToEntries(allFunnelEntries)
    const { create, update, remove, hasChanged } = getDiff({
      oldData: localTimeEntries,
      newData: adoptedFunnelEntries,
      uniqueId: '_id',
    })

    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)
    log(`ES → LS → TimeEntry → C: ${sC} U: ${sU} D: ${sR}`)
    if (hasChanged) {
      const timeEntriesToState = adoptedFunnelEntries.filter(f => {
        return f.start > startISOUI && f.start < endISOUI
      })
      const localEntries = await EntryAction.toArray()
      handleUpdateTimesheetData({
        timeEntries: timeEntriesToState,
        localEntries,
      })

      const mostRecentlyUsedProjectIds = getLastUsedProjectIds(
        allFunnelEntries,
        3,
        _.get(userSettings, 'favoriteProjectIds', []),
      )
      handleAppState({
        mostRecentlyUsedProjectIds,
        timeEntries: eventUtils.getClearedEntriesByLocalEntries(localEntries, timeEntriesToState),
      })
      log(`LS → UI → MostRecentlyUsedProjectIds → ${_.size(mostRecentlyUsedProjectIds)}`)
      log(`LS → UI → TimeEntries → ${_.size(timeEntriesToState)}`)

      if (sR !== 0) await TimeEntry.bulkDelete(remove.map(e => e._id))
      if (sU !== 0) await TimeEntry.bulkPut(update)
      if (sC !== 0) await TimeEntry.bulkAdd(create)
    } else {
      log(`LS → UI → TimeEntries → X`)
    }
    return hasChanged
  } catch (e) {
    logErrorMessage(e.message, 'app/index.js > syncTimeEntries > ')
  }
}

export const syncTimeOffEntries = async ({
  selectedDelegateId,
  getUIRefreshTimePeriod,
  handleAppState,
  client,
}) => {
  try {
    const { startISO, endISO } = getDefaultSyncDateRangeISOs()
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod(true)

    const localTimeOffEntries = await TimeOffEntry.where('entryDate')
      .between(convertDateToLocalTimezone(startISO), convertDateToLocalTimezone(endISO), true, true)
      .or('entryDate')
      .between(
        convertDateToLocalTimezone(startISOUI),
        convertDateToLocalTimezone(endISOUI),
        true,
        true,
      )
      .toArray()

    const funnelTimeOffEntries = await timeOffService.fetchTimeOffEntriesInDateRange({
      client,
      startISO,
      endISO,
      selectedDelegateId,
    })

    const funnelTimeOffEntriesUI = await timeOffService.fetchTimeOffEntriesInDateRange({
      client,
      startISO: startISOUI,
      endISO: endISOUI,
      selectedDelegateId,
    })

    const allFunnelTimeOffEntries = _.uniqBy(
      [...funnelTimeOffEntries, ...funnelTimeOffEntriesUI],
      funnelTimeOffEntry => funnelTimeOffEntry._id,
    )

    const adoptedFunnelTimeOffEntries = addEventDataToPTO(allFunnelTimeOffEntries)
    const { create, update, remove, hasChanged } = getDiff({
      oldData: localTimeOffEntries,
      newData: adoptedFunnelTimeOffEntries,
      uniqueId: '_id',
    })
    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)
    log(`ES → LS → TimeOffEntry → C: ${sC} U: ${sU} D: ${sR}`)
    if (hasChanged) {
      handleAppState({
        timeOffEntries: eventUtils.parseEventsDates(adoptedFunnelTimeOffEntries),
      })
      log(`ES → UI → TimeOffEntry → ${_.size(adoptedFunnelTimeOffEntries)}`)

      if (sR !== 0) await TimeOffEntry.bulkDelete(remove.map(e => e._id))
      if (sU !== 0) await TimeOffEntry.bulkPut(update)
      if (sC !== 0) await TimeOffEntry.bulkAdd(create)
    } else log(`LS → UI → TimeOffEntry → X`)

    return hasChanged
  } catch (e) {
    logErrorMessage(e.message, 'app/index.js > syncTimeOffEntries > ')
  }
}

export const syncProjects = async ({ handleAppState, client }) => {
  try {
    const localProjects = await Project.toArray()
    const funnelProjects = await projectsService.getProjects(client)

    const { create, update, remove, hasChanged } = getDiff({
      oldData: localProjects,
      newData: funnelProjects,
      uniqueId: '_id',
    })
    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)
    log(`ES → LS → Project → C: ${sC} U: ${sU} D: ${sR}`)
    if (hasChanged) {
      handleAppState({
        projects: funnelProjects,
      })
      log(`LS → UI → Project → ${_.size(funnelProjects)}`)

      if (sR !== 0) await Project.bulkDelete(remove.map(p => p._id))
      if (sU !== 0) await Project.bulkPut(update)
      if (sC !== 0) await Project.bulkAdd(create)
    } else log(`LS → UI → Project → X`)

    return hasChanged
  } catch (e) {
    logErrorMessage(e.message, 'syncProjects > ')
  }
}

export const syncTimeOffCodes = async ({ handleAppState, client }) => {
  try {
    const localTimeOffCodes = await TimeOffCode.toArray()
    const funnelTimeOffCodes = await timeOffCodeService.fetchTimeOffCodes(client)

    const { create, update, remove, hasChanged } = getDiff({
      oldData: localTimeOffCodes,
      newData: funnelTimeOffCodes,
      uniqueId: '_id',
    })
    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)
    log(`ES → LS → TimeOffCode → C: ${sC} U: ${sU} D: ${sR}`)
    if (hasChanged) {
      handleAppState({ timeOffCodes: funnelTimeOffCodes })
      log(`LS → UI → TimeOffCode → ${_.size(funnelTimeOffCodes)}`)

      if (sR !== 0) await TimeOffCode.bulkDelete(remove.map(e => e._id))
      if (sU !== 0) await TimeOffCode.bulkPut(update)
      if (sC !== 0) await TimeOffCode.bulkAdd(create)
    } else log(`ES → LS → TimeOffCode → X`)

    return hasChanged
  } catch (e) {
    logErrorMessage(e, 'app/index.js > syncTimeOffCodes > e: ')
  }
}

export const syncReminderSettings = async ({ handleAppState, client }) => {
  const settings = await ReminderSettingsService.getReminderSettings(client)

  if (_.isNil(settings)) {
    handleAppState({
      reminderSettings: initialReminderSettings,
    })
    return
  }

  const { shouldRemind, weekDays, timeFrom, timeTo, periodInMinutes } = settings
  // We have to filter __typename from weekDays
  const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = weekDays

  handleAppState({
    reminderSettings: {
      shouldRemind,
      weekDays: {
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
      },
      timeFrom: new Date(timeFrom),
      timeTo: new Date(timeTo),
      periodInMinutes,
    },
  })
}

export const syncDelegateAccess = async ({ handleAppState, client, shouldRefresh }) => {
  if (!IS_DELEGATE_ENABLED) return
  try {
    const delegateAccesses = await getDelegateAccessMine({
      client,
      shouldRefresh,
    })
    handleAppState({ delegateAccesses })
    return delegateAccesses
  } catch (error) {
    logErrorMessage(error)
  }
}

const initialUserSettings = {
  favoriteProjectIds: [],
  defaultProjectId: null,
}

export const syncUserSettings = async ({ handleAppState, client }) => {
  const userSettings = await userSettingsService.fetchUserSettings(client)
  if (!userSettings) {
    handleAppState({ userSettings: initialUserSettings })
    return
  }
  handleAppState({ userSettings })
}

export const syncOutlookEvents = async ({
  client,
  selectedDelegateId,
  startISO,
  endISO,
  isDelta = false,
  reSyncDelta = false,
}) => {
  try {
    const events = await eventService.getEvents({
      client,
      params: {
        from: startISO,
        to: endISO,
        isDelta,
        reSyncDelta,
        selectedDelegateId,
      },
    })
    log(`\tOutlook: Events fetching ${_.size(events)}`)
    await Event.clear()
    await Event.bulkAdd(events)
  } catch (e) {
    logErrorMessage(e, 'app/index.js > syncAllLastOutlookEvents > ')
  }
}

export const syncOutlookEmails = async ({
  client,
  selectedDelegateId,
  startISO,
  endISO,
  isDelta = false,
  reSyncDelta = false,
}) => {
  try {
    const emails = await messageService.getMessages({
      client,
      params: {
        receivedAfter: startISO,
        receivedBefore: endISO,
        isDelta,
        reSyncDelta,
        folderType: 0,
        selectType: 0,
        selectedDelegateId,
      },
    })

    log(`\tOutlook: Emails fetching ${_.size(emails)}`)
    await Email.clear()
    await Email.bulkAdd(emails)
  } catch (e) {
    logErrorMessage(e, 'app/index.js > syncAllLastOutlookEmails > ')
  }
}

export const syncAssignments = async ({
  selectedDelegateId,
  getUIRefreshTimePeriod,
  handleAppState,
  client,
}) => {
  try {
    const { startISO, endISO } = getDefaultSyncDateRangeISOs()
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod(true)
    const localAssignments = await Assignment.filter(assignment => {
      return (
        assignment.startDate <= convertDateToLocalTimezone(endISO) &&
        assignment.endDate >= convertDateToLocalTimezone(startISO)
      )
    }).toArray()
    const funnelAssignments = await assignmentService.fetchAssignmentsInDateRange({
      client,
      startISO,
      endISO,
      selectedDelegateId,
    })
    const funnelAssignmentsUI = await assignmentService.fetchAssignmentsInDateRange({
      client,
      startISO: startISOUI,
      endISO: endISOUI,
      selectedDelegateId,
    })

    const allFunnelAssignments = _.uniqBy(
      [...funnelAssignments, ...funnelAssignmentsUI],
      funnelEntry => funnelEntry._id,
    )
    const { create, update, remove, hasChanged } = getDiff({
      oldData: localAssignments,
      newData: allFunnelAssignments,
      uniqueId: '_id',
    })
    const sC = _.size(create)
    const sU = _.size(update)
    const sR = _.size(remove)

    if (hasChanged) {
      log(`ES → LS → Assignment → C: ${sC} U: ${sU} D: ${sR}`)
      const assignmentsToState = allFunnelAssignments.filter(f => {
        return f.startDate <= endISOUI && f.endDate >= startISOUI
      })
      log(`LS → UI → Assignment → ${_.size(assignmentsToState)}`)

      if (sR !== 0) await Assignment.bulkDelete(remove.map(e => e._id))
      if (sU !== 0) await Assignment.bulkPut(update)
      if (sC !== 0) await Assignment.bulkAdd(create)
      handleAppState({ assignments: assignmentsToState })
    } else log(`ES → LS → Assignment → X`)
  } catch (e) {
    logErrorMessage(e, 'app/index.js > syncAssignments > ')
  }
}
