import { GET_PROJECTS } from './query'

export const getProjects = async client => {
  const response = await client.query({
    query: GET_PROJECTS,
  })

  const projects = response.data.getProjects
  return projects
}
