import React from 'react'
import Draggable from 'react-draggable'
import { DraggableAnchorLine, DraggableContainer, WrapperContainer } from '../events/style'

class HorizontalSplitter extends React.Component {
  state = {
    controlledPosition: {
      x: 0,
      y: 0,
    },
    wrapperContainerHeight: 0,
    totalTabHeaderAndAnchorHeight: 45,
    isEventsShown: false,
    timeEntryContainerHeight: 0,
    eventsContainerHeight: 0,
    draggableLineHeight: 13,
  }

  wrapperContainerRef = React.createRef()

  timeEntryContainerRef = React.createRef()

  eventsContainerRef = React.createRef()

  componentDidMount() {
    this.setContainersHeight()
    this.setDraggableLinePosition()
    this.setWindowResizeListener()
  }

  componentDidUpdate() {
    if (
      !this.state.isEventsShown &&
      this.state.timeEntryContainerHeight !== this.getDraggableLineVerticalPosition()
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ timeEntryContainerHeight: this.getDraggableLineVerticalPosition() })
    }
  }

  componentWillUnmount() {
    this.removeWindowResizeListener()
  }

  setContainersHeight = () => {
    this.setState({
      wrapperContainerHeight: this.fixedContainerHeight(),
      timeEntryContainerHeight: this.getDraggableLineVerticalPosition(),
      eventsContainerHeight: 0,
    })
  }

  setDraggableLinePosition = () => {
    this.setState({
      controlledPosition: { x: 0, y: this.getDraggableLineVerticalPosition() },
    })
  }

  setWindowResizeListener = () => {
    window.addEventListener('resize', this.updateWrapperContainerHeight)
  }

  removeWindowResizeListener = () => {
    window.removeEventListener('resize', this.updateWrapperContainerHeight)
  }

  fixedContainerHeight = () => {
    return this.wrapperContainerRef.current ? this.wrapperContainerRef.current.clientHeight : 0
  }

  getEventListOffsetTop = () => {
    return this.fixedContainerHeight() - this.state.totalTabHeaderAndAnchorHeight
  }

  getEventListContainerHeight = () => {
    return this.fixedContainerHeight() - this.state.draggableLineHeight
  }

  getDraggableLineVerticalPosition = () => {
    return this.fixedContainerHeight() - this.state.totalTabHeaderAndAnchorHeight
  }

  getDefaultContainerHeight = () => {
    return this.fixedContainerHeight() / 2 - this.state.draggableLineHeight / 2
  }

  updateControlledPosition = newPosition => {
    this.setState({
      controlledPosition: {
        ...this.state.controlledPosition,
        ...newPosition,
      },
    })
  }

  showEventsList = () => {
    this.setState({
      isEventsShown: true,
    })
  }

  hideEventsList = () => {
    this.setState({
      isEventsShown: false,
    })
  }

  onControlledDrag = (e, position) => {
    this.updateControlledPosition(position)
    this.updateContainersHeightWithPosition(position.y)
    this.setState({
      isEventsShown: true,
    })
  }

  updateContainersHeightWithPosition = y => {
    this.setState({
      timeEntryContainerHeight: y,
      eventsContainerHeight: this.getEventListContainerHeight() - y,
    })
  }

  updateWrapperContainerHeight = () => {
    if (this.state.wrapperContainerHeight !== this.fixedContainerHeight()) {
      if (
        this.getDraggableLineVerticalPosition() <= this.state.controlledPosition.y ||
        !this.state.isEventsShown
      ) {
        this.updateControlledPosition({ y: this.getDraggableLineVerticalPosition() })
      }
      this.setState({
        wrapperContainerHeight: this.fixedContainerHeight(),
        timeEntryContainerHeight: this.fixedContainerHeight() - this.state.eventsContainerHeight,
        eventsContainerHeight: this.fixedContainerHeight() - this.state.controlledPosition.y,
      })
    }
  }

  moveDraggableAnchorToBottom = () => {
    this.updateControlledPosition({ y: this.getDraggableLineVerticalPosition() })
    this.setState({
      timeEntryContainerHeight: this.getDraggableLineVerticalPosition(),
      eventsContainerHeight: 0,
    })
  }

  hideEvents = e => {
    e.preventDefault()
    e.stopPropagation()
    this.moveDraggableAnchorToBottom()
    this.hideEventsList()
  }

  showEvents = () => {
    this.resetPosition()
    this.showEventsList()
  }

  resetPosition = () => {
    this.setState({
      controlledPosition: { x: 0, y: 0 },
      timeEntryContainerHeight: 0,
      eventsContainerHeight: this.state.wrapperContainerHeight - this.state.draggableLineHeight,
    })
  }

  renderDraggableLine() {
    return (
      <Draggable
        bounds={{ top: 0, left: 0, right: 0, bottom: this.getEventListOffsetTop() }}
        axis="y"
        handle=".handle"
        defaultPosition={{ x: 0, y: 0 }}
        position={this.state.controlledPosition}
        grid={[5, 5]}
        scale={1}
        onDrag={this.onControlledDrag}
      >
        <DraggableAnchorLine className="handle" />
      </Draggable>
    )
  }

  renderEventListContainer(element) {
    return React.cloneElement(element, {
      isEventsShown: this.state.isEventsShown,
      hideEvents: this.hideEvents,
      showEvents: this.showEvents,
    })
  }

  renderTimelineViewContainer(element) {
    return React.cloneElement(element, {
      onScrollRef: this.timeEntryContainerRef,
    })
  }

  render() {
    const { children } = this.props
    return (
      <WrapperContainer ref={this.wrapperContainerRef}>
        <DraggableContainer>
          <div
            ref={this.timeEntryContainerRef}
            style={{ height: this.state.timeEntryContainerHeight, overflowY: 'auto' }}
          >
            {this.renderTimelineViewContainer(children[0])}
          </div>
          {this.renderDraggableLine()}
          <div ref={this.eventsContainerRef} style={{ height: this.state.eventsContainerHeight }}>
            {this.renderEventListContainer(children[1])}
          </div>
        </DraggableContainer>
      </WrapperContainer>
    )
  }
}

export default HorizontalSplitter
