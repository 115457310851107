import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SuccessModal from '../SuccessModal'
import FailModal from '../FailModal'
import ResolveModal from '../ResolveModal'
import { useStyles } from './style'

const CsvMultiModal = ({
  clearCsvSuccessState,
  clearCsvFailState,
  successCsvInfoTotal,
  failedCsvInfoTotal,
  unhandledEntriesInfo,
  onAdd,
  onDismiss,
  onReplace,
  csvActionButtonsBlocked,
}) => {
  const [showed, setShowed] = useState(0)

  useEffect(() => {
    const isSuccessCsvInfoEmpty = _.isEmpty(
      _.get(successCsvInfoTotal, 'csvInfoByTimesheetTotal', []),
    )
    const isFailedCsvInfoEmpty = _.isEmpty(_.get(failedCsvInfoTotal, 'csvInfoByTimesheetTotal', []))

    if (isSuccessCsvInfoEmpty && !_.isEmpty(unhandledEntriesInfo)) setShowed(1)
    if (isSuccessCsvInfoEmpty && _.isEmpty(unhandledEntriesInfo) && !isFailedCsvInfoEmpty)
      setShowed(2)
    if (isSuccessCsvInfoEmpty && _.isEmpty(unhandledEntriesInfo) && isFailedCsvInfoEmpty) {
      clearCsvSuccessState()
      clearCsvFailState()
    }
  }, [
    successCsvInfoTotal,
    failedCsvInfoTotal,
    unhandledEntriesInfo,
    clearCsvSuccessState,
    clearCsvFailState,
  ])

  const handleNextModal = () => {
    clearCsvSuccessState()
    setShowed(1)
  }

  const classes = useStyles()
  return (
    <>
      <SuccessModal
        classes={classes}
        isOpen={showed === 0}
        handler={handleNextModal}
        csvInfoTotal={successCsvInfoTotal}
      />
      <ResolveModal
        classes={classes}
        isOpen={showed === 1}
        setShowed={setShowed}
        onAdd={onAdd}
        onDismiss={onDismiss}
        onReplace={onReplace}
        csvActionButtonsBlocked={csvActionButtonsBlocked}
        unhandledEntriesInfo={unhandledEntriesInfo}
        failedCsvInfoTotal={failedCsvInfoTotal}
      />
      <FailModal
        classes={classes}
        isOpen={showed === 2}
        handler={clearCsvFailState}
        csvInfoTotal={failedCsvInfoTotal}
      />
    </>
  )
}

CsvMultiModal.propTypes = {
  clearCsvSuccessState: PropTypes.func.isRequired,
  clearCsvFailState: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  successCsvInfoTotal: PropTypes.object.isRequired,
  failedCsvInfoTotal: PropTypes.object.isRequired,
  unhandledEntriesInfo: PropTypes.object.isRequired,
  csvActionButtonsBlocked: PropTypes.bool.isRequired,
}

export default memo(CsvMultiModal)
