/* eslint-disable */

function NetworkViz(settings) {
  const _this = this
  this.LABEL_THRESHOLD = 1
  this.clustercolors = true
  this.neighbors_num = null
  this.drag_node = null
  this.recolorTimer = null
  /* private baseNodeColor = "#ffffff";
        private baseLabelColor = "#c2c2c2";
        private baseStrokeColor = "#fff";
        private baseStrokeOpacity = 0.1; */
  this.baseNodeColor = '#000'
  this.baseLabelColor = '#111'
  this.baseStrokeColor = '#000'
  this.baseStrokeOpacity = 0.1
  this.baseNodeStrokeColor = '#555'
  this.highlightedNodeColor = '#222'
  this.moveContact = function(d) {
    d.fixed = true
    const r = this.settings.nodeSizeFunc(d.attr)
    let newx = d3.event.x + d.x
    let newy = d3.event.y + d.y
    newx = Math.max(r, Math.min(this.settings.size.width - r, newx))
    newy = Math.max(r, Math.min(this.settings.size.height - r - 17, newy))
    d.x = newx
    d.y = newy
    d.px = newx
    d.py = newy
    this.forceTick()
    const g = d3.select(d.parentNode)
    g.attr('transform', function(d) {
      return `translate(${newx},${newy})`
    })
  }
  this.centeredNode = null
  this.settings = settings
  this.svg = d3.select(settings.svgHolder)
  this.svg.attr('pointer-events', 'all')
  this.svg.attr('width', this.settings.size.width)
  this.svg.attr('height', this.settings.size.height)
  this.svg.on('click', function() {
    _this.undoCenterNode()
    _this.settings.clickHandler(null)
  })
  this.defs = this.svg.append('svg:defs')
  this.defs
    .append('svg:filter')
    .attr('id', 'blur')
    .append('svg:feGaussianBlur')
    .attr('stdDeviation', 1)

  this.linksG = this.svg.append('g').attr('id', 'links')
  this.nodesG = this.svg.append('g').attr('id', 'nodes')
  this.glowing = false
  this.labelsVisible = true
}
NetworkViz.prototype.updateNetwork = function(graph) {
  const _this = this
  // remember centered node
  // var centeredNode = this.centeredNode;
  // undo centering
  // this.undoCenterNode();
  let centeredNode = null
  this.filteredNodes = graph.nodes.filter(function(node) {
    return !node.skip
  })
  const idToNode2 = {}

  this.filteredNodes.forEach(function(node) {
    if (_this.idToNode !== undefined && node.id in _this.idToNode) {
      const oldNode = _this.idToNode[node.id]
      if (oldNode === _this.centeredNode) {
        centeredNode = node
      }
      node.x = oldNode.x
      node.px = oldNode.px
      node.y = oldNode.y
      node.py = oldNode.py
    }
    idToNode2[node.id] = node
  })
  this.idToNode = idToNode2
  this.filteredLinks = graph.links.filter(function(link) {
    return !link.skip && !link.source.skip && !link.target.skip
  })
  if (centeredNode) {
    this.draw(false)
  } else {
    this.undoCenterNode()
    this.draw(true)
  }

  // redo centering after network has been updated
  if (centeredNode) {
    // console.log("previously centered node ", centeredNode.attr.contact.name)
    this.centerNode(centeredNode)
  }
}

NetworkViz.prototype.rescale = function() {
  const trans = d3.event.translate
  const scale = d3.event.scale
  this.svg.attr('transform', `translate(${trans})` + ` scale(${scale})`)
}

NetworkViz.prototype.resume = function() {
  // this.force.stop();
  this.force.alpha(0.15)
}

NetworkViz.prototype.draw = function(live) {
  const _this = this
  if (live === undefined) {
    live = this.settings.forceParameters.live
  }
  if (this.force !== undefined) {
    this.force.stop()
  } else {
    this.force = d3.layout.force()
  }
  this.force.size([this.settings.size.width, this.settings.size.height])
  this.force.charge(this.settings.forceParameters.charge)
  this.force.linkDistance(this.settings.forceParameters.linkDistance)
  this.force.gravity(this.settings.forceParameters.gravity)
  this.force.friction(this.settings.forceParameters.friction)
  this.force.nodes(this.filteredNodes)
  this.force.links(this.filteredLinks)
  if (live) {
    this.force.on('tick', function() {
      return _this.forceTick()
    })
  }

  this.redraw()

  this.force.start()
  if (!live) {
    for (let i = 0; i < 150; ++i) {
      this.force.tick()
    }
    this.force.stop()
  }
  // this.force.resume();
  // if(!this.guestbook) {
  // this.nodeBind.selectAll("circle").transition().duration(750).attr("fill", this.baseNodeColor);
  // clear prevous timeouts before starting new ones
  // if(this.recolorTimer !== null) {
  //  clearTimeout(this.recolorTimer);
  // }
  // this.recolorTimer = setTimeout(() => {this.recolorNodes();}, 1000);
  // }
}

NetworkViz.prototype.redraw = function() {
  this.drawNodes()
  this.drawLinks()
}

/* private chargeFunc(d) {
    var absDynamiccharge = d.attr.size*10;
    if(absDynamiccharge < 3000)
    absDynamiccharge = 3000;
    return -absDynamiccharge;
    } */
NetworkViz.prototype.forceTick = function() {
  const _this = this
  this.nodeBind.attr('transform', function(node) {
    const r = _this.settings.nodeSizeFunc(node.attr)

    // node.x += Math.random()*5 - 2.5;
    // node.y += Math.random()*5 - 2.5;
    node.x = Math.max(r, Math.min(_this.settings.size.width - r, node.x))
    node.y = Math.max(r, Math.min(_this.settings.size.height - r - 17, node.y))
    return `translate(${node.x},${node.y})`
  })

  this.linkBind
    .attr('x1', function(link) {
      return link.source.x
    })
    .attr('y1', function(link) {
      return link.source.y
    })
    .attr('x2', function(link) {
      return link.target.x
    })
    .attr('y2', function(link) {
      return link.target.y
    })
}

NetworkViz.prototype.clickNode = function(node) {
  // focus on the selectedNode
  const selectedNode = this.nodeBind.filter(function(d, i) {
    return node === d
  })
  const e = document.createEvent('UIEvents')
  e.initUIEvent('click', true, true, window, 1)
  selectedNode.node().dispatchEvent(e)
}

NetworkViz.prototype.mouseoverNode = function(node) {
  // focus on the selectedNode
  const selectedNode = this.nodeBind.filter(function(d, i) {
    return node === d
  })

  // change the looks of the circle
  selectedNode
    .select('circle')
    .transition()
    .attr('stroke-width', 3.0)
    .attr('stroke', '#000')
    .attr('fill', this.highlightedNodeColor)

  // .attr("filter", (d) => {var verdict = this.glowing ? "url(#blur)" : "none"; return verdict;} )
  // change the looks of the text
  selectedNode
    .select('text')
    .transition()
    .attr('visibility', 'visible')
    .style('font-size', '20px')
    .text(this.settings.nodeLabelFuncHover(node.attr))

  this.linkBind
    .style('stroke-opacity', 0)
    .filter(function(link, i) {
      return link.source === node || link.target === node
    })
    .style('stroke-opacity', 0.5)
}

NetworkViz.prototype.mouseoutNode = function(node) {
  const _this = this
  // console.log("mouseout:" + node.attr.contact.name);
  // focus on the selectedNode
  const selectedNode = this.nodeBind.filter(function(d, i) {
    return node === d
  })

  selectedNode
    .select('circle')
    .transition()
    .attr('stroke', this.baseNodeStrokeColor)
    .attr('stroke-opacity', 0.8)
    .attr('stroke-width', 1.0)
    .attr('opacity', '1.0')
    .attr('fill', function(d) {
      if (_this.clustercolors) return _this.settings.colorFunc(d.attr)
      return _this.baseNodeColor
    })

  selectedNode
    .select('text')
    .transition()
    .text(this.settings.nodeLabelFunc(node.attr))
    .style('font-size', '12px')
    .attr('visibility', function(d) {
      if (
        _this.centeredNode === null &&
        _this.settings.nodeSizeFunc(d.attr) < _this.LABEL_THRESHOLD
      )
        return 'hidden'
    })
  this.linkBind.style('stroke-opacity', this.baseStrokeOpacity)
}

NetworkViz.prototype.undoCenterNode = function() {
  const _this = this
  if (d3.event) {
    d3.event.stopPropagation()
  }

  // don't undo if there is noone centered
  if (this.centeredNode === null) {
    return
  }

  // un-highlight the node if uncentering
  this.mouseoutNode(this.centeredNode)

  const centerNode = this.centeredNode

  // find the neighbors of the centered node (this takes o(1) time given the underlying graph structure)
  const neighbors = {}
  centerNode.links.forEach(function(link) {
    if (link.skip || link.source.skip || link.target.skip) {
      return
    }
    if (link.source !== centerNode) neighbors[link.source.id] = link.source
    if (link.target !== centerNode) neighbors[link.target.id] = link.target
  })

  // === ANIMATION CODE ===
  const centeringNodes = this.nodeBind.style('opacity', 1.0).filter(function(d2, i) {
    return d2 === centerNode || d2.id in neighbors
  })

  centeringNodes.transition().attr('transform', function(d, i) {
    d.x = d.px
    d.y = d.py
    return `translate(${d.x},${d.y})`
  })

  // return the original styles of the cirles of the centering nodes
  centeringNodes
    .select('circle')
    .attr('stroke', this.baseNodeStrokeColor)
    .attr('stroke-opacity', 0.8)
    .attr('stroke-width', 1.0)
    .attr('fill', function(node) {
      if (_this.clustercolors) return _this.settings.colorFunc(node.attr)
      return _this.baseNodeColor
    })

  // return the original style and position of the text of the centering nodes
  centeringNodes
    .select('text')
    .attr('text-anchor', 'middle')
    .attr('dy', function(node) {
      return _this.settings.nodeSizeFunc(node.attr) + 15
    })
    .attr('dx', '0em')
    .attr('transform', null)
    .attr('visibility', function(node) {
      if (_this.settings.nodeSizeFunc(node.attr) < _this.LABEL_THRESHOLD) return 'hidden'
    })

  this.linkBind
    .style('opacity', 1.0)
    .filter(function(link, i) {
      return link.source === centerNode || link.target === centerNode
    })
    .transition()
    .attr('x1', function(link) {
      return link.source.x
    })
    .attr('y1', function(link) {
      return link.source.y
    })
    .attr('x2', function(link) {
      return link.target.x
    })
    .attr('y2', function(link) {
      return link.target.y
    })

  // uncenter the node
  this.centeredNode = null
}

NetworkViz.prototype.centerNode = function(centerNode) {
  const _this = this
  // stop any animation before animating the "centering"
  this.force.stop()

  // un-highlight the node
  // this.mouseoutNode(centerNode);
  if (this.centeredNode === centerNode) {
    this.undoCenterNode()
    return
  }
  this.undoCenterNode()

  // remember the centered node
  this.centeredNode = centerNode

  // store all the neighbors of the centered node (neighbors are found in O(1) time from the graph data structure)
  const neighbors = {}
  let nneighbors = 0
  centerNode.links.forEach(function(link) {
    if (link.skip || link.source.skip || link.target.skip) {
      return
    }
    nneighbors += 1

    if (link.source !== centerNode) neighbors[link.source.id] = link.source
    if (link.target !== centerNode) neighbors[link.target.id] = link.target
  })

  // radius of the "centering" circle
  const radius = 250
  const angle = (2 * Math.PI) / nneighbors

  //  ===COORDINATES COMPUTATION CODE===
  //  ---center node---
  // store old coordinates. Need them when we undo the centering to return objects to their original position.
  centerNode.px = centerNode.x
  centerNode.py = centerNode.y
  centerNode.x = this.settings.size.width / 2.0
  centerNode.y = this.settings.size.height / 2.0

  //  ---neighboring nodes---
  let idx = 0
  const neighbors_array = []
  for (var id in neighbors) {
    neighbors_array.push(neighbors[id])
  }
  neighbors_array.sort(function(a, b) {
    return a.attr.color - b.attr.color
  })

  for (var id in neighbors_array) {
    const node = neighbors_array[id]
    node.px = node.x
    node.py = node.y
    node.x = centerNode.x + radius * Math.cos(idx * angle)
    node.y = centerNode.y + radius * Math.sin(idx * angle)
    node.angle = idx * angle
    idx += 1
  }
  this.neighbors_num = neighbors_array.length - 1

  // === ANIMATION CODE ===
  // ---neighboring nodes---
  this.nodeBind
    .style('opacity', 0.05)
    .filter(function(d2, i) {
      return d2.id in neighbors
    })
    .style('opacity', 1.0)
    .transition()
    .attr('transform', function(d, i) {
      return `translate(${d.x},${d.y})`
    })
    .select('text')
    .attr('text-anchor', function(d, i) {
      const ang = (180 * d.angle) / Math.PI
      if (ang > 90 && ang < 270) {
        return 'end'
      }
      return 'start'
    })
    .attr('dx', function(d, i) {
      const ang = (180 * d.angle) / Math.PI
      if (ang > 90 && ang < 270) {
        return -_this.settings.nodeSizeFunc(d.attr) - 10
      }
      return _this.settings.nodeSizeFunc(d.attr) + 10
    })
    .attr('dy', function(d, i) {
      return 5
    })
    .attr('transform', function(d, i) {
      const ang = (180 * d.angle) / Math.PI
      if (ang > 90 && ang < 270) {
        return `rotate(${ang} 0 0) scale(-1,-1)`
      }
      return `rotate(${ang} 0 0)`
    })
    .attr('visibility', null)

  // ---center node---
  const tmp = this.nodeBind.filter(function(d2, i) {
    return d2 === centerNode
  })

  // make the center node fully vizible
  tmp.select('text').attr('visibility', null)
  tmp
    .style('opacity', 1.0)
    .transition()
    .attr('transform', function(d, i) {
      return `translate(${d.x},${d.y})`
    })
    .select('circle')
    .attr('stroke-width', 3.0)
    .attr('stroke', '#000')
    .attr('fill', this.highlightedNodeColor)

  // un-highlight the node
  this.mouseoutNode(centerNode)

  // ---links---
  this.linkBind
    .style('opacity', 0)
    .filter(function(link, i) {
      return link.source === centerNode || link.target === centerNode
    })
    .style('opacity', 1.0)
    .transition()
    .attr('x1', function(link) {
      return link.source.x
    })
    .attr('y1', function(link) {
      return link.source.y
    })
    .attr('x2', function(link) {
      return link.target.x
    })
    .attr('y2', function(link) {
      return link.target.y
    })
}

NetworkViz.prototype.recolorNodes = function() {
  const _this = this
  if (this.clustercolors) {
    this.nodeBind
      .select('circle')
      .transition()
      .duration(750)
      .attr('fill', function(node) {
        return _this.settings.colorFunc(node.attr)
      })
  } else {
    this.nodeBind.select('circle').attr('fill', this.baseNodeColor)
  }
}

NetworkViz.prototype.glowNodes = function() {
  if (!this.glowing) {
    this.nodeBind
      .select('circle')
      .transition()
      .style('filter', 'url(#blur)')
    this.glowing = true
  } else {
    this.nodeBind
      .select('circle')
      .transition()
      .style('filter', 'none')
    this.glowing = false
  }
}

NetworkViz.prototype.toggleLabelVisibility = function() {
  if (this.labelsVisible) {
    this.nodeBind
      .select('text')
      .transition()
      .style('opacity', 0)
    this.labelsVisible = false
  } else {
    console.log('displaying labels..')
    this.nodeBind
      .select('text')
      .transition()
      .style('opacity', 0.8)
    this.labelsVisible = true
  }
}

NetworkViz.prototype.drawNodes = function() {
  const _this = this
  const tmp = function(node) {
    return node.id
  }
  this.nodeBind = this.nodesG.selectAll('g.node').data(this.filteredNodes, tmp)

  if (this.guestbook) {
    console.log('esaa')
    const filteredNodes_length = this.filteredNodes.length
    for (let u = 0; u < filteredNodes_length; u++) {
      if (this.filteredNodes[u].attr.contact.userinfo.picture !== undefined)
        var picurl = this.filteredNodes[u].attr.contact.userinfo.picture
      else var picurl = '/static/images/default_user_pic.jpg'
      this.defs
        .append('pattern')
        .attr('id', `${this.filteredNodes[u].attr.contact.userinfo.id}_pic`)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 50)
        .attr('height', 50)
        .attr('x', -25)
        .attr('y', 25)
        .append('svg:image')
        .attr('xlink:href', picurl)
        .attr('width', 50)
        .attr('height', 50)
        .attr('x', 0)
        .attr('y', 0)
    }
  }

  // update
  this.nodeBind.attr('transform', function(node) {
    return `translate(${node.x},${node.y})`
  })
  var circles = this.nodeBind.select('circle')
  circles
    .transition()
    .duration(1000)
    .attr('r', function(node) {
      return _this.settings.nodeSizeFunc(node.attr)
    })
    .attr('fill', function(node) {
      if (!_this.guestbook) {
        if (_this.clustercolors) {
          return _this.settings.colorFunc(node.attr)
        }
        return _this.baseNodeColor
      }
      return `url(#${node.attr.contact.userinfo.id}_pic)`
    })

  const labels = this.nodeBind.select('text')
  labels
    .transition()
    .attr('visibility', function(node) {
      if (_this.settings.nodeSizeFunc(node.attr) < _this.LABEL_THRESHOLD) return 'hidden'
    })
    .attr('dy', function(node) {
      return _this.settings.nodeSizeFunc(node.attr) + 15
    })
    .attr('dx', '0em')

  // enter
  const enteringNodes = this.nodeBind.enter().append('g')
  enteringNodes.attr('class', 'node').attr('id', function(node) {
    return node.id
  })

  enteringNodes.attr('transform', function(node) {
    return `translate(${node.x},${node.y})`
  })
  if (!this.guestbook) {
    if (this.settings.clickHandler !== undefined) {
      enteringNodes.on('click.1', this.settings.clickHandler)
      enteringNodes.on('click.centerNode', function(d, i) {
        _this.centerNode(d)
      })
    }
  }

  var circles = enteringNodes.append('circle')
  circles.attr('r', function(node) {
    return _this.settings.nodeSizeFunc(node.attr)
  })
  circles.attr('fill', function(node) {
    if (!_this.guestbook) {
      if (_this.clustercolors) {
        return _this.settings.colorFunc(node.attr)
      }
      return _this.baseNodeColor
    }
    return `url(#${node.attr.contact.userinfo.id}_pic)`
  })

  circles
    .style('opacity', '1')
    .attr('stroke', this.baseNodeStrokeColor)
    .attr('stroke-opacity', 1)
    .attr('stroke-width', 1.0)

  // .style("filter", () => {var verdict = this.glowing ? "url(#blur)" : "none"; return verdict;} )
  if (!this.guestbook) {
    circles
      .on('mouseover', function(d, i) {
        _this.mouseoverNode(d)
      })
      .on('mouseout.1', function(d, i) {
        _this.mouseoutNode(d)
      })
      .call(
        d3.behavior.drag().on('drag', function(node) {
          return _this.moveContact(node)
        }),
      )
  }

  enteringNodes
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dy', function(node) {
      return _this.settings.nodeSizeFunc(node.attr) + 13
    })
    .attr('dx', '0em')
    .attr('class', 'nodelabeltext')
    .attr('visibility', function(node) {
      if (_this.settings.nodeSizeFunc(node.attr) < _this.LABEL_THRESHOLD) return 'hidden'
    })
    .style('font-size', '12px')
    .attr('fill', this.baseLabelColor)
    .attr('opacity', 0.8)
    .style('pointer-events', 'none')
    .text(function(node) {
      return _this.settings.nodeLabelFunc(node.attr)
    })

  // exit
  this.nodeBind.exit().remove()
}

NetworkViz.prototype.showLabel = function(radius) {
  return radius < 5
}

NetworkViz.prototype.drawLinks = function() {
  const _this = this
  const tmp = function(link) {
    return `${link.source.id}#${link.target.id}`
  }
  this.linkBind = this.linksG.selectAll('line.link').data(this.filteredLinks, tmp)

  console.log('this.filteredLInks', this.filteredLinks)

  const sizeExtent = d3.extent(this.filteredLinks, function(link) {
    return link.weight
  })

  // var linkWidth = d3.scale.linear();
  // linkWidth.range([this.settings.linkWidthPx.min, this.settings.linkWidthPx.max]);
  // linkWidth.domain(sizeExtent);
  // update
  this.linkBind.attr('stroke-width', function(link) {
    return _this.settings.linkSizeFunc(link.attr)
  })
  this.linkBind.attr('x1', function(link) {
    return link.source.x
  })
  this.linkBind.attr('y1', function(link) {
    return link.source.y
  })
  this.linkBind.attr('x2', function(link) {
    return link.target.x
  })
  this.linkBind.attr('y2', function(link) {
    return link.target.y
  })

  // enter
  const lines = this.linkBind.enter().append('line')
  lines.attr('class', 'link')
  lines.attr('stroke', this.baseStrokeColor)
  lines.attr('stroke-opacity', this.baseStrokeOpacity)
  lines.attr('stroke-width', function(link) {
    return _this.settings.linkSizeFunc(link.attr)
  })
  lines.attr('x1', function(link) {
    return link.source.x
  })
  lines.attr('y1', function(link) {
    return link.source.y
  })
  lines.attr('x2', function(link) {
    return link.target.x
  })
  lines.attr('y2', function(link) {
    return link.target.y
  })

  // exit
  this.linkBind.exit().remove()
}

export default NetworkViz
