/* eslint-disable */

/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-redeclare */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable no-continue */
/* eslint-disable eqeqeq */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-cond-assign */
import { withApollo } from '@apollo/client/react/hoc'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import * as d3 from 'd3'
import $ from 'jquery'
import _ from 'lodash'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import { mapStateToProps } from './store'
import setupDB from './setupDB'
import NetworkViz from './viz'
import * as Graph from './graph'
import * as messageService from '../../entities/outlook/messages/service'
import moment from 'moment'
// import './d3'
// import './js/db'
// import './js/viz'
// import './js/graph'
// import './js/app'
const nnodes = 1000

// number of nodes to precompute graph for
var NNODES_PRECOMPUTE = 200
var nlinks = 1000000000

let db = null
let viz = null
const GraphView = props => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [sliderMax, setSliderMax] = React.useState(1)
  const [sliderMin, setSliderMin] = React.useState(0)
  const [sliderValueLow, setSliderValueLow] = React.useState(0)
  const [sliderValueHigh, setSliderValueHigh] = React.useState(1)

  const [isLoadingCache, setIsLoadingCache] = React.useState(true)

  const history = useHistory()
  const userInfo = props.userInfo

  //  https://github.com/d3/d3-3.x-api-reference/blob/master/Force-Layout.md
  useEffect(() => {
    const loadEmails = async () => {
      try {
        const emails = await messageService.getMessages({
          client: props.client,
          params: {
            isCache: true,
          },
        })
         
        loadGraph_pre(emails)
        setIsLoading(false)  
      } catch(e) {
        
      }

      try {        
        await messageService.getMessages({
          client: props.client,
          params: {
            receivedAfter: '2018-01-01T05:00:00.000Z',
            receivedBefore: '2019-01-01T05:00:00.000Z',
            isDelta: false,
            reSyncDelta: false,
            folderType: 1,
            selectType: 1,
          },
        })

        await messageService.getMessages({
          client: props.client,
          params: {
            receivedAfter: '2019-01-01T05:00:00.000Z',
            receivedBefore: '2020-01-01T05:00:00.000Z',
            isDelta: false,
            reSyncDelta: false,
            folderType: 1,
            selectType: 1,
          },
        })

        await messageService.getMessages({
          client: props.client,
          params: {
            receivedAfter: '2020-01-01T05:00:00.000Z',
            receivedBefore: '2021-01-01T05:00:00.000Z',
            isDelta: false,
            reSyncDelta: false,
            folderType: 1,
            selectType: 1,
          },
        })

        await messageService.getMessages({
          client: props.client,
          params: {
            receivedAfter: '2021-01-01T05:00:00.000Z',
            receivedBefore: '2022-01-01T05:00:00.000Z',
            isDelta: false,
            reSyncDelta: false,
            folderType: 1,
            selectType: 1,
          },
        })

        setIsLoadingCache(false)

      } catch (e) { console.log(e) }
    }
    loadEmails()
  }, [])

  const loadGraph_pre = emails => {
    const preparedEmails = prepareEmails(emails, userInfo)
    console.log('reparedemails', preparedEmails)
    loadGraph(preparedEmails)
  }

  const loadGraph = preparedEmails => {
    const firstEmail = preparedEmails[0]
    const lastEmail = preparedEmails[preparedEmails.length - 1]

    const getDate = e => e.dateField * 1000
    const sMin = getDate(firstEmail)
    const sMax = getDate(lastEmail)

    setSliderMin(sMin)
    setSliderMax(sMax)
    setSliderValueLow(sMin)
    setSliderValueHigh(sMax)

    db = setupDB(preparedEmails)
    showData(db)
  }

  const findSpecificDates = (specificDate, currentYear, userYear) => {
    if(specificDate === 'CURRENT_WEEK'){
      const startDate = moment().utc().startOf('week').valueOf()
      const endDate = moment().utc().valueOf()
      return {startDate, endDate}
    }
    if(specificDate === 'PAST_WEEK'){
      const startDate = moment().utc().startOf('week').subtract(1, 'week').valueOf()
      const endDate = moment().utc().endOf('week').subtract(1, 'week').valueOf()
      return {startDate, endDate}
    }
    if(specificDate === 'PAST_MONTH'){
      const startDate = moment().utc().subtract(1, 'month').startOf('month').valueOf()
      const endDate = moment().utc().subtract(1, 'month').endOf('month').valueOf()
      return {startDate, endDate}
    }
    if(specificDate === 'YEAR'){
      const actualYear = moment().year()
      const diff = actualYear - currentYear
      const diffUser = currentYear - userYear
      const startDate = diffUser === 0 ? moment(props.userInfo.startDate).utc().valueOf() : moment().utc().startOf('year').subtract(diff, 'year').valueOf()
      const endDate = diff === 0 ? moment().utc().valueOf() : moment().utc().endOf('year').subtract(diff, 'year').valueOf()
      return {startDate, endDate}
    }
    const startDate = moment(props.userInfo.startDate).utc().valueOf()
    const endDate = moment().utc().valueOf()
    return {startDate, endDate}
  }

  const handleButtonSpecificDates = (event, specificDate, currentYear, userYear) => {
    event && event.preventDefault()
    const dates = findSpecificDates(specificDate, currentYear, userYear)
    const {startDate, endDate} = dates
    setSliderValueLow(startDate)
    setSliderValueHigh(endDate)
    updateNetwork(true, db, viz, startDate, endDate)
  }

  const createButtonsYears = (userYear) => {
    let currentYear = moment().year()
    const diff = currentYear - userYear + 1
    const array = Array.from({length: diff}, (_,i) => i)
    const buttons = array.reduce((accum, e) => {
      const year = new Number(currentYear)
      const yearButton = <Button key={year} color="primary"onClick={event => handleButtonSpecificDates(event, 'YEAR', year, userYear)}>{currentYear}</Button>
      currentYear = currentYear-1
      return [...accum, yearButton]
    }, [])
    return buttons
  }

  const sliderRange = `${formatter(new Date(sliderValueLow))} - ${formatter(
    new Date(sliderValueHigh),
  )}`
  const sliderDuration = longAgo(new Date(sliderValueHigh), new Date(sliderValueLow))

  const userStartDate = props.userInfo.startDate
  const userYear = moment(userStartDate).year()
  const buttons = createButtonsYears(userYear)
  return (
    <div style={{ padding: '1em' }}>
      {isLoading && <h1>L O A D I N G . . .</h1>}
      <button type="button" onClick={() => history.push('/')}>
        {'<'}
      </button>
      <svg id="network" />
      <div id="timeline">
        <div style={{ padding: '0 0 0 3em' }}>
          <div>{sliderDuration}</div>
          <div>{sliderRange}</div>
        </div>
        <div style={{ padding: '0 3em 0 3em' }}>
          <Slider
            disabled={isLoading}
            min={sliderMin}
            max={sliderMax}
            value={[sliderValueLow, sliderValueHigh]}
            onChange={(event, values) => {
              const [newSliderLow, newSliderHigh] = values
              setSliderValueLow(newSliderLow)
              setSliderValueHigh(newSliderHigh)
              updateNetwork(true, db, viz, newSliderLow, newSliderHigh)
            }}
          />
        </div>
        <div style={{ padding: '0 3em 0 3em'}}>
          <div style={{ textAlign: 'center'}}>
            <Button color="primary" onClick={event => handleButtonSpecificDates(event, 'ALL')}> All </Button>
            {buttons.map(b => b).reverse()}
            <Button color="primary" onClick={event => handleButtonSpecificDates(event, 'PAST_MONTH')}> Past Month </Button>
            <Button color="primary" onClick={event => handleButtonSpecificDates(event, 'PAST_WEEK')}> Past Week </Button>
            <Button color="primary" onClick={event => handleButtonSpecificDates(event, 'CURRENT_WEEK')}> Current Week </Button>
          </div>
        </div>
        {isLoadingCache && <h3>C A C H I N G . . .</h3>}
      </div>
    </div>
  )
}

GraphView.propTypes = {
  userInfo: PropTypes.any,
  client: PropTypes.object.isRequired,
}

export default withApollo(connect(mapStateToProps)(GraphView))

const prepareEmails = (emails, userInfo) => {
  const parsedEmails = emails.map(e => {
    return {
      // all these emails are [0] full name, [1] email
      fromField: [
        _.get(e, 'sender.emailAddress.name', 'NOT FOUND'),
        _.get(e, 'sender.emailAddress.address', 'NOT FOUND'),
      ],
      toField: e.recipients.map(r => {
        return [
          _.get(r, 'emailAddress.name', 'NOT FOUND'),
          _.get(r, 'emailAddress.address', 'NOT FOUND'),
        ]
      }),
      ccField: e.ccRecipients.map(r => {
        return [
          _.get(r, 'emailAddress.name', 'NOT FOUND'),
          _.get(r, 'emailAddress.address', 'NOT FOUND'),
        ]
      }),
      // number in seconds
      dateField: new Date(e.dateTimeSent).getTime() / 1000,
      // what
      isSent:
        _.get(e, 'sender.emailAddress.address', 'NOT A').toLowerCase() ===
        _.get(userInfo, 'email', 'NOT B').toLowerCase(),
    }
  })


  return _.sortBy(parsedEmails, 'dateField')
}

const showData = db => {
  var FRICTION = 0.5
  var LINKDISTANCE = 50
  var NTOPCONTACTS = 15

  // number of nodes to precompute graph for
  var NNODES_PRECOMPUTE = 200

  // default network viz parameters
  var NNODES_DEFAULT = 100
  var CHARGE_DEFAULT = -2500

  // $('#data').fadeIn()

  // setup the setttings for the network vizualization
  // var sizeExtent = d3.extent(graph.nodes, function(node) { return node.attr.size; });
  // var nodeRadius = d3.scale.linear().range([5, 20]).domain(sizeExtent);
  var color = d3.scale.category10()
  var settings = {
    svgHolder: '#network',
    size: {
      width: $(window).width() - 200,
      height: $(window).height() - 200,
    },
    forceParameters: {
      friction: FRICTION,
      gravity: 0.9,
      linkDistance: LINKDISTANCE,
      charge: CHARGE_DEFAULT,
      live: true,
    },
    nodeLabelFunc(attr) {
      var namefield = attr.contact.name
      if (namefield.indexOf('@') >= 0) {
        return namefield.split('@')[0]
      }
      return namefield.split(' ')[0]
    },
    nodeLabelFuncHover(attr) {
      var namefield = attr.contact.name
      if (namefield.indexOf('@') >= 0) {
        return namefield.split('@')[0]
      }
      return namefield
    },
    nodeSizeFunc: null,
    linkSizeFunc: null,
    colorFunc(attr) {
      return color(attr.color)
    },
    clickHandler(node) {
      //   if (node === null) {
      //     if (App.wasUserStats) App.toggleinfo(true, false)
      //     else App.toggleinfo(false, true)
      //   } else {
      //     showContactDetails(node.attr.contact, start, end)
      //   }
    },
  }

  // vizualize the network
  viz = new NetworkViz(settings, false)
  updateNetwork(true, db, viz)
  // viz.draw()
}

const updateNetwork = function(induceNetwork, db, viz, start, end) {
  /// /generate a network out of email data
  const graph = Graph.induceNetwork(db, NNODES_PRECOMPUTE, start, end)
  // initLinksSlider()
  // Graph.filterNodes(graph, function(nodeAttr, idx) {
  //   return idx < nnodes
  // })
  // Graph.filterLinks(graph, function(linkAttr, idx) {
  //   return idx < nlinks
  // })

  // run community detection on the network
  Graph.communityDetection(graph)
  var sizeExtent = d3.extent(graph.nodes, function(node) {
    return node.attr.size
  })
  var nodeRadius = d3.scale
    .linear()
    .range([3, 50])
    .domain(sizeExtent)
  var linkSizeExtent = d3.extent(graph.links, function(link) {
    return link.attr.weight
  })
  var linkWidth = d3.scale
    .linear()
    .range([1, 12])
    .domain(linkSizeExtent)

  viz.settings.nodeSizeFunc = function(attr) {
    return nodeRadius(attr.size)
  }
  viz.settings.linkSizeFunc = function(attr) {
    return linkWidth(attr.weight)
  }

  console.log('graph', graph)
  viz.updateNetwork(graph)
}

const formatter = d3.time.format('%d %b %Y')

const longAgo = function(a, b) {
  return timespanPretty(timespan(a, b))
}

const timespan = function(a, b) {
  return (a.getTime() - b.getTime()) / 1000
}

// returns the timespan between the two dates in human-friendly format
const timespanPretty = function(seconds) {
  var diff = timedelta(seconds)
  var result = ''
  var postfix = ''
  if (diff.seconds < 70) {
    result = diff.seconds.toFixed(1)
    postfix = 'second'
  } else if (diff.minutes < 70) {
    result = diff.minutes.toFixed(1)
    postfix = 'minute'
  } else if (diff.hours < 24) {
    result = diff.hours.toFixed(1)
    postfix = 'hour'
  } else if (diff.days < 61) {
    result = diff.days.toFixed(1)
    postfix = 'day'
  } else if (diff.months < 12) {
    result = diff.months.toFixed(1)
    postfix = 'month'
  } else {
    result = diff.years.toFixed(1)
    postfix = 'year'
  }
  if (parseFloat(result) > 1) {
    postfix += 's'
  }
  return `${result} ${postfix}`
}

const timedelta = function(seconds) {
  var minutes = seconds / 60
  var hours = minutes / 60
  var days = hours / 24
  var months = days / 30.42
  var years = days / 365
  return {
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  }
}
