import gql from 'graphql-tag'

export const GET_TIME_OFF_CODES = gql`
  query {
    getTimeOffCodes {
      _id
      repliconId
      name
    }
  }
`
