import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { CustomDialog } from './style'

const ConfirmationModal = ({ title, subtitle, isOpen, actionButtons, children, titleColor }) => {
  return (
    <CustomDialog
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false
        }
      }}
      disableEscapeKeyDown
      open={isOpen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" color={titleColor}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{subtitle}</Typography>
      </DialogContent>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {actionButtons.map(e => (
          <Button
            key={e.label}
            onClick={e.handler}
            color={e.color || 'primary'}
            disabled={e.isDisabled}
          >
            {e.label}
          </Button>
        ))}
      </DialogActions>
    </CustomDialog>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  titleColor: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func,
      color: PropTypes.string,
      label: PropTypes.string,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
}

ConfirmationModal.defaultProps = {
  titleColor: 'primary',
  subtitle: '',
}

export default ConfirmationModal
