import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { debounce, isEqual } from 'lodash'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { timeEntryBoxStyles as styles } from '../../ListView/EntryBoxEditStyle'

class DebouncedTextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      originalComment: '',
    }
    this.changed = debounce(this.props.onChange, 250)
  }

  componentDidMount() {
    this.setState({ originalComment: this.props.entry.originalComment })
  }

  componentDidUpdate = prevProps => {
    const newPropsComment = this.props.entry.originalComment
    if (
      !isEqual(newPropsComment, prevProps.entry.originalComment) &&
      !isEqual(newPropsComment, this.state.originalComment)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ originalComment: this.props.entry.originalComment })
    }
  }

  handleChange = e => {
    const originalComment = e.target.value
    this.setState({ originalComment }, () => {
      this.changed({ originalComment })
    })
  }

  onInputFocus = e => {
    // TO SELECT FULL COMMENT WHEN NAVIGATING WITH TABS
    const relatedTarget = e.relatedTarget
    if (!relatedTarget) return
    if (relatedTarget.id !== 'default-project-button') return
    this.props.commentTextFieldRef.current.select()
  }

  render() {
    return (
      <TextField
        data-testid="entryBox.comment"
        inputRef={this.props.commentTextFieldRef}
        id="textarea"
        placeholder="Enter a Comment"
        multiline
        maxRows={2}
        value={this.state.originalComment}
        onChange={this.handleChange}
        InputProps={{
          classes: { input: `${this.props.classes.inputComment} mousetrap` },
          onFocus: this.onInputFocus,
        }}
        className={this.props.classes.inputCommentStyle}
      />
    )
  }
}

DebouncedTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  commentTextFieldRef: PropTypes.object.isRequired,
}

export default withStyles(styles)(DebouncedTextInput)
