import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import withSizes from 'react-sizes'

import {
  Container,
  DayTableWrapper,
  WeekTableWrapper,
  ContainerGrid,
  TableContainer,
} from './styles'
import ExpansionPanel from './ExpansionPanel'
import ResponsiveTable from './ResponsiveTable'
import timeUtils from '../../../utils/timeUtils'

import './table.css'
import HorizontalSplitter from '../TimelineView/HorizontalSplitter'

import { breakpoints } from '../../../constants/breakpoints'

const TableView = props => {
  const timesheets = props.timesheets.filter(t => {
    const { startISO, endISO } = props.uiRefreshTimePeriod
    return timeUtils.doesTimePeriodContainsAnother(
      {
        startISO: t.startDate,
        endISO: t.endDate,
      },
      {
        startISO,
        endISO,
      },
    )
  })

  const days = getSelectedPeriodDays(props.uiRefreshTimePeriod)

  const renderMultipleTables = () => {
    return (
      <WeekTableWrapper>
        <ExpansionPanel
          data={days.map(day => {
            return {
              index: day,
              title: moment(day).format('ddd D'),
              content: renderDayTable(day),
            }
          })}
        />
      </WeekTableWrapper>
    )
  }

  const renderDayTable = day => {
    return (
      <DayTableWrapper>
        <ResponsiveTable
          timesheets={timesheets}
          timeEntries={props.timeEntries}
          day={day}
          buildCurrentEditableEntry={props.buildCurrentEditableEntry}
          projects={props.projects}
          handleCreateEntrySubmission={props.handleCreateEntrySubmission}
          handleUpdateEntrySubmission={props.handleUpdateEntrySubmission}
          showEntryInvoicedModal={props.showEntryInvoicedModal}
          contextMenuActions={props.contextMenuActions}
          handleNoFreeGapModalOpen={props.handleNoFreeGapModalOpen}
          userSettings={props.userSettings}
          mostRecentlyUsedProjectIds={props.mostRecentlyUsedProjectIds}
          assignments={props.assignments}
          person={props.person}
        />
      </DayTableWrapper>
    )
  }

  if (props.isMobile && props.isDayViewPeriodSelected) {
    return (
      <HorizontalSplitter>
        <Container>
          {days.length === 1 ? renderDayTable(days[0]) : renderMultipleTables()}
        </Container>
        {props.children}
      </HorizontalSplitter>
    )
  }

  return (
    <TableContainer container className="table-view">
      <ContainerGrid item xs>
        {days.length === 1 ? renderDayTable(days[0]) : renderMultipleTables()}
      </ContainerGrid>
      <Grid item xs="auto">
        <Hidden smDown>{props.children}</Hidden>
      </Grid>
    </TableContainer>
  )
}

const getSelectedPeriodDays = uiRefreshTimePeriod => {
  let days = []
  let iDay = moment(uiRefreshTimePeriod.startISO).utc().startOf('day')
  const end = moment(uiRefreshTimePeriod.endISO).utc().startOf('day')
  days.push(iDay.toISOString().replace('Z', ''))

  while (iDay.diff(end, 'days') !== 0) {
    iDay = iDay.clone().add(1, 'day')
    days = [...days, iDay.toISOString().replace('Z', '')]
  }
  return days
}

TableView.propTypes = {
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  buildCurrentEditableEntry: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  handleCreateEntrySubmission: PropTypes.func.isRequired,
  handleUpdateEntrySubmission: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  showEntryInvoicedModal: PropTypes.func.isRequired,
  contextMenuActions: PropTypes.shape({
    deleteEntry: PropTypes.func.isRequired,
    duplicateEntry: PropTypes.func.isRequired,
  }).isRequired,
  handleNoFreeGapModalOpen: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  mostRecentlyUsedProjectIds: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isDayViewPeriodSelected: PropTypes.bool,
  person: PropTypes.object.isRequired,
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakpoints.md,
})

export default withSizes(mapSizesToProps)(TableView)
