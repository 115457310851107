import Moment from 'moment'
import _ from 'lodash'

Moment.fn.toLocalString = function() {
  return this.format('YYYY-MM-DDTHH:mm:ss.SSS')
}

Moment.fn.toLocalStringAsUTC = function() {
  return this.format('YYYY-MM-DDTHH:mm:ss.SSS').concat('Z')
}

export default function moment(...args) {
  return _.isEmpty(args) ? Moment() : Moment(...args)
}
