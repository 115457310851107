import gql from 'graphql-tag'

export const GET_ALL_PEOPLE = gql`
  query {
    getAllPeople {
      _id
      startDate
      endDate
      fullName
      email
      division
      status
      position
      employeeType
    }
  }
`
