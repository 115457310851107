import gql from 'graphql-tag'
import { gql as apolloGql } from '@apollo/client'

export const GET_DELEGATE_ACCESS_MINE = gql`
  query($shouldRefresh: Boolean) {
    delegateAccess_getMine(shouldRefresh: $shouldRefresh) {
      _id
      fromId
      fromFullName
      fromPosition
      fromEmployeeType
      startDate
      toId
      hasEmailAccess
      hasCalendarAccess
    }
  }
`
export const GET_DELEGATE_ACCESS_GIVEN = apolloGql`
  query {
    delegateAccess_getGiven {
      _id
      fromId
      toId
      toFullName
      hasEmailAccess
      hasCalendarAccess
    }
  }
`
export const UPDATE_DELEGATE_ACESS = apolloGql`
  mutation($_id: String!, $hasEmailAccess: Boolean!, $hasCalendarAccess: Boolean!) {
    delegateAccess_update(
			_id: $_id,
      hasEmailAccess: $hasEmailAccess,
			hasCalendarAccess: $hasCalendarAccess
    )
  }
`
