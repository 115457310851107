import { useMutation } from '@apollo/client'
import { UPDATE_DELEGATE_ACESS } from '../../entities/delegateAccess/service/query'

const useDelegateAccessMutation = () => {
  const [updateDelegateAccess, { data, loading, error }] = useMutation(UPDATE_DELEGATE_ACESS)

  return { updateDelegateAccess, data, loading, error }
}

export default useDelegateAccessMutation
