import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import Modal from '../common/Modal'
import updates from './updates'

import { ReleaseDate, NewTitle, NewSubtitle, NewParagraph, NewRelease, NewUL } from './style'

const WhatsNew = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="WHAT'S NEW">
      <div className="updates">
        {updates
          .map(release => {
            return (
              <NewRelease key={release.date} className="release">
                <ReleaseDate time={release.date}>
                  {moment(release.date).format('MMMM Do YYYY')}
                </ReleaseDate>
                <div className="news">
                  {release.news.map(currentNew => {
                    return (
                      <div key={currentNew.title || currentNew.subtitle}>
                        {currentNew.title ? <NewTitle>{currentNew.title}</NewTitle> : null}
                        {currentNew.subtitle ? (
                          <NewSubtitle>{currentNew.subtitle}</NewSubtitle>
                        ) : null}
                        {currentNew.text ? <NewParagraph>{currentNew.text}</NewParagraph> : null}
                        {currentNew.items ? (
                          <NewUL>
                            {currentNew.items.map(element => {
                              return <li key={element.text}>{element.text}</li>
                            })}
                          </NewUL>
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              </NewRelease>
            )
          })
          .reverse()}
      </div>
    </Modal>
  )
}

WhatsNew.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default WhatsNew
