import _ from 'lodash'
import { getCellsConfiguration } from '.'

export const TABLE_DEFAULTS = {
  HEADER_HEIGHT: 26,
  ROW_HEIGHT: 40,
  DISPLAYED_ROWS: 1,
}

export const COL_HEADERS = ['Project', '$', 'Comment', 'Duration', 'Entry ID']

const getTableHeight = data => {
  const HEIGHT_OFFSET = 2
  const { HEADER_HEIGHT, ROW_HEIGHT, DISPLAYED_ROWS } = TABLE_DEFAULTS

  const nRows = data.length + DISPLAYED_ROWS
  return nRows * ROW_HEIGHT + HEADER_HEIGHT + HEIGHT_OFFSET
}

export const getHotDisabledState = ({ timesheets, day }) => {
  const timesheet = timesheets.find(t => t.startDate === day)
  const isDisabled = _.get(timesheet, 'approvalStatus') === 'Approved'
  const doesntExist = !timesheet

  return isDisabled || doesntExist
}

export const inyectMeasure = (width, settings) => ({
  ...settings,
  colWidths: [width * 0.3, width * 0.15, width * 0.45, width * 0.099, width * 0.001],
})

export const getHotSettings = ({
  data,
  timeEntries,
  projects,
  afterOnCellMouseDown,
  afterChange,
  beforeKeyDown,
  beforeColumnSort,
  contextMenuActions,
  isHotDisabled,
  beforeChange,
  hotInstance,
}) => {
  const cellsConfig = getCellsConfiguration({
    timeEntries,
    projects,
    colHeaders: COL_HEADERS,
    data,
  })
  const tableHeight = getTableHeight(data)
  const isColumnSortingEnabled = data.length > 1

  const getContextMenu = () => {
    if (isHotDisabled) return false

    return {
      items: {
        delete_entry_custom: {
          name: 'Delete',
          callback: (key, selection) => {
            const { row: rowToDelete } = selection[0].start
            // we need hot instace data bc it does not get update with filters
            const upToDateData = hotInstance.getData()

            const dataTimeEntryIdCol = 4
            const timeEntryId = upToDateData[rowToDelete][dataTimeEntryIdCol]

            const entryToDelete = timeEntries.find(entry => entry._id === timeEntryId)

            if (_.isNil(entryToDelete)) return
            contextMenuActions.deleteEntry(entryToDelete)
          },
        },
        duplicate_entry_custom: {
          name: 'Duplicate',
          callback: (key, selection) => {
            const { row: rowToDuplicate } = selection[0].start
            // we need hot instace data bc it does not get update with filters
            const upToDateData = hotInstance.getData()

            const dataTimeEntryIdCol = 4
            const timeEntryId = upToDateData[rowToDuplicate][dataTimeEntryIdCol]

            const entryToDuplicate = timeEntries.find(entry => entry._id === timeEntryId)

            if (_.isEmpty(entryToDuplicate)) return
            contextMenuActions.duplicateEntry(entryToDuplicate)
          },
        },
      },
    }
  }

  const settings = {
    data,
    readOnly: isHotDisabled,
    minSpareRows: isHotDisabled ? 0 : 1,
    disableVisualSelection: ['header', 'area'],
    colHeaders: COL_HEADERS,
    cell: cellsConfig,
    afterOnCellMouseDown,
    enterMoves: {
      row: 0,
      col: 1,
    },
    afterChange,
    height: tableHeight,
    columnHeaderHeight: TABLE_DEFAULTS.HEADER_HEIGHT,
    rowHeights: TABLE_DEFAULTS.ROW_HEIGHT,
    contextMenu: getContextMenu(),
    beforeKeyDown,
    beforeColumnSort,
    columnSorting: isColumnSortingEnabled,
    beforeChange,
  }

  return settings
}
