import React from 'react'
import PropTypes from 'prop-types'

import { TIMESHEET_VIEWS } from '../../../constants/glimpseViews'
import eventUtils from '../../../utils/eventUtils'
import ProgressBar from './ProgressBar'
import AddAllEventsButton from '../AddAllEventsButton'
import {
  DailyViewBar,
  StylessButton,
  EventsContainer,
  AllDayEvent,
  EventsToolBar,
  AllDayEventContainer,
  ResponsiveDiv,
} from './styles'

const getAllDayEvents = events => {
  return events.reduce((allDayEvents, event) => {
    if (event.isAllDay === true) allDayEvents.push(event)
    return allDayEvents
  }, [])
}

const DailyProgressBar = props => {
  const className = `calendar-events-offset ${props.isDayViewPeriodSelected ? 'visible' : ''}`
  const allDayEvents = getAllDayEvents(props.exchangeEvents)
  return (
    <DailyViewBar>
      <div className="progress-bar-daily list-view">
        <div className="left-blank-div" />
        <ProgressBar
          key={props.selectedDate.getTime() + 1}
          date={props.selectedDate}
          events={eventUtils.getEventsBySelectedDate(props.events, props.selectedDate)}
          timesheet={eventUtils.getTimesheetForDay(props.selectedDate, props.timesheets)}
          isConnected={props.isConnected}
        />
        {props.view === TIMESHEET_VIEWS.TIMELINE && (
          <ResponsiveDiv className={className}>
            <EventsContainer>
              <EventsToolBar>
                <AddAllEventsButton
                  isTimesheetSubmitted={props.isTimesheetSubmitted}
                  isConnected={props.isConnected}
                  handleCreateEntriesForAllEvents={props.handleCreateEntriesForAllEvents}
                  timeEntries={props.timeEntries}
                  localEntries={props.localEntries}
                  exchangeEvents={props.exchangeEvents}
                  isSubmitInProgress={props.isSubmitInProgress}
                  isFetchingTimesheetData={props.isFetchingTimesheetData}
                  uiRefreshTimePeriod={props.uiRefreshTimePeriod}
                />
                <StylessButton
                  type="button"
                  className={`events-link ${props.isEmailSelected ? '' : ' disabled-link'}`}
                  onClick={props.selectCalendarEvents}
                >
                  Events
                </StylessButton>
              </EventsToolBar>
              {allDayEvents.length > 0 && (
                <AllDayEvent>
                  {allDayEvents.map(allDayEvent => (
                    <AllDayEventContainer key={allDayEvent.subject}>
                      {allDayEvent.subject}
                    </AllDayEventContainer>
                  ))}
                </AllDayEvent>
              )}
            </EventsContainer>
            <StylessButton
              type="button"
              className={`calendar-link ${props.isEmailSelected ? ' disabled-link' : ''}`}
              onClick={props.selectEmails}
            >
              Emails
            </StylessButton>
          </ResponsiveDiv>
        )}
      </div>
    </DailyViewBar>
  )
}

DailyProgressBar.propTypes = {
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  isEmailSelected: PropTypes.bool.isRequired,
  selectCalendarEvents: PropTypes.func.isRequired,
  selectEmails: PropTypes.func.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
}

export default DailyProgressBar
