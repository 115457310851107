import React from 'react'

import Grid from '@material-ui/core/Grid'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { DelegateAccessModelButton } from '../style'
import { getFunnelUser } from '../../../server/store'

const getBodyEmail = (email, name) => {
  return `User ${email} (${name}) wants to grant delegate access to another user. Please reach out to this user so this action can be completed.`
}

const DelegateAccessButtons = () => {
  const handleRequestIt = () => {
    const user = getFunnelUser()
    if (!user) return
    window.location = `mailto:labs_franklin@keystonestrategy.com?subject=Request Delegate Access&body=${getBodyEmail(
      user.email,
      user.fullName,
    )}`
  }

  return (
    <Grid container spacing={5} style={{ marginTop: '1rem' }}>
      <Grid item>
        <DelegateAccessModelButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<RecordVoiceOverIcon style={{ fontSize: 25 }} />}
          onClick={() => handleRequestIt()}
        >
          Request IT to add a profile to your list
        </DelegateAccessModelButton>
      </Grid>
    </Grid>
  )
}
export default DelegateAccessButtons
