import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import _ from 'lodash'
import { Container, ContainerProfile, UserName } from './styles'
import Selector from './Selector'

class Header extends React.PureComponent {
  render() {
    const {
      currentWeek,
      currentViewSelected,
      isDisabledProjectView,
      isDisabledPartnerView,
      setSelfView,
      setProjectView,
      setPartnerView,
      history,
      person,
      goToNextWeek,
      goToPreviousWeek,
      isConnected,
    } = this.props
    const userName = _.get(person, 'fullName', '')
    return (
      <Container>
        <ContainerProfile>
          <IconButton
            onClick={() => history.push('/')}
            size="medium"
            style={{ backgroundColor: '#2196f3' }}
          >
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <UserName>{userName}</UserName>
        </ContainerProfile>
        <Selector
          currentWeek={currentWeek}
          currentViewSelected={currentViewSelected}
          isDisabledProjectView={isDisabledProjectView}
          isDisabledPartnerView={isDisabledPartnerView}
          setSelfView={setSelfView}
          setProjectView={setProjectView}
          setPartnerView={setPartnerView}
          goToNextWeek={goToNextWeek}
          goToPreviousWeek={goToPreviousWeek}
          isConnected={isConnected}
        />
      </Container>
    )
  }
}

Header.propTypes = {
  currentWeek: PropTypes.string.isRequired,
  currentViewSelected: PropTypes.string.isRequired,
  isDisabledProjectView: PropTypes.bool.isRequired,
  isDisabledPartnerView: PropTypes.bool.isRequired,
  setSelfView: PropTypes.func.isRequired,
  setProjectView: PropTypes.func.isRequired,
  setPartnerView: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  goToNextWeek: PropTypes.func.isRequired,
  goToPreviousWeek: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default Header
