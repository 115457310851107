import EntryAction from '../models/entryAction'
import { logError } from '../../utils/logger'
import { DELETE } from '../../models/entryOperation'

export const removeEntryFromQueue = async entryOperation => {
  try {
    await EntryAction.where('sourceId')
      .equals(entryOperation.sourceId)
      .delete()
  } catch (e) {
    logError(e, 'actions > entryActionActions > removeEntryFromQueue > e: ')
  }
}

export const updateLocalEntry = async operationToUpdate => {
  try {
    await EntryAction.where('sourceId')
      .equals(operationToUpdate.sourceId)
      .modify({ data: operationToUpdate.data })
  } catch (e) {
    logError(e, 'actions > entryActionActions > updateLocalEntry > e: ')
  }
}

export const convertToDeleteAction = async entryOperation => {
  try {
    await EntryAction.where('sourceId')
      .equals(entryOperation.sourceId)
      .modify(value => {
        // This is how dexie works
        value.action = DELETE // eslint-disable-line
      })
  } catch (e) {
    logError(e, 'actions > entryActions > updateLocalEntry > e: ')
  }
}

export const handleSafeExecEntryAction = async (actionFunc, onGenericError, onConnectionError) => {
  try {
    const entry = await actionFunc()
    return entry
  } catch (e) {
    if (
      e.name.includes('Network') ||
      e.name.includes('network') ||
      e.message.includes('Network') ||
      e.message.includes('network')
    ) {
      await onConnectionError()
    } else {
      await onGenericError(e)
    }
    return null
  }
}
