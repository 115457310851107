import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function AlertMessage({
  isOpen,
  handleClose,
  message,
  severity, // "error" - " warning" - "info" - "success"
  duration,
  verticalPos,
  horizontalPos,
}) {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration || 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: verticalPos || 'top', horizontal: horizontalPos || 'center' }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

AlertMessage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  duration: PropTypes.number,
  verticalPos: PropTypes.string,
  horizontalPos: PropTypes.string,
}

export default AlertMessage
