import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ProjectTableWrapper, ProjectContainer } from './styles'
import BasicTable from '../Table'

class PartnerView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return { projectSelected: this.props.currentProject }
  }

  handleSelectProject = project => {
    this.setState({ projectSelected: project })
    this.props.setCurrentProject(project)
  }

  renderProjectSelector = () => {
    const { projects } = this.props

    return (
      <ProjectContainer>
        <Autocomplete
          id="project-selector"
          options={projects}
          getOptionLabel={option => option.name}
          value={this.state.projectSelected}
          onChange={(event, newValue) => {
            this.handleSelectProject(newValue)
          }}
          renderInput={params => (
            <TextField {...params} label="Select a Project to see the Assignments" required />
          )}
        />
      </ProjectContainer>
    )
  }

  groupAssignmentsByProject = () => {
    const { projectAssignments, projects, people, currentProject } = this.props
    const assignments = _.map(projectAssignments, assignment => {
      const findProject = _.find(projects, project => project._id === assignment.projectId)
      const findPerson = _.find(people, person => person._id === assignment.personId)
      return { ...assignment, project: findProject, person: findPerson }
    })
    return { [currentProject?.projectCode]: assignments }
  }

  renderTable = () => {
    const groupedAssignments = this.groupAssignmentsByProject()
    const projectCode = this.state.projectSelected?.projectCode
    return (
      <ProjectTableWrapper>
        {this.renderProjectTable(projectCode, groupedAssignments[projectCode])}
      </ProjectTableWrapper>
    )
  }

  renderProjectTable = (projectCode, assignments) => {
    const {
      shouldBeAbleToEdit,
      setCurrentEditableAssignment,
      currentEditableAssignment,
      isConnected,
    } = this.props
    if (assignments.length === 0) {
      return <span>There are no Assignments for the Project in the selected week. </span>
    }
    const columns = [
      'Person Name',
      'Project Code',
      'Start Date',
      'End Date',
      'Staffing Category',
      'Percent',
    ]
    const fields = [
      '_id',
      'person.fullName',
      'project.projectCode',
      'startDate',
      'endDate',
      'investment',
      'percent',
    ]
    const rows = _.map(assignments, assignment => {
      return _.reduce(
        fields,
        (accum, field) => {
          const value = _.get(assignment, field)
          if (field === 'startDate' || field === 'endDate') {
            return { ...accum, [field]: moment.utc(value).format('MM/DD/YYYY') }
          }
          return { ...accum, [field]: value }
        },
        {},
      )
    })
    return (
      <>
        <BasicTable
          rows={rows}
          columns={columns}
          shouldBeAbleToEdit={shouldBeAbleToEdit}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          currentEditableAssignment={currentEditableAssignment}
          isConnected={isConnected}
        />
      </>
    )
  }

  render = () => {
    return (
      <>
        {this.renderProjectSelector()}
        {this.renderTable()}
      </>
    )
  }
}

PartnerView.propTypes = {
  projectAssignments: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  people: PropTypes.array.isRequired,
  shouldBeAbleToEdit: PropTypes.bool.isRequired,
  setCurrentEditableAssignment: PropTypes.func.isRequired,
  currentEditableAssignment: PropTypes.object,
  currentProject: PropTypes.object,
  setCurrentProject: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default PartnerView
