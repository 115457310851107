import React from 'react'
import { Button, withStyles } from '@material-ui/core'
import Clear from '@material-ui/icons/Clear'
import styled from 'styled-components'

export const Container = styled.div`
  flex-grow: 1;
  height: auto;
  width: 100%;
  position: relative;
  margin-top: -32px;
  @media (max-width: 960px) {
    margin: 0;
  }
`

export const SuggestionContainer = styled.div`
  background-color: #fff;
  position: absolute;
  max-height: ${props => props.height || '450px'};
  width: ${props => props.width || 'auto'};
  top: ${props => props.top || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  overflow-y: scroll;
  z-index: 1200;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  transition: height 0.2s ease;
`

export const ExpandIconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const ClearIcon = withStyles(() => ({
  root: {
    fontSize: 15,
    color: '#999',
  },
}))(props => <Clear {...props} />)

export const ClearButton = withStyles(() => ({
  root: {
    margin: 0,
    minWidth: 0,
    padding: 0,
    marginRight: 10,
  },
}))(props => (
  <Button {...props}>
    <ClearIcon />
  </Button>
))

export const SuggestionSectionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${({ highlighted }) => (highlighted ? '#efefef' : 'inherit')};

  &:hover {
    cursor: pointer;
    background-color: #efefef;
  }

  &:focus {
    background-color: #efefef;
    outline: none;
  }
`

export const TextContainer = styled.div`
  flex-basis: 100%;
  height: 100%;
  margin-left: 40px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999;
  color: #666;
`

export const Text = styled.div`
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => (props.disabled ? '#999999' : '#333333')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const Favorite = styled.div`
  margin-left: auto;
  min-width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SuggestionSectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`

export const IconContainer = styled.div`
  min-width: 40px;
  text-align: center;
`

export const TitleContainer = styled.div`
  flex-basis: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-bottom: 1px solid #999;
  color: #666;
  padding-left: 5px;
`

export const Title = styled.div`
  font-size: 14px;
  line-height: 1em;
`

export const Description = styled.div`
  font-size: 12px;
  color: #999;
  font-weight: 300;
  line-height: 1em;
`
