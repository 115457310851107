import React from 'react'
import PropTypes from 'prop-types'
import {
  SuggestionSectionTitleContainer,
  IconContainer,
  TitleContainer,
  Title,
  Description,
} from './styles'

const iconStyle = { fontSize: '1.1 rem', color: '#666' }

class SuggestionSectionTitle extends React.Component {
  renderIcon = Icon => {
    return <Icon style={iconStyle} />
  }

  render() {
    return (
      <SuggestionSectionTitleContainer>
        <IconContainer>{this.renderIcon(this.props.icon)}</IconContainer>
        <TitleContainer>
          <Title>{this.props.title}</Title>
          <Description>{this.props.description}</Description>
        </TitleContainer>
      </SuggestionSectionTitleContainer>
    )
  }
}

SuggestionSectionTitle.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default SuggestionSectionTitle
