import styled from 'styled-components'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { PLAY, STOP } from './modes'

export const Container = styled.div`
  grid-area: timer;

  display: flex;
  align-items: center;
`

const buttonColors = {
  [PLAY]: blue[500],
  [STOP]: red[500],
}

const backgroundColorSelector = props =>
  props.disabled ? 'rgba(0, 0, 0, 0.12)' : buttonColors[props.mode]

export const ButtonContainer = styled.button`
  background-color: ${backgroundColorSelector};
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;

  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
