import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import config from '../config'
import { getFunnelUser } from '../server/store'

const { IS_WEB, VERSION, SENTRY_DSN } = config

export const init = showSentryError =>
  Sentry.init({
    ignoreErrors: [
      'Failed to fetch',
      'network error',
      'Network Error',
      'No activity within 45000 milliseconds. Reconnecting',
      'Error: ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    dsn: SENTRY_DSN,
    beforeSend(event) {
      showSentryError(event)
      return event
    },
  })

export const setContext = () => {
  const user = getFunnelUser()
  if (!user || _.isEmpty(user)) return

  Sentry.configureScope(scope => {
    scope.setUser({
      _id: user._id,
      email: user.email,
    })
    scope.setTag('isWeb', IS_WEB)
    scope.setTag('version', VERSION)
  })
}

export const captureException = Sentry.captureException

export const captureMessage = Sentry.captureMessage

export const getSentryErrorMessage = event => {
  const errorValue = _.get(event, 'exception.values[0].value')
  const errorType = _.get(event, 'exception.values[0].type')
  return errorType || errorValue ? `${errorType}: ${errorValue}` : ''
}
