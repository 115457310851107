import styled from 'styled-components'

export const WrapperContainer = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  padding: 0;
  position: relative;
`

export const DraggableContainer = styled.div`
  width: 100%;
  position: relative;
`

export const DraggableAnchorLine = styled.div`
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 1em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  transform: none !important;

  &::before {
    content: '';
    width: 16px;
    height: 4px;
    position: absolute;
    border-top: 1px solid #888888;
    border-bottom: 1px solid #888888;
    left: 50%;
    margin-left: -8px;
    margin-top: 4px;
  }

  &:hover {
    cursor: row-resize;
  }
`

export const EventListArrowIconWrapper = styled.div`
  position: absolute;
  top: 7px;
  right: 5px;

  &:hover {
    cursor: pointer;
  }
`

export const EventsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  flexContainer: {
    justifyContent: 'space-around',
  },
  AppBarRoot: {
    boxShadow:
      '0px 1px 4px -1px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1)',
  },
  tabsRoot: {
    marginRight: '25px',
    height: '33px',
    minHeight: '33px',
  },
  tabsIndicator: {
    backgroundColor: '#0072C6',
  },
  tabSelected: {
    color: '#40a9ff',
  },
  tabRoot: {
    fontSize: '1rem',
    textTransform: 'initial',
    minWidth: 32,
    minHeight: 32,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
})

export const EventListButton = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`
