/* eslint-disable max-classes-per-file */
export class ConnectionError extends Error {
  constructor({ message }) {
    super()
    Error.captureStackTrace(this, this.constructor)
    this.name = 'ConnectionError'
    this.message = message
  }
}

export class TimesheeIsAlreadyApprovedError extends Error {
  constructor() {
    super()
    Error.captureStackTrace(this, this.constructor)
    this.name = 'TimesheeIsAlreadyApprovedError'
  }
}
