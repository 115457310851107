/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { WeeklyViewBar, StylessButton } from './styles'
import eventUtils from '../../../utils/eventUtils'
import ProgressBar from './ProgressBar'
import timeUtils from '../../../utils/timeUtils'

const WeeklyProgressBar = props => {
  const renderDaysOfWeek = () => {
    const weekDates = timeUtils.getSelectedDayWeekDays(props.selectedDate)
    return _.map(weekDates, date => (
      <ProgressBar
        key={date.getTime() + 1}
        date={date}
        events={eventUtils.getEventsBySelectedDate(props.events, date)}
        timesheet={eventUtils.getTimesheetForDay(date, props.timesheets)}
        isConnected={props.isConnected}
      />
    ))
  }

  const handleNavigateToDay = item => () => {
    props.handleCalendarSelect(item.props.date)
    props.setViewPeriodDay()
  }

  const daysOfWeekWithName = renderDaysOfWeek()

  return (
    <WeeklyViewBar>
      <div className="days-of-week">
        <div className="calendar-slider">
          {_.map(daysOfWeekWithName, (item, index) => (
            <div className="day-header" key={index}>
              <StylessButton onClick={handleNavigateToDay(item)} className="week-label">
                {moment(item.props.date).format('ddd D')}
              </StylessButton>
              {item}
            </div>
          ))}
        </div>
      </div>
    </WeeklyViewBar>
  )
}

WeeklyProgressBar.propTypes = {
  events: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
}

export default WeeklyProgressBar
