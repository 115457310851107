import _ from 'lodash'
import timeUtils from '../../../utils/timeUtils'
import innerText from '../../../lib/innerText'

export const shouldRenderButton = (entries, event) => {
  const entriesWithEvents = entries.filter(entry => entry.referenceId)
  return entriesWithEvents.every(el => el.referenceId !== event.id)
}
export const parseInnerTextFromElement = elements => {
  const innerElementText = innerText(elements)
  return _.trim(innerElementText)
}

export const getTimeRange = (start, end, date) => {
  if (timeUtils.areSameDay(start, end)) {
    // same day, format normally
    return `${timeUtils.formatTime(start, 'ampm')} - ${timeUtils.formatTime(end, 'ampm')}`
  }

  if (timeUtils.areSameDay(start, date)) return `Starts at ${timeUtils.formatTime(start, 'ampm')}`

  if (timeUtils.areSameDay(end, date)) return `Ends at ${timeUtils.formatTime(end, 'ampm')}`

  return 'All day'
}

export const removeTagsFromBody = body => {
  const newBody = body
    .replace(/<script([\s\S]*?)<\/script>/g, '')
    .replace(/<meta[^>]+>/g, '')
    .replace(/<body[^>]+>/g, '')
    .replace(/<body>/g, '')
    .replace('</body>', '')
    .replace(/<html>/g, '')
    .replace('</html>', '')
    .replace(/<head>/g, '')
    .replace('</head>', '')
  return newBody
}

const excludeBodyTags = ['img']

export const bodyParserOptions = {
  transform: node => {
    if (node.type === 'tag' && excludeBodyTags.includes(node.name)) {
      return null
    }
  },
}

export const isElementInViewport = (element, container, offset = 0) => {
  const cTop = container.scrollTop + offset
  const cBottom = cTop + container.clientHeight

  // Get element properties
  const eTop = element.offsetTop
  const eBottom = eTop + element.clientHeight

  // Check if in view
  const isTotal = eTop >= cTop && eBottom <= cBottom

  // Return outcome
  return isTotal
}
