import styled from 'styled-components'
import Box from '@material-ui/core/Box'

export const Container = styled(Box)`
  display: grid;
  padding: 12px;
  grid-template-columns: 2fr 8fr 2fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas:
    'profile notifications menu'
    'period calendar views'
    'timer totals submit';
  z-index: 250;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  @media (min-width: 768px) {
    grid-template-columns: 2fr 24fr 2fr;
  }
`

export const ContainerProfile = styled(Box)`
  grid-area: profile;
  display: flex;
`

export const UserName = styled.span`
  margin: auto;
  margin-left: 8px;

  font-size: 12px;
`
