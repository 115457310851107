import React from 'react'
import PropTypes from 'prop-types'
import AddCircle from '@material-ui/icons/AddCircle'

import { getEventsFromEntriesInfo } from '../utils'
import CustomTooltip from '../../../components/CustomTooltip'
import { ButtonContainer } from './styles'

const AddAllEventsButton = props => {
  const getTooltipTitle = (uiExchangeEventCount, areAllEventsAdded, isTimesheetSubmitted) => {
    if (isTimesheetSubmitted) return 'Cannot add events to a submitted timesheet'
    if (uiExchangeEventCount === 0) return 'No events available'
    if (areAllEventsAdded) return 'All events have been added'
    return 'Add all events'
  }

  const { startISO, endISO } = props.uiRefreshTimePeriod
  const { uiExchangeEvents, areAllEventsAdded } = getEventsFromEntriesInfo({
    startISO,
    endISO,
    exchangeEvents: props.exchangeEvents,
    timeEntries: props.timeEntries,
    localEntries: props.localEntries,
  })
  const uiExchangeEventCount = uiExchangeEvents.length
  const tooltipTitle = getTooltipTitle(
    uiExchangeEventCount,
    areAllEventsAdded,
    props.isTimesheetSubmitted,
  )

  const isAddAllEventsButtonBlocked =
    props.isFetchingTimesheetData ||
    areAllEventsAdded ||
    props.isSubmitInProgress ||
    uiExchangeEventCount === 0 ||
    !props.isConnected ||
    props.isTimesheetSubmitted

  return (
    <CustomTooltip title={tooltipTitle} aria-label="Add" placement="left">
      <span style={{ marginTop: '2px' }}>
        <ButtonContainer
          disabled={isAddAllEventsButtonBlocked}
          type="button"
          onClick={() => props.handleCreateEntriesForAllEvents(true)}
        >
          <AddCircle style={{ fontSize: 14, color: '#666' }} />
        </ButtonContainer>
      </span>
    </CustomTooltip>
  )
}

AddAllEventsButton.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
}

export default AddAllEventsButton
