import _ from 'lodash'
import GET_EVENTS from '../query'
import { requestPersistently } from '../../../utils/request'
import {
  getOutlookEventDeltaToken,
  updateOutlookEventDeltaToken,
} from '../../../../../server/store'

const DATA_LIMIT_PER_REQUEST = 100

export const requestEvents = async ({ client, params }) => {
  const { from, to, isDelta, reSyncDelta, selectedDelegateId } = params

  let nextDeltaLink = null
  let toReturn = []
  do {
    const requestDeltaToken = getOutlookEventDeltaToken()

    const requestProps = {
      client,
      type: 'query',
      options: {
        query: GET_EVENTS,
        variables: {
          startDateTime: from,
          endDateTime: to,
          top: isDelta ? undefined : DATA_LIMIT_PER_REQUEST,
          isCancelled: false,
          isDelta: isDelta || reSyncDelta,
          nextDeltaLink: nextDeltaLink != null ? nextDeltaLink : undefined,
          deltaToken:
            isDelta && !reSyncDelta && requestDeltaToken !== '' ? requestDeltaToken : undefined,
          selectedDelegateId,
        },
      },
    }
    const response = await requestPersistently({ requestProps })

    const deltaToken = _.get(response, 'data.getEvents.deltaToken')
    if (deltaToken && typeof deltaToken === 'string') updateOutlookEventDeltaToken(deltaToken)

    const toAdd = _.get(response, 'data.getEvents.deltaEvents', [])
    toReturn = [...toReturn, ...toAdd]

    const currentLink = nextDeltaLink
    nextDeltaLink = _.get(response, 'data.getEvents.nextDeltaLink', null)
    if (nextDeltaLink === currentLink) {
      nextDeltaLink = null
    }
  } while (nextDeltaLink != null)
  return toReturn
}
