import { isEmpty } from 'lodash'
import Mousetrap from 'mousetrap'
import eventUtils from '../../../utils/eventUtils'
import { keysCodes } from '../../../utils/keyboard'
import {
  FUNNEL_ENTRIES_RESOURCE_ID,
  placeholderEventMock,
  createOneHourPlaceholderAfterLastestTimeEntry,
} from '../../../constants/events'

import { isEntryValid } from '../../../models/entry'
import { getFreeGap } from '../utils'

export const entryCreationKeyDown = ({
  currentEditableEntry,
  selectedDayEntries,
  selectedDate,
  addCalendarPlaceholderEvent,
  onEntrySubmit,
  onEntryUpdate,
  isDayViewPeriodSelected,
  isTimesheetLocked,
  handleNoFreeGapModalOpen,
}) => {
  Mousetrap.bind(['command+b', 'ctrl+b'], async () => {
    if (isTimesheetLocked) return
    if (isEmpty(currentEditableEntry) && isDayViewPeriodSelected) {
      const freeGap = getFreeGap({ selectedDate, selectedDayEntries })

      if (!freeGap) {
        handleNoFreeGapModalOpen(true)
        return
      }
      addCalendarPlaceholderEvent({
        ...placeholderEventMock,
        start: freeGap.start,
        end: freeGap.end,
      })
    }

    if (!isEntryValid(currentEditableEntry)) return

    const { start, end } = createOneHourPlaceholderAfterLastestTimeEntry(currentEditableEntry)

    const actionToPerform = eventUtils.isPlaceholderEntry(currentEditableEntry)
      ? onEntrySubmit
      : onEntryUpdate

    await actionToPerform(currentEditableEntry)

    setTimeout(() => {
      addCalendarPlaceholderEvent({ ...placeholderEventMock, start, end })
    }, 500)
  })
}

export const onKeyDownEvent = ({
  event,
  // accumulateAndMoveDown,
  // accumulateAndMoveUp,
  // move,
  onTimeEntrySlotClick,
  deselectSlot,
  selectedArea,
}) => {
  event.preventDefault()
  if (event.nativeEvent.code === keysCodes.down) {
    /* if (event.shiftKey) {
      accumulateAndMoveDown()
    } else {
      move('down')
    } */
  }

  if (event.nativeEvent.code === keysCodes.up) {
    /* if (event.shiftKey) {
      accumulateAndMoveUp()
    } else {
      move('up')
    } */
  }

  if (event.nativeEvent.code === keysCodes.enter) {
    onTimeEntrySlotClick({
      ...selectedArea,
      resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
    })
    deselectSlot()
  }

  if (event.nativeEvent.code === keysCodes.esc) {
    deselectSlot()
  }
}

export const entryListKeyDown = ({ event, onEntryDelete, entry }) => {
  Mousetrap.bind(
    ['command+delete', 'ctrl+delete', 'command+backspace', 'ctrl+backspace'],
    async () => {
      event.preventDefault()
      onEntryDelete(entry)
    },
  )
}
