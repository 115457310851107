import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { HiddenInput } from './styles'
import { mapStateToProps, mapDispatchToProps } from './store'
import FloatingMenu from './FloatingMenu'

const GlimpseMenu = props => {
  const [anchorMenu, setAnchorMenu] = useState(null)

  const handleMenuClick = event => {
    setAnchorMenu(event.currentTarget)
  }

  const isOpen = Boolean(anchorMenu)

  return (
    <div>
      <IconButton
        aria-label="Menu"
        aria-owns={isOpen ? 'glimpse-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <HiddenInput
        disabled={!props.isConnected}
        accept=".csv"
        id="contained-button-file"
        type="file"
        onChange={e => {
          if (!props.isConnected) return
          props.handleUpload(e)
          e.target.value = null
        }}
      />
      <FloatingMenu
        logout={props.logout}
        handleReloadApp={props.handleReloadApp}
        handleSwitchIsIssueReportModalOpen={props.handleSwitchIsIssueReportModalOpen}
        handleSwitchIsDelegateAccessModalOpen={props.handleSwitchIsDelegateAccessModalOpen}
        handleCreateEntriesForAllEvents={props.handleCreateEntriesForAllEvents}
        timeEntries={props.timeEntries}
        localEntries={props.localEntries}
        exchangeEvents={props.exchangeEvents}
        isSubmitInProgress={props.isSubmitInProgress}
        isFetchingTimesheetData={props.isFetchingTimesheetData}
        uiRefreshTimePeriod={props.uiRefreshTimePeriod}
        isConnected={props.isConnected}
        setAnchorMenu={setAnchorMenu}
        anchorMenu={anchorMenu}
        reminderSettings={props.reminderSettings}
        handleSetReminderSettings={props.handleSetReminderSettings}
        isTimesheetSubmitted={props.isTimesheetSubmitted}
        person={props.person}
      />
    </div>
  )
}

GlimpseMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,

  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  isConnected: PropTypes.bool.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlimpseMenu)
