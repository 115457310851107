import React from 'react'
import styled from 'styled-components'
import { createTheme } from '@material-ui/core/styles'
import { Button, Checkbox, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export const EntryBoxEditContainer = styled.div`
  margin: ${props => (props.margin ? props.margin : '8px')};
  padding: 6px;
  border: ${props => (props.border ? props.border : '1px solid #c3c3c3')};
`

export const Container = styled.div`
  padding: 10px 20px 20px;
  width: 100%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
`

export const EntryTimelineViewContainer = styled.div.attrs(props => ({
  isDayViewPeriodSelected: props.isDayViewPeriodSelected,
}))`
  width: ${props => (props.isDayViewPeriodSelected ? 'calc(100% - 32px)' : 'calc(100% - 16px)')};
  z-index: 1000;
  margin: ${props => (props.isDayViewPeriodSelected ? '16px' : '0')};
  position: ${props => (props.isDayViewPeriodSelected ? 'relative' : 'absolute')};
  bottom: 0;
  right: ${props => (props.isDayViewPeriodSelected ? '0' : '16px')};
`

export const EntryListContainer = styled.div`
  margin: 16px;
`

export const CloseButton = withStyles(() => ({
  root: {
    position: 'absolute',
    right: 5,
    top: 5,
    margin: 0,
    minWidth: 0,
    padding: 0,
  },
}))(props => <Button {...props} />)

export const DeleteButton = withStyles(() => ({
  root: {
    padding: 0,
    minWidth: 0,
  },
}))(props => <Button {...props} />)

export const Column = styled.div`
  width: ${({ width }) => width || '100%'};
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`

export const ProjectContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 50%;
    margin-right 10px;
  }
`

export const FlexWrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DefaultProjectWrapper = styled.div`
  font-size: 0.8rem;
  margin-left: auto;
  margin-right: 15px;
`

export const DefaultProjectButton = styled.button`
  color: ${props => (props.disabled ? '#A6A6A6' : '#3789e2')};
  text-decoration: underline;
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`

export const ActionButtonContainer = styled.div`
  margin-top: 40px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (min-width: 960px) {
    margin-top: 0;
    margin-left: 10px;
  }
`

export const ActionButton = withStyles(() => ({
  root: {
    fontSize: '1.1rem',
    marginLeft: '12px',
  },
}))(props => <Button {...props} />)

export const PickersTimeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: calc(100% + 15px);
  width: 100%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

export const PickersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const NewPickersContainer = styled.div`
  display: flex;
  border: 1px solid #808080;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px;
  position: relative;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 5px;
    margin-left: -5px;
  }

  &:before {
    border-color: rgba(128, 128, 128, 0);
    border-top-color: #808080;
    border-width: 6px;
    margin-left: -6px;
  }

  input[type='time']::-webkit-inner-spin-button {
    display: none;
  }
`

export const InputAutoSuggestionHelper = styled.div`
  display: flex;
  align-items: center;
`

export const DurationPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 960px) {
    margin: 0;
    width: 50%;
    padding-bottom: 1px;
  }
`

export const timeEntryBoxTheme = createTheme({
  palette: {
    primary: {
      main: '#2182F6',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

export const timeEntryBoxStyles = theme => ({
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
  },
  rootFormControlLabel: {
    marginRight: '0',
  },
  rootCheckbox: {
    padding: '5px',
  },
  inputAutoSuggestions: {
    color: '#467fd7',
    fontSize: '1rem',
    '&::placeholder': {
      color: '#999999',
      opacity: '1',
      fontSize: '1rem',
    },
  },
  rootButtonCancel: {
    color: '#2196f3',
    backgroundColor: '#f1f1f1',
  },
  labelButton: {
    fontSize: '1.1rem',
  },
  inputComment: {
    fontSize: '1.1rem',
    margin: '16px 0 0 0',
  },
  inputCommentStyle: {
    width: '90%',
    cursor: 'text',
    marginTop: '29px',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: '10px',
      marginRight: '20px',
      marginTop: '-48px',
    },
  },
  inputPicker: {
    fontSize: '1rem',
  },
  helperText: {
    fontSize: '1rem',
  },
  characterCountRedHelperText: {
    fontSize: '1rem',
    color: '#FF0400',
  },
  characterCountGreenHelperText: {
    fontSize: '1rem',
    color: '#6FAD47',
  },
  labelText: {
    fontSize: '1rem',
  },
  deleteIcon: {
    fontSize: 34,
  },
  tooltipText: {
    fontSize: 10,
  },
  popper: {
    zIndex: 99999,
  },
})

export const StyledCheckbox = withStyles({
  root: {
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, .15)',
    },
  },
  checked: {
    color: '#fff !important',
    backgroundColor: '#6B8068 !important',
    '&:hover': {
      backgroundColor: '#6B8068 !important',
    },
  },
})(Checkbox)

export const WarningAlert = styled(Alert)`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin: 4px 0 -10px;
  font-size: 0.8rem;

  svg {
    font-size: 1.2rem;
  }
`
