export const UNASSIGNED_PROJECT_REPLICON_ID =
  'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:project:2184'

export const UNASSIGNED_PROJECT_ID = '5f73aad545130d00173c862f'

export const VALID_PTOS = [
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:1',
    name: 'Vacation',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:3',
    name: 'Bereavement',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:4',
    name: 'Family Leave',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:6',
    name: 'Jury Duty',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:9',
    name: 'Sick',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:12',
    name: 'Maternity Leave',
  },
  {
    repliconId: 'urn:replicon-tenant:29ce9d5e144b4ab4b60683efe738f3fa:time-off-type:10',
    name: 'Floating Holiday',
  },
]

export const UNASSIGNED_PROJECT = {
  _id: '5f73aad545130d00173c862f',
  name: 'Unassigned Project',
  projectCode: 'KS-99999',
  billable: 'Non-billable',
  client: 'TEST',
  isOpenForTimeEntries: true,
  isOpenForInvoicing: true,
  repliconProjectLead: '58daa8fd82f38b00119a2b1e',
  owner: null,
  taskIds: [],
  CreatedDate: null,
  lead: null,
  deleted: false,
  repliconId: UNASSIGNED_PROJECT_REPLICON_ID,
}
