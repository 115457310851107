import _ from 'lodash'
import csv from 'csvtojson'
import moment from 'moment'

import { FUNNEL_ENTRIES_RESOURCE_ID } from '../../../constants/events'
import eventUtils from '../../../utils/eventUtils'
import { getDurationInHours } from '../date'
import { Result } from '../result'
import { timesheet } from '../../../constants/billingOptions'
import { UNASSIGNED_PROJECT_REPLICON_ID } from '../../../constants/projects'
import { log } from '../../../utils/logger'
import * as projectUtils from '../../../utils/projectUtils'
import { isInvalidDurationTime } from '../../../models/entry'
import { fetchTimeEntriesInDateRange } from '../../../entities/timeEntry/service'

const COMMENT = 'Comment'
const PROJECT_CODE = 'Project Code'
const BILLABLE = 'Billable'
const START_TIME = 'Start Time'
const END_TIME = 'End Time'
const DATE_FORMAT = 'MM/DD/YYYY HH:mm'
const DATE_FORMAT_WITHOUT_TIME = 'MM/DD/YYYY'
const DURATION = 'Duration Hours'
const DATE = 'Date'
const HEADERS = [COMMENT, PROJECT_CODE, BILLABLE, DURATION, DATE]

export class CsvEntryBuilder {
  constructor(csvText, projects, {client, selectedDelegateId} = {}) {
    this.csvText = csvText
    this.projects = projects
    this.errors = {
      failedEntries: [],
      errorMessages: [],
    }
    this.client = client
    this.selectedDelegateId = selectedDelegateId
    this.entries = []
  }

  async build() {
    const rows = await csv().fromString(this.csvText)

    if (_.isEmpty(rows)) {
      return Result.fail(
        'The file you uploaded seems to be empty. Confirm that the file you are uploading contains data and try again.',
      )
    }

    const keys = Object.keys(rows[0])
    const headers = HEADERS.filter(header => !keys.includes(header))
    headers.forEach(header => this.errors.errorMessages.push(`Header '${header}' not found.`))

    if (!_.isEmpty(this.errors.errorMessages)) {
      return Result.failWithErrors(this.errors)
    }

    const rowsWithDates = await this.createDatesForEachEntryByDay(rows)
    _.forEach(rowsWithDates, (item, index) => this.tryToConvertToEntry(item, index + 1))

    return Result.ok(this.entries, this.errors)
  }

  createDatesForEachEntryByDay = async (csvEntryRows) => {
    const entriesPerDay = _.groupBy(csvEntryRows, entry => entry[DATE])
    const entriesPerDayArray = Object.entries(entriesPerDay)
    const entriesPerDayArrayWithDates = []
    for (const [date, perDayCSVTimeEntries] of entriesPerDayArray) {
      const filteredTimeEntries = await fetchTimeEntriesInDateRange({
        client: this.client,
        selectedDelegateId: this.selectedDelegateId,
        startISO: moment(date, DATE_FORMAT).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')+'Z',
        endISO: moment(date, DATE_FORMAT).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')+'Z',
      })
      entriesPerDayArrayWithDates.push(
        ...this.createCSVCalculatedDates(perDayCSVTimeEntries, filteredTimeEntries)
      ) 
    }
    return entriesPerDayArrayWithDates
  }

  createCSVCalculatedDates = (csvEntries, currentTimeentries) => {
    const currentFreeSlots = this.calculateFreeSlots( currentTimeentries )
    const transformedEntries = []
    let workingEntries = csvEntries

    while(true){
      const  { transformedEntries: _transformedEntries, noPlacedEntries } = this.loadEntriesIntoSlots(workingEntries, currentFreeSlots)
      transformedEntries.push(..._transformedEntries)
      if(noPlacedEntries.length === 0) break;
      currentFreeSlots.fill(true)
      workingEntries = noPlacedEntries
    }

    return transformedEntries
  }

  loadEntriesIntoSlots = (entries, currentFreeSlots) => {
    const transformedEntries = []
    const noPlacedEntries = []
    entries.forEach( entry => {
      const duration = entry[DURATION]?.replace(',','.')
      const durationMinutes = Math.round(duration * 60)
      const startTimeOfWorkingDay = 8
      let entryOK = false
      let slotAcum = 0
      
      currentFreeSlots.some( (slot, index) => {
        if(index < (startTimeOfWorkingDay * 60)) return false
        if(slot) { slotAcum++ } else { slotAcum = 0 }
        if(slotAcum === durationMinutes || durationMinutes === 0) {
          index++
          
          const startHour = moment().startOf('day').add(index-durationMinutes, 'minutes').format('HH')
          const startMinutes = moment().startOf('day').add(index-durationMinutes, 'minutes').format('mm')
          const start = new Date(entry[DATE])
          start.setHours(startHour)
          start.setMinutes(startMinutes)

          const end = new Date(entry[DATE])
          const endHour = moment().startOf('day').add(index, 'minutes').format('HH')
          const endMinutes = moment().startOf('day').add(index, 'minutes').format('mm')
          end.setHours(endHour)
          end.setMinutes(endMinutes)

          currentFreeSlots.fill(false, index-durationMinutes, index)

          entry[START_TIME] = moment(start).format(DATE_FORMAT)
          entry[END_TIME] = moment(end).format(DATE_FORMAT)
          delete entry[DURATION]

          transformedEntries.push({...entry})
          entryOK = true
          return true
        }
        return false
      })
      if(!entryOK) { noPlacedEntries.push(entry) }
    })
    return {
      transformedEntries,
      noPlacedEntries,
    }
  }

  calculateFreeSlots = (currentTimeentries) => {
    const freeSlots = new Array(60*24).fill(true)
    currentTimeentries.forEach( e => {
        const start = moment(e.start).utc()
        const end = moment(e.end).utc()
        const minutes =  start.get('minutes')
        const hour = start.get('hour')
        const slotPosition = (hour * 60) + minutes
        const duration = moment.duration(end.diff(start)).asMinutes()
        freeSlots.fill(false, slotPosition, slotPosition + duration)
    })
    return freeSlots
  }

  tryToConvertToEntry = (item, line) => {
    try {
      this.convertToEntry(item, line)
    } catch (e) {
      log(`tryToConvertToEntry e: ${e}`)
      log(`Could not to convert to entry item:`, item)
    }
  }

  convertToEntry = (item, line) => {
    const originalComment = _.get(item, COMMENT)
    const billable = this.parseBillableStatus(item)
    const start = this.parseDate(item[START_TIME])
    const end = this.parseDate(item[END_TIME])
    const originalDurationInHours = getDurationInHours(start, end)
    const project = this.parseProject(item, line)
    const eventSource = 'Timesheet'
    const referenceId = null

    const sourceId = eventUtils.getEventId(_.get(project, '_id', ''), originalComment, start, end)

    const fieldsToInheritFromProject = projectUtils.getFieldsToInheritToEntry(project)

    const entry = {
      sourceId,
      _id: sourceId,
      resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
      start: moment(start).toLocalString(),
      end: moment(end).toLocalString(),
      originalDurationInHours,
      eventSource,
      originalComment,
      referenceId,
      ...fieldsToInheritFromProject,
      billable,
    }

    if (this.isEntryValid(entry, line)) {
      this.entries.push(entry)
      return
    }

    this.errors.failedEntries.push(entry)
  }

  parseDate = dateString => {
    const date = moment(dateString, DATE_FORMAT)
    if (!date.isValid()) return null
    return date.toDate()
  }

  parseBillableStatus = entry => {
    const billableStatus = entry[BILLABLE].toLowerCase()
    const result = {
      true: timesheet.BILLABLE,
      false: timesheet.NONBILLABLE,
    }
    return result[billableStatus] || null
  }

  parseProject = item => {
    const projectCode = _.get(item, PROJECT_CODE, undefined)

    const unassignedProject = this.projects.find(
      project => project.repliconId === UNASSIGNED_PROJECT_REPLICON_ID,
    )

    const foundProject =
      _.find(this.projects, project => {
        return (
          _.get(project, 'name', 'DOES NOT HAVE ANY NAME') === projectCode ||
          _.get(project, 'projectCode', 'DOES NOT HAVE ANY PROJECT CODE') === projectCode
        )
      }) || unassignedProject

    return foundProject
  }

  isEntryValid = (entry, line) => {
    const isCommentValid = this.isCommentValid(entry, line)
    const isBillableStatusValid = this.isBillableStatusValid(entry, line)
    const isDateValid = this.isDateValid(entry, line)
    const isDurationInHoursValid = this.isDurationInHoursValid(entry, line)
    const isProjectValid = this.isProjectValid(entry, line)
    return (
      isCommentValid &&
      isBillableStatusValid &&
      isDateValid &&
      isDurationInHoursValid &&
      isProjectValid
    )
  }

  isCommentValid = (item, line) => {
    const comment = _.get(item, 'originalComment')

    if (_.isUndefined(comment) || _.isEmpty(comment)) {
      this.addErrorMessage(`Comment cannot be empty.`, line)
      return false
    }

    return true
  }

  isBillableStatusValid = (entry, line) => {
    if (entry.billable === timesheet.BILLABLE || entry.billable === timesheet.NONBILLABLE) {
      return true
    }

    this.addErrorMessage("Billable value not parseable, should be either 'true' or 'false'.", line)

    return false
  }

  isDateValid = (item, line) => {
    const startTime = item.start
    const endTime = item.end

    const isStartTimeValid = startTime && moment(startTime).isValid()
    const isEndTimeValid = endTime && moment(endTime).isValid()

    if( [startTime, endTime].includes('Invalid date') ) {
      this.addErrorMessage(`Date value should comply format ${DATE_FORMAT_WITHOUT_TIME}.`, line)
      return false
    }

    if (!isStartTimeValid) {
      this.addErrorMessage(`Start time Date value should comply format ${DATE_FORMAT}. ${startTime}, ${endTime}`, line)
    }

    if (!isEndTimeValid) {
      this.addErrorMessage(`End time Date value should comply format ${DATE_FORMAT}.`, line)
    }

    return isStartTimeValid && isEndTimeValid
  }

  isDurationInHoursValid = (item, line) => {
    if (isInvalidDurationTime(item)) {
      this.addErrorMessage('Invalid time duration', line)
      return false
    }

    return true
  }

  isProjectValid = (item, line) => {
    if (item.isPTO) {
      this.addErrorMessage(`Paid time off entries are not supported for now.`, line)
      return false
    }
    return true
  }

  addErrorMessage(message, line) {
    this.errors.errorMessages.push(`Error on line ${line} of csv file: ${message}`)
  }
}
