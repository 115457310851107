import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container } from './styles'
import PlayStopButton from './PlayStopButton'
import { useTimerWithEntryCreation } from './hooks'

const Timer = ({
  addCalendarPlaceholderEvent,
  handleCalendarSelect,
  changeViewIfNeeded,
  isDisabled,
  currentEditableEntry,
}) => {
  const entryCreationHandler = selectedSlot => {
    changeViewIfNeeded() // needed for table view
    handleCalendarSelect(new Date(), () => {
      addCalendarPlaceholderEvent(selectedSlot)
    })
  }
  const { milliseconds, isRunning, handleClick } = useTimerWithEntryCreation(
    entryCreationHandler,
    currentEditableEntry,
  )
  const prettyTime = moment.utc(milliseconds).format('H:mm:ss')
  return (
    <Container>
      <PlayStopButton isRunning={isRunning} onClick={handleClick} isDisabled={isDisabled} />
      {prettyTime}
    </Container>
  )
}

Timer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  changeViewIfNeeded: PropTypes.func.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
}

export default Timer
