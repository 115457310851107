import styled from 'styled-components'

export const ErrorPageContainer = styled.form`
  height: 100vh;
  -webkit-app-region: no-drag;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DraggableContainer = styled.div`
  width: 100%;
  height: 100vh;
  -webkit-app-region: drag;
`

export const ErrorText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    padding: 10px;
  }
`

export const SyncNavigation = styled.div`
  display: flex;
  justify-content: center;
`
