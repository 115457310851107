const keysCodes = {
  enter: 'Enter',
  tab: 'Tab',
  esc: 'Escape',
  up: 'ArrowUp',
  down: 'ArrowDown',
  delete: 'Delete',
  backspace: 'Backspace',
}

export { keysCodes }
