import React from 'react'
import PropTypes from 'prop-types'

import { TIMESHEET_VIEWS } from '../../../constants/glimpseViews'
import DailyProgressBar from './DailyProgressBar'
import WeeklyProgressBar from './WeeklyProgressBar'
import eventUtils from '../../../utils/eventUtils'
import { APPROVED } from '../../../constants/timesheetStatus'

const CalendarProgressBar = props => {
  if (props.isDayViewPeriodSelected || props.view === TIMESHEET_VIEWS.TABLE) {
    const todayTimesheet = eventUtils.getTimesheetForDay(props.selectedDate, props.timesheets)
    const isTimesheetSubmitted = todayTimesheet.approvalStatus === APPROVED
    return (
      <DailyProgressBar
        isDayViewPeriodSelected={props.isDayViewPeriodSelected}
        selectedDate={props.selectedDate}
        events={props.events}
        timesheets={props.timesheets}
        isConnected={props.isConnected}
        view={props.view}
        isEmailSelected={props.isEmailSelected}
        selectCalendarEvents={props.selectCalendarEvents}
        selectEmails={props.selectEmails}
        handleCreateEntriesForAllEvents={props.handleCreateEntriesForAllEvents}
        timeEntries={props.timeEntries}
        localEntries={props.localEntries}
        exchangeEvents={props.exchangeEvents}
        isFetchingTimesheetData={props.isFetchingTimesheetData}
        isSubmitInProgress={props.isSubmitInProgress}
        uiRefreshTimePeriod={props.uiRefreshTimePeriod}
        isTimesheetSubmitted={isTimesheetSubmitted}
      />
    )
  }
  return (
    <WeeklyProgressBar
      events={props.events}
      timesheets={props.timesheets}
      isConnected={props.isConnected}
      selectedDate={props.selectedDate}
      handleCalendarSelect={props.handleCalendarSelect}
      setViewPeriodDay={props.setViewPeriodDay}
    />
  )
}

CalendarProgressBar.propTypes = {
  view: PropTypes.string.isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  selectCalendarEvents: PropTypes.func.isRequired,
  selectEmails: PropTypes.func.isRequired,
  isEmailSelected: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
}

export default CalendarProgressBar
