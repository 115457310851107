import React from 'react'
import PropTypes from 'prop-types'
import { Header1, DelegateAccessModelContentWrapper, InfoPara } from './style'
import PermissionRow from './components/PermissionRow'
import DelegateAccessButtons from './components/DelegateAccessButtons'

const DelegateAccessModelContent = props => {
  const { delegateUsersList } = props
  return (
    <DelegateAccessModelContentWrapper>
      <Header1>Profiles Available to Access</Header1>
      {delegateUsersList.map(delegateUser => {
        return (
          <PermissionRow
            key={delegateUser.fromId + delegateUser.toId}
            delegateAccessId={delegateUser._id}
            name={delegateUser.toFullName}
            hasEmailAccess={delegateUser.hasEmailAccess}
            hasCalendarAccess={delegateUser.hasCalendarAccess}
            refetchSurrogateAccess={props.refetchSurrogateAccess}
          />
        )
      })}
      {(!delegateUsersList || delegateUsersList.length === 0) && (
        <InfoPara>There are no profiles to access</InfoPara>
      )}
      <DelegateAccessButtons />
    </DelegateAccessModelContentWrapper>
  )
}

DelegateAccessModelContent.propTypes = {
  delegateUsersList: PropTypes.array,
  refetchSurrogateAccess: PropTypes.func.isRequired,
}

export default DelegateAccessModelContent
