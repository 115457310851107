const RECURRING_SYNC_DEFAULT_START = process.env.RECURRING_SYNC_DEFAULT_START || 'startOf month'
const RECURRING_SYNC_DEFAULT_END = process.env.RECURRING_SYNC_DEFAULT_END || 'endOf month'
const RECURRING_SYNC_PAST = process.env.REACT_APP_RECURRING_SYNC_PAST || '1 month'
const RECURRING_SYNC_FUTURE = process.env.REACT_APP_RECURRING_SYNC_FUTURE || '1 month'

const [
  RECURRING_SYNC_DEFAULT_START_FUNCTION,
  RECURRING_SYNC_DEFAULT_START_PARAMETER,
] = RECURRING_SYNC_DEFAULT_START.split(' ')
const [
  RECURRING_SYNC_DEFAULT_END_FUNCTION,
  RECURRING_SYNC_DEFAULT_END_PARAMETER,
] = RECURRING_SYNC_DEFAULT_END.split(' ')
const [RECURRING_SYNC_PAST_MINUEND, RECURRING_SYNC_SUBTRAHEND] = RECURRING_SYNC_PAST.split(' ')
const [RECURRING_SYNC_FUTURE_ADD, RECURRING_SYNC_FUTURE_ADDFROM] = RECURRING_SYNC_FUTURE.split(' ')

export default {
  RECURRINC_SYNC_SYNC_INTERVAL: process.env.REACT_APP_SYNC_INTERVAL || 120000,
  RECURRING_SYNC_DEFAULT_START_FUNCTION,
  RECURRING_SYNC_DEFAULT_START_PARAMETER,
  RECURRING_SYNC_DEFAULT_END_FUNCTION,
  RECURRING_SYNC_DEFAULT_END_PARAMETER,
  RECURRING_SYNC_PAST_MINUEND,
  RECURRING_SYNC_SUBTRAHEND,
  RECURRING_SYNC_FUTURE_ADD,
  RECURRING_SYNC_FUTURE_ADDFROM,
}
