import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import Fuse from 'fuse.js'
import _ from 'lodash'

import { StyledMenuHeading, StyledMenuItem, ClearIcon } from '../styles'
import DelegateAccessProfileRow from './DelegateAccessProfileRow'

const headerText = 'Profiles Avaliable to Access '
const DelegateAccessProfileList = props => {
  const [inputText, setInputText] = React.useState('')
  const isOpen = Boolean(props.anchorMenu)

  const getFilteredDelegateProfiles = profiles => {
    if (!inputText) {
      return _.sortBy(profiles, ['fromFullName'], ['asc'])
    }

    const delegateOptions = {
      shouldSort: true,
      keys: ['fromFullName'],
    }
    const fuse = new Fuse(profiles, delegateOptions)
    return fuse.search(inputText)
  }

  const renderDelegateProfiles = () => {
    if (!props.delegateAccessList?.length) {
      return <StyledMenuItem disableGutters>No one has given you delegate access</StyledMenuItem>
    }

    const filteredDelegateProfiles = getFilteredDelegateProfiles(props.delegateAccessList)

    if (!filteredDelegateProfiles?.length) {
      return <StyledMenuItem>No profiles found for this search</StyledMenuItem>
    }

    return filteredDelegateProfiles?.map(delegateAccess => {
      return (
        <DelegateAccessProfileRow
          key={delegateAccess._id}
          delegateAccess={delegateAccess}
          selectedDelegateId={props.selectedDelegateId}
          handleSelectedDelegateId={props.handleSelectedDelegateId}
          anchorMenuClose={props.anchorMenuClose}
        />
      )
    })
  }

  return (
    <>
      <Menu
        anchorEl={props.anchorMenu}
        id="delegate-Access-profile"
        open={isOpen}
        onClose={props.anchorMenuClose}
        PaperProps={{
          style: {
            width: 400,
            marginTop: 30,
            padding: '12px 20px',
          },
        }}
      >
        <StyledMenuHeading>{headerText}</StyledMenuHeading>
        {props.delegateAccessList?.length ? (
          <StyledMenuItem onKeyDown={event => event.stopPropagation()}>
            <TextField
              placeholder="Search by name..."
              value={inputText}
              onChange={event => setInputText(event.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {inputText ? <ClearIcon onClick={() => setInputText('')} /> : null}
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </StyledMenuItem>
        ) : null}
        {renderDelegateProfiles()}
      </Menu>
    </>
  )
}

DelegateAccessProfileList.propTypes = {
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func,
  delegateAccessList: PropTypes.array,
  anchorMenu: PropTypes.object,
  anchorMenuClose: PropTypes.func.isRequired,
}

export default DelegateAccessProfileList
