import React from 'react'

import {
  StyledInstructionsContainer,
  StyledInstructionsList,
  StyledInstructionsListItem,
} from './style'

function ChangePermissionInstructions() {
  return (
    <StyledInstructionsContainer>
      <StyledInstructionsList>
        <StyledInstructionsListItem>
          On Google Chrome, click on the information icon to the left of the URL. Next to
          &quot;Notifications&quot;, select either &quot;Ask&quot;, &quot;Allow&quot; or
          &quot;Block&quot;. Reload the page to apply the updated settings.
        </StyledInstructionsListItem>
      </StyledInstructionsList>
    </StyledInstructionsContainer>
  )
}

export default ChangePermissionInstructions
