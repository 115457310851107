import _ from 'lodash'

import { dataToTable } from '.'
import { VALID_PTOS } from '../../../../constants/projects'
import * as projectUtils from '../../../../utils/projectUtils'

export const getValidProjects = projects => {
  const validPTOsIds = _.map(VALID_PTOS, pto => pto.repliconId)

  const projectsWithValidTimeOffCodes = _.filter(projects, project => {
    return !projectUtils.isPTOCodeProject(project) || _.includes(validPTOsIds, project.repliconId)
  })

  return _.sortBy(projectsWithValidTimeOffCodes, 'name')
}

export const getTableData = ({ timeEntries, projects }) => {
  return dataToTable(timeEntries, projects)
}
