/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { Container, TimesheetTotals, SubmissionToolbarContainer, MenuContainer } from './styles'
import NotificationBar from './NotificationBar'
import UserProfile from './UserProfile'
import TimesheetInfoItem from './TimesheetInfoItem'
import eventUtils from '../../../utils/eventUtils'
import CalendarDateSelector from './CalendarDateSelector'
import Timer from './Timer'
import SubmissionToolbar from './SubmissionToolbar'
import { Small } from '../../../styledComponents/text'
import timeUtils from '../../../utils/timeUtils'
import GlimpseMenu from './GlimpseMenu'
import DelegateAccess from './DelegateAccess'
import { APPROVED } from '../../../constants/timesheetStatus'
import { userSettingsPropTypes } from '../../../constants/userSettings'
import {
  searchForNextClosestMissingTimesheetMonth,
  getMissingNavigationStartDate,
  searchForPreviousClosestMissingTimesheetMonth,
} from '../../../utils/timesheetUtils'
import { mapStateToProps } from './store'
import { isEntryStartDateEqualToDate } from '../utils'
import config from '../../../config'

const { IS_DELEGATE_ENABLED } = config

class Header extends React.Component {
  state = {
    currentMonth: this.props.selectedDate,
    isDatePickerOpen: false,
  }

  componentDidUpdate(prevProps) {
    if (!timeUtils.areSameDay(prevProps.selectedDate, this.props.selectedDate)) {
      this.handleCloseDayPicker()
    }
  }

  handleCloseDayPicker = () => {
    this.setState({ isDatePickerOpen: false })
  }

  handleDateRangeClick = () => {
    this.setState({ currentMonth: this.props.selectedDate, isDatePickerOpen: true })
  }

  updateCurrentMonth = date => this.setState({ currentMonth: date })

  _getMissingNavigationStartDate = () => getMissingNavigationStartDate(this.props.person.startDate)

  navigateToPreviousMissingTimesheet = () => {
    const newCurrentMonth = searchForPreviousClosestMissingTimesheetMonth({
      missingNavigationStartDate: this._getMissingNavigationStartDate(),
      searchingDate: moment(this.state.currentMonth)
        .subtract(1, 'months')
        .startOf('month'),
      timesheets: this.props.timesheets,
    })
    this.updateCurrentMonth(newCurrentMonth)
  }

  navigateToNextMissingTimesheet = () => {
    const newCurrentMonth = searchForNextClosestMissingTimesheetMonth({
      missingNavigationStartDate: this._getMissingNavigationStartDate(),
      searchingDate: moment(this.state.currentMonth)
        .add(1, 'months')
        .startOf('month'),
      timesheets: this.props.timesheets,
    })
    this.updateCurrentMonth(newCurrentMonth)
  }

  navigateToEarliestMissingTimesheet = () => {
    const missingNavigationStartDate = this._getMissingNavigationStartDate()
    const newCurrentMonth = searchForNextClosestMissingTimesheetMonth({
      missingNavigationStartDate,
      searchingDate: missingNavigationStartDate,
      timesheets: this.props.timesheets,
    })
    this.updateCurrentMonth(newCurrentMonth)
  }

  changeDate = selectedDate => {
    this.props.handleCalendarSelect(selectedDate)
    this.handleCloseDayPicker()
  }

  renderTotals = events => {
    const groupedByProjectEvents = eventUtils.groupEventsByProjectType(events, this.props.projects)
    const eventsWithInternalProjects = _.get(groupedByProjectEvents, 'Internal', [])
    const eventsWithPTOCodeProjects = _.get(groupedByProjectEvents, 'PTO', [])
    const eventsWithOtherProjects = _.get(groupedByProjectEvents, 'Project', [])
    const totalsTitle = this.props.isDayViewPeriodSelected ? 'Timesheet total' : 'Week total'

    return (
      <Small>
        <TimesheetInfoItem events={events} title={totalsTitle} showZero />
        {!_.isEmpty(groupedByProjectEvents) && ' | '}
        <TimesheetInfoItem events={eventsWithOtherProjects} title="Project" />
        <TimesheetInfoItem events={eventsWithInternalProjects} title="Internal" />
        <TimesheetInfoItem events={eventsWithPTOCodeProjects} title="PTO" />
      </Small>
    )
  }

  renderTimesheetTotals() {
    const { timeEntries, timeOffEntries, selectedDate } = this.props
    const events = _.concat(timeEntries, timeOffEntries)
    if (this.props.isDayViewPeriodSelected) {
      const dayEvents = eventUtils.getEventsBySelectedDate(events, selectedDate)
      const filteredEvents = dayEvents.filter(event =>
        isEntryStartDateEqualToDate(event, this.props.selectedDate),
      )
      return this.renderTotals(filteredEvents)
    }
    const weekEvents = eventUtils.getSelectedWeekEventsByDate(events, selectedDate)
    return this.renderTotals(weekEvents)
  }

  render() {
    const todayTimesheet = eventUtils.getTimesheetForDay(new Date(), this.props.timesheets)
    const isTimerDisabled = todayTimesheet.approvalStatus === APPROVED
    return (
      <Container>
        <UserProfile person={this.props.person} timesheets={this.props.timesheets} />
        <NotificationBar
          hasTimesheetUnassignedEntries={this.props.hasTimesheetUnassignedEntries}
          hasWeekTimesheetsUnassignedEntries={this.props.hasWeekTimesheetsUnassignedEntries}
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
          localEntries={this.props.localEntries}
          isConnected={this.props.isConnected}
        />
        {IS_DELEGATE_ENABLED && (
          <DelegateAccess
            delegateAccessList={this.props.delegateAccessList}
            selectedDelegateId={this.props.selectedDelegateId}
            handleSelectedDelegateId={this.props.handleSelectedDelegateId}
          />
        )}

        <MenuContainer>
          <GlimpseMenu
            handleReloadApp={this.props.handleReloadApp}
            handleUpload={this.props.handleUpload}
            handleSwitchIsIssueReportModalOpen={this.props.handleSwitchIsIssueReportModalOpen}
            handleSwitchIsDelegateAccessModalOpen={this.props.handleSwitchIsDelegateAccessModalOpen}
            handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
            timeEntries={this.props.timeEntries}
            localEntries={this.props.localEntries}
            exchangeEvents={this.props.exchangeEvents}
            isSubmitInProgress={this.props.isSubmitInProgress}
            isFetchingTimesheetData={this.props.isFetchingTimesheetData}
            uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
            isConnected={this.props.isConnected}
            reminderSettings={this.props.reminderSettings}
            handleSetReminderSettings={this.props.handleSetReminderSettings}
            isTimesheetSubmitted={isTimerDisabled}
            person={this.props.person}
          />
        </MenuContainer>
        <CalendarDateSelector
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          changeDate={this.changeDate}
          onNavigate={this.props.onNavigate}
          selectedDate={this.props.selectedDate}
          handleDateRangeClick={this.handleDateRangeClick}
          handleCloseDayPicker={this.handleCloseDayPicker}
          isDatePickerOpen={this.state.isDatePickerOpen}
          isTimesheetLoading={this.props.isTimesheetLoading}
          setViewPeriodDay={this.props.setViewPeriodDay}
          setViewPeriodWeek={this.props.setViewPeriodWeek}
          view={this.props.view}
          timesheets={this.props.timesheets}
          events={this.props.events}
          userInfo={this.props.person}
          currentMonth={this.state.currentMonth}
          updateCurrentMonth={this.updateCurrentMonth}
          navigateToPreviousMissingTimesheet={this.navigateToPreviousMissingTimesheet}
          navigateToNextMissingTimesheet={this.navigateToNextMissingTimesheet}
          navigateToEarlistMissingTimesheet={this.navigateToEarliestMissingTimesheet}
          handleTogglePageView={this.props.handleTogglePageView}
          userSettings={this.props.userSettings}
          selectedDelegateId={this.props.selectedDelegateId}
          delegateAccessList={this.props.delegateAccessList}
        />
        <Timer
          isDisabled={isTimerDisabled}
          addCalendarPlaceholderEvent={this.props.addCalendarPlaceholderEvent}
          handleCalendarSelect={this.props.handleCalendarSelect}
          changeViewIfNeeded={this.props.changeViewIfNeeded}
          currentEditableEntry={this.props.currentEditableEntry}
        />
        <TimesheetTotals>{this.renderTimesheetTotals()}</TimesheetTotals>
        <SubmissionToolbarContainer>
          <SubmissionToolbar
            localEntries={this.props.localEntries}
            isConnected={this.props.isConnected}
            isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
            timesheet={this.props.selectedTimesheet}
            isSubmitInProgress={this.props.isSubmitInProgress}
            handleTimesheetSubmission={this.props.handleTimesheetSubmission}
            saveLocalEntryOperations={this.props.saveLocalEntryOperations}
            tabIndex="-1"
            hasTimesheetUnassignedEntries={this.props.hasTimesheetUnassignedEntries}
            hasWeekTimesheetsUnassignedEntries={this.props.hasWeekTimesheetsUnassignedEntries}
            areAllTimesheetsSubmitted={this.props.areAllTimesheetsSubmitted}
            areAllTimesheetsWithEntries={this.props.areAllTimesheetsWithEntries}
            areAllTimesheetsWithoutEntries={this.props.areAllTimesheetsWithoutEntries}
            uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
            isFetchingTimesheetData={this.props.isFetchingTimesheetData}
            person={this.props.person}
            delegateAccessList={this.props.delegateAccessList}
            selectedDelegateId={this.props.selectedDelegateId}
          />
        </SubmissionToolbarContainer>
      </Container>
    )
  }
}

Header.propTypes = {
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func,
  delegateAccessList: PropTypes.array.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  person: PropTypes.object.isRequired,
  selectedTimesheet: PropTypes.object.isRequired,
  handleTimesheetSubmission: PropTypes.func.isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  handleTogglePageView: PropTypes.func.isRequired,
  changeViewIfNeeded: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  areAllTimesheetsSubmitted: PropTypes.bool.isRequired,
  areAllTimesheetsWithEntries: PropTypes.bool.isRequired,
  areAllTimesheetsWithoutEntries: PropTypes.bool.isRequired,
  isTimesheetLoading: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  setViewPeriodWeek: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  saveLocalEntryOperations: PropTypes.func.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
  timeOffEntries: PropTypes.array.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  userSettings: userSettingsPropTypes,
}

export default connect(mapStateToProps)(Header)
