import { CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK } from './query'

export const analytics_initialSyncTime_track = async ({
  client,
  calendarEventsLoadTime,
  emailsLoadTime,
  timesheetDataLoadTime,
}) => {
  const { data } = await client.mutate({
    mutation: CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK,
    variables: {
      calendarEventsLoadTime,
      emailsLoadTime,
      timesheetDataLoadTime,
    },
  })
  return data.analytics_initialSyncTime_track
}
