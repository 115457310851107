import styled from 'styled-components'

export const ShortcutTitle = styled.h2`
  font-size: 14px;
  font-weight: 600;
`

export const ShortcutItem = styled.p`
  font-size: 12px;
`
