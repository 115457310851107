// replace error messages numeric values for [NUMBER_PLACEHOLDER] to avoid dynamic messages
import moment from 'moment'

import { TIMESHEET_VIEWS } from '../constants/glimpseViews'

export const ERRORS_TO_FILTER = [
  'TimesheeIsAlreadyApprovedError: No error message',
  'BulkError: timeEntry.bulkAdd(): [NUMBER_PLACEHOLDER] of [NUMBER_PLACEHOLDER] operations failed. Errors: ConstraintError: Key already exists in the object store.',
  'BulkError: event.bulkAdd(): [NUMBER_PLACEHOLDER] of [NUMBER_PLACEHOLDER] operations failed. Errors: ConstraintError: Key already exists in the object store.',
  'Error: timeout of [NUMBER_PLACEHOLDER]ms exceeded',
  'Error: Network error: Response not successful: Received status code [NUMBER_PLACEHOLDER]',
]

const now = moment()
  .utc()
  .toISOString()

export const initialReminderSettings = {
  shouldRemind: false,
  weekDays: {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  },
  timeFrom: new Date(now),
  timeTo: new Date(now),
  periodInMinutes: 30,
}

export const initialUserSettings = {
  favoriteProjectIds: [],
  defaultProjectId: null,
}

export const initialState = {
  view: TIMESHEET_VIEWS.TIMELINE,
  viewPeriod: 'day',
  numberOfInitialSyncRetries: 0,
  updateAvailable: false,
  appVersionNumber: '',
  modal: {
    open: false,
    message: '',
    title: '',
  },
  hasUserGotStarted: true,
  initialSync: {
    inProgress: false,
    done: true,
    syncItems: {},
  },
  isRecurrentSyncInProgress: false,
  isSubmitInProgress: false,
  mostRecentlyUsedProjectIds: [],
  isTimesheetLoading: false,
  timeOffCodes: [],
  events: [],
  localEntries: [],
  timeEntries: [],
  timeOffEntries: [],
  unhandledEntries: [],
  unhandledEntriesInfo: {},
  exchangeEvents: [],
  exchangeEmails: [],
  timesheets: [],
  delegateAccesses: [],
  selectedDelegateId: null,
  selectedDate: new Date(),
  projects: [],
  userSettings: initialUserSettings,
  usedExchangeEvents: {},
  currentEditableEntry: {},
  initialCurrentEditableEntry: {},
  isAddAllEntriesFromEventsModalOpen: false,
  isAllEventsButton: false,
  isNoFreeGapModalOpen: false,
  successCsvInfoTotal: {},
  failedCsvInfoTotal: {},
  csvUploadData: {
    isCSVUploadInProgress: false,
    csvsToUploadTotal: 0,
    csvUploadCount: 0,
  },
  csvActionButtonsBlocked: false,
  areEventsBeingAdded: false,
  isDelegateAccessModalFromMenuOpen: false,
  isIssueReportModalFromMenuOpen: false,
  isIssueReportModalFromSentryOpen: false,
  sentryError: {},
  isFetchingTimesheetData: false,
  isReportPageShown: false,
  reminderSettings: initialReminderSettings,
  assignments: [],
}
