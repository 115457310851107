import moment from 'moment'

export const roundUpEnd = startTime => {
  const nowAsMs = new Date().getTime()
  const currentDuration = nowAsMs - startTime
  const sixMinutesAsMs = moment.duration(6, 'minutes').asMilliseconds()
  const sixMinutesOverStartTime = startTime + sixMinutesAsMs
  const endAsMs = currentDuration < sixMinutesAsMs ? sixMinutesOverStartTime : nowAsMs
  return new Date(endAsMs)
}
