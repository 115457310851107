import _ from 'lodash'
import eventUtils from './eventUtils'

export const HEADER_PLACEHOLDER = 'Specify a project'
export const COMMENT_PLACEHOLDER = 'Add an entry here by typing a comment below'

export const getHeaderTitle = (event, projects, timeOffCodes) => {
  return eventUtils.isTimeOffEvent(event)
    ? getTimeOffHeaderTitle(event, timeOffCodes)
    : getTimeEntryHeaderTitle(event, projects)
}

const getTimeOffHeaderTitle = (event, timeOffCodes) => {
  // must be a Number
  const timeOffObject = _.find(timeOffCodes, {
    _id: event.timeOffCodeId,
  })
  const codeName = _.get(timeOffObject, 'name', 'TimeOff')
  return privacyFilter(codeName)
}

const getTimeEntryHeaderTitle = (event, projects) => {
  const project = getProjectToDisplay(event, projects)
  const projectName = _.get(project, 'name', HEADER_PLACEHOLDER)
  return projectName
}

const getProjectToDisplay = (event, projects) => {
  return _.find(projects, { _id: event.originalProjectId })
}

const privacyFilter = codeName => {
  if (_.endsWith(codeName, 'Leave') || _.startsWith(codeName, 'Sick')) return 'Leave'
  return codeName
}
