import React from 'react'
import PropTypes from 'prop-types'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Fade from '@material-ui/core/Fade'

import { Item, ItemTitle, ItemContent } from './style'

const ExpandableItem = ({ children, title, toggle, isOpen }) => {
  return (
    <div>
      <Item onMouseDown={() => toggle()} isOpen={isOpen}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        <ItemTitle>{title}</ItemTitle>
      </Item>
      <Fade in={isOpen} timeout={1000}>
        <ItemContent isOpen={isOpen}>{children}</ItemContent>
      </Fade>
    </div>
  )
}

ExpandableItem.propTypes = {
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default ExpandableItem
