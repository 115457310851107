import _ from 'lodash'
import moment from 'moment'

import timeutils from '../../utils/timeUtils'

export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'

export const isAnOperation = entryOrOperation => {
  return ['data', 'action', 'sourceId'].every(operationKey =>
    Object.keys(entryOrOperation).includes(operationKey),
  )
}

export const getOperationStart = operation => {
  return _.get(operation, 'data.start')
}

export const getOperationEnd = operation => {
  return _.get(operation, 'data.end')
}

export const isADeleteOperation = operation => {
  return getAction(operation) === DELETE
}

export const doesOperationFallInsideDateRange = (operation, dateRange) => {
  const operationStart = getOperationStart(operation)
  const operationEnd = getOperationEnd(operation)

  const startISO = moment(operationStart).toLocalStringAsUTC()
  const endISO = moment(operationEnd).toLocalStringAsUTC()

  return timeutils.doesTimePeriodContainsAnother({ startISO, endISO }, dateRange)
}

export const hasLocalOperations = (uiPeriod, localEntries) => {
  return localEntries.some(operation => {
    return doesOperationFallInsideDateRange(operation, uiPeriod)
  })
}

export const getAction = operation => _.get(operation, 'action')

export const getEntry = operation => {
  return _.get(operation, 'data')
}

export const getEntryOrOperation = entryOrOperation => {
  return isAnOperation(entryOrOperation) ? getEntry(entryOrOperation) : entryOrOperation
}

export const getSourceId = operation => {
  return _.get(operation, 'sourceId')
}
