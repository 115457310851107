import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as SendIssueReportService from '../../entities/outlook/issueReport'
import { logError } from '../../utils/logger'
import FormModal from '../FormModal'
import FinishModal from '../FinishModal'

import { INITIAL, IN_PROGRESS, SUBMITTED } from './reportStatuses'

const IssueReportFromMenu = props => {
  const [report, setReport] = useState('')
  const [reportStatus, setReportStatus] = useState(INITIAL)

  const onSubmitIssueReport = async () => {
    try {
      setReportStatus(IN_PROGRESS)
      await SendIssueReportService.sendIssueReport({
        client: props.client,
        report,
        selectedDate: props.selectedDate,
        viewPeriod: props.viewPeriod,
      })
      setReportStatus(SUBMITTED)
      setReport('')
    } catch (e) {
      logError(e, 'IssueReport > onSubmitIssueReport')
      onFinish()
    }
  }

  const onReportChange = event => setReport(event.target.value)

  const onFinish = () => {
    setReportStatus(INITIAL)
    setReport('')
    props.handleSwitchIsIssueReportModalOpen()
  }

  const isFormModalOpen = props.isOpen && (reportStatus === INITIAL || reportStatus === IN_PROGRESS)
  const isFinishModalOpen = props.isOpen && reportStatus === SUBMITTED
  return (
    <>
      <FormModal
        reportStatus={reportStatus}
        subtitle="Please describe what you were doing in order for us to recreate the issue and fix it as soon as possible."
        isOpen={isFormModalOpen}
        handleSwitchIsIssueReportModalOpen={props.handleSwitchIsIssueReportModalOpen}
        onSubmitIssueReport={onSubmitIssueReport}
        onReportChange={onReportChange}
        report={report}
      />
      <FinishModal isOpen={isFinishModalOpen} onFinish={onFinish} />
    </>
  )
}

IssueReportFromMenu.propTypes = {
  client: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  viewPeriod: PropTypes.string.isRequired,
  selectedDate: PropTypes.object.isRequired,
}

export default IssueReportFromMenu
