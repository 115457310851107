export const HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Duration Hours', key: 'durationHours' },
  { label: 'Comment', key: 'comment' },
  { label: 'Project Code', key: 'projectCode' },
  { label: 'Billable', key: 'billable' },
]

export const CSV_DATA = [
  {
    date: '2/25/2023',
    durationHours: '0.5',
    comment: 'Conference Call - Keystone Strategy & AY Project',
    projectCode: 'KS Office - MA',
    billable: 'false',
  },
  {
    date: '2/25/2023',
    durationHours: '1',
    comment: 'James - Rohit 1-1',
    projectCode: 'KS Training and Development',
    billable: 'false',
  },
  {
    date: '2/25/2023',
    durationHours: '2',
    comment: 'Dev Plan Discussion',
    projectCode: 'KS Training and Development',
    billable: 'false',
  },
]
