import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import { ModalTitle, ModalContent } from './style'

const Modal = ({ isOpen, handleClose, title, titleColor, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <>
          {title ? (
            <DialogTitle disableTypography>
              <ModalTitle color={titleColor}>{title}</ModalTitle>
            </DialogTitle>
          ) : null}
          <DialogContent style={{ padding: 0 }}>
            <ModalContent>{children}</ModalContent>
          </DialogContent>
        </>
      </Fade>
    </Dialog>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
}

export default Modal
