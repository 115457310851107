import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DurationPickerInput from './DurationPickerInput'
import TimeInputPicker from './TimeInputPicker'
import {
  NewPickersContainer,
  DurationPickerContainer,
  PickersTimeInputContainer,
} from '../../ListView/EntryBoxEditStyle'
import { getDurationInHours } from '../../../../server/utils/date'
import { getNewEndDate, getNewStartDate } from './utils'

class DurationPicker extends PureComponent {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.hideDurationPicker()
    }
  }

  handleDurationChange = (newDurationInHours, setControlledValue) => {
    if (newDurationInHours === '') {
      this.props.handleUpdateEntry({
        end: this.props.entry.start,
        originalDurationInHours: '',
      })
      setControlledValue('')
      return
    }

    const newDurationInHoursNumber = Number(newDurationInHours)
    if (Number.isNaN(newDurationInHoursNumber)) return
    const invalidDuration =
      newDurationInHoursNumber < 0 || (newDurationInHoursNumber !== 0 && !newDurationInHoursNumber)

    if (invalidDuration) return

    const newEnd = moment(this.props.entry.start)
      .add(newDurationInHoursNumber, 'hours')
      .toDate()

    this.props.handleUpdateEntry({
      end: newEnd,
      originalDurationInHours: newDurationInHoursNumber,
    })
    setControlledValue(newDurationInHours)
  }

  handleStartTimeChange = newStart => {
    const start = getNewStartDate(newStart, this.props.entry.end)
    const newDurationInHours = getDurationInHours(start, this.props.entry.end)
    this.props.handleUpdateEntry({
      start,
      originalDurationInHours:
        newDurationInHours < 0.1 ? this.props.entry.originalDurationInHours : newDurationInHours,
    })
  }

  handleEndTimeChange = newEnd => {
    const end = getNewEndDate(newEnd, this.props.entry.start)
    const newDurationInHours = getDurationInHours(this.props.entry.start, end)
    this.props.handleUpdateEntry({
      end,
      originalDurationInHours:
        (newDurationInHours || 0) < 0
          ? this.props.entry.originalDurationInHours
          : newDurationInHours,
    })
  }

  render() {
    return (
      <DurationPickerContainer ref={this.wrapperRef}>
        <DurationPickerInput
          data-testid="entryBox.duration"
          id="duration"
          InputProps={{ classes: { input: this.props.classes.inputPicker } }}
          // eslint-disable-next-line
          inputProps={{
            onClick: this.props.showDurationPicker,
            className: 'mousetrap',
            min: 0.1,
            max: 10,
            step: 0.1,
          }}
          onChange={this.handleDurationChange}
          value={this.props.entry.originalDurationInHours}
          width="100%"
          textalign="center"
        />
        <PickersTimeInputContainer isVisible={this.props.isDurationPickerVisible}>
          <NewPickersContainer>
            <TimeInputPicker
              id="from"
              InputProps={{
                classes: { input: this.props.classes.inputPicker },
                disableUnderline: true,
              }}
              helperTextProps={{ classes: { root: this.props.classes.helperText } }}
              value={this.props.entry.start}
              onChange={this.handleStartTimeChange}
              width="auto"
              padding="0 5px 2px"
              minWidth="0"
              marginBottom="-3px"
            />
            <div className="dash-bar">-</div>
            <TimeInputPicker
              id="till"
              InputProps={{
                classes: { input: this.props.classes.inputPicker },
                disableUnderline: true,
              }}
              helperTextProps={{ classes: { root: this.props.classes.helperText } }}
              value={this.props.entry.end}
              onChange={this.handleEndTimeChange}
              width="auto"
              padding="0 5px 2px"
              minWidth="0"
              marginBottom="-3px"
            />
          </NewPickersContainer>
        </PickersTimeInputContainer>
      </DurationPickerContainer>
    )
  }
}

DurationPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  showDurationPicker: PropTypes.func.isRequired,
  hideDurationPicker: PropTypes.func.isRequired,
  isDurationPickerVisible: PropTypes.bool.isRequired,
  entry: PropTypes.object.isRequired,
  handleUpdateEntry: PropTypes.func.isRequired,
}

export default DurationPicker
