import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { useMeasure } from 'react-use'

import { HotTable, HotColumn } from '@handsontable/react'
import CheckBoxCell from './CheckBoxCell'
import { TableContainer } from './styles'

import eventUtils from '../../../utils/eventUtils'
import { genProjectEditor, genProjectsList } from './editors'
import { projectUpdater, billableUpdater, commentUpdater, durationUpdater } from './updaters'
import { getCellsConfiguration, selectEntryToEdit, beforeKeyDown } from './utils'

import { isAnOperation, getEntry } from '../../../models/entryOperation'
import timeUtils from '../../../utils/timeUtils'
import {
  getHotSettings,
  inyectMeasure,
  COL_HEADERS,
  getHotDisabledState,
  TABLE_DEFAULTS,
} from './utils/tableSettings'
import {
  getUpdatedSortConfig,
  getDataWithHeaderKeys,
  getDataWithoutHeaderKeys,
  applySort,
} from './utils/columnSorting'

import { getValidProjects, getTableData } from './utils/tableData'

const ResponsiveTable = ({
  timesheets,
  timeEntries: unfilteredTimeEntries,
  day,
  buildCurrentEditableEntry,
  projects,
  handleCreateEntrySubmission,
  handleUpdateEntrySubmission,
  showEntryInvoicedModal,
  contextMenuActions,
  handleNoFreeGapModalOpen,
  userSettings,
  mostRecentlyUsedProjectIds,
  assignments,
  person,
}) => {
  const hot = useRef()
  const [ref, { width }] = useMeasure()
  const [sortConfig, setSortConfig] = useState([])

  const dayDate = new Date(day)

  const timeEntries = unfilteredTimeEntries.filter(entryOrOperation => {
    const isOperation = isAnOperation(entryOrOperation)
    const entry = isOperation ? getEntry(entryOrOperation) : entryOrOperation
    return timeUtils.areSameDay(entry.start, dayDate)
  })
  useEffect(() => {
    const hotInstance = hot.current.hotInstance

    // updates sort header states
    const columnSortPlugin = hotInstance.getPlugin('columnSorting')
    columnSortPlugin.columnStatesManager.setSortStates(sortConfig)

    // update hot data and cell config
    const data = getDataToRender(sortConfig)
    const cell = getCellsConfiguration({ timeEntries, projects, colHeaders: COL_HEADERS, data })
    hotInstance.updateSettings({ data, cell })
  })

  // Hot Hooks
  const beforeColumnSort = (currentSortConfig, destinationSortConfigs) => {
    const tableInstance = hot.current.hotInstance
    // eslint-disable-next-line no-unused-vars
    const [startRow, startCol, endRow, endCol] = tableInstance.getSelectedLast()

    const updatedSortConfig = getUpdatedSortConfig({
      currentSortConfig,
      destinationSortConfigs,
      lastSelectedColumn: startCol,
    })
    setSortConfig(updatedSortConfig)

    // escape hot default sorting
    return false
  }

  const afterChange = (update, source) => {
    // We need to handle this edge cases to avoid reupdating in the cycles
    if (source === 'loadData') return
    // eslint-disable-next-line no-unused-vars
    const [row, col, oldValue, newValue] = update[0]
    if (_.isNil(newValue)) return

    const selectedDate = new Date(day)
    const entryToEdit = selectEntryToEdit({
      hotInstance: hot.current.hotInstance,
      row,
      timeEntries,
      selectedDate,
      buildCurrentEditableEntry,
    })

    if (!entryToEdit) {
      handleNoFreeGapModalOpen(true)
      return
    }
    // -------------------------------------------------------------------
    // Here we choose wich updater is gonna be used given the row
    const updaters = [projectUpdater, billableUpdater, commentUpdater, durationUpdater]
    // Update current editable entry
    const updatedEntry = updaters[col]({ editableEntry: entryToEdit, newValue, projects })
    if (updatedEntry._id !== 'placeholder') {
      handleUpdateEntrySubmission(updatedEntry)
      return
    }

    const newTableHeight = hotSettings.height + TABLE_DEFAULTS.ROW_HEIGHT
    hot.current.hotInstance.updateSettings({ height: newTableHeight })

    handleCreateEntrySubmission(updatedEntry)
  }

  const beforeChange = update => {
    // eslint-disable-next-line no-unused-vars
    const [row, col, oldValue, newValue] = update[0]
    if (
      _.isNil(newValue) ||
      newValue === '<strong>Default projects</strong>' ||
      newValue === '<strong>Favorite projects</strong>' ||
      newValue === '<strong>Recently Used Projects</strong>' ||
      newValue === '<strong>All projects</strong>'
    )
      return false
    return true
  }

  const afterOnCellMouseDown = (event, coords) => {
    const { row } = coords
    if (eventUtils.isEntryInvoiced(timeEntries[row])) {
      showEntryInvoicedModal()
    }
  }

  const isHotDisabled = getHotDisabledState({ timesheets, day })
  const data = getTableData({ timeEntries, projects })

  const getDataToRender = updatedSortConfig => {
    const dataWithoutEmptyRow = isHotDisabled ? data : data.slice(0, data.length - 1)
    const dataWithHeaders = getDataWithHeaderKeys(dataWithoutEmptyRow)
    const sortedData = applySort(dataWithHeaders, updatedSortConfig)
    const dataWithoutHeaders = getDataWithoutHeaderKeys(sortedData)

    return [...dataWithoutHeaders]
  }

  const hotSettings = getHotSettings({
    data,
    timeEntries,
    projects,
    afterOnCellMouseDown,
    afterChange,
    beforeKeyDown,
    beforeColumnSort,
    contextMenuActions,
    isHotDisabled,
    beforeChange,
    hotInstance: _.get(hot, 'current.hotInstance', null),
  })

  const newSettings = inyectMeasure(width, hotSettings)

  const sortedValidProjects = getValidProjects(projects)

  const editor = genProjectEditor(sortedValidProjects)

  // if user's position or user's employeeType are Contractor - Expert, only show projects that they are assigned to
  const isExpert =
    _.get(person, 'position') === 'Contractor - Expert' ||
    _.get(person, 'employeeType', '').includes('Contractor - Expert')
  const expertProjects = _.filter(projects, project =>
    _.includes(_.map(assignments, 'projectId'), project._id),
  )
  const tableProjects = isExpert ? expertProjects : sortedValidProjects
  const source = genProjectsList(
    tableProjects,
    userSettings,
    mostRecentlyUsedProjectIds,
    assignments,
  )

  return (
    <TableContainer ref={ref}>
      <HotTable ref={hot} settings={newSettings}>
        <HotColumn
          settings={{
            type: 'autocomplete',
            allowHtml: true,
          }}
          title="Project"
          editor={editor}
          source={source}
        />
        <HotColumn
          title="$"
          className="htCenter htMiddle"
          settings={{
            type: 'checkbox',
          }}
        >
          <CheckBoxCell isDisabled={isHotDisabled} afterChange={afterChange} hot-renderer />
        </HotColumn>
        <HotColumn title="Comment" />
        <HotColumn
          title="Duration"
          settings={{
            type: 'numeric',
            numericFormat: {
              pattern: '0.0',
            },
            className: 'htCenter htMiddle',
          }}
        />
        <HotColumn title="Entry ID" readOnly />
      </HotTable>
    </TableContainer>
  )
}

ResponsiveTable.propTypes = {
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  buildCurrentEditableEntry: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  handleCreateEntrySubmission: PropTypes.func.isRequired,
  handleUpdateEntrySubmission: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  day: PropTypes.string.isRequired,
  showEntryInvoicedModal: PropTypes.func.isRequired,
  contextMenuActions: PropTypes.shape({
    deleteEntry: PropTypes.func.isRequired,
    duplicateEntry: PropTypes.func.isRequired,
  }).isRequired,
  handleNoFreeGapModalOpen: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  mostRecentlyUsedProjectIds: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  person: PropTypes.object.isRequired,
}

export default ResponsiveTable
