import { orderBy, reduce } from 'lodash'

export const getUpdatedSortConfig = ({
  currentSortConfig,
  destinationSortConfigs,
  lastSelectedColumn: startCol,
}) => {
  const currentSort = currentSortConfig.find(sort => sort.column === startCol)

  if (!currentSort) return [...currentSortConfig, ...destinationSortConfigs]

  const { column: currentSortCol, sortOrder: currentSortOrder } = currentSort

  if (currentSortOrder === 'asc') {
    const withoutDestination = currentSortConfig.filter(sort => sort.column !== currentSortCol)
    return [...withoutDestination, { column: currentSortCol, sortOrder: 'desc' }]
  }

  return currentSortConfig.filter(sort => sort.column !== currentSortCol)
}

export const getDataWithHeaderKeys = data => {
  const headers = ['projectName', 'isBillable', 'comment', 'duration']

  return data.map(row => {
    return reduce(
      row,
      (accum, current, index) => {
        return {
          ...accum,
          [headers[index]]: current,
        }
      },
      [],
    )
  })
}

export const getDataWithoutHeaderKeys = data => data.map(row => Object.values(row))

export const applySort = (dataObj, sortConfig) => {
  const headers = ['projectName', 'isBillable', 'comment', 'duration']

  const { columnsToSort, columnsSortOrder } = sortConfig.reduce(
    (accum, columnSortConfig) => {
      const { column, sortOrder } = columnSortConfig
      const columnToSort = headers[column]

      return {
        columnsToSort: [...accum.columnsToSort, columnToSort],
        columnsSortOrder: [...accum.columnsSortOrder, sortOrder],
      }
    },
    {
      columnsToSort: [],
      columnsSortOrder: [],
    },
  )

  return orderBy(dataObj, columnsToSort, columnsSortOrder)
}
