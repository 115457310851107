import _ from 'lodash'

class _Result {
  constructor(entries = [], errors = {}) {
    this.entries = entries
    this.errors = errors
  }

  isOk() {
    const failedEntries = _.get(this.errors, 'failedEntries', [])
    return !_.isEmpty(this.entries) || !_.isEmpty(failedEntries)
  }

  getPrettyErrors() {
    const errorMessages = _.get(this.errors, 'errorMessages', [])
    const errorList = _.map(errorMessages, error => `\n${error}`).join()
    return new Error(
      `There were ${errorMessages.length} errors in the file you uploaded. Please fix those issues and try again.${errorList}`,
    )
  }
}

const fail = message => {
  return new _Result([], { errorMessages: [message] })
}

const failWithErrors = errors => {
  return new _Result([], errors)
}

const ok = (entries, errors) => {
  return new _Result(entries, errors)
}

export const Result = {
  fail,
  failWithErrors,
  ok,
}
