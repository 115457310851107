import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import CsvLoaderModal from './CsvLoaderModal'
import MultiModal from './MultiModal'

const CsvMultiModal = ({
  clearCsvSuccessState,
  clearCsvFailState,
  successCsvInfoTotal,
  failedCsvInfoTotal,
  unhandledEntriesInfo,
  onAdd,
  onDismiss,
  onReplace,
  csvActionButtonsBlocked,
  csvUploadData,
}) => {
  const shoudRenderMultiModal =
    !csvUploadData.isCSVUploadInProgress &&
    (!_.isEmpty(successCsvInfoTotal) ||
      !_.isEmpty(unhandledEntriesInfo) ||
      !_.isEmpty(failedCsvInfoTotal))
  return (
    <>
      <CsvLoaderModal csvUploadData={csvUploadData} />
      {shoudRenderMultiModal && (
        <MultiModal
          clearCsvSuccessState={clearCsvSuccessState}
          clearCsvFailState={clearCsvFailState}
          successCsvInfoTotal={successCsvInfoTotal}
          failedCsvInfoTotal={failedCsvInfoTotal}
          unhandledEntriesInfo={unhandledEntriesInfo}
          onAdd={onAdd}
          onDismiss={onDismiss}
          onReplace={onReplace}
          csvActionButtonsBlocked={csvActionButtonsBlocked}
        />
      )}
    </>
  )
}

CsvMultiModal.propTypes = {
  clearCsvSuccessState: PropTypes.func.isRequired,
  clearCsvFailState: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  successCsvInfoTotal: PropTypes.object.isRequired,
  failedCsvInfoTotal: PropTypes.object.isRequired,
  unhandledEntriesInfo: PropTypes.object.isRequired,
  csvActionButtonsBlocked: PropTypes.bool.isRequired,
  csvUploadData: PropTypes.object.isRequired,
}

export default memo(CsvMultiModal)
