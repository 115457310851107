export const PERMISSION_DEFAULT = 'default'

export const PERMISSION_DENIED = 'denied'

export const PERMISSION_GRANTED = 'granted'

export const PERMISSION_NONE = 'none'

export const checkNotificationFeature = () => {
  if (!!window && 'Notification' in window) return true

  return false
}

export const askNotificationPermission = () => {
  return Notification.requestPermission()
}
