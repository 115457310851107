import _ from 'lodash'
import { convertMessageToGlimpseEmail } from '../../../utils/events'

export default (messageDeltas = []) => {
  return _.reduce(
    messageDeltas,
    (acc, messageDelta) => {
      const messageAction = messageDelta.deltaType

      if (messageAction === 'delete')
        return {
          ...acc,
          delete: [...acc.delete, { _id: messageDelta.id }],
        }

      const glimpseEmail = convertMessageToGlimpseEmail(messageDelta.message)

      if (messageAction === 'modify') {
        return {
          ...acc,
          [messageAction]: [...acc[messageAction], glimpseEmail],
        }
      }
      return acc
    },
    { create: [], modify: [], delete: [] },
  )
}
