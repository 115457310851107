import gql from 'graphql-tag'

export const CREATE_ANALYTICS_INITIAL_SYNC_TIME_TRACK = gql`
  mutation(
    $calendarEventsLoadTime: Float!
    $emailsLoadTime: Float!
    $timesheetDataLoadTime: Float!
  ) {
    analytics_initialSyncTime_track(
      calendarEventsLoadTime: $calendarEventsLoadTime
      emailsLoadTime: $emailsLoadTime
      timesheetDataLoadTime: $timesheetDataLoadTime
    )
  }
`
