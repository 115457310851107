import gql from 'graphql-tag'

export const GET_USER_SETTINGS = gql`
  query {
    getUserSettings {
      _id
      favoriteProjectIds
      defaultProjectId
      isAdmin
      firstMonthRange
      lastMonthRange
    }
  }
`
export const GET_USER_PHOTO = gql`
  query {
    getUserPhoto {
      hasPicture
      picture
      contentType
    }
  }
`
