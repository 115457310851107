import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import ConfirmationModal from '../../ConfirmationModal'
import { getFailSubtitle } from '../utils'

const FailModal = ({ classes, csvInfoTotal, handler, isOpen }) => {
  const failedTimesheetTotal = _.get(csvInfoTotal, 'csvInfoByTimesheetTotal', [])
  const subtitle = getFailSubtitle(csvInfoTotal)
  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="ATTENTION!"
      titleColor="secondary"
      subtitle={subtitle}
      actionButtons={[{ handler, label: 'OK', color: 'primary' }]}
    >
      <TableContainer className={classes.container}>
        <Table>
          <TableBody>
            {_.map(failedTimesheetTotal, e => (
              <TableRow key={e.start}>
                <TableCell align="center">{e.start}</TableCell>
                <TableCell align="center">
                  {e.count} {e.count > 1 ? 'entries' : 'entry'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfirmationModal>
  )
}

FailModal.propTypes = {
  classes: PropTypes.object.isRequired,
  csvInfoTotal: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default FailModal
