/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { connect } from 'react-redux'
import { withAuth0 } from '@auth0/auth0-react'

import { logoutThunk } from '../redux/thunks/auth'
import { getLatestGlimpseToken } from '../redux/services/auth'

import config from '../config'

const ClientContext = createContext()

const JWT_INVALID_TOKEN_ERROR = 'Context creation failed: JWT INVALID'

const ApolloClientProvider = props => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) return
    const shouldLogout = graphQLErrors.some(e => {
      return e.message === JWT_INVALID_TOKEN_ERROR
    })

    if (shouldLogout) props.logout()
  })

  const httpLink = createHttpLink({
    uri: `${config.API_URL}`,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = await getLatestGlimpseToken(props.auth0)
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const link = ApolloLink.from([authLink, errorLink, httpLink])

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions,
  })

  return (
    <ApolloProvider client={client}>
      <ClientContext.Provider value={client}>{props.children}</ClientContext.Provider>
    </ApolloProvider>
  )
}

const mapDispatchToProps = {
  logout: logoutThunk,
}

export const useClient = () => {
  const context = useContext(ClientContext)
  if (context === undefined) {
    throw new Error('must be within ContextProvider')
  }
  return context
}

ApolloClientProvider.propTypes = {
  logout: PropTypes.func.isRequired,
  auth0: PropTypes.object.isRequired,
}

export const Provider = connect(null, mapDispatchToProps)(withAuth0(ApolloClientProvider))
