class Store {
  constructor(prefix = 'global', store = window.localStorage) {
    this.store = store
    this.prefix = prefix
  }

  set(key, value) {
    this.store.setItem(`${this.prefix}/${key}`, JSON.stringify(value))
  }

  get(key) {
    return JSON.parse(this.store.getItem(`${this.prefix}/${key}`))
  }

  clear() {
    this.store.clear()
  }
}

export default Store
