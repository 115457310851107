import _ from 'lodash'

// Models
import Email from '../../server/models/email'
import Event from '../../server/models/event'
import Timesheet from '../../server/models/timesheet'
import TimeEntry from '../../server/models/timeEntry'
import EntryAction from '../../server/models/entryAction'
import TimeOffEntry from '../../server/models/timeOffEntry'
import UnhandledEntry from '../../server/models/unhandledEntry'
import TimeOffCode from '../../server/models/timeOffCode'
import Project from '../../server/models/project'
import Assignment from '../../server/models/assignment'

// Utils
import eventUtils from '../../utils/eventUtils'
import { log, logError } from '../../utils/logger'
import { convertDateToLocalTimezone } from '../../server/utils/date'
import { getLastUsedProjectIds } from '../../server/utils/projects'

import ipcRenderer from '../../ipcRenderer'

export const updateViewOutlookEvents = async ({
  getUIRefreshTimePeriod,
  handleUpdateEventsData,
}) => {
  try {
    const { startISO, endISO } = getUIRefreshTimePeriod()
    const startISOLocal = convertDateToLocalTimezone(startISO)
    const endISOLocal = convertDateToLocalTimezone(endISO)

    const events = await Event.where('start')
      .between(startISOLocal, endISOLocal, true, true)
      .filter(event => event.type !== 'seriesMaster')
      .toArray()
    const eventCount = _.size(events)
    log(`LS → UI → Event → ${eventCount}`)
    handleUpdateEventsData(events)
    return eventCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewOutlookEmailsAndEvents > e:')
  }
}

export const updateViewOutlookEmails = async ({
  getUIRefreshTimePeriod,
  handleUpdateEmailData,
}) => {
  try {
    const { startISO, endISO } = getUIRefreshTimePeriod()
    const startISOLocal = convertDateToLocalTimezone(startISO)
    const endISOLocal = convertDateToLocalTimezone(endISO)

    const emails = await Email.where('start')
      .between(startISOLocal, endISOLocal, true, true)
      .toArray()
    const emailCount = _.size(emails)
    log(`LS → UI → Email → ${emailCount}`)
    handleUpdateEmailData(emails)
    return emailCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewOutlookEmailsAndEvents > e:')
  }
}

export const updateViewTimesheets = async ({ handleAppState }) => {
  try {
    const timesheets = await Timesheet.toArray()
    const timesheetCount = _.size(timesheets)
    log(`LS → UI → Timesheet → ${timesheetCount}`)
    handleAppState({
      timesheets,
    })
    return timesheetCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewTimesheets > e: ')
  }
}

export const updateViewTimesheetData = async ({
  getUIRefreshTimePeriod,
  userSettings,
  handleAppState,
  handleUpdateTimesheetData,
}) => {
  try {
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod(true)

    const timeEntries = await TimeEntry.where('originalEntryDate')
      .between(
        convertDateToLocalTimezone(startISOUI),
        convertDateToLocalTimezone(endISOUI),
        true,
        true,
      )
      .toArray()
    const timeEntryCount = _.size(timeEntries)
    log(`LS → UI → TimeEntry → ${timeEntryCount}`)

    const timeOffEntries = await TimeOffEntry.toArray()
    const timeOffEntryCount = _.size(timeOffEntries)
    log(`LS → UI → TimeOffEntry → ${timeOffEntryCount}`)

    const mostRecentlyUsedProjectIds = getLastUsedProjectIds(
      timeEntries,
      3,
      userSettings.favoriteProjectIds,
    )
    const mruCount = _.size(mostRecentlyUsedProjectIds)
    log(`LS → UI → MostRecentlyUsedProjectIds → ${mruCount}`)
    handleAppState({
      timeOffEntries: eventUtils.parseEventsDates(timeOffEntries),
      mostRecentlyUsedProjectIds,
    })

    const localEntries = await EntryAction.toArray()
    const entryOperationCount = _.size(localEntries)

    if (ipcRenderer) ipcRenderer.send('save-offline-entries', localEntries)

    log(`LS → UI → EntryOperation → ${entryOperationCount}`)

    handleUpdateTimesheetData({
      timeEntries,
      localEntries,
    })
    return timeEntryCount + timeOffEntryCount + entryOperationCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewTimesheetData > e: ')
  }
}

export const updateUnhandledEntries = async ({ handleAppState }) => {
  const unhandledEntries = await UnhandledEntry.toArray()
  const unhandledEntriesCount = _.size(unhandledEntries)
  log(`LS → UI → UnhandledEntry → ${unhandledEntriesCount}`)
  handleAppState({
    unhandledEntries: eventUtils.parseUnhandledEntries(unhandledEntries),
    unhandledEntriesInfo: eventUtils.parseUnhandledEntriesInfo(unhandledEntries),
  })
  return unhandledEntriesCount
}

export const updateViewTimeOffCodes = async ({ handleAppState }) => {
  try {
    const timeOffCodes = await TimeOffCode.toArray()
    const timeOffCodeCount = _.size(timeOffCodes)
    log(`LS → UI → TimeOffCode → ${timeOffCodeCount}`)
    handleAppState({
      timeOffCodes,
    })
    return timeOffCodeCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewTimeOffCodes > e:')
  }
}

export const updateViewProjects = async ({ handleAppState }) => {
  try {
    const projects = await Project.toArray()
    const projectCount = _.size(projects)
    log(`LS → UI → Project → ${projectCount}`)
    handleAppState({
      projects,
    })
    return projectCount
  } catch (e) {
    logError(e, 'app/index.js > updateViewProjects > e: ')
  }
}

export const updateViewAssignments = async ({ getUIRefreshTimePeriod, handleAppState }) => {
  try {
    const { startISO: startISOUI, endISO: endISOUI } = getUIRefreshTimePeriod(true)
    const assignments = await Assignment.filter(assignment => {
      return (
        assignment.startDate <= convertDateToLocalTimezone(endISOUI) &&
        assignment.endDate >= convertDateToLocalTimezone(startISOUI)
      )
    }).toArray()
    const assignmentCount = _.size(assignments)
    log(`LS → UI → Assignment → ${assignmentCount}`)

    handleAppState({
      assignments,
    })

    return assignmentCount
  } catch (e) {
    logError(e, 'app/index.js > syncAssignments > ')
  }
}
