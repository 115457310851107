import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'

const StylessButton = styled.button`
  border: none;
`

const GroupedEvent = ({ date, count, topic, used, children }) => {
  const [toggle, setToggle] = useState(false)

  const onToggle = () => {
    setToggle(!toggle)
  }
  const timeRange = `Last sent at ${moment(date).format('HH:mm A')}`
  const title = `${topic || '(No title)'} (${count})`
  const toggleClass = toggle ? 'event-toggled' : ''
  const arrowClass = `arrow arrow-${toggle ? 'bottom' : 'right'}`

  const renderChildren = () => {
    return toggle
      ? React.Children.map(children, child => React.cloneElement(child, { used }))
      : null
  }

  return (
    <div className={`grouped-events ${used ? 'used' : ''}`}>
      <div className="event-header">
        <StylessButton className="toggle-indicator" onClick={onToggle}>
          <span className={arrowClass} />
        </StylessButton>
        <StylessButton className={`grouped-event ${toggleClass}`} onClick={onToggle}>
          <div className="name">{title}</div>
          <div className="time">{timeRange}</div>
        </StylessButton>
      </div>
      <div className="grouped-event-list">{renderChildren()}</div>
    </div>
  )
}

GroupedEvent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  used: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

export default GroupedEvent
