/* eslint-disable no-new */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// MUI components
import FormControlLabel from '@material-ui/core/FormControlLabel'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import Popover from '@material-ui/core/Popover'

// custom components
import RemindMeCheckbox from './RemindMeCheckbox'
import DayCheckbox from './DayCheckbox'
import AlertMessage from '../AlertMessage'
import ChangePermissionInstructions from './ChangePermissionInstructions'

import {
  RemindMeLaterLabel,
  DayLabel,
  DaysFormGroup,
  FieldLabel,
  StyledButton,
  TimePickerContainer,
  ReminderTimeInput,
  ReminderTimeFormControl,
  StyledMainContainer,
  StyledCenterItem,
  FromToContainer,
  StyledButtonsContainer,
  StyledPermissionStatusMessage,
  StyledPermissionStatusSpan,
  useStyles,
  StyledErrorMessageContainer,
  StyledErrorMessage,
  StyledErrorIcon,
} from './style'

// services
import { useClient } from '../../graphql/provider'
import * as ReminderSettingsService from '../../entities/reminderSettings/service'

import {
  PERMISSION_DEFAULT,
  PERMISSION_DENIED,
  PERMISSION_GRANTED,
  PERMISSION_NONE,
  checkNotificationFeature,
  askNotificationPermission,
} from '../../utils/Notifications'

import config from '../../config'

const { IS_WEB } = config

const Form = props => {
  const client = useClient()
  const classes = useStyles()
  const now = moment()
    .utc()
    .toISOString()

  const [shouldRemind, setShouldRemind] = useState(true)
  const [weekDays, setWeekDays] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  })

  const [timeFrom, setTimeFrom] = useState(new Date(now))
  const [timeTo, setTimeTo] = useState(new Date(now))
  const [periodInMinutes, setPeriodInMinutes] = useState(30)
  const [alertMessage, setAlertMessage] = useState({
    isOpen: false,
    message: '',
    severity: 'success',
  })
  const [isSavingSettings, setIsSavingSettings] = useState(false)
  const [showPermissionStatusMessage, setShowPermissionStatusMessage] = useState(PERMISSION_NONE)
  const [anchorEl, setAnchorEl] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  useEffect(() => {
    if (!props.reminderSettings) return

    const {
      shouldRemind: appIsReminderEnable,
      weekDays: appReminderDayStatus,
      timeFrom: appSelectedFromDate,
      timeTo: appSelectedToDate,
      periodInMinutes: appMinutesBeforeReminder,
    } = props.reminderSettings

    setShouldRemind(appIsReminderEnable)
    setWeekDays(appReminderDayStatus)
    setTimeFrom(appSelectedFromDate)
    setTimeTo(appSelectedToDate)
    setPeriodInMinutes(appMinutesBeforeReminder)

    if (!IS_WEB || !checkNotificationFeature()) return

    if (Notification.permission === PERMISSION_DENIED) {
      setShowPermissionStatusMessage(PERMISSION_DENIED)
    }

    if (Notification.permission === PERMISSION_GRANTED) {
      setShowPermissionStatusMessage(PERMISSION_GRANTED)
    }
    // eslint-disable-next-line
  }, [])

  const openPopover = !!anchorEl

  const handleReminderDaysChange = event => {
    const selectedDayName = event.target.name
    const updatedWeekDays = {
      ...weekDays,
      [selectedDayName]: !weekDays[selectedDayName],
    }
    setWeekDays(updatedWeekDays)
  }

  const handleMinutesBeforeReminderChange = event => {
    const minutesToSet = Number(event.target.value)

    if (minutesToSet < 0) return
    setPeriodInMinutes(minutesToSet)
  }

  const handleSelectedFromDateChange = date => {
    const momentTimeFrom = moment(date)

    const momentTimeTo = moment(timeTo)

    if (momentTimeTo.diff(momentTimeFrom) < 0) {
      setErrorMessage('From Date must be lower or equal to To Date!')

      return setTimeout(() => {
        setErrorMessage(null)
      }, 5000)
    }
    setErrorMessage(null)
    setTimeFrom(date.toDate())
  }

  const handleSelectedToDateChange = date => {
    const momentTimeTo = moment(date)

    const momentTimeFrom = moment(timeFrom)

    if (momentTimeTo.diff(momentTimeFrom) < 0) {
      setErrorMessage('To Date must be greater or equal to From Date!')
      return setTimeout(() => {
        setErrorMessage(null)
      }, 5000)
    }
    setErrorMessage(null)
    setTimeTo(date.toDate())
  }

  const handleAlertMessageClose = () => {
    setAlertMessage({
      isOpen: false,
      message: '',
      severity: 'success',
    })
  }

  const handleSubmit = async () => {
    const utcTimeFrom = moment(timeFrom)
      .utc()
      .toISOString()
    const utcTimeTo = moment(timeTo)
      .utc()
      .toISOString()

    const args = {
      shouldRemind,
      weekDays,
      timeFrom: utcTimeFrom,
      timeTo: utcTimeTo,
      periodInMinutes: Number(periodInMinutes),
    }

    await ReminderSettingsService.setReminderSettings(client, args)

    props.handleSetReminderSettings({
      shouldRemind,
      weekDays,
      timeFrom,
      timeTo,
      periodInMinutes,
    })

    setAlertMessage({
      isOpen: true,
      message: 'Reminder settings have been saved!',
      severity: 'success',
    })

    setTimeout(() => {
      props.handleModalClose()

      setIsSavingSettings(false)
    }, 1000)
  }

  const handleRemindMeCheckBoxClick = async e => {
    const newValue = e.target.checked

    if (!IS_WEB || !checkNotificationFeature()) return setShouldRemind(false)

    if (Notification.permission === PERMISSION_DENIED) {
      setShowPermissionStatusMessage(PERMISSION_DENIED)

      return setShouldRemind(false)
    }

    if (Notification.permission === PERMISSION_GRANTED) {
      setShowPermissionStatusMessage(PERMISSION_GRANTED)

      return setShouldRemind(newValue)
    }

    if (Notification.permission === PERMISSION_DEFAULT) {
      if (!newValue) return setShouldRemind(false)

      setShouldRemind(true)

      await askNotificationPermission()

      if (Notification.permission === PERMISSION_DENIED) {
        setShowPermissionStatusMessage(PERMISSION_DENIED)
        setShouldRemind(false)
      }
      if (Notification.permission === PERMISSION_GRANTED) {
        setShowPermissionStatusMessage(PERMISSION_GRANTED)

        setShouldRemind(true)
      }
      if (Notification.permission === PERMISSION_DEFAULT) {
        setShowPermissionStatusMessage(PERMISSION_NONE)

        setShouldRemind(false)
      }
    }
  }

  const handlePopoverOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const displayPermissionStatusMessages = showPermission => {
    if (showPermission === PERMISSION_DENIED) {
      return (
        <StyledPermissionStatusMessage color="red">
          Notifications has been Blocked by the user. You can unblock Notifications following these
          <StyledPermissionStatusSpan
            color="#137cbd"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            Instructions
          </StyledPermissionStatusSpan>
        </StyledPermissionStatusMessage>
      )
    }

    if (showPermission === PERMISSION_GRANTED) {
      return (
        <StyledPermissionStatusMessage color="#137cbd">
          Notifications has been Granted by the user. You can block Notifications following these
          <StyledPermissionStatusSpan
            color="red"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            Instructions
          </StyledPermissionStatusSpan>
        </StyledPermissionStatusMessage>
      )
    }

    return <StyledPermissionStatusMessage> </StyledPermissionStatusMessage>
  }

  const displayErrorMessages = errMessage => {
    if (!errMessage) return <StyledErrorMessageContainer> </StyledErrorMessageContainer>

    return (
      <StyledErrorMessageContainer>
        <StyledErrorIcon fontSize="large" color="secondary" />
        <StyledErrorMessage>{errMessage}</StyledErrorMessage>
      </StyledErrorMessageContainer>
    )
  }

  const reminderDays = Object.keys(weekDays)

  reminderDays.shift()

  reminderDays.push('sunday')

  return (
    <>
      <StyledMainContainer>
        <StyledCenterItem>
          <FormControlLabel
            control={
              <RemindMeCheckbox
                checked={shouldRemind}
                onChange={handleRemindMeCheckBoxClick}
                name="reminder"
              />
            }
            label={<RemindMeLaterLabel>Remind Me</RemindMeLaterLabel>}
          />
        </StyledCenterItem>
        <StyledCenterItem>
          <DaysFormGroup>
            {reminderDays.map(day => {
              return (
                <FormControlLabel
                  style={{ margin: 0 }}
                  key={day}
                  control={
                    <DayCheckbox
                      checked={weekDays[day]}
                      onChange={handleReminderDaysChange}
                      name={day}
                    />
                  }
                  label={
                    <DayLabel checked={weekDays[day]} label={day.substring(0, 3).toUpperCase()} />
                  }
                />
              )
            })}
          </DaysFormGroup>
        </StyledCenterItem>
        {displayErrorMessages(errorMessage)}
        <StyledCenterItem>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <FromToContainer>
              <FieldLabel>From:</FieldLabel>
              <TimePickerContainer>
                <KeyboardTimePicker
                  margin="normal"
                  id="from-time-picker"
                  value={timeFrom}
                  onChange={handleSelectedFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </TimePickerContainer>
            </FromToContainer>
            <FromToContainer>
              <FieldLabel>To:</FieldLabel>
              <TimePickerContainer>
                <KeyboardTimePicker
                  margin="normal"
                  id="to-time-picker"
                  value={timeTo}
                  onChange={handleSelectedToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </TimePickerContainer>
            </FromToContainer>
          </MuiPickersUtilsProvider>
          <ReminderTimeFormControl>
            <FieldLabel>Every</FieldLabel>
            <ReminderTimeInput
              type="number"
              aria-describedby="every-reminder"
              value={periodInMinutes}
              onChange={handleMinutesBeforeReminderChange}
            />
            <FieldLabel>minutes</FieldLabel>
          </ReminderTimeFormControl>
        </StyledCenterItem>
        <StyledButtonsContainer>
          <StyledButton
            variant="contained"
            disabled={isSavingSettings}
            onClick={props.handleModalClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            variant="contained"
            disabled={isSavingSettings}
            onClick={handleSubmit}
          >
            Save
          </StyledButton>
        </StyledButtonsContainer>
        {displayPermissionStatusMessages(showPermissionStatusMessage)}
      </StyledMainContainer>
      <AlertMessage
        isOpen={alertMessage.isOpen}
        handleClose={handleAlertMessageClose}
        message={alertMessage.message}
        severity={alertMessage.severity}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ChangePermissionInstructions />
      </Popover>
    </>
  )
}

Form.propTypes = {
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
}

export default Form
