import _ from 'lodash'
import { timesheet, billingOptions } from '../constants/billingOptions'

export const isProjectInternal = project => {
  return _.get(project, 'client', '').toUpperCase() === 'INTERNAL KEYSTONE'
}

export const isPTOCodeProject = project => !_.isEmpty(project) && project.projectType === 'pto'

export const getBillableByProject = project => {
  return _.isEmpty(project) ||
    isProjectInternal(project) ||
    project.billable === billingOptions.NONBILLABLE
    ? timesheet.NONBILLABLE
    : timesheet.BILLABLE
}

export const getFieldsToInheritToEntry = project => {
  const billable = getBillableByProject(project)

  const isPTO = isPTOCodeProject(project)
  const type = _.get(project, 'name')
  const originalProjectId = _.get(project, '_id', null)

  return {
    originalProjectId,
    billable,
    isPTO,
    type,
  }
}
