import moment from 'moment'

export const getNewStartDate = (newStart, endDate) => {
  const minutesDiff = moment(endDate).diff(newStart, 'minutes')
  const isNegativeTimeSpan = minutesDiff < 0

  if (isNegativeTimeSpan) return endDate
  return newStart
}

export const getNewEndDate = (newEnd, startDate) => {
  const minutesDiff = moment(newEnd).diff(startDate, 'minutes')
  const isNegativeTimeSpan = minutesDiff < 0

  if (isNegativeTimeSpan) return startDate
  return newEnd
}
