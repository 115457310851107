import gql from 'graphql-tag'

export const GET_MESSAGES = gql`
  query(
    $receivedAfter: String
    $receivedBefore: String
    $deltaToken: String
    $isDelta: Boolean
    $nextDeltaLink: String
    $folderType: Int
    $selectType: Int
    $isCache: Boolean
    $selectedDelegateId: String
  ) {
    getMessages(
      receivedAfter: $receivedAfter
      receivedBefore: $receivedBefore
      isDelta: $isDelta
      deltaToken: $deltaToken
      nextDeltaLink: $nextDeltaLink
      folderType: $folderType
      selectType: $selectType
      isCache: $isCache
      selectedDelegateId: $selectedDelegateId
    ) {
      deltaToken
      nextDeltaLink
      deltaMessages {
        id
        deltaType
        message {
          id
          subject
          from {
            emailAddress {
              name
              address
            }
          }
          toRecipients {
            emailAddress {
              name
              address
            }
          }
          ccRecipients {
            emailAddress {
              name
              address
            }
          }
          receivedDateTime
          bodyPreview
          body {
            contentType
            content
          }
        }
      }
    }
  }
`
