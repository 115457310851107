import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DayPicker, { DateUtils } from 'react-day-picker'
import Button from '@material-ui/core/Button'
import './testStyle.css'
import _ from 'lodash'

import moment from 'moment'
import {
  Column,
  FormContainer,
  ProjectContainer,
  PersonContainer,
  DatesContainer,
  DayPickerContainer,
  OtherFieldsContainer,
  ButtonsContainer,
  StyledTooltip,
} from './style'

const categoryOptions = ['Billable', 'Investment', 'Not Assigned', 'Time Off', 'Sales']

class NewAssignmentBox extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.state = this.getInitialState()
  }

  getInitialState() {
    const formattedStartDate = moment
      .utc(this.props.currentStartNextWeek, 'MM/DD/YYYY')
      .format('MM/DD/YYYY')
    const formattedEndDate = moment
      .utc(this.props.currentEndNextWeek, 'MM/DD/YYYY')
      .format('MM/DD/YYYY')

    return {
      from: new Date(formattedStartDate),
      to: new Date(formattedEndDate),
      min: new Date(formattedStartDate),
      projectSelected: this.getFirstProject(),
      personSelected: this.props.people[0],
      categorySelected: categoryOptions[0],
      percentageSelected: Number(0),
      currentView: this.props.currentView,
      currentEditableAssignment: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentView !== prevProps.currentView) {
      const { setCurrentEditableAssignment } = this.props
      setCurrentEditableAssignment(null)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.getInitialState())
    }

    if (!_.isEqual(this.props.currentEditableAssignment, prevProps.currentEditableAssignment)) {
      if (this.props.currentEditableAssignment) {
        this.fillAssignmentForm(this.props.currentEditableAssignment)
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(this.getInitialState())
      }
    }
  }

  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return
    }
    const range = DateUtils.addDayToRange(day, this.state)
    this.setState(range)
  }

  handleResetClick() {
    const { setCurrentEditableAssignment } = this.props
    setCurrentEditableAssignment(null)
    this.setState(this.getInitialState())
  }

  getFirstProject() {
    const { projects, currentProject } = this.props
    return currentProject ? _.find(projects, { _id: currentProject._id }) : projects[0]
  }

  fillAssignmentForm = assignment => {
    const formattedStartDate = moment
      .utc(_.get(assignment, 'startDate'), 'MM/DD/YYYY')
      .format('MM/DD/YYYY')
    const formattedEndDate = moment
      .utc(_.get(assignment, 'endDate'), 'MM/DD/YYYY')
      .format('MM/DD/YYYY')
    const category = _.get(assignment, 'investment')
    const personName = _.get(assignment, 'person.fullName')
    const projectCode = _.get(assignment, 'project.projectCode')
    const percent = _.get(assignment, 'percent').split('%')[0]
    const obj = {
      from: new Date(formattedStartDate),
      to: new Date(formattedEndDate),
      projectSelected: _.find(this.props.projects, {
        projectCode,
      }),
      personSelected: personName
        ? _.find(this.props.people, { fullName: personName })
        : this.state.personSelected,
      categorySelected: category,
      percentageSelected: Number(percent),
      currentEditableAssignment: assignment,
    }
    this.setState(obj)
  }

  validateState = () => {
    const personId = _.get(this.state, 'personSelected')
    const projectId = _.get(this.state, 'projectSelected')
    const percent = _.get(this.state, 'percentageSelected')
    const investment = _.get(this.state, 'categorySelected')
    const startDate = _.get(this.state, 'from')
    const endDate = _.get(this.state, 'to')
    return personId && projectId && percent && investment && startDate && endDate
  }

  getAssignmentFromState = () => {
    const personId = _.get(this.state, 'personSelected._id')
    const projectId = _.get(this.state, 'projectSelected._id')
    const percent = `${_.get(this.state, 'percentageSelected')}%`
    const investment = _.get(this.state, 'categorySelected')
    const startDate = moment(this.state.from).format('MM/DD/YYYY')
    const endDate = moment(this.state.to).format('MM/DD/YYYY')
    return { personId, projectId, startDate, endDate, percent, investment }
  }

  submitAssignment = async () => {
    const isStateValid = this.validateState()
    if (isStateValid) {
      const assignment = this.getAssignmentFromState()
      await this.props.createAssignment(assignment)
      this.setState(this.getInitialState())
      return
    }
    console.log('Cannot create assignment. State is not valid')
  }

  updateAssignment = async () => {
    const isStateValid = this.validateState()
    if (isStateValid) {
      const assignment = this.getAssignmentFromState()
      const assignmentId = _.get(this.state, 'currentEditableAssignment._id')
      await this.props.updateAssignment(assignmentId, assignment)
      this.setState(this.getInitialState())
      return
    }
    console.log('Cannot update assignment. State is not valid')
  }

  deleteAssignment = async () => {
    const assignmentId = _.get(this.state, 'currentEditableAssignment._id')
    await this.props.deleteAssignment(assignmentId)
    this.setState(this.getInitialState())
  }

  handleSelectProject = project => {
    this.setState({ projectSelected: project })
  }

  handleSelectPerson = person => {
    this.setState({ personSelected: person })
  }

  handleSelectCategory = category => {
    this.setState({ categorySelected: category })
  }

  handleSelectPercentage = percentage => {
    if (percentage <= 100 && percentage >= 0) {
      this.setState({ percentageSelected: Number(percentage) })
    }
  }

  renderProjectSelector = () => {
    const { projects } = this.props

    return (
      <ProjectContainer>
        <Autocomplete
          id="project-selector"
          options={projects}
          getOptionLabel={option => option.name}
          value={this.state.projectSelected}
          onChange={(event, newValue) => {
            this.handleSelectProject(newValue)
          }}
          renderInput={params => <TextField {...params} label="Project" required />}
        />
      </ProjectContainer>
    )
  }

  renderPersonSelector = () => {
    const { people } = this.props

    return (
      <PersonContainer>
        <Autocomplete
          id="person-selector"
          options={people}
          getOptionLabel={option => option.fullName}
          value={this.state.personSelected}
          onChange={(event, newValue) => {
            this.handleSelectPerson(newValue)
          }}
          renderInput={params => <TextField {...params} label="Person" required />}
        />
      </PersonContainer>
    )
  }

  renderOtherFields = () => {
    return (
      <OtherFieldsContainer>
        <Autocomplete
          id="category-selector"
          options={categoryOptions}
          value={this.state.categorySelected}
          onChange={(event, newValue) => {
            this.handleSelectCategory(newValue)
          }}
          renderInput={params => <TextField {...params} label="Staffing Category" required />}
        />
        <TextField
          id="standard-basic"
          label="Percent"
          required
          type="number"
          value={this.state.percentageSelected}
          onChange={event => {
            this.handleSelectPercentage(event.target.value)
          }}
        />
      </OtherFieldsContainer>
    )
  }

  renderButtons = () => {
    return this.props.currentEditableAssignment
      ? this.renderUpdateButtons()
      : this.renderCreateButtons()
  }

  renderCreateButtons = () => {
    const isValidState = this.validateState()
    return (
      <ButtonsContainer style={{ textAlign: 'right' }}>
        <Tooltip
          title={
            <StyledTooltip>
              {isValidState ? 'Save your Assignment' : 'You need to complete the form first'}
            </StyledTooltip>
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={this.submitAssignment}
              style={{ marginRight: '10px' }}
              disabled={!this.props.isConnected || !isValidState}
            >
              Save
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={<StyledTooltip>Reset All the Fields</StyledTooltip>}>
          <span>
            <Button variant="contained" onClick={this.handleResetClick}>
              Reset
            </Button>
          </span>
        </Tooltip>
      </ButtonsContainer>
    )
  }

  renderUpdateButtons = () => {
    const isValidState = this.validateState()
    return (
      <ButtonsContainer style={{ textAlign: 'right' }}>
        <Tooltip title={<StyledTooltip>Delete the Assignment</StyledTooltip>}>
          <span>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteAssignment}
              style={{ marginRight: '10px' }}
              disabled={!this.props.isConnected}
            >
              Delete
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            <StyledTooltip>
              {isValidState ? 'Update your Assignment' : 'You need to complete the form first'}
            </StyledTooltip>
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={this.updateAssignment}
              style={{ marginRight: '10px' }}
              disabled={!this.props.isConnected || !isValidState}
            >
              Update
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={<StyledTooltip>Reset All the Fields</StyledTooltip>}>
          <span>
            <Button variant="contained" onClick={this.handleResetClick}>
              Reset
            </Button>
          </span>
        </Tooltip>
      </ButtonsContainer>
    )
  }

  renderPickerSelector = () => {
    const { from, to, min } = this.state
    const { numberOfMonths } = this.props
    const modifiers = { start: from, end: to }
    const css = `
    .DayPicker-Day {
        display: table-cell;
        padding: 0.5em;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
      }

    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  `
    return (
      <DayPickerContainer>
        <div className="RangeExample">
          <p>
            {!from && !to && 'Please select the first day.'}
            {from && !to && 'Please select the last day.'}
            {from &&
              to &&
              `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
          </p>
          <style>{css}</style>
          <DayPicker
            className="Selectable"
            numberOfMonths={numberOfMonths}
            initialMonth={min}
            disabledDays={{ before: min }}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
          />
        </div>
      </DayPickerContainer>
    )
  }

  renderDatesSelector = () => {
    return (
      <DatesContainer>
        <TextField
          id="standard-basic"
          label="Start Date"
          helperText="MM/DD/YYYY"
          value={this.state.from ? this.state.from.toLocaleDateString() : ''}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          id="standard-basic"
          label="End Date"
          helperText="MM/DD/YYYY"
          value={this.state.to ? this.state.to.toLocaleDateString() : ''}
          InputProps={{
            readOnly: true,
          }}
          required
        />
      </DatesContainer>
    )
  }

  render = () => {
    return (
      <FormContainer>
        <Column width="50%">{this.renderPickerSelector()}</Column>
        <Column width="50%">
          <Column width="100%">{this.renderDatesSelector()}</Column>
          <Column width="100%"> {this.renderProjectSelector()} </Column>
          <Column width="100%"> {this.renderPersonSelector()} </Column>
          <Column width="100%"> {this.renderOtherFields()} </Column>
          <Column width="100%"> {this.renderButtons()} </Column>
        </Column>
      </FormContainer>
    )
  }
}

NewAssignmentBox.propTypes = {
  projects: PropTypes.array.isRequired,
  people: PropTypes.array.isRequired,
  currentStartNextWeek: PropTypes.object.isRequired,
  currentEndNextWeek: PropTypes.object.isRequired,
  currentView: PropTypes.string.isRequired,
  numberOfMonths: PropTypes.number.isRequired,
  currentEditableAssignment: PropTypes.object,
  setCurrentEditableAssignment: PropTypes.func.isRequired,
  createAssignment: PropTypes.func.isRequired,
  updateAssignment: PropTypes.func.isRequired,
  deleteAssignment: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  currentProject: PropTypes.object,
}

export default NewAssignmentBox
