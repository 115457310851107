import React from 'react'
import PropTypes from 'prop-types'
import Item from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'

import CustomTooltip from '../../../../components/CustomTooltip'

export const MenuItem = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})(Item)

const StyledListItemIcon = withStyles({
  root: {
    padding: '0',
    margin: '0',
    marginLeft: '10px',
    color: props => (props.color ? props.color : 'black'),
  },
})(ListItemIcon)

const StyledMenuTitle = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${props => (props.color ? props.color : 'black')};
`
const ConditionalTooltip = ({ title, label, children }) =>
  title ? (
    <CustomTooltip title={title} aria-label={label} placement="top">
      <>{children}</>
    </CustomTooltip>
  ) : (
    children
  )

ConditionalTooltip.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
}

export const StyledMenuItem = React.forwardRef(
  ({ icon, title, color, tooltipTitle, tooltipLabel, ...props }, ref) => (
    <MenuItem {...props} ref={ref}>
      <ConditionalTooltip title={tooltipTitle} label={tooltipLabel}>
        <StyledListItemIcon color={color}>{icon}</StyledListItemIcon>
        <StyledMenuTitle color={color}>{title}</StyledMenuTitle>
      </ConditionalTooltip>
    </MenuItem>
  ),
)

StyledMenuItem.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipLabel: PropTypes.string,
}

export const StyledMenuItemWithSubMenu = props => {
  return (
    <MenuItem {...props}>
      <StyledListItemIcon>{props.icon}</StyledListItemIcon>
      <StyledMenuTitleContainer>
        <StyledMenuTitle>{props.title}</StyledMenuTitle>
      </StyledMenuTitleContainer>
      {props.children}
    </MenuItem>
  )
}

StyledMenuItemWithSubMenu.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

const StyledMenuTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Ident = styled.span`
  margin-left: 16px;
`

export const HiddenInput = styled.input`
  display: none;
`

export const Label = styled.label`
  outline: none;
`

export const CsvLinkButon = styled(CSVLink)`
  text-decoration: none;
  color: inherit;
`
