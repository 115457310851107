import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import EmailIcon from '@material-ui/icons/Email'
import EventIcon from '@material-ui/icons/Event'
import Tooltip from '@material-ui/core/Tooltip'

import useDelegateAccessMutation from '../../../hooks/delegateAccess/useDelegateAccessMutation'

import { DelegatePermission, DelegatePermissionName } from '../style'

const PermissionRow = props => {
  const { delegateAccessId, hasEmailAccess, hasCalendarAccess, name } = props
  const { updateDelegateAccess } = useDelegateAccessMutation()

  const handleClick = async args => {
    await updateDelegateAccess({
      variables: {
        _id: delegateAccessId,
        hasEmailAccess,
        hasCalendarAccess,
        ...args,
      },
    })

    props.refetchSurrogateAccess()
  }

  return (
    <DelegatePermission>
      <DelegatePermissionName>{name}</DelegatePermissionName>
      <Tooltip
        title={`Allow user to access to your calendar events. Status: ${
          hasCalendarAccess ? 'Active' : 'Disabled'
        }`}
      >
        <IconButton onClick={() => handleClick({ hasCalendarAccess: !hasCalendarAccess })}>
          <EventIcon color={hasCalendarAccess ? 'primary' : 'disabled'} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={`Allow user to access to your emails. Status: ${
          hasEmailAccess ? 'Active' : 'Disabled'
        }`}
      >
        <IconButton onClick={() => handleClick({ hasEmailAccess: !hasEmailAccess })}>
          <EmailIcon color={hasEmailAccess ? 'primary' : 'disabled'} fontSize="small" />
        </IconButton>
      </Tooltip>
    </DelegatePermission>
  )
}

PermissionRow.propTypes = {
  delegateAccessId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hasEmailAccess: PropTypes.bool.isRequired,
  hasCalendarAccess: PropTypes.bool.isRequired,
  refetchSurrogateAccess: PropTypes.func.isRequired,
}

export default PermissionRow
