// initial

export default {
  timesheet: '_id, startDate',
  project: '_id',
  email: '_id',
  event: '_id, start',
  failedEntry: 'sourceId',
  paidTimeOff: '_id, startDate',
  scoreStreak: '_id',
  timeEntry: '_id, start',
  timeOffCode: '_id',
  unhandledEntry: '_id, sourceId, start',
}
