import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Container } from './TextLineClampStyle'

const getLineCount = ({ containerHeight, offsetBottom, offsetTop, lineHeight }) => {
  if (!containerHeight) return 2
  const lineCount = (containerHeight - offsetBottom - offsetTop) / lineHeight
  const displayLines = Math.floor(lineCount)
  return displayLines
}

const TextLinesClamp = ({ children, containerHeight, lineHeight, offsetBottom, offsetTop }) => {
  const displayLines = getLineCount({ containerHeight, lineHeight, offsetBottom, offsetTop })
  return <Container lines={displayLines}>{children}</Container>
}

TextLinesClamp.propTypes = {
  children: PropTypes.node.isRequired,
  containerHeight: PropTypes.number,
  lineHeight: PropTypes.number.isRequired,
  offsetBottom: PropTypes.number.isRequired,
  offsetTop: PropTypes.number.isRequired,
}

TextLinesClamp.defaultProps = {
  containerHeight: 0,
}

export default memo(TextLinesClamp)
