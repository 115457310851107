import { logErrorMessage } from '../../../utils/logger'

const waitRandomMilliseconds = ({ maxWaitLimit, minWaitLimit = 0 }) => {
  const randomMillisecondsToWait =
    Math.floor(Math.random() * (maxWaitLimit - minWaitLimit)) + minWaitLimit

  return new Promise(resolve => setTimeout(resolve, randomMillisecondsToWait))
}

export const requestPersistently = async ({ requestProps, numberOfTimesToRetry = 5 }) => {
  const { client, type, options } = requestProps

  try {
    if (type === 'query') {
      return await client.query(options)
    }

    if (type === 'mutation') {
      return await client.mutate(options)
    }
  } catch (err) {
    logErrorMessage(err.message, `entities > outlook > utils > requestPersistently`)
    if (numberOfTimesToRetry === 0) throw new Error('there are no more retries')

    await waitRandomMilliseconds({ maxWaitLimit: 5000, minWaitLimit: 2000 })
    return requestPersistently({ requestProps, numberOfTimesToRetry: numberOfTimesToRetry - 1 })
  }
}
