import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { TimePickerContainer } from '../../ListView/style'
import { formatDateToHHMM, calcMeridiemAndSetTimeOnDate } from '../../../../server/utils/date'

class TimeInputPicker extends PureComponent {
  handleTextFieldChange = e => {
    const date = calcMeridiemAndSetTimeOnDate(this.props.value, e.target.value)
    this.props.onChange(date)
  }

  renderInputTextField = () => (
    <TextField
      id={this.props.id}
      type="time"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      FormHelperTextProps={this.props.helperTextProps}
      InputProps={this.props.InputProps}
      onChange={this.handleTextFieldChange}
      value={formatDateToHHMM(this.props.value)}
      required
    />
  )

  render = () => (
    <TimePickerContainer
      width={this.props.width}
      padding={this.props.padding}
      minWidth={this.props.minWidth}
      marginBottom={this.props.marginBottom}
    >
      {this.renderInputTextField()}
    </TimePickerContainer>
  )
}

TimeInputPicker.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  helperTextProps: PropTypes.object.isRequired,
  InputProps: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired,
  minWidth: PropTypes.string.isRequired,
  marginBottom: PropTypes.string.isRequired,
}

export default TimeInputPicker
