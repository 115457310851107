import _ from 'lodash'
import moment from 'moment'

import eventUtils from './eventUtils'
import { getFieldsToInheritToEntry } from './projectUtils'
import {
  getDurationInHours,
  getEndDateShiftedTime,
  getFullDayDurationInHours,
  getStartWorkingDateTime,
  convertDateToLocalTimezone,
} from '../server/utils/date'
import { FUNNEL_ENTRIES_RESOURCE_ID } from '../constants/events'

import { isAnOperation } from '../models/entryOperation'

const getBasicEntryFields = entry => {
  return {
    ...entry,
    sourceId: eventUtils.getEventId(entry.repliconProjectId, entry.comment, entry.start, entry.end),
    resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
  }
}

const getPTOEntryFields = ({ start, end, isFullDayEvent, daysRepeat }) => {
  if (isFullDayEvent) {
    const startDate = getStartWorkingDateTime(start)
    const endDate = getEndDateShiftedTime(end, daysRepeat)
    return {
      isFullDayEvent,
      daysRepeat,
      start: startDate,
      end: endDate,
      originalDurationInHours: getFullDayDurationInHours(startDate, endDate),
    }
  }

  return {
    isFullDayEvent,
    daysRepeat,
  }
}

export const buildNewEntryEvent = entrySubmission => {
  const entry = getBasicEntryFields(entrySubmission)

  if (entry.isPTO) {
    const PTOCode = getTimeOffCodeFields(entrySubmission)
    const PTOfields = getPTOEntryFields(entrySubmission)
    return _.merge({}, entry, PTOCode, PTOfields)
  }

  return entry
}

export const buildUpdatedEntryEvent = (updatedEntry, entry) => {
  if (entry.isPTO) {
    const PTOCode = getTimeOffCodeFields(updatedEntry)
    const PTOfields = getPTOEntryFields(updatedEntry)
    return _.merge({}, updatedEntry, PTOCode, PTOfields)
  }

  return updatedEntry
}

const getTimeOffCodeFields = entry => {
  return {
    repliconTypeId: entry.repliconProjectId,
    type: entry.type,
  }
}

export const buildUpdatedEntry = ({ event, start, end }) => {
  const updates = {
    start,
    end,
    originalDurationInHours: getDurationInHours(start, end),
  }
  if (isAnOperation(event)) {
    return {
      ...event,
      data: {
        ...event.data,
        ...updates,
      },
    }
  }
  return {
    ...event,
    ...updates,
  }
}

export const buildDroppedUpdatedEntry = ({ event, start, end }, timesheets) => {
  const targetDate = moment(start)
    .startOf('day')
    .utc(true)
    .toISOString()
  const convertedTargetDay = convertDateToLocalTimezone(targetDate)
  const filteredTimesheets = _.find(timesheets, timesheet =>
    moment(timesheet.startDate).isSame(convertedTargetDay),
  )

  const updates = {
    start,
    end,
    durationInHours: getDurationInHours(start, end),
    entryDate: targetDate,
    repliconTimesheetId: filteredTimesheets.repliconId,
  }

  if (isAnOperation(event)) {
    return {
      ...event,
      data: {
        ...event.data,
        ...updates,
      },
    }
  }
  return {
    ...event,
    ...updates,
  }
}

export const buildCurrentEditableEntry = ({ entry, projects, defaultProjectId }) => {
  const projectObject = eventUtils.findProjectObject(entry, projects, defaultProjectId)
  const fieldsToInherit = getFieldsToInheritToEntry(projectObject)

  return {
    ...entry,
    start: _.get(entry, 'start', new Date()),
    end: _.get(entry, 'end', new Date()),
    originalDurationInHours: _.get(entry, 'originalDurationInHours', 0),
    originalComment: _.get(entry, 'originalComment', ''),
    referenceId: _.get(entry, 'referenceId', null),
    referenceType: _.get(entry, 'referenceType', null),
    isFullDayEvent: _.get(entry, 'isFullDayEvent', false),
    daysRepeat: _.get(entry, 'daysRepeat', 0),
    ...fieldsToInherit,
  }
}

export const buildPlaceholderEntry = ({
  start,
  end,
  resourceId,
  originalComment,
  originalProjectId,
  billable,
  referenceId,
  referenceType,
  title,
  eventTagId,
}) => {
  return {
    _id: 'placeholder',
    title,
    start: new Date(start),
    end: new Date(end),
    originalDurationInHours: getDurationInHours(start, end),
    eventSource: 'Timesheet',
    resourceId,
    originalComment,
    billable,
    originalProjectId,
    referenceId,
    referenceType,
    eventTagId,
  }
}

const mergeStateWithUpdatesByFiled = (state, updates, filed = '_id') => {
  return _.chain(state)
    .keyBy(filed)
    .merge(_.keyBy(updates, filed))
    .values()
    .value()
}

const parseEventsDate = events => {
  return eventUtils.parseEventsDates(events)
}

const updateTimesheets = key => (state, updates) => {
  return {
    [key]: mergeStateWithUpdatesByFiled(state, updates),
  }
}

const updateEvents = key => (state, updates) => {
  return {
    [key]: mergeStateWithUpdatesByFiled(state, parseEventsDate(updates)),
  }
}

const getUpdateFuncByType = key => {
  if (key === 'timesheets') return updateTimesheets(key)
  if (key === 'timeEntries' || key === 'timeOffEntries') return updateEvents(key)
}

export const updateStateById = (state, data) => {
  return _.reduce(
    _.keys(data),
    (acc, key) => {
      const result = getUpdateFuncByType(key)(state[key], data[key])
      return {
        ...acc,
        ...result,
      }
    },
    {},
  )
}

export const isWindows = () => {
  const { platform } = navigator
  return String(platform).includes('Win32')
}

export const addKeyDownListener = cb => {
  document.addEventListener(
    'keydown',
    (...params) => {
      cb(...params)
    },
    false,
  )
}

export const removeKeyDownListener = cb => {
  document.removeEventListener(
    'keydown',
    (...params) => {
      console.log('appUtils, removeKeyDownListener')
      cb(...params)
    },
    false,
  )
}

export const removeClickListener = cb => {
  document.removeEventListener('click', cb, false)
}

export const parseDurationValueAndGetFractionDigit = value => {
  return parseFloat(value).toFixed(1)
}

export const isSuggestionPTO = suggestion => suggestion.projectType === 'pto'

export const isTimesheetAbleToBeReopened = timesheet => {
  const now = moment()
  const timesheetSubmittedDate = timesheet.startDate

  const twoMonthsAgo = moment(now)
    .subtract(2, 'months')
    .startOf('month')

  const oneMonthAfter = moment(now)
    .add(1, 'month')
    .endOf('month')

  return (
    moment(timesheetSubmittedDate).isSame(twoMonthsAgo) ||
    moment(timesheetSubmittedDate).isBetween(twoMonthsAgo, oneMonthAfter) ||
    moment(timesheetSubmittedDate).isSame(oneMonthAfter)
  )
}

export const getSelectedDelegate = (delegateId, delegateList) => {
  if (!delegateId) return null
  return _.find(delegateList, { _id: delegateId })
}
