import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ContactsIcon from '@material-ui/icons/Contacts'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PieChartIcon from '@material-ui/icons/PieChart'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import SmsFailedIcon from '@material-ui/icons/SmsFailed'
import RefreshIcon from '@material-ui/icons/Refresh'
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation'
import StarsIcon from '@material-ui/icons/Stars'
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import _ from 'lodash'
import history from '../../../../utils/history'
import {
  MenuItem,
  Ident,
  Label,
  CsvLinkButon,
  StyledMenuItem,
  StyledMenuItemWithSubMenu,
} from './styles'
import { getEventsFromEntriesInfo } from '../../utils'
import config from '../../../../config'
import { HEADERS, CSV_DATA } from './constants'

import ReminderSettings from '../../../../components/ReminderSettings'
import KeyboardShortcuts from '../../../../components/KeyboardShortcuts'
import WhatsNew from '../../../../components/WhatsNew'

const {
  VERSION,
  IS_REMINDER_SETTINGS_ENABLED,
  IS_GRAPH_ENABLED,
  IS_DELEGATE_ENABLED,
  IS_ASSIGNMENTS_ENABLED,
} = config

const FloatingMenu = props => {
  const [isCsvMenuOpen, setIsCsvMenuOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false)
  const [isKeyboardShortcutsModalOpen, setIsKeyboardShortcutsModalOpen] = useState(false)
  const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useState(false)

  const handleMenuClose = (handler = () => {}) => () => {
    props.setAnchorMenu(null)
    setIsCsvMenuOpen(false)
    setIsSettingsOpen(false)
    handler()
  }

  const toggleCsvMenu = () => {
    setIsCsvMenuOpen(!isCsvMenuOpen)
  }

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen)
  }

  const goToReports = () => history.push('/reports')

  const goToGraph = () => history.push('/graph')

  const goToAssignments = () => history.push('/assignments')

  const renderTooltipTitle = (uiExchangeEventCount, areAllEventsAdded) => {
    if (uiExchangeEventCount === 0) return 'No events available'
    if (areAllEventsAdded) return 'All events have been added'
    return ''
  }

  const isOpen = Boolean(props.anchorMenu)
  const { startISO, endISO } = props.uiRefreshTimePeriod
  const { uiExchangeEvents, areAllEventsAdded } = getEventsFromEntriesInfo({
    startISO,
    endISO,
    exchangeEvents: props.exchangeEvents,
    timeEntries: props.timeEntries,
    localEntries: props.localEntries,
  })
  const uiExchangeEventCount = uiExchangeEvents.length
  const isAddAllEventsButtonBlocked =
    props.isFetchingTimesheetData ||
    areAllEventsAdded ||
    props.isSubmitInProgress ||
    uiExchangeEventCount === 0 ||
    !props.isConnected ||
    props.isTimesheetSubmitted

  const tooltipTitle = renderTooltipTitle(uiExchangeEventCount, areAllEventsAdded)

  // if user's position or user's employeeType are Contractor - Expert, do not show assignments, reports and graph sections
  const isExpert =
    _.get(props.person, 'position') === 'Contractor - Expert' ||
    _.get(props.person, 'employeeType', '').includes('Contractor - Expert')

  return (
    <>
      <ReminderSettings
        isOpen={isReminderModalOpen}
        handleClose={() => setIsReminderModalOpen(false)}
        reminderSettings={props.reminderSettings}
        handleSetReminderSettings={props.handleSetReminderSettings}
      />
      <KeyboardShortcuts
        isOpen={isKeyboardShortcutsModalOpen}
        handleClose={() => setIsKeyboardShortcutsModalOpen(false)}
      />
      <WhatsNew isOpen={isWhatsNewModalOpen} handleClose={() => setIsWhatsNewModalOpen(false)} />
      <Menu
        anchorEl={props.anchorMenu}
        id="glimpse-menu"
        open={isOpen}
        onClose={handleMenuClose()}
        PaperProps={{
          style: {
            width: 250,
          },
        }}
      >
        <StyledMenuItem
          icon={<PlaylistAddIcon fontSize="medium" />}
          title="Add All Events"
          disabled={isAddAllEventsButtonBlocked}
          onClick={handleMenuClose(props.handleCreateEntriesForAllEvents)}
          tooltipTitle={tooltipTitle}
          tooltipLabel="Add"
        />
        {IS_ASSIGNMENTS_ENABLED && !isExpert && (
          <StyledMenuItem
            icon={<AssignmentTurnedInIcon fontSize="medium" />}
            title="Assignments (Alpha)"
            disabled={!props.isConnected}
            onClick={goToAssignments}
          />
        )}
        {IS_DELEGATE_ENABLED && (
          <StyledMenuItem
            icon={<ContactsIcon fontSize="medium" />}
            title="Delegate Access"
            onClick={handleMenuClose(props.handleSwitchIsDelegateAccessModalOpen)}
          />
        )}

        {!isExpert && (
          <StyledMenuItem
            icon={<AssignmentIcon fontSize="medium" />}
            title="Reports (Beta)"
            disabled={!props.isConnected}
            onClick={goToReports}
          />
        )}
        {IS_GRAPH_ENABLED && !isExpert && (
          <StyledMenuItem
            icon={<PieChartIcon fontSize="medium" />}
            title="Graph (Beta)"
            disabled={!props.isConnected}
            onClick={handleMenuClose(goToGraph)}
          />
        )}
        <StyledMenuItemWithSubMenu
          icon={<InsertChartIcon fontSize="medium" />}
          title="CSV"
          onClick={toggleCsvMenu}
        >
          {isCsvMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </StyledMenuItemWithSubMenu>
        <Collapse in={isCsvMenuOpen} unmountOnExit>
          <Label htmlFor="contained-button-file">
            <MenuItem disabled={!props.isConnected} onClick={handleMenuClose()}>
              <Ident>Upload csv file</Ident>
            </MenuItem>
          </Label>
          <CsvLinkButon data={CSV_DATA} headers={HEADERS} filename="csv template.csv">
            <MenuItem disabled={!props.isConnected} onClick={handleMenuClose()}>
              <Ident>Download csv template</Ident>
            </MenuItem>
          </CsvLinkButon>
        </Collapse>
        <StyledMenuItem
          icon={<SmsFailedIcon fontSize="medium" />}
          title="Report Issue"
          disabled={!props.isConnected}
          onClick={handleMenuClose(props.handleSwitchIsIssueReportModalOpen)}
        />
        <StyledMenuItem
          icon={<RefreshIcon fontSize="medium" />}
          title="Restart App"
          disabled={!props.isConnected}
          onClick={handleMenuClose(props.handleReloadApp)}
        />
        <Divider />
        <StyledMenuItem
          icon={<PermDeviceInformationIcon fontSize="medium" />}
          title={`Version: ${VERSION || 'N/A'}`}
          onClick={handleMenuClose()}
        />
        <StyledMenuItem
          icon={<StarsIcon fontSize="medium" />}
          title="What's New"
          onClick={() => {
            handleMenuClose()()
            setIsWhatsNewModalOpen(true)
          }}
        />
        <StyledMenuItem
          icon={<KeyboardHideIcon fontSize="medium" />}
          title="Keyboard Shortcuts"
          onClick={() => {
            handleMenuClose()()
            setIsKeyboardShortcutsModalOpen(true)
          }}
        />
        {IS_REMINDER_SETTINGS_ENABLED && (
          <div>
            <StyledMenuItemWithSubMenu
              icon={<SettingsIcon fontSize="medium" />}
              title="Settings"
              onClick={toggleSettings}
            >
              {isSettingsOpen ? <ExpandLess /> : <ExpandMore />}
            </StyledMenuItemWithSubMenu>
            <Collapse in={isSettingsOpen} unmountOnExit>
              <MenuItem
                disabled={!props.isConnected}
                onClick={() => {
                  handleMenuClose()()
                  setIsReminderModalOpen(true)
                }}
              >
                <Ident>Reminders</Ident>
              </MenuItem>
            </Collapse>
          </div>
        )}
        <StyledMenuItem
          icon={<ExitToAppIcon fontSize="medium" />}
          title="Logout"
          disabled={!props.isConnected}
          onClick={handleMenuClose(props.logout)}
          color="red"
        />
      </Menu>
    </>
  )
}

FloatingMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  isConnected: PropTypes.bool.isRequired,
  setAnchorMenu: PropTypes.func.isRequired,
  anchorMenu: PropTypes.object,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  isTimesheetSubmitted: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
}

export default FloatingMenu
