import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const getTooltipText = (isEditorEnabled, initialCheckValue) => {
  const isEditorUnfined = _.isUndefined(isEditorEnabled)
  if (isNewEmptyRow(initialCheckValue)) return ''
  if (isEditorUnfined) return 'This project is set to accept billable & non-billable entries.'
  if (!isEditorEnabled && !initialCheckValue)
    return 'This project is set to accept non-billable entries only.'
  return 'This project is set to accept billable entries only.'
}

const isNewEmptyRow = value => _.isNull(value)

const CheckBoxCell = props => {
  const onChange = () => {
    const updatedData = [props.row, props.col, props.value, !props.value] // In order: row, column, old Value, new Value
    const update = [updatedData]
    props.afterChange(update, 'edit')
  }

  if (isNewEmptyRow(props.value)) return null

  return (
    <div
      className="htCenter htMiddle"
      title={getTooltipText(props.cellProperties.editor, props.value)}
    >
      <input
        type="checkbox"
        className="htCheckboxRendererInput"
        autoComplete="off"
        tabIndex="-1"
        checked={props.value}
        onChange={onChange}
        disabled={!_.isUndefined(props.cellProperties.editor) || props.isDisabled}
        style={{ marginTop: '13px' }}
      />
    </div>
  )
}

CheckBoxCell.defaultProps = {
  value: null,
}

CheckBoxCell.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  value: PropTypes.bool,
  cellProperties: PropTypes.object,
  afterChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default CheckBoxCell
