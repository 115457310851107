import React from 'react'
import PropTypes from 'prop-types'

const CardEntryBtn = ({ action, label }) => {
  return (
    <button type="button" onMouseDown={action} className="event-add-to-entry-btn">
      {label}
    </button>
  )
}

CardEntryBtn.propTypes = {
  action: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default CardEntryBtn
