import { EventSourcePolyfill } from 'event-source-polyfill'
import Ping from '../ping'
import config from '../../config'
import { logErrorMessage } from '../../utils/logger'

const { FUNNEL_URL, IS_PING_ENABLED } = config

export const initializeHeartBeatThunk = () => dispatch => {
  if (!IS_PING_ENABLED) return
  try {
    const evtSource = new EventSourcePolyfill(`${FUNNEL_URL}ping`, {
      headers: {
        Accept: 'text/event-stream',
        'Accept-Encoding': 'identity;q=1.0',
      },
    })
    const handler = async e => {
      if (e.type === 'message') {
        dispatch(Ping.pingSuccess())
      }
      if (e.type === 'error') {
        dispatch(Ping.pingFail(e))
      }
    }

    evtSource.addEventListener('message', handler)
    evtSource.addEventListener('error', handler)

    window.addEventListener('online', () => {
      dispatch(Ping.pingSuccess())
    })
    window.addEventListener('offline', e => {
      dispatch(Ping.pingFail(e))
    })
  } catch (error) {
    logErrorMessage(error)
  }
}
