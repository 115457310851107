// provisional
import moment2 from 'moment'
import moment from './moment'

import config from '../../config'

const {
  RECURRING_SYNC_DEFAULT_START_FUNCTION,
  RECURRING_SYNC_DEFAULT_START_PARAMETER,
  RECURRING_SYNC_DEFAULT_END_FUNCTION,
  RECURRING_SYNC_DEFAULT_END_PARAMETER,
  RECURRING_SYNC_PAST_MINUEND,
  RECURRING_SYNC_SUBTRAHEND,
  RECURRING_SYNC_FUTURE_ADD,
  RECURRING_SYNC_FUTURE_ADDFROM,
} = config

export const convertDateToNonLocalString = timeSpan => {
  return moment2(timeSpan).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
}

export const convertDateToLocalTimezone = date => {
  if (typeof date === 'string') return date.replace('Z', '')
  return moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
}

export const parseStartDate = date => {
  return moment(date)
    .startOf('day')
    .toLocalString()
}

export const parseUTCStartDate = date => {
  return moment2
    .utc(date)
    .startOf('day')
    .toLocalString()
}

export const parseUTCStartDateAsUTC = (date, unit = 'day') => {
  return moment2
    .utc(date)
    .startOf(unit)
    .toLocalStringAsUTC()
}

export const parseStartDateAsUTC = (date, unit = 'day') => {
  return moment(date)
    .startOf(unit)
    .toLocalStringAsUTC()
}

export const parseUTCEndDateAsUTC = (date, unit = 'day') => {
  return moment2
    .utc(date)
    .endOf(unit)
    .toLocalStringAsUTC()
}

export const parseEndDateAsUTC = (date, unit = 'day') => {
  return moment(date)
    .endOf(unit)
    .toLocalStringAsUTC()
}

const setHHMMTimeOnDate = (date, HHMM) => {
  const [hours, minutes] = HHMM.split(':')
  const hour = parseInt(hours, 10)
  const minute = parseInt(minutes, 10)
  return moment(date)
    .set({ hour, minute })
    .toDate()
}

export const calcMeridiemAndSetTimeOnDate = (prevDate, HHMM) => {
  const prevHours = moment(prevDate).format('HH')
  const [nextHours, minutes] = HHMM.split(':')
  if (prevHours === '23' && nextHours === '12') return setHHMMTimeOnDate(prevDate, `00:${minutes}`)
  if (prevHours === '12' && nextHours === '23') return setHHMMTimeOnDate(prevDate, `11:${minutes}`)
  if (prevHours === '11' && nextHours === '00') return setHHMMTimeOnDate(prevDate, `12:${minutes}`)
  if (prevHours === '00' && nextHours === '11') return setHHMMTimeOnDate(prevDate, `23:${minutes}`)

  return setHHMMTimeOnDate(prevDate, HHMM)
}

export const formatDateToHHMM = date => {
  return moment(date).format('HH:mm')
}

export const getDiffDaysCount = (start, end) => {
  const startTime = moment(start)
  const endTime = moment(end)
  return endTime.diff(startTime, 'days') + 1
}

export const getFullDayDurationInHours = (start, end) => {
  const daysOff = getDiffDaysCount(start, end)
  return daysOff * 8
}

export const getDurationInHours = (start, end) => {
  const endTime = moment(end)
  const startTime = moment(start)
  const duration = endTime.diff(startTime, 'minutes')
  const durationInHours = duration / 60
  return Math.round(durationInHours * 10) / 10
}

export const getDefaultSyncDateRangeISOs = () => {
  const defaultStart = moment()
    [RECURRING_SYNC_DEFAULT_START_FUNCTION](RECURRING_SYNC_DEFAULT_START_PARAMETER)
    .toISOString()
  const defaultEnd = moment()
    [RECURRING_SYNC_DEFAULT_END_FUNCTION](RECURRING_SYNC_DEFAULT_END_PARAMETER)
    .toISOString()

  const start = moment(defaultStart)
    .subtract(RECURRING_SYNC_PAST_MINUEND, RECURRING_SYNC_SUBTRAHEND)
    .toISOString()

  const end = moment(defaultEnd)
    .add(RECURRING_SYNC_FUTURE_ADD, RECURRING_SYNC_FUTURE_ADDFROM)
    .toISOString()

  return { startISO: start, endISO: end }
}

export const getStartEndISOForTheDay = date => {
  const startISO = parseStartDateAsUTC(date)
  const endISO = parseEndDateAsUTC(date)
  return { startISO, endISO }
}

export const getUTCStartEndISOForTheDay = date => {
  const startISO = parseUTCStartDateAsUTC(date)
  const endISO = parseUTCEndDateAsUTC(date)
  return { startISO, endISO }
}

export const getStartWorkingDateTime = date => {
  return `${date}T08:00:00.000`
}

export const getEndWorkingDateTime = date => {
  return `${date}T17:00:00.000`
}

export const setStartWorkingDateTime = date => {
  return moment(date).set({ hour: 8, minute: 0 })
}

export const setEndWorkingDateTime = date => {
  return moment(date).set({ hour: 17, minute: 0 })
}

export const getEndDateShiftedTime = (date, shiftDays) => {
  if (shiftDays === 0) return getEndWorkingDateTime(date)
  return getEndWorkingDateTime(moment(date).add(shiftDays, 'd'))
}

export const isZeroStartEndEvent = (start, end) => {
  const startDate = moment(start)
  const endDate = moment(end)
  return (
    startDate.get('hour') === 0 &&
    startDate.get('minute') === 0 &&
    endDate.get('hour') === 0 &&
    endDate.get('minute') === 0
  )
}

export const parsePTOStartStopDate = ({ entryDate, durationInHours }) => {
  const startDate = convertDateToLocalTimezone(entryDate)

  const start = setStartWorkingDateTime(startDate).toLocalString()
  const end = moment(start)
    .add(durationInHours, 'hours')
    .toLocalString()

  return {
    start,
    end,
  }
}
