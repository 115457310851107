/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { Provider as ApolloProvider } from './graphql/provider'
import Auth0Provider from './components/Auth0'
import { SocketIOProvider } from './components/SocketIO'

import './index.css'
import history from './utils/history'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider>
      <ApolloProvider>
        <SocketIOProvider>
          <Router history={history}>
            <App />
          </Router>
        </SocketIOProvider>
      </ApolloProvider>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
)

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
