import _ from 'lodash'

export const getDiff = ({ oldData, newData, uniqueId = '_id', predicate = {} }) => {
  const { create, update } = newData.reduce(
    (accum, rawItem) => {
      const newItem = _.pickBy(rawItem, field => !_.isUndefined(field))
      const existingItem = _.find(
        oldData,
        oldItem => String(oldItem[uniqueId]) === String(newItem[uniqueId]),
      )

      if (!existingItem) {
        return {
          ...accum,
          create: [...accum.create, predicate.create ? predicate.create(newItem) : newItem],
        }
      }
      if (_.isEqual(newItem, existingItem)) return accum

      const updatedItem = predicate.update ? predicate.update(newItem, existingItem) : newItem
      const itemToUpdateInDb = { ...existingItem, ...updatedItem }

      return {
        ...accum,
        update: [...accum.update, itemToUpdateInDb],
      }
    },
    {
      create: [],
      update: [],
    },
  )

  const deleted = oldData.reduce((accum, item) => {
    const existingItem = _.find(
      newData,
      newItem => String(newItem[uniqueId]) === String(item[uniqueId]),
    )

    if (existingItem) return accum

    if (!predicate.delete) {
      return [...accum, item]
    }
    if (!predicate.remove(item)) return accum
    return [...accum, predicate.remove(item)]
  }, [])

  const sum = _.size(create) + _.size(update) + _.size(deleted)
  const hasChanged = sum !== 0
  return {
    create,
    update,
    remove: deleted,
    hasChanged,
  }
}
