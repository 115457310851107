import gql from 'graphql-tag'

export const GET_TIME_OFF_ENTRIES = gql`
  query($startISO: String!, $endISO: String!) {
    timeOffEntries_getAll(startISO: $startISO, endISO: $endISO) {
      _id
      durationInHours
      timeOffCodeId
      entryDate
    }
  }
`
export const GET_TIME_OFF_ENTRIES_AS_DELEGATE = gql`
  query($startISO: String!, $endISO: String!, $delegateId: String!) {
    timeOffEntries_getAll_asDelegate(
      startISO: $startISO
      endISO: $endISO
      delegateId: $delegateId
    ) {
      _id
      durationInHours
      timeOffCodeId
      entryDate
    }
  }
`
