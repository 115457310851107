// https://github.com/dfahlander/Dexie.js/tree/master/samples/react-redux

import Dexie from 'dexie'
import v1 from './v1'
import v2 from './v2'
import v3 from './v3'
import v4 from './v4'
import v5 from './v5'
import v6 from './v6'
import v7 from './v7'
import v8 from './v8'
import v9 from './v9'
import v10 from './v10'

import config from '../../config'

const IS_WEB = config.IS_WEB
const IS_BETA = config.IS_BETA

const db = new Dexie(`glimpse-${IS_WEB ? 'web' : 'desktop'}-${IS_BETA ? 'beta' : 'alpha'}`)

db.version(1).stores(v1)
db.version(2).stores(v2)
db.version(3).stores(v3)
db.version(4).stores(v4)
db.version(5).stores(v5)
db.version(6).stores(v6)
db.version(7).stores(v7)
db.version(8).stores(v8)
db.version(9).stores(v9)
db.version(10).stores(v10)

export default db
