import moment from 'moment'
// import { isNull, has, isEmpty } from 'lodash'
import { has, isEmpty } from 'lodash'
import Mousetrap from 'mousetrap'

import { keysCodes } from '../../../utils/keyboard'
import eventUtils from '../../../utils/eventUtils'
import timeUtils from '../../../utils/timeUtils'
import { isEntryValid } from '../../../models/entry'
import {
  getNearestEntryBoxElement,
  getNoTimeEntriesDOMElements,
  getTimeEntriesDOMElements,
  placeholderEventMock,
  createOneHourPlaceholderAfterLastestTimeEntry,
} from '../../../constants/events'

export const listViewEntryListKeyDown = ({
  event,
  // getNextTabIndexNode,
  // getPrevTabIndexNode,
  isSubmitedTimesheet,
  onSelectEntryToEdit,
  createPlaceholderEntry,
  onEntryDelete,
  entry,
}) => {
  const currentActiveNode = document.activeElement
  if (event.nativeEvent.code === keysCodes.down) {
    /* const nextNode = getNextTabIndexNode(currentActiveNode)
    if (isNull(nextNode)) return
    nextNode.focus() */
  }
  if (event.nativeEvent.code === keysCodes.up) {
    /* const prevNode = getPrevTabIndexNode(currentActiveNode)
    if (isNull(prevNode)) return
    prevNode.focus() */
  }
  if (event.nativeEvent.code === keysCodes.enter && currentActiveNode) {
    event.stopPropagation()
    if (isSubmitedTimesheet) return
    if (has(entry, 'id')) {
      onSelectEntryToEdit(entry)
    } else {
      createPlaceholderEntry(entry.start, entry.end)
    }
  }
  Mousetrap.bind(
    ['command+delete', 'ctrl+delete', 'command+backspace', 'ctrl+backspace'],
    async () => {
      event.preventDefault()
      onEntryDelete(entry)
    },
  )
}

const getIsActiveNodeTimeEntry = activeNode => {
  const activeNodeId = activeNode.id
  const timeEntriesBoxes = getTimeEntriesDOMElements()

  return timeEntriesBoxes.filter(entryBox => entryBox.id === activeNodeId).length === 1
}

const getEightAMAvailability = (dayTimeEntries, selectedDate) => {
  const dayBusyTime = eventUtils.getDayBusyTime(dayTimeEntries)
  const todayEightAM = moment(selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ')
    .set({ h: 8 })
    .toISOString()

  return dayBusyTime.every(
    busySlot => !timeUtils.momentIsBetweenExclusiveEnd(todayEightAM, busySlot.start, busySlot.end),
  )
}

export const entryCreationKeyDown = ({
  currentEditableEntry,
  onEntrySubmit,
  onEntryUpdate,
  addCalendarPlaceholderEvent,
  selectedDate,
  selectedDayEntries,
  isTimesheetLocked,
}) => {
  Mousetrap.bind(['command+b', 'ctrl+b'], async () => {
    console.log('ListView, entryCreationKeyDown')
    if (isTimesheetLocked) return
    const activeNode = document.activeElement
    const isActiveNodeATimeEntry = getIsActiveNodeTimeEntry(activeNode)

    if (!isEmpty(currentEditableEntry)) {
      if (!isEntryValid(currentEditableEntry)) return

      const { start, end } = createOneHourPlaceholderAfterLastestTimeEntry(currentEditableEntry)

      const actionToPerform = eventUtils.isPlaceholderEntry(currentEditableEntry)
        ? onEntrySubmit(currentEditableEntry)
        : onEntryUpdate(currentEditableEntry)

      await actionToPerform

      setTimeout(() => {
        addCalendarPlaceholderEvent({ ...placeholderEventMock, start, end })
      }, 500)
    }

    if (isActiveNodeATimeEntry) {
      // create an entry in the nearest no time entry box
      const noTimeEntriesBoxes = getNoTimeEntriesDOMElements()
      if (noTimeEntriesBoxes.length === 0) return

      const nearestEntryBoxChild = getNearestEntryBoxElement(activeNode, noTimeEntriesBoxes)

      const targetEntryBox = nearestEntryBoxChild.childNodes[0]
      targetEntryBox.click()
      return
    }

    if (!isActiveNodeATimeEntry) {
      const isEightAvailable = getEightAMAvailability(selectedDayEntries, selectedDate)

      if (isEightAvailable) {
        const today = moment(selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ')

        const start = today.set({ h: 8 }).toISOString()
        const end = today.set({ h: 9 }).toISOString()

        addCalendarPlaceholderEvent({ ...placeholderEventMock, start, end })
        return
      }
      // create an entry in the first no time entry box if there are some
      const noTimeEntriesBoxes = getNoTimeEntriesDOMElements()
      if (noTimeEntriesBoxes.length === 0) return

      const targetEntryBox = noTimeEntriesBoxes[0].childNodes[0]
      targetEntryBox.click()
    }
  })
}
