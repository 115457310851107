import _ from 'lodash'
import moment from 'moment'
import { getFieldsToInheritToEntry } from '../../../utils/projectUtils'
import timeUtils from '../../../utils/timeUtils'
import eventUtils from '../../../utils/eventUtils'
import { billingOptions, timesheet } from '../../../constants/billingOptions'
import { isAnOperation, getEntry } from '../../../models/entryOperation'

const mergeEditableEntryChanges = (editableEntry, updates) => {
  const isOperation = isAnOperation(editableEntry)
  return isOperation
    ? {
        ...editableEntry,
        data: {
          ...editableEntry.data,
          ...updates,
        },
      }
    : {
        ...editableEntry,
        ...updates,
      }
}

export const projectUpdater = ({ editableEntry, newValue, projects }) => {
  if (_.isEmpty(newValue)) return editableEntry

  const project = _.find(projects, { name: newValue })
  if (_.isEmpty(project)) return editableEntry

  const fieldsToInherit = getFieldsToInheritToEntry(project)
  return mergeEditableEntryChanges(editableEntry, {
    ...fieldsToInherit,
  })
}

export const commentUpdater = ({ editableEntry, newValue }) => {
  return mergeEditableEntryChanges(editableEntry, {
    originalComment: newValue,
  })
}

export const billableUpdater = ({ editableEntry, newValue, projects }) => {
  const project = eventUtils.findProjectObject(editableEntry, projects)
  if (project === null || project.billable !== billingOptions.BOTH) return editableEntry

  const billable = newValue ? timesheet.BILLABLE : timesheet.NONBILLABLE

  return mergeEditableEntryChanges(editableEntry, { billable })
}

export const durationUpdater = ({ editableEntry, newValue }) => {
  const entry = isAnOperation(editableEntry) ? getEntry(editableEntry) : editableEntry
  const parsedValue = Number(newValue)
  if (_.isNaN(parsedValue) || parsedValue < 0.1) {
    return mergeEditableEntryChanges(entry, {
      end: moment(entry.start)
        .add(6, 'minutes')
        .toDate(),
      originalDurationInHours: 0.1,
    })
  }

  const newEnd = moment(entry.start)
    .add(parsedValue, 'hours')
    .toDate()

  // validate same day
  const areSameDay = timeUtils.areSameDay(entry.start, newEnd)
  const todayEnd = moment(entry.start)
    .endOf('day')
    .toDate()
  const clampedEnd = areSameDay ? newEnd : todayEnd

  // validate more than 9 hours
  const nineHoursAsMilliseconds = moment.duration(9, 'h').asMilliseconds()
  const actualDurationAsMilliseconds = moment(clampedEnd).diff(moment(entry.start))
  const startPlusNineHours = moment(entry.start)
    .add(nineHoursAsMilliseconds, 'ms')
    .toDate()

  const lessThanNineEnd =
    actualDurationAsMilliseconds <= nineHoursAsMilliseconds ? clampedEnd : startPlusNineHours

  const originalDurationInHours = moment
    .duration(moment(lessThanNineEnd).diff(entry.start))
    .asHours()

  return mergeEditableEntryChanges(editableEntry, {
    end: lessThanNineEnd,
    originalDurationInHours,
  })
}
