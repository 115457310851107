import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'

export const Container = styled(Box)`
  grid-area: profile;

  display: flex;
`

export const PictureWrapper = styled(Box)`
  display: flex;
`

export const Picture = styled(Avatar)`
  width: 36px;
  height: 36px;
`

export const UserName = styled.span`
  margin: auto;
  margin-left: 8px;

  font-size: 12px;
`
