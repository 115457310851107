import _ from 'lodash'
import { SET_REMINDER_SETTINGS } from './mutation'
import { GET_REMINDER_SETTINGS } from './query'

export const setReminderSettings = async (client, args) => {
  try {
    await client.mutate({
      mutation: SET_REMINDER_SETTINGS,
      variables: { ...args },
    })
  } catch (e) {
    throw new Error('setting reminder options failed', e)
  }
}

export const getReminderSettings = async client => {
  const reminderSettings = await client.query({
    query: GET_REMINDER_SETTINGS,
  })
  return _.get(reminderSettings, 'data.getReminderSettings', null)
}
