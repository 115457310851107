import gql from 'graphql-tag'

export const SEND_ISSUE_REPORT = gql`
  mutation(
    $version: String!
    $isWeb: Boolean!
    $report: String!
    $selectedDateWhenReporting: String!
    $viewPeriodWhenReporting: String!
  ) {
    sendReport(
      version: $version
      isWeb: $isWeb
      report: $report
      selectedDateWhenReporting: $selectedDateWhenReporting
      viewPeriodWhenReporting: $viewPeriodWhenReporting
    )
  }
`
