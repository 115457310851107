/* eslint-disable no-extend-native */
/* eslint-disable func-names */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-redeclare */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable no-continue */
/* eslint-disable eqeqeq */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-cond-assign */

import * as d3 from 'd3'

String.prototype.toTitleCase = function() {
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|vs?\.?|via)$/i

  return this.replace(/([^\W_]+[^\s-]*) */g, function(match, p1, index, title) {
    if (
      index > 0 &&
      index + p1.length !== title.length &&
      p1.search(smallWords) > -1 &&
      title.charAt(index - 2) !== ':' &&
      title.charAt(index - 1).search(/[^\s-]/) < 0
    ) {
      return match.toLowerCase()
    }

    if (p1.substr(1).search(/[A-Z]|\../) > -1) {
      return match
    }

    return match.charAt(0).toUpperCase() + match.substr(1)
  })
}

const userinfo = {
  name: 'Pedro Veintimilla',
}

const setupDB = emails => {
  emails.sort(function(a, b) {
    return a.dateField - b.dateField
  })

  // find the person's email aliases by going through the sent emails and reading the 'from' field
  const my = {}
  emails.forEach(function(email) {
    if (email.isSent) {
      my[email.fromField[1]] = true
    }
  })

  // normalize data
  emails.forEach(function(mail) {
    mail.fromField = [
      normalizeName(mail.fromField[0], mail.fromField[1]),
      trim(mail.fromField[1]).toLowerCase(),
    ]

    for (var i = 0; i < mail.toField.length; i++) {
      mail.toField[i] = [
        normalizeName(mail.toField[i][0], mail.toField[i][1]),
        trim(mail.toField[i][1]).toLowerCase(),
      ]
    }
    if (mail.ccField !== undefined) {
      for (var i = 0; i < mail.ccField.length; i++) {
        mail.ccField[i] = [
          normalizeName(mail.ccField[i][0], mail.ccField[i][1]),
          trim(mail.ccField[i][1]).toLowerCase(),
        ]
      }
    } else {
      mail.ccField = []
    }

    // add more aliases if they match the person's name
    const addrs = [mail.fromField].concat(mail.toField, mail.ccField)
    addrs.forEach(function(addr) {
      const name = addr[0]
      const email = addr[1]
      if (email in my) {
        return
      }
      if (name === userinfo.name) {
        my[email] = true
      }
    })
  })

  // DONE normalizing data
  // assign each email address a unique name
  const email_to_name = {}
  emails.forEach(function(mail) {
    const addrs = [mail.fromField].concat(mail.toField, mail.ccField)
    addrs.forEach(function(addr) {
      const name = addr[0]
      const email = addr[1]
      if (email in my) {
        return
      }

      // if its the first time we see this email
      if (!(email in email_to_name)) {
        email_to_name[email] = name
      }

      // overwrite the name if the previous name is the same as email or he is the sender
      if (name !== email && (email_to_name[email] === email || email === mail.fromField[1])) {
        email_to_name[email] = name
      }
    })
  })

  // DONE assign each email address a unique name
  // assign each name a unique contact with all the email addresses
  let uniqid = 0
  const contacts = {}
  const name_to_id = {}

  for (var email in email_to_name) {
    var name = email_to_name[email]
    if (!(name in name_to_id)) {
      name_to_id[name] = uniqid.toString()
      contacts[uniqid.toString()] = {
        name,
        aliases: [],
        sent: 0,
        rcv: 0,
        id: uniqid.toString(),
      }
      uniqid++
    }
    contacts[name_to_id[name]].aliases.push(email)
  }

  // calculate the no. of sent and rcv for each unique contact
  emails.forEach(function(mail) {
    const a = mail.fromField[1]
    if (mail.isSent) {
      const addrs = [mail.fromField].concat(mail.toField, mail.ccField)
      addrs.forEach(function(addr) {
        // const b_name = addr[0]
        const b = addr[1]
        if (b in email_to_name) {
          contacts[name_to_id[email_to_name[b]]].sent++
        }
      })
    } else if (a in email_to_name) {
      contacts[name_to_id[email_to_name[a]]].rcv++
    }
  })

  for (var name in name_to_id) {
    const id = name_to_id[name]
    const contact = contacts[id]
    if (Math.min(contact.sent, contact.rcv) < 1) {
      contact.aliases.forEach(function(email) {
        delete email_to_name[email]
      })
      delete contacts[id]
    }
  }

  // make the events list
  const events = []
  emails.forEach(function(mail) {
    const a = mail.fromField[1]
    const tmp = []
    const addrs = mail.toField.concat(mail.ccField)
    addrs.forEach(function(addr) {
      //      const b_name = addr[0]
      const b = addr[1]
      if (b !== a && !(b in my)) {
        if (b in email_to_name) {
          var id = name_to_id[email_to_name[b]]
        } else {
          var id = b
        }
        tmp.push(id.toString())
      }
    })
    if (mail.isSent) {
      events.push({ threadid: mail.threadid, timestamp: mail.dateField, destinations: tmp })
    } else {
      if (a in email_to_name) {
        var id = name_to_id[email_to_name[a]]
      } else {
        var id = a
      }
      events.push({
        threadid: mail.threadid,
        timestamp: mail.dateField,
        source: id,
        destinations: tmp,
      })
    }
  })

  return new InMemoryDB(events, contacts, Object.keys(my))
}

function InMemoryDB(emails, contacts, aliases) {
  this.start = 0
  this.end = emails.length
  this.emails = emails
  this.contacts = contacts
  this.aliases = aliases
  this.contactDetails = this.getContactDetails()
  const tmp = InMemoryDB.getNSentRcvEmails(emails)
  this.nSent = tmp[0]

  // this.nSentScore = d3.bisectLeft(stats['nsent'], this.nSent)/stats['nsent'].length * 100;
  this.nRcv = tmp[1]

  // this.nRcvScore = d3.bisectLeft(stats['nrcv'], this.nRcv)/stats['nrcv'].length * 100;
  this.nCollaborators = InMemoryDB.getNumberOfContacts(contacts)

  // this.nCollaboratorsScore = d3.bisectLeft(stats['ncollaborators'], this.nCollaborators)/stats['ncollaborators'].length * 100;
  this.myReplyTimes = InMemoryDB.getReplyTimes(true, emails)
  this.othersReplyTimes = InMemoryDB.getReplyTimes(false, emails)
}
InMemoryDB.prototype.getTopContacts = function(topN, start, end, ascending) {
  const contactDetails = this.getContactDetails(start, end)
  const p = -3
  const getScores = function(a) {
    if (contactDetails[a.id] === undefined) {
      return null
    }
    return [
      Math.pow(
        (Math.pow(contactDetails[a.id].nRcvEmails, p) +
          Math.pow(contactDetails[a.id].nSentEmails, p)) /
          2.0,
        1.0 / p,
      ),
    ]
  }
  return this.getRanking(topN, getScores, ascending)
}

InMemoryDB.getNumberOfContacts = function(contacts) {
  let ncontacts = 0
  for (const cid in contacts) {
    const contact = contacts[cid]
    if (Math.min(contact.rcv, contact.sent) >= 3) {
      ncontacts += 1
    }
  }
  return ncontacts
}

InMemoryDB.prototype.getContactDetails = function(start, end) {
  // var emails = db.getEmails();
  const contactDetails = []
  if (start === undefined) {
    start = new Date(this.emails[0].timestamp * 1000)
  }
  if (end === undefined) {
    // don't use end = new Date() since some emails might have a timestamp in the future
    end = new Date(this.emails[this.emails.length - 1].timestamp * 1000)
  }
  const startt = +start
  const endt = +end
  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const time = this.emails[i].timestamp * 1000
    if (time < startt || time > endt) {
      continue
    }

    // var isSent = (ev.f == undefined);
    const isSent = !ev.hasOwnProperty('source')
    if (!isSent && this.isContact(ev.source)) {
      const a = ev.source.toString()
      if (contactDetails[a] === undefined) {
        contactDetails[a] = {
          id: a,
          nRcvEmails: 0,
          nSentEmails: 0,
          nRcvEmailsPvt: 0,
          nSentEmailsPvt: 0,
          nSentEmailsNorm: 0,
          nRcvEmailsNorm: 0,
          firstEmail: new Date(ev.timestamp * 1000),
          lastEmail: undefined,
        }
      }
      contactDetails[a].nRcvEmails += 1
      contactDetails[a].nRcvEmailsNorm += 1.0 / (ev.destinations.length + 1)
      contactDetails[a].lastEmail = new Date(ev.timestamp * 1000)
      if (ev.destinations.length === 0) {
        contactDetails[a].nRcvEmailsPvt += 1
      }
    }
    for (let j = 0; j < ev.destinations.length; j++) {
      var b = ev.destinations[j].toString()
      if (!this.isContact(b)) {
        continue
      }
      if (contactDetails[b] === undefined) {
        contactDetails[b] = {
          id: b,
          nRcvEmails: 0,
          nSentEmails: 0,
          nRcvEmailsPvt: 0,
          nSentEmailsPvt: 0,
          nSentEmailsNorm: 0,
          nRcvEmailsNorm: 0,
          firstEmail: new Date(ev.timestamp * 1000),
          lastEmail: undefined,
        }
      }
      if (isSent) {
        contactDetails[b].lastEmail = new Date(ev.timestamp * 1000)
        contactDetails[b].nSentEmails += 1
        contactDetails[b].nSentEmailsNorm += 1.0 / ev.destinations.length
      }
    }
    if (isSent && this.isContact(b) && ev.destinations.length === 1) {
      b = ev.destinations[0].toString()
      contactDetails[b].nSentEmailsPvt += 1
    }
  }
  return contactDetails
}

InMemoryDB.prototype.getEmailDatesSent = function() {
  const dates = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isSent = !ev.hasOwnProperty('source')
    if (isSent) {
      dates.push({ date: new Date(ev.timestamp * 1000), weight: 1.0 })
    }
  }
  return dates
}

InMemoryDB.prototype.getEmailDatesRcv = function() {
  const dates = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isSent = !ev.hasOwnProperty('source')
    if (!isSent) {
      dates.push({ date: new Date(ev.timestamp * 1000), weight: 1.0 })
    }
  }
  return dates
}

InMemoryDB.prototype.getEmailDatesByContact = function(contact) {
  const dates = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]

    // var isSent = (ev.f == undefined);
    const isSent = !ev.hasOwnProperty('source')
    if (isSent) {
      for (let j = 0; j < ev.destinations.length; j++) {
        if (ev.destinations[j].toString() === contact.id) {
          var weight = 1.0
          dates.push({ date: new Date(ev.timestamp * 1000), weight })
        }
      }
    } else if (ev.source.toString() === contact.id) {
      var weight = 1.0
      dates.push({ date: new Date(ev.timestamp * 1000), weight })
    }
  }
  return dates
}

InMemoryDB.prototype.buildIntroductionTrees = function() {
  const nodes = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isRcv = ev.hasOwnProperty('source')

    if (isRcv) {
      var a = ev.source.toString()
      if (!this.isContact(a)) {
        continue
      }
      var score = Math.min(this.contactDetails[a].nRcvEmails, this.contactDetails[a].nSentEmails)
      if (score < 1) {
        continue
      }
      if (nodes[a] === undefined) {
        nodes[a] = { contact: this.contacts[a] }
      }
    }
    for (let j = 0; j < ev.destinations.length; j++) {
      const b = ev.destinations[j].toString()
      if (!this.isContact(b)) {
        continue
      }
      var score = Math.min(this.contactDetails[b].nRcvEmails, this.contactDetails[b].nSentEmails)
      if (score < 1) {
        continue
      }
      if (nodes[b] === undefined) {
        nodes[b] = { contact: this.contacts[b] }
        if (isRcv) {
          if (nodes[a].children === undefined) {
            nodes[a].children = []
          }
          nodes[a].children.push(nodes[b])
          nodes[b].father = nodes[a]
        }
      }
    }
  }
  return nodes
}

InMemoryDB.prototype.isContact = function(id) {
  if (isNaN(id)) return false

  // var score = Math.min(this.contactDetails[id].nRcvEmails, this.contactDetails[id].nSentEmails);
  // return score >=1;
  return true
}

InMemoryDB.prototype.getTimestampsFromContact = function(contact) {
  const res = []
  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isRcv = ev.hasOwnProperty('source')
    if (isRcv) {
      const a = ev.source.toString()
      if (a === contact.id) {
        res.push(ev.timestamp)
      }
    } else {
      for (let j = 0; j < ev.destinations.length; j++) {
        const b = ev.destinations[j].toString()
        if (b === contact.id) {
          res.push(ev.timestamp)
          break
        }
      }
    }
  }
  return res
}

InMemoryDB.getNSentRcvEmails = function(emails) {
  let countSent = 0
  let countRcv = 0
  for (let i = 0; i < emails.length; i++) {
    const ev = emails[i]
    const isSent = !ev.hasOwnProperty('source')
    if (isSent) {
      countSent++
    } else {
      countRcv++
    }
  }
  return [countSent, countRcv]
}

InMemoryDB.prototype.getNormCommunicationVariance = function(contact) {
  console.log(`${contact.name}-------------------------------------`)
  const times = this.getTimestampsFromContact(contact)

  for (var i = 0; i < times.length; i++) {
    times[i] /= 1000000
  }
  for (let k = 1; k < 100; k++) {
    const assignment = new Array(times.length)

    // initialize centroids
    const centroid = new Array(k)
    for (var j = 0; j < k; j++) {
      var randint = Math.floor(Math.random() * times.length)
      centroid[j] = times[randint]
    }

    // initialize centroids size
    const npoints = new Array(k)
    for (let iter = 0; iter < 100; iter++) {
      const centroid2 = new Array(k)
      for (var j = 0; j < k; j++) {
        centroid2[j] = 0
        npoints[j] = 0
      }

      for (var i = 0; i < times.length; i++) {
        let minDist = 2000000000
        let minIdx = -1
        for (var j = 0; j < k; j++) {
          // compute distance between point i and centroid j
          const dist = Math.abs(times[i] - centroid[j])
          if (dist < minDist) {
            minDist = dist
            minIdx = j
          }
        }

        // assign point i to cluster minIdx
        assignment[i] = minIdx
        centroid2[minIdx] += times[i]
        npoints[minIdx] += 1
      }
      for (var j = 0; j < k; j++) {
        if (npoints[j] === 0) {
          var randint = Math.floor(Math.random() * times.length)
          centroid[j] = times[randint]
        } else {
          centroid[j] = centroid2[j] / npoints[j]
        }
      }
    }

    // compute within clusters variance
    const clusterVariance = new Array(k)
    for (var j = 0; j < k; j++) {
      clusterVariance[j] = 0
    }

    for (var i = 0; i < times.length; i++) {
      clusterVariance[assignment[i]] += Math.pow(times[i] - centroid[assignment[i]], 2)
    }
    for (var j = 0; j < k; j++) {
      clusterVariance[j] /= npoints[j]
    }
    let wcv = 0
    for (var j = 0; j < k; j++) {
      wcv += clusterVariance[j]
    }

    // compute between clusters variance
    let mean = 0
    for (var j = 0; j < k; j++) {
      mean += centroid[j]
    }
    mean /= k
    let bcv = 0
    for (var j = 0; j < k; j++) {
      bcv += Math.pow(centroid[j] - mean, 2)
    }
    bcv /= k
    console.log(`${k} : ${Math.sqrt(bcv) + Math.sqrt(wcv)}`)
  }
  return 0
}

InMemoryDB.getReplyTimes = function(my, emails) {
  const replyDict = {}
  const allTimes = []
  const pastYearTimes = []
  const pastMonthTimes = []
  const pastWeekTimes = []

  // timestamps should always be seconds since the epoch
  const pastYear_ts = +d3.time.year.offset(new Date(), -1) / 1000
  const pastMonth_ts = +d3.time.month.offset(new Date(), -1) / 1000
  const pastWeek_ts = +d3.time.week.offset(new Date(), -1) / 1000

  for (let i = 0; i < emails.length; i++) {
    const ev = emails[i]
    let isFirst = ev.hasOwnProperty('source')
    if (!my) {
      isFirst = !isFirst
    }
    if (isFirst && !(ev.threadid in replyDict)) {
      replyDict[ev.threadid] = ev.timestamp
    } else if (!isFirst && ev.threadid in replyDict && replyDict[ev.threadid] !== false) {
      const ts = replyDict[ev.threadid]
      const diffSec = ev.timestamp - ts
      allTimes.push(diffSec)
      if (ts > pastYear_ts) {
        pastYearTimes.push(diffSec)
      }
      if (ts > pastMonth_ts) {
        pastMonthTimes.push(diffSec)
      }
      if (ts > pastWeek_ts) {
        pastWeekTimes.push(diffSec)
      }
      replyDict[ev.threadid] = false
    }
  }
  return {
    all: allTimes,
    pastYear: pastYearTimes,
    pastMonth: pastMonthTimes,
    pastWeek: pastWeekTimes,
  }
}

InMemoryDB.prototype.getCommunicationVariance = function(contact) {
  const times = this.getTimestampsFromContact(contact)

  // compute the average
  let mean = 0
  for (var i = 0; i < times.length; i++) {
    mean += times[i] / 1000000 / times.length
  }
  let variance = 0
  for (var i = 0; i < times.length; i++) {
    variance += Math.pow(times[i] / 1000000 - mean, 2) / times.length
  }
  return Math.sqrt(variance)
}

InMemoryDB.prototype.getTimestampsNewContacts = function() {
  const seen = []
  const dates = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isRcv = ev.hasOwnProperty('source')
    if (isRcv && this.isContact(ev.source)) {
      if (seen[ev.source] === undefined) {
        dates.push({ date: new Date(ev.timestamp * 1000), weight: 1.0 })
        seen[ev.source] = true
      }
    }
    if (!isRcv) {
      for (let j = 0; j < ev.destinations.length; j++) {
        const b = ev.destinations[j].toString()
        if (this.isContact(b)) {
          if (seen[b] === undefined) {
            dates.push({ date: new Date(ev.timestamp * 1000), weight: 1.0 })
            seen[b] = true
          }
        }
      }
    }
  }
  return dates
}

InMemoryDB.prototype.getIntroductions = function(contact) {
  const father = []
  const seen = []
  const children = []

  for (let i = this.start; i < this.end; i++) {
    const ev = this.emails[i]
    const isRcv = ev.hasOwnProperty('source')
    if (isRcv) {
      const a = ev.source.toString()
      if (!this.isContact(a)) {
        continue
      }
      seen[a] = true
    }
    for (let j = 0; j < ev.destinations.length; j++) {
      const b = ev.destinations[j].toString()
      if (!this.isContact(b)) {
        continue
      }
      if (seen[b] === undefined && isRcv) {
        father[b] = this.contacts[ev.source.toString()]
      }
      if (isRcv && ev.source.toString() === contact.id) {
        if (seen[b] === undefined) {
          const score = Math.min(
            this.contactDetails[b].nRcvEmails,
            this.contactDetails[b].nSentEmails,
          )
          if (score >= 1) {
            children.push(this.contacts[b])
          }
        }
      }
      seen[b] = true
    }
  }
  let id = contact.id
  const fathers = []
  while (father[id] !== undefined) {
    fathers.push(father[id])
    id = father[id]
  }
  return { children, fathers }
}

InMemoryDB.prototype.getRanking = function(topN, getScores, ascending) {
  const results = []
  for (const id in this.contacts) {
    const contact = this.contacts[id]
    const scores = getScores(contact)
    if (scores === null) {
      continue
    }
    results.push({ contact, scores })
  }
  const comp = function(a, b) {
    for (let i = 0; i < a.scores.length; i++) {
      if (a.scores[i] !== b.scores[i]) {
        return b.scores[i] - a.scores[i]
      }
    }
    return 0
  }
  results.sort(comp)
  if (ascending) {
    results.reverse()
  }
  return results.slice(0, topN)
}

function normalizeName(name = 'NOT FOUND', email = 'NOT FOUND') {
  if (name instanceof Array) {
    if (name.length > 0) {
      name = name[0]
    } else {
      name = ''
    }
  }

  // trim and strip off ' and "
  name = name
    .trim()
    .replace("'", '')
    .replace('"', '')

  // strip off the text between parenthesis, i.e. (some text)
  name = name
    .replace(/\(.*\)/, '')
    .trim()
    .toLowerCase()

  // strip and convert email to lowercase
  email = trim(email).toLowerCase()
  if (name === '' || name === email) {
    return email
  }
  if (name.indexOf(',') !== -1) {
    var ss = name.split(',')

    var first = trim(ss[ss.length - 1]).toTitleCase()
    var last = trim(ss[0]).toTitleCase()
  } else {
    var ss = name.split(/\s+/)

    if (ss.length === 1) {
      return trim(ss[0]).toTitleCase()
    }

    var first = trim(ss[0]).toTitleCase()

    var last = trim(ss[ss.length - 1]).toTitleCase()
  }
  return `${first} ${last}`
}

function parser(str) {
  const tokenizer = new Tokenizer(str)
  const tokens = tokenizer.tokenize()

  const addresses = []
  let address = []
  let parsedAddresses = []

  tokens.forEach(function(token) {
    if (token.type == 'operator' && (token.value == ',' || token.value == ';')) {
      addresses.push(address)
      address = []
    } else {
      address.push(token)
    }
  })

  if (address.length) {
    addresses.push(address)
  }

  addresses.forEach(function(address) {
    address = handleAddress(address)
    if (address.length) {
      parsedAddresses = parsedAddresses.concat(address)
    }
  })

  return parsedAddresses
}

/**
 * Converts tokens for a single address into an address object
 *
 * @param {Array} tokens Tokens object
 * @return {Object} Address object
 */
function handleAddress(tokens) {
  let token
  let isGroup = false
  let state = 'text'
  let address
  let addresses = []
  const data = {
    address: [],
    comment: [],
    group: [],
    text: [],
  }
  let i
  let len

  // Filter out <addresses>, (comments) and regular text
  for (i = 0, len = tokens.length; i < len; i++) {
    token = tokens[i]

    if (token.type == 'operator') {
      switch (token.value) {
        case '<':
          state = 'address'
          break
        case '(':
          state = 'comment'
          break
        case ':':
          state = 'group'
          isGroup = true
          break
        default:
          state = 'text'
      }
    } else if (token.value) {
      data[state].push(token.value)
    }
  }

  // If there is no text but a comment, replace the two
  if (!data.text.length && data.comment.length) {
    data.text = data.comment
    data.comment = []
  }

  if (data.group.length) {
    if (data.text.length) {
      data.text = data.text.join(' ')
    }

    addresses = addresses.concat(
      parser(data.group.join(',')).map(function(address) {
        address.name = data.text || address.name
        return address
      }),
    )
  } else {
    // If no address was found, try to detect one from regular text
    if (!data.address.length && data.text.length) {
      for (i = data.text.length - 1; i >= 0; i--) {
        if (data.text[i].match(/^[^@\s]+@[^@\s]+$/)) {
          data.address = data.text.splice(i, 1)
          break
        }
      }

      // still no address
      if (!data.address.length) {
        for (i = data.text.length - 1; i >= 0; i--) {
          data.text[i] =
            data.text[i] ||
            ''
              .replace(/\s*\b[^@\s]+@[^@\s]+\b\s*/, function(address) {
                if (!data.address.length) {
                  data.address = [trim(address)]
                  return ' '
                }
                return address
              })
              .trim()
          if (data.address.length) {
            break
          }
        }
      }
    }

    // If there's still is no text but a comment exixts, replace the two
    if (!data.text.length && data.comment.length) {
      data.text = data.comment
      data.comment = []
    }

    // Keep only the first address occurence, push others to regular text
    if (data.address.length > 1) {
      data.text = data.text.concat(data.address.splice(1))
    }

    // Join values with spaces
    data.text = data.text.join(' ')
    data.address = data.address.join(' ')

    if (!data.address && isGroup) {
      return []
    }
    address = {
      address: data.address || data.text || '',
      name: data.text || data.address || '',
    }

    if (address.address == address.name) {
      if ((address.address || '').match(/@/)) {
        address.name = ''
      } else {
        address.address = ''
      }
    }

    addresses.push(address)
  }

  return addresses
}

/**
 * Creates a TOkenizer object for tokenizing address field strings
 *
 * @constructor
 * @param {String} str Address field string
 */
function Tokenizer(str) {
  this.str = (str || '').toString()
  this.operatorCurrent = ''
  this.operatorExpecting = ''
  this.node = null
  this.escaped = false

  this.list = []
}

/**
 * Operator tokens and which tokens are expected to end the sequence
 */
Tokenizer.prototype.operators = {
  '"': '"',
  '(': ')',
  '<': '>',
  ',': '',
  ':': ';',
}

/**
 * Tokenizes the original input string
 *
 * @return {Array} An array of operator|text tokens
 */
Tokenizer.prototype.tokenize = function() {
  let chr
  const list = []
  for (let i = 0, len = this.str.length; i < len; i++) {
    chr = this.str.charAt(i)
    this.checkChar(chr)
  }

  this.list.forEach(function(node) {
    node.value = (node.value || '').toString().trim()
    if (node.value) {
      list.push(node)
    }
  })

  return list
}

/**
 * Checks if a character is an operator or text and acts accordingly
 *
 * @param {String} chr Character from the address field
 */
Tokenizer.prototype.checkChar = function(chr) {
  if ((chr in this.operators || chr == '\\') && this.escaped) {
    this.escaped = false
  } else if (this.operatorExpecting && chr == this.operatorExpecting) {
    this.node = {
      type: 'operator',
      value: chr,
    }
    this.list.push(this.node)
    this.node = null
    this.operatorExpecting = ''
    this.escaped = false
    return
  } else if (!this.operatorExpecting && chr in this.operators) {
    this.node = {
      type: 'operator',
      value: chr,
    }
    this.list.push(this.node)
    this.node = null
    this.operatorExpecting = this.operators[chr]
    this.escaped = false
    return
  }

  if (!this.escaped && chr == '\\') {
    this.escaped = true
    return
  }

  if (!this.node) {
    this.node = {
      type: 'text',
      value: '',
    }
    this.list.push(this.node)
  }

  if (this.escaped && chr != '\\') {
    this.node.value += '\\'
  }

  this.node.value += chr
  this.escaped = false
}

const trim = str => (str || '').trim()

export default setupDB
