import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useClient } from '../../graphql/provider'
import * as assignmentService from '../../entities/assignment/service'
import * as peopleService from '../../entities/people/service'
import Header from './Header'
import SelfView from './SelfView'
import ProjectView from './ProjectView'
import PartnerView from './PartnerView'
import NewAssignmentBox from './NewAssignmentBox'

import { ContentContainer, AssignmentContainer, CircularContainer } from './styles'

const moment = require('moment')
const _ = require('lodash')

const Assignments = props => {
  const { person, projects, isConnected } = props

  const getValidProjects = () => {
    return _.sortBy(
      _.filter(projects, project => {
        const client = _.get(project, 'client')
        const stageName = _.get(project, 'StageName')
        const projectCode = _.get(project, 'projectCode')
        return (
          _.includes(
            [
              'KS-99998 Booked Time Off',
              'KS-99999 Not assigned',
              'KS-27869 Keystone Development',
              'KS-28000 Booked Leave',
              'KS-26596 ETA Practice',
              'KS-26586 Strategy Practice',
              'KS-05001 Client Development',
              'KS-26609 Antitrust & Competition',
              'KS-28112 KS Financial Economics & Tax (FET)',
              'KS-28110 KS Health Tech',
              'KS-26613 Intellectual Property',
              'KS-26605 Information Strategy & Risk Regulation',
              'KS-28351 KS Survey and Experimentation',
            ],
            projectCode,
          ) ||
          (!_.includes(['Complete', 'Did Not Close', 'Pending', null], stageName) &&
            client !== 'Internal Keystone' &&
            projectCode)
        )
      }),
      ['name'],
    )
  }

  const getValidPeople = people => {
    return _.sortBy(
      _.filter(people, p => {
        const email = _.get(p, 'email')
        const division = _.get(p, 'division')
        const status = _.get(p, 'status')
        const employeeType = _.get(p, 'employeeType')
        return (
          email &&
          (
            ( _.includes(['Consulting', 'Information Technology'], division) && status !== 'inactive' ) ||
            _.includes(employeeType, 'Contractor')
          )
        )
      }),
      ['fullName'],
    )
  }

  const validProjects = getValidProjects()
  const today = moment.utc()

  const startNextWeek = day => {
    return moment
      .utc(day)
      .startOf('week')
      .add(1, 'week')
  }

  const endNextWeek = day => {
    return moment
      .utc(day)
      .endOf('week')
      .add(1, 'week')
  }

  const calculateShouldBeAbleToEdit = date => {
    const todayDay = today.get('isoWeekday')
    // Change this to the desired day of the week to lock the assignments
    const isDayAvailable = todayDay >= 1 && todayDay <= 7
    return today.isSameOrBefore(date) && isDayAvailable
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  const [isLoading, setIsLoading] = useState(true)
  const [selfAssignments, setSelfAssignments] = useState([])
  const [leadAssignments, setLeadAssignments] = useState([])
  const [projectLeadAssignments, setProjectLeadAssignments] = useState([])
  const [projectAssignments, setProjectAssignments] = useState([])
  const [allPeople, setAllPeople] = useState([])
  const [currentView, setCurrentView] = useState('SELF')
  const [isProjectLead, setIsProjectLead] = useState(false)
  const [isPartner, setIsPartner] = useState(false)
  const [currentDate, setCurrentDate] = useState(today)
  const [currentStartNextWeek, setCurrentStartNextWeek] = useState(startNextWeek(today))
  const [currentEndNextWeek, setCurrentEndNextWeek] = useState(endNextWeek(today))
  const [projectsLeaded, setProjectsLeaded] = useState([])
  const [shouldBeAbleToEdit, setShouldBeAbleToEdit] = useState(
    calculateShouldBeAbleToEdit(currentStartNextWeek),
  )
  const [currentEditableAssignment, setCurrentEditableAssignment] = useState(null)
  const [executedOperation, setExecutedOperation] = useState(false)
  const [currentProject, setCurrentProject] = useState(null)
  const [partnerViewAssignments, setPartnerViewAssignments] = useState([])

  const history = useHistory()
  const client = useClient()

  const weekToDisplay = moment
    .utc(currentDate)
    .add(1, 'week')
    .format('MM/DD/YYYY')

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      if (currentProject) {
        const prAssignments = await assignmentService.fetchAssignmentsByProjectIdsInDateRange({
          client,
          projectIds: currentProject?._id.toString(),
          startISO: currentStartNextWeek.toISOString(),
          endISO: currentEndNextWeek.toISOString(),
        })
        setPartnerViewAssignments(prAssignments)
      }
      setIsLoading(false)
    }
    loadData()
  }, [currentProject, client, currentStartNextWeek, currentEndNextWeek])

  useEffect(() => {
    const loadData = async () => {
      setExecutedOperation(false)
      setCurrentEditableAssignment(null)
      setIsLoading(true)
      setIsProjectLead(false)
      const pAssignments = await assignmentService.fetchAssignmentsInDateRange({
        client,
        startISO: currentStartNextWeek.toISOString(),
        endISO: currentEndNextWeek.toISOString(),
      })
      const self = pAssignments.filter(a => a.type === 0)
      const lead = pAssignments.filter(a => a.type !== 0)

      const projectLead = pAssignments.filter(a => a.type === 1 || a.type === 2)
      const partnerLead = pAssignments.filter(a => a.type === 2)
      setSelfAssignments(self)
      setLeadAssignments(lead)
      setProjectLeadAssignments(projectLead)

      // find a way of knowing if the user is a partner lead
      // op1. user has a partner lead assignment
      // op2. user has partner job title
      const personPosition = _.get(person, 'position')
      if (partnerLead.length > 0 || (personPosition && personPosition.includes('Partner'))) {
        setIsPartner(true)
      }

      const assignmentsToGetProjects = projectLead

      const projectIds = _.compact(_.uniq(_.map(assignmentsToGetProjects, 'projectId')))
      const leadedProjects = _.filter(projects, project => {
        return _.includes(projectIds, project._id)
      })
      setProjectsLeaded(leadedProjects)
      if (projectIds.length > 0) {
        const prAssignments = await assignmentService.fetchAssignmentsByProjectIdsInDateRange({
          client,
          projectIds: projectIds.join(','),
          startISO: currentStartNextWeek.toISOString(),
          endISO: currentEndNextWeek.toISOString(),
        })
        setProjectAssignments(prAssignments)
        setIsProjectLead(true)
      }

      const people = await peopleService.getAllPeople(client)
      const validPeople = getValidPeople(people)
      setAllPeople(validPeople)
      setIsLoading(false)
    }
    loadData()
  }, [currentStartNextWeek, currentEndNextWeek, executedOperation, client, projects, person])

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const setSelfView = () => {
    setCurrentView('SELF')
  }

  const setProjectView = () => {
    setCurrentView('PROJECT')
  }

  const setPartnerView = () => {
    setCurrentView('PARTNER')
  }

  const createAssignment = async assignment => {
    const created = await assignmentService.createAssignment({ client, assignment })
    setExecutedOperation(true)
    return created
  }

  const updateAssignment = async (assignmentId, assignment) => {
    const updated = await assignmentService.updateAssignment({ client, assignmentId, assignment })
    setExecutedOperation(true)
    return updated
  }

  const deleteAssignment = async assignmentId => {
    const deleted = await assignmentService.deleteAssignment({ client, assignmentId })
    setExecutedOperation(true)
    return deleted
  }

  const goToNextWeek = () => {
    const nextWeek = moment(currentDate).add(1, 'week')
    setCurrentDate(nextWeek)
    setCurrentStartNextWeek(startNextWeek(nextWeek))
    setCurrentEndNextWeek(endNextWeek(nextWeek))
    setShouldBeAbleToEdit(calculateShouldBeAbleToEdit(startNextWeek(nextWeek)))
  }

  const goToPreviousWeek = () => {
    const previousWeek = moment(currentDate).subtract(1, 'week')
    setCurrentDate(previousWeek)
    setCurrentStartNextWeek(startNextWeek(previousWeek))
    setCurrentEndNextWeek(endNextWeek(previousWeek))
    setShouldBeAbleToEdit(calculateShouldBeAbleToEdit(startNextWeek(previousWeek)))
  }

  const renderViewSelfAssignments = () => {
    return (
      <SelfView
        selfAssignments={selfAssignments}
        leadAssignments={leadAssignments}
        projects={validProjects}
        shouldBeAbleToEdit={shouldBeAbleToEdit}
        setCurrentEditableAssignment={setCurrentEditableAssignment}
        currentEditableAssignment={currentEditableAssignment}
        isConnected={isConnected}
        currentStartNextWeek={currentStartNextWeek}
      />
    )
  }

  const renderViewProjectAssignments = () => {
    return (
      <ProjectView
        projectAssignments={projectAssignments}
        leadAssignments={projectLeadAssignments}
        projects={validProjects}
        people={allPeople}
        shouldBeAbleToEdit={shouldBeAbleToEdit}
        setCurrentEditableAssignment={setCurrentEditableAssignment}
        currentEditableAssignment={currentEditableAssignment}
        isConnected={isConnected}
      />
    )
  }

  const renderViewPartnerAssignments = () => {
    return (
      <PartnerView
        projectAssignments={partnerViewAssignments}
        projects={validProjects}
        people={allPeople}
        shouldBeAbleToEdit={shouldBeAbleToEdit}
        setCurrentEditableAssignment={setCurrentEditableAssignment}
        currentEditableAssignment={currentEditableAssignment}
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
        isConnected={isConnected}
      />
    )
  }

  const renderCircularProgress = () => {
    return (
      <ContentContainer>
        <CircularContainer>
          <CircularProgress size={60} />
          <h3>Loading</h3>
        </CircularContainer>
      </ContentContainer>
    )
  }

  const renderSelectedView = () => {
    return (
      <ContentContainer>
        {currentView === 'SELF' && renderViewSelfAssignments()}
        {currentView === 'PROJECT' && renderViewProjectAssignments()}
        {currentView === 'PARTNER' && renderViewPartnerAssignments()}
      </ContentContainer>
    )
  }

  const renderHeader = () => {
    return (
      <Header
        currentWeek={weekToDisplay}
        currentViewSelected={currentView}
        isDisabledProjectView={!isProjectLead}
        isDisabledPartnerView={!isPartner}
        setSelfView={setSelfView}
        setProjectView={setProjectView}
        setPartnerView={setPartnerView}
        history={history}
        person={person}
        goToNextWeek={goToNextWeek}
        goToPreviousWeek={goToPreviousWeek}
        isConnected={isConnected}
      />
    )
  }

  const renderSelfNewAssignmentBox = () => {
    return (
      <AssignmentContainer>
        <NewAssignmentBox
          projects={validProjects}
          people={[person]}
          currentStartNextWeek={currentStartNextWeek}
          currentEndNextWeek={currentEndNextWeek}
          currentView={currentView}
          numberOfMonths={windowSize.innerWidth >= 960 ? 2 : 1}
          currentEditableAssignment={currentEditableAssignment}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          createAssignment={createAssignment}
          updateAssignment={updateAssignment}
          deleteAssignment={deleteAssignment}
          isConnected={isConnected}
        />
      </AssignmentContainer>
    )
  }

  const renderProjectNewAssignmentBox = () => {
    return (
      <AssignmentContainer>
        <NewAssignmentBox
          projects={projectsLeaded}
          people={allPeople}
          currentStartNextWeek={currentStartNextWeek}
          currentEndNextWeek={currentEndNextWeek}
          currentView={currentView}
          numberOfMonths={windowSize.innerWidth >= 960 ? 2 : 1}
          currentEditableAssignment={currentEditableAssignment}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          createAssignment={createAssignment}
          updateAssignment={updateAssignment}
          deleteAssignment={deleteAssignment}
          isConnected={isConnected}
        />
      </AssignmentContainer>
    )
  }

  const renderPartnerNewAssignmentBox = () => {
    return (
      <AssignmentContainer>
        <NewAssignmentBox
          projects={validProjects}
          people={allPeople}
          currentStartNextWeek={currentStartNextWeek}
          currentEndNextWeek={currentEndNextWeek}
          currentView={currentView}
          numberOfMonths={windowSize.innerWidth >= 960 ? 2 : 1}
          currentEditableAssignment={currentEditableAssignment}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          createAssignment={createAssignment}
          updateAssignment={updateAssignment}
          deleteAssignment={deleteAssignment}
          isConnected={isConnected}
          currentProject={currentProject}
        />
      </AssignmentContainer>
    )
  }

  const renderSelectedNewAssignmentBox = () => {
    if (currentView === 'SELF') return renderSelfNewAssignmentBox()
    if (currentView === 'PROJECT') return renderProjectNewAssignmentBox()
    if (currentView === 'PARTNER') return renderPartnerNewAssignmentBox()
  }

  return (
    <div>
      {renderHeader()}
      {isLoading ? renderCircularProgress() : renderSelectedView()}
      {!isLoading && shouldBeAbleToEdit && renderSelectedNewAssignmentBox()}
    </div>
  )
}

Assignments.propTypes = {
  person: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default Assignments
