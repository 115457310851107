import _ from 'lodash'
import { GET_TIME_OFF_ENTRIES, GET_TIME_OFF_ENTRIES_AS_DELEGATE } from './query'
import TimeOffEntry from '../../../server/models/timeOffEntry'

import { convertDateToLocalTimezone } from '../../../server/utils/date'
import { getDiff } from '../../../utils/diff'
import { addEventDataToPTO } from '../../../server/funnel/funnelUtils'

import { logError, log } from '../../../utils/logger'

export const fetchTimeOffEntriesInDateRange = async ({
  client,
  startISO,
  endISO,
  selectedDelegateId,
}) => {
  const { data } = await client.query({
    query: selectedDelegateId ? GET_TIME_OFF_ENTRIES_AS_DELEGATE : GET_TIME_OFF_ENTRIES,
    variables: {
      startISO,
      endISO,
      delegateId: selectedDelegateId,
    },
  })
  return selectedDelegateId ? data.timeOffEntries_getAll_asDelegate : data.timeOffEntries_getAll
}

export const assurePTOsExistLocally = async ({ client, startISO, endISO, selectedDelegateId }) => {
  try {
    // TODO: HERE FUNNEL PAID TIME OFF ENTRIES IN RANGE IS RETURNING EMPTY ARRAY WHEN IT SHOULD NOT
    // THIS IS OCURRING BEACAUSE IS A 3 DAYS RECURRING PTO
    const localStartISO = convertDateToLocalTimezone(startISO)
    const localEndISO = convertDateToLocalTimezone(endISO)

    const funnelPaidTimeOff = await fetchTimeOffEntriesInDateRange({
      client,
      startISO,
      endISO,
      selectedDelegateId,
    })

    const localPaidTimeOff = await TimeOffEntry.where('entryDate')
      .between(localStartISO, localEndISO, true, true)
      .toArray()

    const adoptedFunnelTimeOffEntries = addEventDataToPTO(funnelPaidTimeOff)
    const { create, update, remove } = getDiff({
      oldData: localPaidTimeOff,
      newData: adoptedFunnelTimeOffEntries,
      uniqueId: '_id',
    })
    log(`ES -> LS -> PaidTimeOff → C: ${_.size(create)} U: ${_.size(update)} D: ${_.size(remove)}`)
    await TimeOffEntry.bulkDelete(remove.map(e => e._id))
    await TimeOffEntry.bulkPut(update)
    await TimeOffEntry.bulkAdd(create)
  } catch (e) {
    logError(e, 'actions > paidTimeOffEntryAction > fetchPaidTimeOffEntriesForTheDay > e:')
  }
}
