import _ from 'lodash'
import moment from 'moment'

import { convertDateToLocalTimezone } from '../../../server/utils/date'
import eventUtils from '../../../utils/eventUtils'
import { APPROVED } from '../../../constants/timesheetStatus'

export const isEntryLocked = (entry, timesheets) => {
  const date = entry.startDate || entry.start
  return isColumnLocked(date, timesheets)
}

export const isColumnLocked = (date, timesheets) => {
  const entryTimesheet = eventUtils.getTimesheetForDay(
    new Date(convertDateToLocalTimezone(date)),
    timesheets,
  )
  const doesntExistTimesheet = _.isEqual(entryTimesheet, {})
  return (
    (!_.isEmpty(entryTimesheet) && entryTimesheet.approvalStatus === APPROVED) ||
    doesntExistTimesheet
  )
}

export const getTooltipTitle = event => {
  return `${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}: ${
    eventUtils.isTimeOffEvent(event) ? 'Time Off' : event.originalComment
  }`
}

export const getTooltipDates = event => {
  return `${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}`
}

export const getTooltipComment = event => {
  return `${eventUtils.isTimeOffEvent(event) ? 'Time Off' : event.originalComment}`
}

export const getIsDroppableEvent = (eventArgs, timesheets) => {
  const { end, event } = eventArgs

  if (isEntryLocked(event, timesheets) || isEntryLocked({ startDate: end }, timesheets))
    return false

  return true
}
