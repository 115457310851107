import _ from 'lodash'
import Event from '../../../../../server/models/event'
import getTransformedEvents from '../../../events/service/utils/eventsTransformations'

const uniqById = e => _.uniqBy(e, 'id')
const uniqueByIdValues = obj => _.mapValues(obj, uniqById)

export default async (client, eventDeltasRaw = []) => {
  const toTransformEvents = eventDeltasRaw
    .filter(eventDelta => eventDelta.deltaType !== 'delete')
    .map(eventDelta => eventDelta.event)

  const transformedEvents = (
    await getTransformedEvents({ client, events: toTransformEvents, ignoreFiltration: true })
  ).reduce((eventsObjectified, event) => {
    const toReturn = eventsObjectified
    toReturn[event.id] = event
    return toReturn
  }, {})

  const eventDeltas = eventDeltasRaw.map(eventDelta =>
    transformedEvents[eventDelta.id]
      ? { ...eventDelta, event: transformedEvents[eventDelta.id] }
      : eventDelta,
  )

  const seriesMasterIdDeleted = eventDeltas
    .filter(
      eventDelta =>
        eventDelta.deltaType === 'delete' ||
        (eventDelta.deltaType !== 'delete' &&
          (eventDelta.event.type === 'seriesMaster' || eventDelta.event.type === 'occurrence')),
    )
    .map(eventDelta =>
      eventDelta.event && eventDelta.event.type === 'occurrence'
        ? eventDelta.event.seriesMasterId
        : eventDelta.id,
    )

  const eventsToDelete = await Event.where('seriesMasterId')
    .anyOf(seriesMasterIdDeleted)
    .toArray()

  const reduceSeed = {
    create: [],
    modify: [],
    delete: eventsToDelete,
  }

  const actionDeltas = _.reduce(
    eventDeltas,
    (acc, eventDelta) => {
      const eventAction = eventDelta.deltaType

      if (eventAction === 'delete') {
        return {
          ...acc,
          delete: [...acc.delete, { _id: eventDelta.id }],
        }
      }

      const glimpseEvent = eventDelta.event

      if (glimpseEvent.isCancelled === true) {
        return {
          ...acc,
          delete: acc.delete.concat(glimpseEvent),
        }
      }

      return {
        ...acc,
        [eventAction]: acc[eventAction].concat(glimpseEvent),
      }
    },
    reduceSeed,
  )

  const uniqueActionDeltas = uniqueByIdValues(actionDeltas)
  return uniqueActionDeltas
}
