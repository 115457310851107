import _ from 'lodash'
import moment from 'moment'

import { getDurationInHours } from '../server/utils/date'
import timeUtils from '../utils/timeUtils'
import {
  getLastOutlookEventSyncDate,
  getLastOutlookMessageSyncDate,
  getOutlookEventDeltaToken,
  getOutlookMessageDeltaToken,
} from '../server/store'

import UnhandledEntry from '../server/models/unhandledEntry'
import { logError, log } from '../utils/logger'
import { getLatestGlimpseToken } from '../redux/services/auth'
import config from '../config'

const { MAX_ELAPSED_HOURS_TO_SYNC_ALL } = config

export const buildInitialSyncMessage = (entity, { startISO, endISO }) => {
  const from = moment(startISO).format('MMMM Do')
  const to = moment(endISO).format('MMMM Do')
  return `${entity} from ${from} to ${to}`
}

export const isOutlookDeltaTokensValid = () => {
  const lastEventSyncDate = getLastOutlookEventSyncDate()
  const lastMessageSyncDate = getLastOutlookMessageSyncDate()
  const lastSyncDate =
    lastEventSyncDate < lastMessageSyncDate ? lastEventSyncDate : lastMessageSyncDate
  const doesDeltaTokenExist =
    !_.isEmpty(getOutlookEventDeltaToken()) && !_.isEmpty(getOutlookMessageDeltaToken())
  if (!(lastSyncDate && doesDeltaTokenExist)) return false
  const timeElapsed = getDurationInHours(lastSyncDate, new Date())
  return Number(timeElapsed) < MAX_ELAPSED_HOURS_TO_SYNC_ALL
}

export const calcIsUserLoggedIn = async auth0 => {
  // TODO: if not connected then we get token from the store getGlimpseApiToken
  // else continue
  const haveToken = Boolean(await getLatestGlimpseToken(auth0))
  const _isOutlookDeltaTokensValid = isOutlookDeltaTokensValid()
  return _isOutlookDeltaTokensValid && haveToken
}

export const deleteUnhandledEntriesForDate = async dateStr => {
  try {
    const day = moment.utc(dateStr).format('YYYY-MM-DD')
    log('Deleting unhandled entry for date: ', day)
    await UnhandledEntry.where('start')
      .startsWith(`${day}`)
      .delete()
  } catch (e) {
    logError(e, 'app/utils > deleteUnhandledEntriesForDate > e: ')
  }
}

export const getUnhandledEntriesForDate = async dateStr => {
  try {
    const day = moment.utc(dateStr).format('YYYY-MM-DD')
    const entries = await UnhandledEntry.where('start')
      .startsWith(`${day}`)
      .toArray()
    return entries
  } catch (e) {
    logError(e, 'app/utils > deleteUnhandledEntriesForDate > e: ')
  }
}

const matchAnyNumberLengthRegex = /[0-9]+/g
export const replaceErrorMessageDynamicData = errorMessage =>
  errorMessage.replace(matchAnyNumberLengthRegex, '[NUMBER_PLACEHOLDER]')

export const getCsvInfoTotal = csvEntries => {
  const csvEntriesInfo = _.map(csvEntries, e => {
    const { miliseconds } = timeUtils.getTimeUnitsBetweenDates(e.start, e.end)
    return {
      start: moment(e.start).format('MM/DD/YY'),
      date: e.start,
      durationInHours: Number(e.durationInHours),
      durationInMiliseconds: miliseconds,
    }
  })
  const groupedCsvEntriesInfo = _.values(_.groupBy(csvEntriesInfo, 'start'))
  const csvInfoByTimesheetTotalUnordered = _.map(groupedCsvEntriesInfo, e => {
    return {
      start: e[0].start,
      date: new Date(e[0].date),
      durationInHours: _.sumBy(e, 'durationInHours'),
      durationInMiliseconds: _.sumBy(e, 'durationInMiliseconds'),
      count: e.length,
    }
  })

  const csvInfoTotal = {
    count: _.sumBy(csvInfoByTimesheetTotalUnordered, 'count'),
    durationInHours: _.sumBy(csvInfoByTimesheetTotalUnordered, 'durationInHours'),
    durationInMiliseconds: _.sumBy(csvInfoByTimesheetTotalUnordered, 'durationInMiliseconds'),
    timesheetsCount: csvInfoByTimesheetTotalUnordered.length,
    csvInfoByTimesheetTotal: _.orderBy(csvInfoByTimesheetTotalUnordered, ['date'], ['asc']),
  }

  return csvInfoTotal
}

export const getNameInitials = name => {
  const getInitial = str => _.upperCase(str.charAt(0))

  const splitedName = _.split(name, ' ', 2)
  return _.join(_.map(splitedName, getInitial), '')
}

export const getProject = ({ projects, projectRepliconId, projectId }) => {
  return projects.find(project => {
    return project.repliconId === projectRepliconId || project._id === projectId
  })
}
