import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  pingSuccess: null,
  pingFail: ['error'],

  clear: null,
})

export const PingTypes = Types
export default Creators

const INITIAL_STATE = {
  isConnected: true,
  error: null,
}

const pingSuccess = state => ({
  ...state,
  isConnected: true,
  error: null,
})

const pingFail = (state, action) => ({
  ...state,
  error: action.error,
  isConnected: false,
})

const clear = () => INITIAL_STATE

const HANDLERS = {
  [Types.PING_SUCCESS]: pingSuccess,
  [Types.PING_FAIL]: pingFail,

  [Types.CLEAR]: clear,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
