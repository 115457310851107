import _ from 'lodash'
import { GET_USER_SETTINGS, GET_USER_PHOTO } from './query'
import { SET_USER_SETTINGS } from './mutation'

export const fetchUserSettings = async client => {
  const response = await client.query({
    query: GET_USER_SETTINGS,
  })

  return _.get(response, 'data.getUserSettings', {
    defaultProjectId: null,
    favoriteProjectIds: [],
  })
}

export const fetchUserPicture = async client => {
  const response = await client.query({
    query: GET_USER_PHOTO,
  })
  return _.get(response, 'data.getUserPhoto', { hasPicture: false })
}

export const setUserSettings = async (client, settings) => {
  const { favoriteProjectIds, defaultProjectId } = settings
  try {
    await client.mutate({
      mutation: SET_USER_SETTINGS,
      variables: { favoriteProjectIds, defaultProjectId },
    })
  } catch (e) {
    throw new Error('failed to set user settings', e)
  }
}
