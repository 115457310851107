import FEATURE_FLAGS from './featureFlags'
import SYNC from './sync'

const {
  NODE_ENV,
  REACT_APP_REVIEW_ID: REVIEW_ID,
  REACT_APP_API_URL: API_URL = 'http://localhost:5900/api/gql',
  REACT_APP_API_URL_PRODUCTION: API_URL_PRODUCTION,
  REACT_APP_API_URL_TEST: API_URL_TEST,
  REACT_APP_FUNNEL_URL: FUNNEL_URL = 'http://localhost:9090/api/',
  REACT_APP_FUNNEL_URL_PRODUCTION: FUNNEL_URL_PRODUCTION,
  REACT_APP_FUNNEL_URL_TEST: FUNNEL_URL_TEST,
  REACT_APP_0AUTH_CLIENT_ID: AUTH0_CLIENT_ID = 'SV6BT7ugBwE4tfXZ7GZuffXHMAn9cF6F',
  REACT_APP_0AUTH_DOMAIN: AUTH0_DOMAIN = 'keystonelabs.auth0.com',
  REACT_APP_0AUTH_GLIMPSE_AUDIENCE: AUTH0_GLIMPSE_AUDIENCE = 'glimpse-api.keystonestrategy.io',
  REACT_APP_0AUTH_MICROSOFT_AUDIENCE: AUTH0_MICROSOFT_AUDIENCE = 'keystonelabs.onmicrosoft.com',
  REACT_APP_0AUTH_CONNECTION: AUTH0_CONNECTION,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_IS_WEB,
  REACT_APP_IS_DESK,
  REACT_APP_KS_ENV,
  REACT_APP_VERSION: VERSION,
  REACT_APP_IS_PING_ENABLED: IS_PING_ENABLED_TEXT = 'true',
  REACT_APP_TIMESHEETS_FETCH_UPPER_LIMIT: TIMESHEETS_FETCH_LOWEST_LIMIT = '2020-06-01T00:00:00.000Z',
  REACT_APP_SOCKETIO_URL: SOCKETIO_URL = 'http://localhost:5900',
  REACT_APP_SOCKETIO_URL_PRODUCTION: SOCKETIO_URL_PRODUCTION,
  REACT_APP_SOCKETIO_URL_TEST: SOCKETIO_URL_TEST,
  REACT_APP_SOCKETIO_RECONNECTION_DELAY: SOCKETIO_RECONNECTION_DELAY = 1000,
} = process.env

const IS_TEST = NODE_ENV === 'test'
const IS_DEV = NODE_ENV === 'development'
const IS_WEB = REACT_APP_IS_WEB === 'true'
const IS_DESK = REACT_APP_IS_DESK === 'true'
const IS_TEST_E2E = REACT_APP_KS_ENV === 'true'

const IS_BETA = VERSION && VERSION.includes('beta')

const HAS_REVIEW_ID = !!REVIEW_ID && REVIEW_ID !== 'undefined'

const getFunnelURL = () => {
  if (IS_DEV || IS_WEB || IS_TEST) return FUNNEL_URL
  if (IS_BETA) return FUNNEL_URL_PRODUCTION
  return FUNNEL_URL_TEST
}

const getWebDevTestApiUrl = () => {
  if (HAS_REVIEW_ID) {
    return `https://glimpse-api-review-pr-${REVIEW_ID}.herokuapp.com/api/gql`
  }

  return API_URL
}

const getApiUrl = () => {
  if (IS_DEV || IS_WEB || IS_TEST || (IS_DESK && HAS_REVIEW_ID)) return getWebDevTestApiUrl()
  if (IS_BETA) return API_URL_PRODUCTION
  return API_URL_TEST
}

const getWebDevTestSocketIOUrl = () => {
  if (HAS_REVIEW_ID) {
    return `https://glimpse-api-review-pr-${REVIEW_ID}.herokuapp.com`
  }

  return SOCKETIO_URL
}

const getSocketIOUrl = () => {
  if (IS_DEV || IS_WEB || IS_TEST || (IS_DESK && HAS_REVIEW_ID)) return getWebDevTestSocketIOUrl()
  if (IS_BETA) return SOCKETIO_URL_PRODUCTION
  return SOCKETIO_URL_TEST
}

console.log('ISTEST', IS_TEST)
console.log('ISDEV', IS_DEV)
console.log('ISWEB', IS_WEB)
console.log('ISDESK', IS_DESK)
console.log('IS_BETA', IS_BETA)
console.log('getApiUrl', getApiUrl())
console.log('getFunnelURL', getFunnelURL())
console.log('getSocketIOUrl', getSocketIOUrl())

export default {
  ...FEATURE_FLAGS,
  ...SYNC,
  API_URL: getApiUrl(),
  FUNNEL_URL: getFunnelURL(),
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_GLIMPSE_AUDIENCE,
  AUTH0_MICROSOFT_AUDIENCE,
  AUTH0_CONNECTION,
  SOCKETIO_URL: getSocketIOUrl(),
  SOCKETIO_RECONNECTION_DELAY,
  SENTRY_DSN,
  VERSION,
  IS_DEV,
  IS_WEB,
  IS_DESK,
  IS_TEST,
  IS_TEST_E2E,
  IS_BETA,
  MAX_ELAPSED_HOURS_TO_SYNC_ALL: 300,
  IS_PING_ENABLED: IS_PING_ENABLED_TEXT === 'true',
  TIMESHEETS_FETCH_LOWEST_LIMIT,
}
