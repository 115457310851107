import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import timeUtils from '../../../../utils/timeUtils'
import { HtmlTooltip } from './styles'
import { APPROVED } from '../../../../constants/timesheetStatus'
import TooltipContent from './TooltipContent'

const DayTooltip = ({ timesheet, date }) => {
  const isSubmitted = !_.isNil(timesheet) && timesheet.approvalStatus === APPROVED
  const dayCellClass = classNames({
    'DayPicker-Tooltip': true,
    submitted: isSubmitted,
    opened: !isSubmitted,
  })

  const formatedDayYYYYMMDD = timeUtils.formatDateToYYYYMMDD(date)

  return (
    <HtmlTooltip
      arrow
      title={
        <TooltipContent
          isSubmitted={isSubmitted}
          totalHours={isSubmitted ? timesheet.totalHours : null}
          date={date}
        />
      }
    >
      <div data-testid={`datePicker.day.${formatedDayYYYYMMDD}`} className={dayCellClass}>
        {date.getDate()}
      </div>
    </HtmlTooltip>
  )
}

DayTooltip.propTypes = {
  date: PropTypes.object.isRequired,
  timesheet: PropTypes.object,
}

export default DayTooltip
