import _ from 'lodash'
import { GET_MESSAGES } from '../query'
import { requestPersistently } from '../../../utils/request'
import {
  getOutlookMessageDeltaToken,
  updateOutlookMessageDeltaToken,
} from '../../../../../server/store'

export const requestMessages = async ({ client, params }) => {
  const {
    receivedAfter,
    receivedBefore,
    isDelta,
    reSyncDelta,
    folderType,
    selectType,
    isCache = false,
    selectedDelegateId,
  } = params

  let nextDeltaLink = null
  let toReturn = []
  do {
    const requestDeltaToken = getOutlookMessageDeltaToken()

    const requestProps = {
      client,
      type: 'query',
      options: {
        query: GET_MESSAGES,
        variables: {
          receivedAfter,
          receivedBefore,
          isDelta: isDelta || reSyncDelta,
          nextDeltaLink: nextDeltaLink != null ? nextDeltaLink : undefined,
          deltaToken:
            isDelta && !reSyncDelta && requestDeltaToken !== '' ? requestDeltaToken : undefined,
          folderType,
          selectType,
          isCache,
          selectedDelegateId,
        },
      },
    }

    const response = await requestPersistently({ requestProps })

    const deltaToken = _.get(response, 'data.getMessages.deltaToken')
    if (deltaToken && typeof deltaToken === 'string') updateOutlookMessageDeltaToken(deltaToken)

    const toAdd = _.get(response, 'data.getMessages.deltaMessages', [])
    toReturn = [...toReturn, ...toAdd]

    const currentLink = nextDeltaLink
    nextDeltaLink = _.get(response, 'data.getMessages.nextDeltaLink', null)
    if (nextDeltaLink === currentLink) {
      nextDeltaLink = null
    }
  } while (nextDeltaLink != null)
  return toReturn
}

export const requestAllMessages = () => {}
