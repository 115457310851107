import _ from 'lodash'
import classNames from 'classnames'
import eventUtils from '../../../../utils/eventUtils'
import { getFieldsToInheritToEntry } from '../../../../utils/projectUtils'
import { timesheet, billingOptions } from '../../../../constants/billingOptions'
import { placeholderEventMock } from '../../../../constants/events'
import { UNASSIGNED_PROJECT } from '../../../../constants/projects'
import { buildPlaceholderEntry } from '../../../../utils/appUtils'
import { isAnOperation, getEntry, getEntryOrOperation } from '../../../../models/entryOperation'
import { getFreeGap } from '../../utils'
import timeUtils from '../../../../utils/timeUtils'

export const selectEntryToEdit = ({
  hotInstance,
  row,
  timeEntries,
  selectedDate,
  buildCurrentEditableEntry,
}) => {
  const dataRow = hotInstance.getDataAtRow(row)

  const timeEntry = timeEntries.find(entry => {
    const isOperationEntry = isAnOperation(entry)
    const _id = isOperationEntry ? entry.sourceId : entry._id

    const entryToModify = dataRow.includes(_id)

    return entryToModify
  })

  const selectedDayEntries = timeEntries.filter(entryOrOperation => {
    const entry = getEntryOrOperation(entryOrOperation)
    const { start, end } = entry

    const isEntryInSelectedDate =
      timeUtils.areSameDay(start, selectedDate) && timeUtils.areSameDay(end, selectedDate)
    return isEntryInSelectedDate
  })

  if (timeEntry) return timeEntry

  const freeGap = getFreeGap({ selectedDate, selectedDayEntries })
  const unassignedProjectFields = getFieldsToInheritToEntry(UNASSIGNED_PROJECT)

  if (!freeGap) return
  const data = {
    ...placeholderEventMock,
    start: freeGap.start,
    end: freeGap.end,
    ...unassignedProjectFields,
  }
  const entry = buildPlaceholderEntry(_.merge({}, data, { title: '' }))
  return buildCurrentEditableEntry(entry)
}

export const dataToTable = (timeEntries, projects) => {
  return timeEntries.map(entryOrOperation => {
    const isOperation = isAnOperation(entryOrOperation)
    const entry = isOperation ? getEntry(entryOrOperation) : entryOrOperation

    const projectObject = eventUtils.findProjectObject(entry, projects)
    const projectName = projectObject.name
    const entryId = isOperation ? entry.sourceId : entry._id

    return [
      projectName,
      entry.billable === timesheet.BILLABLE,
      entry.originalComment,
      entry.originalDurationInHours,
      entryId,
    ]
  })
}

const createDisabledRow = (row, colHeaders) =>
  colHeaders.map((e, col) => ({
    row,
    col,
    editor: false,
    className: classNames({
      htDimmed: true,
      htCenter: col === 1 || col === 3,
      htMiddle: col === 1 || col === 3,
    }),
  }))

const createOperationRow = (row, colHeaders) =>
  colHeaders.map((e, col) => ({
    row,
    col,
    className: classNames({
      'table-offline-cell': true,
      htCenter: col === 1 || col === 3,
      htMiddle: col === 1 || col === 3,
    }),
  }))

export const getCellsConfiguration = ({ timeEntries, projects, colHeaders, data }) => {
  const cells = timeEntries.reduce((acc, curr) => {
    const isOperation = isAnOperation(curr)
    const entry = isOperation ? getEntry(curr) : curr

    const row = data.findIndex(timeEntry => {
      const entryId = isOperation ? entry.sourceId : entry._id
      return timeEntry.includes(entryId)
    })

    if (isOperation) {
      const operationRow = createOperationRow(row, colHeaders)
      return [...acc, ...operationRow]
    }

    const isEntryInvoiced = eventUtils.isEntryInvoiced(entry)
    if (isEntryInvoiced) {
      const disabledRow = createDisabledRow(row, colHeaders)
      return [...acc, ...disabledRow]
    }

    // Disabled billable
    const project = eventUtils.findProjectObject(entry, projects)
    if (project.billable !== billingOptions.BOTH) {
      return [...acc, { col: 1, editor: false, row, className: 'htCenter htMiddle htDimmed' }]
    }
    return acc
  }, [])
  return cells
}

// NOTE: This is copied and pasted from the internet
export const beforeKeyDown = function beforeKeyDown(e) {
  const selectedLast = this.getSelectedLast()
  if (!selectedLast) {
    return
  }
  const [row, col] = selectedLast
  const celltype = this.getCellMeta(row, col).type

  // prevent Alpha chars in numeric sheet cells
  if (celltype === 'numeric') {
    const evt = e || window.event // IE support
    const key = evt.charCode || evt.keyCode || 0

    // check for cut and paste
    let isClipboard = false
    const ctrlDown = evt.ctrlKey || evt.metaKey // Mac support

    // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
    if (ctrlDown && evt.altKey) isClipboard = false
    // Check for ctrl+c, v and x
    else if (ctrlDown && key === 67) isClipboard = true
    // c
    else if (ctrlDown && key === 86) isClipboard = true
    // v
    else if (ctrlDown && key === 88) isClipboard = true // x

    // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers
    // ONLY home, end, F5, F12, minus (-), period (.)
    // console.log('Key: ' + key + ' Shift: ' + e.shiftKey + ' Clipboard: ' + isClipboard);
    const isNumeric =
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 ||
      key === 116 ||
      key === 123 ||
      key === 189 ||
      key === 190 ||
      key === 110 ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105)

    if ((!isNumeric && !isClipboard) || e.shiftKey) {
      // prevent alpha characters
      e.stopImmediatePropagation()
      e.preventDefault()
    }
  }

  if (celltype === 'checkbox') {
    const evt = e || window.event // IE support
    const key = evt.charCode || evt.keyCode || 0
    const isSpaceKey = key === 32

    if (isSpaceKey) {
      const checkboxCellElement = this.getCell(row, col)
      const checkboxElement = checkboxCellElement.getElementsByTagName('input')[0]

      if (!checkboxElement) return

      checkboxElement.click()
    }
  }
}
