import styled from 'styled-components'
import blue from '@material-ui/core/colors/blue'

export const DailyViewBar = styled.div`
  border-top: 1px solid #23557f;
  height: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
`

export const StylessButton = styled.button`
  border: none;
  background-color: transparent;
  width: 50%;
  cursor: pointer;
  color: ${blue[500]};
  &:hover {
    text-decoration: underline;
  }
`

export const AllDayEvent = styled.div`
  position: absolute;
  background: #ececec;
  top: 17px;
  width: 100%;
  text-align: center;
  padding: 10px 0px 0px 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: ${blue[500]};
`

export const AllDayEventContainer = styled.div`
  border-bottom: solid 1px #c5c5c5;
  margin-bottom: 6px;
  padding-bottom: 5px;
`

export const EventsToolBar = styled.div`
  display: flex;
  padding-left: 50%;
  transform: translateX(-18%);
`

export const WeeklyViewBar = styled.div`
  display: flex;
`

export const EventsContainer = styled.div`
  width: 50%;
  display: flex;
  position: relative;
`

export const ResponsiveDiv = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`
