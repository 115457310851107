import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Views } from 'react-big-calendar'
import { MuiThemeProvider } from '@material-ui/core/styles'
import '../server/db'
import '../style.css'
import '../transitions.css'
import '../views/Calendar/TimelineView/timeline.css'
import '../views/Calendar/react-day-picker.css'
import 'handsontable/dist/handsontable.full.css'
import { withApollo } from '@apollo/client/react/hoc'

import { withAuth0 } from '@auth0/auth0-react'

import * as timeEntryService from '../entities/timeEntry/service'
import * as timeOffService from '../entities/timeOffEntry/service'
import * as intialSyncTimeService from '../entities/analytics/initialSyncTime/service'
import theme from '../theme'

import { setLastSyncUpdate } from '../server/store'

// Components
import DataSync from '../components/DataSync'
import Application from '../components/Application'

// Sync Funcs
import {
  syncTimesheets,
  syncTimeEntries,
  syncTimeOffEntries,
  syncProjects,
  syncTimeOffCodes,
  syncReminderSettings,
  syncDelegateAccess,
  syncUserSettings,
  syncOutlookEvents,
  syncOutlookEmails,
  syncAssignments,
} from '../components/DataSync/syncFunc'

// Update Functions
import {
  updateViewOutlookEvents,
  updateViewOutlookEmails,
  updateViewTimesheets,
  updateViewTimesheetData,
  updateUnhandledEntries,
  updateViewTimeOffCodes,
  updateViewProjects,
} from '../components/DataSync/updateFunc'

// Utils
import eventUtils from '../utils/eventUtils'
import timeUtils from '../utils/timeUtils'
import {
  getDefaultSyncDateRangeISOs,
  parseStartDateAsUTC,
  parseEndDateAsUTC,
  convertDateToLocalTimezone,
  getDiffDaysCount,
} from '../server/utils/date'
import { getFreeGap } from '../views/Calendar/utils'
import { getEntryOrOperation } from '../models/entryOperation'
import { buildCurrentEditableEntry, buildPlaceholderEntry } from '../utils/appUtils'

// Actions
import * as timesheetService from '../server/actions/timesheetAction'

import { mapStateToProps, mapDispatchToProps } from './store'
import { buildInitialSyncMessage } from './utils'

import { logError, log, logErrorMessage } from '../utils/logger'

// SocketIO
import { withSocketIO } from '../components/SocketIO'

import { initialState } from './constants'

const { DAY: VIEW_PERIOD_DAY } = Views

class App extends React.Component {
  state = initialState

  startInitialSync = async () => {
    log(`Outlook: Initial Outlook Service`)
    this.setState({ hasUserGotStarted: false })

    this._handleInitialSyncUpdate({ inProgress: true, done: false })

    try {
      const { startISO, endISO } = getDefaultSyncDateRangeISOs()
      const timesEmailsAndEvents = await this.startInitialOutlookSync({ startISO, endISO })
      await this.startInitialDelegateAccessSync()
      const timeTimesheet = await this.startInitialTimesheetsSync({ startISO, endISO })
      await this.updateView()
      const initialSyncEndTime = moment()
      setLastSyncUpdate(initialSyncEndTime.toISOString(true))

      if (!timesEmailsAndEvents || !timeTimesheet) {
        return
      }

      try {
        const { emailsLoadTime, calendarEventsLoadTime } = timesEmailsAndEvents
        const { timesheetDataLoadTime } = timeTimesheet
        await intialSyncTimeService.analytics_initialSyncTime_track({
          client: this.props.client,
          calendarEventsLoadTime,
          emailsLoadTime,
          timesheetDataLoadTime,
        })
      } catch (e) {
        logErrorMessage(e)
      }
    } catch (e) {
      logError(e, 'startInitialSync')
    }

    this._handleInitialSyncUpdate({ inProgress: false, done: true })
  }

  setInitialCurrentEditableEntry = (cb, shouldClearEntry) => {
    const stateCurrentEditableEntry = this.state.currentEditableEntry
    const selectedDayEntries = this.state.timeEntries.filter(entryOrOperation => {
      const entry = getEntryOrOperation(entryOrOperation)
      const { start, end } = entry

      const isEntryInSelectedDate =
        timeUtils.areSameDay(start, this.state.selectedDate) &&
        timeUtils.areSameDay(end, this.state.selectedDate)
      return isEntryInSelectedDate
    })

    const defaultFreeGap = {
      start: moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ').set({ h: 8, m: 0 }).toISOString(),
      end: moment(this.state.selectedDate, 'ddd MMM D YYYY HH:mm:ss ZZ').set({ h: 8, m: 30 }).toISOString()
    }

    const freeGap = getFreeGap({ selectedDate: this.state.selectedDate, selectedDayEntries }) ?? defaultFreeGap

    const entry = buildPlaceholderEntry({
      resourceId: 1,
      start: freeGap.start,
      end: freeGap.end,
    })

    const defaultProjectId = _.get(this.state.userSettings, 'defaultProjectId', null)
    const currentEditableEntry = buildCurrentEditableEntry({
      entry,
      projects: this.state.projects,
      defaultProjectId,
    })

    const entryToState =
      _.isEqual(stateCurrentEditableEntry, {}) || shouldClearEntry
        ? {
            ...currentEditableEntry,
            hideOnWeekPeriod: true,
          }
        : {
            ...stateCurrentEditableEntry,
            start: new Date(freeGap.start),
            end: new Date(freeGap.end),
          }

    this.setState(
      {
        currentEditableEntry: entryToState,
        initialCurrentEditableEntry: entryToState,
      },
      cb,
    )
  }

  initialSyncHandler = fn => async (stepName, message) => {
    try {
      this._handleInitialSyncUpdate({
        syncItems: {
          [stepName]: { inProgress: true, done: false, text: message },
        },
      })
      await fn()
      this._handleInitialSyncUpdate({
        syncItems: {
          [stepName]: { inProgress: false, done: true, error: false },
        },
      })
    } catch (e) {
      this._handleInitialSyncUpdate({
        syncItems: {
          [stepName]: { inProgress: false, done: false, error: true },
        },
      })
      logError(e, 'initialSyncHandler')
    }
  }

  startInitialOutlookSync = async ({ startISO, endISO }) => {
    try {
      const syncData = {
        selectedDelegateId: this.state.selectedDelegateId,
        client: this.props.client,
      }

      const syncEvents = this.initialSyncHandler(() =>
        syncOutlookEvents({ ...syncData, startISO, endISO, isDelta: true, reSyncDelta: true }),
      )
      const syncEmails = this.initialSyncHandler(() =>
        syncOutlookEmails({ ...syncData, startISO, endISO, isDelta: true, reSyncDelta: true }),
      )
      const eventsStartTime = Date.now()
      await syncEvents('events', buildInitialSyncMessage('Calendar events', { startISO, endISO }))
      const eventsEndTime = Date.now()

      const emailsStartTime = Date.now()
      await syncEmails('emails', buildInitialSyncMessage('Emails', { startISO }))
      const emailsEndTime = Date.now()

      return {
        calendarEventsLoadTime: eventsEndTime - eventsStartTime,
        emailsLoadTime: emailsEndTime - emailsStartTime,
      }
    } catch (error) {
      logError(error)
    }
  }

  startInitialDelegateAccessSync = async () => {
    const syncData = {
      handleAppState: this.handleAppState,
      client: this.props.client,
      shouldRefresh: true,
    }
    return syncDelegateAccess(syncData).then()
  }

  startInitialTimesheetsSync = async ({ startISO, endISO }) => {
    try {
      const syncData = {
        selectedDelegateId: this.state.selectedDelegateId,
        userSettings: this.state.userSettings,
        localEntries: this.state.localEntries,
        handleAppState: this.handleAppState,
        client: this.props.client,
        getUIRefreshTimePeriod: this.getUIRefreshTimePeriod,
        handleUpdateTimesheetData: this._handleUpdateTimesheetData,
      }

      const syncTimesheetsFuncs = this.initialSyncHandler(async () => {
        await syncProjects(syncData)
        await syncTimeOffCodes(syncData)
        await syncTimesheets(syncData)
        await syncTimeEntries(syncData)
        await syncTimeOffEntries(syncData)
        await syncReminderSettings(syncData)
        await syncUserSettings(syncData)
        await syncAssignments(syncData)
        this.setInitialCurrentEditableEntry()
      })

      const timesheetStartTime = Date.now()
      await syncTimesheetsFuncs(
        'timesheets',
        buildInitialSyncMessage('Timesheet data', { startISO, endISO }),
      )
      const timesheetEndTime = Date.now()
      return {
        timesheetDataLoadTime: timesheetEndTime - timesheetStartTime,
      }
    } catch (error) {
      logError(error)
    }
  }

  getUIRefreshTimePeriod = (shouldIncludeYesterday = false) => {
    try {
      const hasToFetchAllWeekData = !this.isDayViewPeriodSelected()
      const selectedDayDate = moment(this.state.selectedDate)

      if (!hasToFetchAllWeekData) {
        const yesterdayStart = selectedDayDate
          .clone()
          .subtract(shouldIncludeYesterday ? 1 : 0, 'day')
          .startOf('day')
        const todayEnd = selectedDayDate.clone().endOf('day')

        return {
          startISO: parseStartDateAsUTC(yesterdayStart),
          endISO: parseEndDateAsUTC(todayEnd),
        }
      }

      const weekMonday = selectedDayDate
        .clone()
        .weekday(1)
        .subtract(1, 'day')
        .startOf('day')
      const weekSunday = selectedDayDate
        .clone()
        .weekday(7)
        .subtract(1, 'day')
        .endOf('day')

      return {
        startISO: parseStartDateAsUTC(weekMonday),
        endISO: parseEndDateAsUTC(weekSunday),
      }
    } catch (error) {
      logError(error)
    }
  }

  updateView = async () => {
    const syncData = {
      selectedDelegateId: this.state.selectedDelegateId,
      userSettings: this.state.userSettings,
      localEntries: this.state.localEntries,
      handleAppState: this.handleAppState,
      client: this.props.client,
      getUIRefreshTimePeriod: this.getUIRefreshTimePeriod,
      handleUpdateTimesheetData: this._handleUpdateTimesheetData,
      handleUpdateEmailData: this.handleUpdateEmailData,
      handleUpdateEventsData: this.handleUpdateEventsData,
    }

    const timesheetCount = await updateViewTimesheets(syncData)

    const timesheetDataCount = await updateViewTimesheetData(syncData)
    const unhandledEntriesCount = await updateUnhandledEntries(syncData)

    const emailCount = await updateViewOutlookEmails(syncData)
    const eventCount = await updateViewOutlookEvents(syncData)

    const timeoffCount = await updateViewTimeOffCodes(syncData)
    const projectCount = await updateViewProjects(syncData)

    const sumatory = _.sum([
      timesheetCount,
      timesheetDataCount,
      unhandledEntriesCount,
      emailCount,
      eventCount,
      timeoffCount,
      projectCount,
    ])
    log(`LS → UI → Everything being sent → ${sumatory}\n`)
  }

  setToInitialState = () => this.setState(initialState)

  _handleInitialSyncUpdate = initialSync => {
    const prevInitialSync = this.state.initialSync
    const shouldResetHasUserGotStarted = !prevInitialSync.inProgress && initialSync.inProgress

    this.setState(prevState => ({
      initialSync: _.merge({}, prevState.initialSync, initialSync),
      hasUserGotStarted: shouldResetHasUserGotStarted ? false : prevState.hasUserGotStarted,
    }))
  }

  handleUpdateEventsData = events => {
    this.setState({
      exchangeEvents: eventUtils.parseEventsDates(events),
    })
  }

  handleUpdateEmailData = emails => {
    this.setState({
      exchangeEmails: eventUtils.parseEmailDates(emails),
    })
  }

  _handleUpdateTimesheetData = data => {
    const { timeEntries, localEntries } = data
    const parsedLocalEntries = _.map(localEntries, entryOperation => {
      return {
        ...entryOperation,
        data: eventUtils.parseLocalEntryDatesAndSource(entryOperation.data),
      }
    })

    const localEntriesData = _.map(localEntries, 'data')
    this.setState({
      timeEntries: eventUtils.getClearedEntriesByLocalEntries(localEntriesData, timeEntries),
      localEntries: parsedLocalEntries,
    })
  }

  updateViewData = async () => {
    try {
      this.setState({ isFetchingTimesheetData: true })
      const { startISO, endISO } = this.getUIRefreshTimePeriod(true)

      if (this.props.isConnected) {
        const { startISO: tSheetStartISO, endISO: tSheetEndISO } = this.getUIRefreshTimePeriod(
          false,
        )
        const tSheetStartLocal = convertDateToLocalTimezone(tSheetStartISO)
        const tSheetEndLocal = convertDateToLocalTimezone(tSheetEndISO)

        const stateTimesheetsQuery = this.state.timesheets.filter(f => {
          return f.startDate >= tSheetStartLocal && f.startDate <= tSheetEndLocal
        })
        const diff = getDiffDaysCount(tSheetStartISO, tSheetEndISO)

        if (stateTimesheetsQuery.length < diff) {
          await timesheetService.assureTimesheetsExistLocally({
            client: this.props.client,
            startISO: tSheetStartISO,
            endISO: tSheetEndISO,
            startLocal: tSheetStartLocal,
            endLocal: tSheetEndLocal,
            selectedDelegateId: this.state.selectedDelegateId,
            timesheets: this.state.timesheets,
          })
        }

        const doesOutsideContainInside = timeUtils.doesTimePeriodContainsAnother(
          { startISO, endISO },
          getDefaultSyncDateRangeISOs(),
        )

        if (!doesOutsideContainInside) {
          await timeEntryService.assureTimeEntriesExistLocally({
            client: this.props.client,
            startISO,
            endISO,
            selectedDelegateId: this.state.selectedDelegateId,
          })
          await timeOffService.assurePTOsExistLocally({
            client: this.props.client,
            startISO,
            endISO,
            selectedDelegateId: this.state.selectedDelegateId,
          })
        }
      }

      const syncData = {
        selectedDelegateId: this.state.selectedDelegateId,
        userSettings: this.state.userSettings,
        localEntries: this.state.localEntries,
        handleAppState: this.handleAppState,
        client: this.props.client,
        getUIRefreshTimePeriod: this.getUIRefreshTimePeriod,
        handleUpdateTimesheetData: this._handleUpdateTimesheetData,
        handleUpdateEmailData: this.handleUpdateEmailData,
        handleUpdateEventsData: this.handleUpdateEventsData,
      }

      await updateViewTimesheets(syncData)
      await updateViewTimesheetData(syncData)
      await updateViewOutlookEmails(syncData)
      await updateViewOutlookEvents(syncData)

      this.setState({ isFetchingTimesheetData: false })

      if (!this.props.isConnected) return

      const doesOutsideContainInside = timeUtils.doesTimePeriodContainsAnother(
        { startISO, endISO },
        getDefaultSyncDateRangeISOs(),
      )

      if (!doesOutsideContainInside) {
        await syncOutlookEmails({ ...syncData, startISO, endISO })
        await syncOutlookEvents({ ...syncData, startISO, endISO })
      }
    } catch (e) {
      this.setState({ isFetchingTimesheetData: false })
    }
  }

  clearCurrentEditableEntry = cb => {
    this.setInitialCurrentEditableEntry(cb, true)
  }

  _handleInitialSyncRetry = () => {
    this.setState({
      numberOfInitialSyncRetries: this.state.numberOfInitialSyncRetries + 1,
    })
    return this.startInitialSync()
  }

  isDayViewPeriodSelected = () => this.state.viewPeriod === VIEW_PERIOD_DAY

  handleAppState = (newObj, asyncFn = () => {}) => {
    this.setState(newObj, asyncFn)
  }

  handleSelectedDelegateId = (selectedDelegateId, saveOnLocalStorage = false, extraProps = {}) =>
    this.setState({ ...extraProps, selectedDelegateId }, () => {
      if (saveOnLocalStorage) {
        window.localStorage.setItem('selectedDelegateId', selectedDelegateId)
      }
    })

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <DataSync
          initializeHeartBeat={this.props.initializeHeartBeat}
          isConnected={this.props.isConnected}
          auth0={this.props.auth0}
          client={this.props.client}
          io={this.props.io}
          loginSuccess={this.props.loginSuccess}
          isUserLoggedIn={this.props.isUserLoggedIn}
          hasUserGotStarted={this.state.hasUserGotStarted}
          getUIRefreshTimePeriod={this.getUIRefreshTimePeriod}
          handleAppState={this.handleAppState}
          handleUpdateTimesheetData={this._handleUpdateTimesheetData}
          setToInitialState={this.setToInitialState}
          handleUpdateEventsData={this.handleUpdateEventsData}
          handleUpdateEmailData={this.handleUpdateEmailData}
          updateViewData={this.updateViewData}
          selectedDelegateId={this.state.selectedDelegateId}
          reminderSettings={this.state.reminderSettings}
          isRecurrentSyncInProgress={this.state.isRecurrentSyncInProgress}
          userSettings={this.state.userSettings}
          localEntries={this.state.localEntries}
          setInitialCurrentEditableEntry={this.setInitialCurrentEditableEntry}
          handleSelectedDelegateId={this.handleSelectedDelegateId}
          startInitialDelegateAccessSync={this.startInitialDelegateAccessSync}
          setIsTimesheetLoading={isLoading => this.setState({ isTimesheetLoading: isLoading })}
        />
        <Application
          isConnected={this.props.isConnected}
          client={this.props.client}
          isUserLoggedIn={this.props.isUserLoggedIn}
          userInfo={this.props.userInfo}
          appState={this.state}
          handleAppState={this.handleAppState}
          handleInitialSyncRetry={this._handleInitialSyncRetry}
          startInitialSync={this.startInitialSync}
          updateViewData={this.updateViewData}
          updateView={this.updateView}
          getUIRefreshTimePeriod={this.getUIRefreshTimePeriod}
          modal={this.state.modal}
          isNoFreeGapModalOpen={this.state.isNoFreeGapModalOpen}
          numberOfInitialSyncRetries={this.state.numberOfInitialSyncRetries}
          initialSync={this.state.initialSync}
          events={this.state.events}
          timeEntries={this.state.timeEntries}
          localEntries={this.state.localEntries}
          selectedDate={this.state.selectedDate}
          timesheets={this.state.timesheets}
          csvUploadData={this.state.csvUploadData}
          viewPeriod={this.state.viewPeriod}
          unhandledEntries={this.state.unhandledEntries}
          userSettings={this.state.userSettings}
          selectedDelegateId={this.state.selectedDelegateId}
          projects={this.state.projects}
          currentEditableEntry={this.state.currentEditableEntry}
          timeOffEntries={this.state.timeOffEntries}
          delegateAccesses={this.state.delegateAccesses}
          view={this.state.view}
          exchangeEmails={this.state.exchangeEmails}
          exchangeEvents={this.state.exchangeEvents}
          isSubmitInProgress={this.state.isSubmitInProgress}
          mostRecentlyUsedProjectIds={this.state.mostRecentlyUsedProjectIds}
          isTimesheetLoading={this.state.isTimesheetLoading}
          timeOffCodes={this.state.timeOffCodes}
          isFetchingTimesheetData={this.state.isFetchingTimesheetData}
          reminderSettings={this.state.reminderSettings}
          isAllEventsButton={this.state.isAllEventsButton}
          isAddAllEntriesFromEventsModalOpen={this.state.isAddAllEntriesFromEventsModalOpen}
          areEventsBeingAdded={this.state.areEventsBeingAdded}
          successCsvInfoTotal={this.state.successCsvInfoTotal}
          failedCsvInfoTotal={this.state.failedCsvInfoTotal}
          unhandledEntriesInfo={this.state.unhandledEntriesInfo}
          csvActionButtonsBlocked={this.state.csvActionButtonsBlocked}
          isIssueReportModalFromMenuOpen={this.state.isIssueReportModalFromMenuOpen}
          isDelegateAccessModalFromMenuOpen={this.state.isDelegateAccessModalFromMenuOpen}
          hasUserGotStarted={this.state.hasUserGotStarted}
          isIssueReportModalFromSentryOpen={this.state.isIssueReportModalFromSentryOpen}
          sentryError={this.state.sentryError}
          assignments={this.state.assignments}
          clearCurrentEditableEntry={this.clearCurrentEditableEntry}
          setInitialCurrentEditableEntry={this.setInitialCurrentEditableEntry}
          initialCurrentEditableEntry={this.state.initialCurrentEditableEntry}
          handleSelectedDelegateId={this.handleSelectedDelegateId}
        />
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  io: PropTypes.object.isRequired,
  initializeHeartBeat: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  auth0: PropTypes.object.isRequired,
  loginSuccess: PropTypes.func.isRequired,
}

export default withAuth0(
  withApollo(withSocketIO(connect(mapStateToProps, mapDispatchToProps)(App))),
)
