import { createStructuredSelector } from 'reselect'
import { userInfo, isUserLoggedIn } from '../redux/selectors/auth'
import { isConnected } from '../redux/selectors/ping'
import { initializeHeartBeatThunk } from '../redux/thunks/ping'
import AuthRedux from '../redux/auth'

export const mapStateToProps = createStructuredSelector({
  isUserLoggedIn,
  userInfo,
  isConnected,
})

export const mapDispatchToProps = {
  loginSuccess: AuthRedux.loginSuccess,
  initializeHeartBeat: initializeHeartBeatThunk,
}
