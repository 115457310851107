import { requestMessages } from './utils/requestMessages'
import { convertMessageToGlimpseEmail } from '../../utils/events'

export const getMessages = async ({ client, params }) => {
  const {
    receivedAfter,
    receivedBefore,
    isDelta,
    reSyncDelta,
    folderType,
    selectType,
    isCache,
    selectedDelegateId,
  } = params
  const messages = await requestMessages({
    client,
    params: {
      receivedAfter,
      receivedBefore,
      isDelta,
      reSyncDelta,
      folderType,
      selectType,
      isCache,
      selectedDelegateId,
    },
  })

  const transformedMessages = getTransformedMessages(messages)
  return transformedMessages
}

const getTransformedMessages = messages => {
  return messages.reduce((glimpseMessages, deltaMessage) => {
    if (deltaMessage.deltaType !== `modify`) return glimpseMessages
    return [...glimpseMessages, convertMessageToGlimpseEmail(deltaMessage.message)]
  }, [])
}
