import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../common/Modal'
import ReminderSettingsForm from './Form'

const ReminderSettings = ({ isOpen, handleClose, reminderSettings, handleSetReminderSettings }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <ReminderSettingsForm
        reminderSettings={reminderSettings}
        handleSetReminderSettings={handleSetReminderSettings}
        handleModalClose={handleClose}
      />
    </Modal>
  )
}

ReminderSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
}

export default ReminderSettings
