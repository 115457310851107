import io from 'socket.io-client'
import config from '../../../config'

import SocketIOActions from './actions'

// React Redux
import store from '../../../redux/store'
import { logoutThunk } from '../../../redux/thunks/auth'
import { getLatestGlimpseToken } from '../../../redux/services/auth'

export default class SocketIOWrapper {
  constructor(apolloClient, auth0) {
    this.socket = null
    this.actions = null
    this.connecting = false
    this.disconnecting = false
    this.connected = false
    this.isAuthorized = false
    this.debug = true
    this.apolloClient = apolloClient
    this.auth0 = auth0
    this.socketHandlers = {
      eventsUpdated: null,
      emailsUpdated: null,
    }
  }

  connect = () => {
    this.socket = io(config.SOCKETIO_URL, {
      reconnection: true,
      reconnectionDelay: config.SOCKETIO_RECONNECTION_DELAY,
      transports: ['websocket', 'polling'],
    })
    this.connecting = true
    this.actions = new SocketIOActions(
      this.socket,
      this.apolloClient,
      this.auth0,
      this.socketHandlers,
      this.debug,
    )

    this.socket.on('connect', this.onConnect)
    this.socket.on('disconnect', this.onDisconnect)
    this.socket.on('connect_error', () => {
      this.connecting = false
    })
    this.socket.on('authorized', this.onAuthorized)
    this.socket.on('eventReceived', event => this.actions.eventReceived(event))
    this.socket.on('messageReceived', message => this.actions.messageReceived(message))
    this.socket.on('logout', message => this.actions.logout(message))
  }

  disconnect = () => {
    if (this.debug === true) console.log('IO', 'Disconnected!')
    if (this.socket === null) {
      return
    }
    this.socket.disconnect()
    this.disconnecting = true
  }

  onConnect = async () => {
    if (this.debug === true) console.log('IO', 'Connected!')
    this.connecting = false
    this.connected = true
    const glimpseApiToken = await getLatestGlimpseToken(this.auth0)
    if (glimpseApiToken) this.socket.emit('authorization', glimpseApiToken)
  }

  onAuthorized = authorized => {
    if (this.debug === true) console.log('IO', 'Authorized!')
    this.isAuthorized = authorized
    this.socket.emit('subscribeToNotifications')
  }

  onDisconnect = reason => {
    if (this.debug === true) console.log('IO', `Disconnected! (${reason})`)
    this.connected = false
    this.isAuthorized = false
    this.disconnecting = false
    this.socket.reconnectionDelay /= 2

    // Server disconnected because of failed authorization, logging out user to send valid token again
    if (reason === 'io server disconnect') {
      store.dispatch(logoutThunk())
    }
  }
}
