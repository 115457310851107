import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import config from '../../config'

export default props => (
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={config.AUTH0_GLIMPSE_AUDIENCE}
  >
    {props.children}
  </Auth0Provider>
)
