import { SEND_RESOURCE_CONSUMPTION } from './mutation'
import config from '../../../config'

const { VERSION } = config

export const sendResourceConsuptionReport = async (client, args) => {
  try {
    await client.mutate({
      mutation: SEND_RESOURCE_CONSUMPTION,
      variables: {
        ...args,
        appVersion: VERSION,
      },
    })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
