import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import ExpansionPanel from '../Header/ExpansionPanel'
import { ProjectTableWrapper } from './styles'
import BasicTable from '../Table'

class ProjectView extends React.PureComponent {
  groupAssignmentsByProject = () => {
    const { leadAssignments, projectAssignments, projects, people } = this.props
    const assignments = _.map(projectAssignments, assignment => {
      const findProject = _.find(projects, project => project._id === assignment.projectId)
      const findPerson = _.find(people, person => person._id === assignment.personId)
      return { ...assignment, project: findProject, person: findPerson }
    })
    const leadProjects = _.map(leadAssignments, assignment => {
      const findProject = _.find(projects, project => project._id === assignment.projectId)
      return _.get(findProject, 'projectCode')
    })
    return _.reduce(
      _.uniq(leadProjects),
      (accum, projectCode) => {
        const pAssignments = _.filter(
          assignments,
          assignment => _.get(assignment, 'project.projectCode') === projectCode,
        )
        return { ...accum, [projectCode]: pAssignments }
      },
      {},
    )
  }

  renderMultipleTables = () => {
    const groupedAssignments = this.groupAssignmentsByProject()
    const projectCodes = Object.keys(groupedAssignments)
    return (
      <ProjectTableWrapper>
        <ExpansionPanel
          data={_.map(projectCodes, projectCode => {
            return {
              index: projectCode,
              title: `${projectCode} (${groupedAssignments[projectCode].length})`,
              content: this.renderProjectTable(projectCode, groupedAssignments[projectCode]),
            }
          })}
        />
      </ProjectTableWrapper>
    )
  }

  renderProjectTable = (projectCode, assignments) => {
    const {
      shouldBeAbleToEdit,
      setCurrentEditableAssignment,
      currentEditableAssignment,
      isConnected,
    } = this.props
    if (assignments.length === 0) {
      return <span>There are no Assignments for the Project in the selected week. </span>
    }
    const columns = [
      'Person Name',
      'Project Code',
      'Start Date',
      'End Date',
      'Staffing Category',
      'Percent',
    ]
    const fields = [
      '_id',
      'person.fullName',
      'project.projectCode',
      'startDate',
      'endDate',
      'investment',
      'percent',
    ]
    const rows = _.map(assignments, assignment => {
      return _.reduce(
        fields,
        (accum, field) => {
          const value = _.get(assignment, field)
          if (field === 'startDate' || field === 'endDate') {
            return { ...accum, [field]: moment.utc(value).format('MM/DD/YYYY') }
          }
          return { ...accum, [field]: value }
        },
        {},
      )
    })
    return (
      <>
        <BasicTable
          rows={rows}
          columns={columns}
          shouldBeAbleToEdit={shouldBeAbleToEdit}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          currentEditableAssignment={currentEditableAssignment}
          isConnected={isConnected}
        />
      </>
    )
  }

  render = () => {
    return <>{this.renderMultipleTables()}</>
  }
}

ProjectView.propTypes = {
  projectAssignments: PropTypes.array.isRequired,
  leadAssignments: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  people: PropTypes.array.isRequired,
  shouldBeAbleToEdit: PropTypes.bool.isRequired,
  setCurrentEditableAssignment: PropTypes.func.isRequired,
  currentEditableAssignment: PropTypes.object,
  isConnected: PropTypes.bool.isRequired,
}

export default ProjectView
