import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import eventUtils from '../../../../utils/eventUtils'
import {
  COMMENT_PLACEHOLDER,
  getHeaderTitle,
  HEADER_PLACEHOLDER,
} from '../../../../utils/entriesUtils'
import {
  EventCommentBox,
  EventContainer,
  EventDuration,
  TimelineViewEventTitle,
  TimelineViewEventHeader,
} from '../TimelineViewEventStyle'
import TextLinesClamp from '../TextLinesClamp'
import CustomTooltip from '../../../../components/CustomTooltip'
import { getTooltipDates, getTooltipComment } from '../utils'
import { isAnOperation } from '../../../../models/entryOperation'
import { isEntryStartDateEqualToDate } from '../../utils'

class TimelineViewEvent extends React.PureComponent {
  containerRef = React.createRef()

  state = {
    containerHeight: 0,
  }

  componentDidMount() {
    if (this.isContainerHeightAvailable()) {
      this.updateContainerHeight()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isContainerHeightAvailable()) {
      if (this.containerRef.current.clientHeight !== prevState.containerHeight) {
        this.updateContainerHeight()
      }
    }
  }

  isContainerHeightAvailable = () => {
    return !!this.containerRef.current
  }

  updateContainerHeight() {
    this.setState({
      containerHeight: this.containerRef.current.clientHeight,
    })
  }

  render() {
    const { entry } = this.props
    const entryToUse = isAnOperation(entry) ? entry.data : entry
    const entryHeaderTitle = getHeaderTitle(
      entryToUse,
      this.props.projects,
      this.props.timeOffCodes,
    )

    const isPlaceholder = eventUtils.isPlaceholderEntry(entryToUse)

    const { hours, minutes } = eventUtils.getTimeEntryDurationUnits(entry)
    const tooltipText = (
      <div>
        <h4>{getTooltipDates(entryToUse)}</h4>
        <p data-testid={`timeline.calendarEntry.tooltip.${entry._id}`}>
          {getTooltipComment(entryToUse)}
        </p>
      </div>
    )

    return (
      <CustomTooltip
        isTimeline
        width="37vw"
        textAlign="left"
        minHeight="80px"
        padding="15px 10px"
        title={tooltipText}
        transition={Fade}
      >
        <EventContainer
          tabIndex={0}
          onDoubleClick={this.props.onDoubleClick}
          onKeyDown={this.props.handleEntryKeyDown}
          ref={this.containerRef}
          data-testid={`timeline.calendarEntry.${entry._id}`}
          isPlaceholder={isPlaceholder}
        >
          <TimelineViewEventHeader>
            <TimelineViewEventTitle
              id="entry-header-title"
              isPlaceholder={isPlaceholder}
              hasTitle={entryHeaderTitle !== HEADER_PLACEHOLDER}
            >
              {entryHeaderTitle}
            </TimelineViewEventTitle>
            {isEntryStartDateEqualToDate(entry, this.props.selectedDate) && (
              <EventDuration id="entry-duration">{`${hours} h ${minutes} min`}</EventDuration>
            )}
          </TimelineViewEventHeader>
          {entryToUse.originalDurationInHours > 0.35 && (
            <EventCommentBox>
              <TextLinesClamp
                containerHeight={this.state.containerHeight}
                lineHeight={12}
                offsetBottom={10}
                offsetTop={0}
              >
                <span
                  id="entry-comment"
                  style={{
                    fontWeight: isPlaceholder && !entryToUse.originalComment ? 'lighter' : 'normal',
                  }}
                >
                  {!isPlaceholder
                    ? entryToUse.originalComment
                    : entryToUse.originalComment || COMMENT_PLACEHOLDER}
                </span>
              </TextLinesClamp>
            </EventCommentBox>
          )}
        </EventContainer>
      </CustomTooltip>
    )
  }
}

TimelineViewEvent.propTypes = {
  entry: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  timeOffCodes: PropTypes.array.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  handleEntryKeyDown: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
}

export default TimelineViewEvent
