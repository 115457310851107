import styled from 'styled-components'

export const ButtonContainer = styled.button`
  border: none;
  background-color: transparent;
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  padding: 0;
  display: flex;
  align-content: center;
`
