import styled from 'styled-components'

export const ReportsPage = styled.div`
  heigth: 100%;
`

export const ReportsPageHeader = styled.div`
  position: relative;
  padding: 16px 0px;
  text-align: center;
`

export const GoBackContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
`

export const ReportPageTitle = styled.h1`
  margin: 0;
`
