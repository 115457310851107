import styled from 'styled-components'

export const ModalTitle = styled.h1`
  font-size: 16px;
  color: ${props => props.color || 'rgba(1, 1, 1, 0.8)'};
`

export const ModalContent = styled.div`
  padding: 0 30px 25px 30px;
  min-width: 600px;
`
