import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import ErrorIcon from '@material-ui/icons/Error'

export const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(5),
  },
}))

export const RemindMeLaterLabel = styled.span`
  font-size: 24px;
  font-weight: 600;
`

const Container = styled.div`
  padding: 8px 10px;
  text-align: center;
  background-color: ${props => (props.checked ? '#137cbd' : '#ffffff')};
`

const Label = styled.span`
  color: ${props => (props.checked ? '#ffffff' : '#000000')};
`

export const DayLabel = ({ checked, label }) => {
  return (
    <Container checked={checked}>
      <Label checked={checked}>{label}</Label>
    </Container>
  )
}

DayLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

export const DaysFormGroup = withStyles(() => {
  return {
    root: {
      flexDirection: 'row',
      border: '2px solid #ccc',
      margin: '30px 0 15px',
    },
  }
})(({ children, classes }) => <FormControl className={classes.root}>{children}</FormControl>)

export const FieldLabel = styled.span`
  display: inline-block;
  min-width: 40px;
  font-size: 14px;
  font-weight: 600;
`

export const TimePickerContainer = styled.div`
  display: inline-block;
  margin: 0 0 0 20px;
  padding-bottom: 10px;
`
export const FromToContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`

export const StyledButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#137cbd',
    '&:hover': {
      backgroundColor: '#0d5784',
    },
    marginTop: '20px',
    fontSize: '16px',
    fontWeight: 600,
    width: '45%',
  },
}))(Button)

export const StyledButtonsContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ReminderTimeInput = withStyles(() => ({
  root: {
    width: '50px',
    margin: '0 20px',
  },
}))(Input)

export const ReminderTimeFormControl = withStyles(() => ({
  root: {
    display: 'inline-block',
    marginTop: '20px',
  },
}))(FormControl)

const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`
const CenterContainer = styled.div`
  width: 80%;
`

export const StyledMainContainer = ({ children }) => {
  return (
    <MainContainer>
      <CenterContainer>{children}</CenterContainer>
    </MainContainer>
  )
}

const CenterItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ControlsContainer = styled.div`
  margin: 15px 0 20px;
  width: 70%;
`

export const StyledCenterItem = ({ children }) => {
  return (
    <CenterItem>
      <ControlsContainer>{children}</ControlsContainer>
    </CenterItem>
  )
}

export const StyledPermissionStatusMessage = styled.p`
  margin: 0;
  padding: 0;
  height: 20px;
  color: ${props => props.color};
  font-size: 12px;
  margin-top: 12px;
`
export const StyledPermissionStatusSpan = styled.span`
  padding: 0;
  margin: 0;
  height: 20px;
  font-size: 14px;
  color: ${props => props.color};
  font-weight: bold;
  margin-left: 5px;
  text-decoration: underline;
  transform: all 0.3s linear;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`
export const StyledInstructionsContainer = styled.div`
  width: 200px;
`

export const StyledInstructionsList = styled.ul``

export const StyledInstructionsListItem = styled.li``
export const StyledErrorMessageContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 60px;
`

export const StyledErrorMessage = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
  margin-left: 10px;
`
export const StyledErrorIcon = styled(ErrorIcon)`
  && {
    padding: 0;
    margin: 0;
  }
`
