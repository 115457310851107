import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 10px 20px 20px;
  width: 100%;
  height: 310px;
  border: 1px solid #c3c3c3;
  background-color: #fff;
`

export const Column = styled.div`
  width: ${({ width }) => width || '100%'};
  display: inline-table;
`

export const ProjectContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 90%;
    margin-right 10px;
  }
`
export const PersonContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 90%;
    margin-right 10px;
  }
`

export const DatesContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 90%;
    margin-right 10px;
  }
`

export const OtherFieldsContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 90%;
    margin-right 10px;
  }
`

export const ButtonsContainer = styled.div`
  width: 90%;
  position: relative;
  @media (min-width: 960px) {
    width: 90%;
    margin-right: 10px;
    margin-top: 10px;
    text-align: rigth;
  }
`

export const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const StyledTooltip = styled.span`
  font-size: 12px;
`
