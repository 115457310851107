import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import styled from 'styled-components'
import CustomTooltip from '../../components/CustomTooltip'

export const ButtonContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  text-transform: none;
`

export const TodayButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 12,
  },
})(props => <Button variant="text" color="primary" {...props} />)

export const MissingButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 12,
  },
})(props => (
  <CustomTooltip title="Go to earliest missing timesheet">
    <Button variant="text" color="secondary" {...props} />
  </CustomTooltip>
))

export const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999999;
`

export const CalendarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
`
