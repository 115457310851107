import React from 'react'
import PropTypes from 'prop-types'
import eventUtils from '../../../utils/eventUtils'
import { FUNNEL_ENTRIES_RESOURCE_ID } from '../../../constants/events'
import { APPROVED } from '../../../constants/timesheetStatus'

/*
 * Total the number of hours of events, then display an indicator based on 8 hours a day
 */
const ProgressBar = props => {
  const getProgressBarBackgroundColor = () => {
    if (!props.isConnected) return 'gray'
    if (props.timesheet.approvalStatus === APPROVED) return 'green'

    return 'red'
  }

  const eventHours = props.events.reduce((total, e) => {
    if (e.resourceId !== FUNNEL_ENTRIES_RESOURCE_ID) return total
    return total + eventUtils.getHoursForEventOnDate(e, props.date)
  }, 0)

  const width = Math.min(eventHours / 8, 1)
  const progressBarBackgroundColor = getProgressBarBackgroundColor()

  return (
    <div className="progress-bar">
      {props.timesheet && (
        <span
          className="dot"
          data-testid="progressBarColor"
          style={{
            backgroundColor: progressBarBackgroundColor,
            width: `${width * 100}%`,
          }}
        />
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  date: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  timesheet: PropTypes.object.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default ProgressBar
