import { useState } from 'react'

export const useCheckboxState = unhandledDates => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const handleCheck = e => {
    const newSelection = e.target.value
    if (selectedCheckboxes.includes(newSelection)) {
      setSelectedCheckboxes(selectedCheckboxes.filter(s => s !== newSelection))
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, newSelection])
    }
  }
  const clearCheckboxState = () => {
    setSelectedCheckboxes([])
  }
  const handleSelectAll = () => {
    if (unhandledDates.length === selectedCheckboxes.length) {
      setSelectedCheckboxes([])
      return
    }
    setSelectedCheckboxes(unhandledDates.map(e => e.toString()))
  }
  return [selectedCheckboxes, handleCheck, clearCheckboxState, handleSelectAll]
}
