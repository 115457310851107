import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Mail from '@material-ui/icons/Mail'
import { Fade } from '@material-ui/core'
import { EmailSubject, EmailHour, EmailBody } from '../TimelineViewEventStyle'

import CustomTooltip from '../../../../components/CustomTooltip'
import timeUtils from '../../../../utils/timeUtils'

class TimelineViewEmailEvent extends Component {
  state = {
    emailsContainerWidth: null,
  }

  emailsContainerRef = React.createRef()

  constructor(props) {
    super(props)
    this._setCurrentSelectedEvent = _.debounce(this.props.setCurrentSelectedEvent, 500)
  }

  componentDidMount() {
    this.updateContainerWidth()
    this.setWindowResizeListener()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isContainerWidthAvailable()) {
      if (this.emailsContainerRef.current.clientWidth !== prevState.emailsContainerWidth) {
        this.updateContainerWidth()
      }
    }
  }

  componentWillUnmount() {
    this.removeWindowResizeListener()
  }

  updateContainerWidth = () => {
    if (this.isContainerWidthAvailable()) {
      this.setState({
        emailsContainerWidth: this.emailsContainerRef.current.clientWidth,
      })
    }
  }

  isContainerWidthAvailable = () => {
    return !!this.emailsContainerRef.current
  }

  setWindowResizeListener = () => {
    window.addEventListener('resize', this.updateContainerWidth)
  }

  removeWindowResizeListener = () => {
    window.removeEventListener('resize', this.updateContainerWidth)
  }

  trimSubject = email => {
    const subject = _.get(email, 'subject', '') || ''
    const transformed = subject.length > 40 ? `${subject.substr(0, 40)}...` : subject
    return transformed
  }

  renderTooltip = email => (
    <div>
      <EmailSubject>{_.capitalize(this.trimSubject(email))}</EmailSubject>
      <EmailHour>{timeUtils.standardTimeFormat(email.dateTimeSent)}</EmailHour>
      <br />
      <EmailBody>{_.capitalize(email.bodyPreview)}</EmailBody>
    </div>
  )

  renderSingleEmail = (email, isLocked) => {
    const selectedEmail = isLocked ? null : email
    return (
      <Mail
        className={`email-envelope-icon ${isLocked ? 'used' : ''}`}
        onMouseOver={() => this.props.setEmail(selectedEmail)}
        onFocus
        onClick={() => this._setCurrentSelectedEvent(email.id, 1)}
      />
    )
  }

  renderTruncatedEmails = emailsCount => {
    return (
      <button
        type="button"
        className="email-icon-truncated"
        onClick={this.props.handleSetPageViewList}
      >
        <Mail color="disabled" className="email-envelope-icon" />
        <span className="email-quantity">+{emailsCount}</span>
      </button>
    )
  }

  renderEmails() {
    const { event } = this.props
    const emails = _.get(event, 'emails', [])
    const emailsCount = _.size(emails)
    const emailIconWidth = 19
    const visibleEmailsCount = Math.floor(this.state.emailsContainerWidth / emailIconWidth) * 2
    const isEmailsMoreThenCanShow = emailsCount > visibleEmailsCount
    const hiddenEmailsCount = emailsCount - visibleEmailsCount

    return _.map(emails, (email, index) => {
      const isLastVisibleEmail = index === visibleEmailsCount - 1
      const isTruncatedEmail = isEmailsMoreThenCanShow && isLastVisibleEmail
      const isLocked = this.props.isLockedEvent(email)
      return (
        <CustomTooltip
          isEmail
          width="37vw"
          textAlign="left"
          minHeight="80px"
          padding="15px 15px"
          transition={Fade}
          key={email._id}
          title={this.renderTooltip(email)}
        >
          {isTruncatedEmail
            ? this.renderTruncatedEmails(hiddenEmailsCount)
            : this.renderSingleEmail(email, isLocked)}
        </CustomTooltip>
      )
    })
  }

  render() {
    return <div ref={this.emailsContainerRef}>{this.renderEmails()}</div>
  }
}

TimelineViewEmailEvent.propTypes = {
  event: PropTypes.object.isRequired,
  handleSetPageViewList: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setCurrentSelectedEvent: PropTypes.func.isRequired,
  isLockedEvent: PropTypes.func.isRequired,
}

export default TimelineViewEmailEvent
