const timesheet = {
  NONBILLABLE: 'NonBillable',
  BILLABLE: 'ProjectRate',
  USERRATE: 'UserOverrideRate',
  ROLERATE: 'RoleRate',
  DEPARTMENTRATE: 'DepartmentOverrideRate',
}

const project = {
  NONBILLABLE: 'AllowNonBillable',
  BILLABLE: 'AllowBillable',
  BOTH: 'AllowBoth',
}

const billingOptions = {
  NONBILLABLE: 'Non-billable',
  BILLABLE: 'Billable',
  BOTH: 'Both',
}

module.exports = {
  timesheet,
  project,
  billingOptions,
}
