import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import Modal from '@material-ui/core/Modal'

import { useStyles } from './style'

export default function CsvLoaderModal({ csvUploadData }) {
  const classes = useStyles()
  const percentage = (csvUploadData.csvUploadCount * 100) / csvUploadData.csvsToUploadTotal || 0
  return (
    <Modal open={csvUploadData.isCSVUploadInProgress}>
      <div className={classes.root}>
        <div className={classes.loaderContainer}>
          <div className={classes.loaderBarContainer}>
            <LinearProgress variant="determinate" value={percentage} />
          </div>
          <span className={classes.text}>
            Uploading {csvUploadData.csvUploadCount} / {csvUploadData.csvsToUploadTotal} time
            entries
          </span>
        </div>
      </div>
    </Modal>
  )
}

CsvLoaderModal.propTypes = {
  csvUploadData: PropTypes.shape({
    isCSVUploadInProgress: PropTypes.bool,
    csvsToUploadTotal: PropTypes.number,
    csvUploadCount: PropTypes.number,
  }).isRequired,
}
