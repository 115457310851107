import moment from 'moment'
import _ from 'lodash'
import timeUtils from '../../utils/timeUtils'

export const getUnhandledEntriesInfoByTimesheet = groupedUnhandledEntries => {
  const unhandledEntriesInfoByTimesheet = _.map(groupedUnhandledEntries, e => ({
    date: e[0].start,
    start: moment.utc(e[0].start).format('MM/DD/YY'),
    durationInHours: _.sumBy(e, d => Number(d.originalDurationInHours)),
    count: e.length,
  }))
  return _.orderBy(unhandledEntriesInfoByTimesheet, [e => new Date(e.date)], ['asc'])
}

export const getUnhandledTotalInfo = unhandledEntriesInfoByTimesheet => ({
  count: _.sumBy(unhandledEntriesInfoByTimesheet, 'count'),
  durationInHours: _.sumBy(unhandledEntriesInfoByTimesheet, 'durationInHours'),
  timesheetsCount: unhandledEntriesInfoByTimesheet.length,
})

export const getSuccessSubtitle = successCsvInfoTotal => {
  const count = _.get(successCsvInfoTotal, 'count', 0)
  const timesheetCount = _.get(successCsvInfoTotal, 'timesheetsCount', 0)
  const totalDurationInMiliseconds = _.get(successCsvInfoTotal, 'durationInMiliseconds', 0)

  const entryWord = count > 1 ? 'entries' : 'entry'
  const timesheetWord = timesheetCount > 1 ? 'timesheets' : 'timesheet'

  return `You uploaded ${count} ${entryWord} for ${timesheetCount} ${timesheetWord} (${timeUtils.getPrettyDuration(
    totalDurationInMiliseconds,
  )})`
}

export const getResolveSubtitle = unhandledTotalInfo => {
  const resolveCsvInfoTotalCount = _.get(unhandledTotalInfo, 'count', 0)
  const resolveCsvInfoTotalTimesheetCount = _.get(unhandledTotalInfo, 'timesheetsCount', 0)

  const resolveEntryWord = resolveCsvInfoTotalCount > 1 ? 'entries were' : 'entry was'
  const resolveTimesheetWord = resolveCsvInfoTotalTimesheetCount > 1 ? 'timesheets' : 'timesheet'
  const resolveSubtitle = `${resolveCsvInfoTotalCount} ${resolveEntryWord} uploaded to ${resolveCsvInfoTotalTimesheetCount} submitted ${resolveTimesheetWord}`
  return resolveSubtitle
}

export const getFailSubtitle = failedCsvInfoTotal => {
  const failedCsvInfoTotalCount = _.get(failedCsvInfoTotal, 'count', 0)

  const failEntryWord = failedCsvInfoTotalCount > 1 ? 'entries' : 'entry'
  const failSubtitle = `${failedCsvInfoTotalCount} ${failEntryWord} could not be uploaded.`
  return failSubtitle
}
