import { requestEvents } from '../../events/service/utils/requestEvents'
import { requestMessages } from '../../messages/service/utils/requestMessages'
import processEventDeltas from './utils/processEventDeltas'
import processMessageDeltas from './utils/processMessageDeltas'

export const getEventDeltas = async ({ client, from, to }) => {
  const eventDeltas = await requestEvents({
    client,
    params: { from, to, isDelta: true, reSyncDelta: false },
  })

  const events = await processEventDeltas(client, eventDeltas)
  return events
}

export const getMessageDeltas = async ({ client, folderType, selectType }) => {
  const messageDeltas = await requestMessages({
    client,
    params: { isDelta: true, reSyncDelta: false, folderType, selectType },
  })

  return processMessageDeltas(messageDeltas)
}
