import Auth from '../auth'

import * as Sentry from '../../utils/sentry'
import { logError } from '../../utils/logger'

import { loginService, deleteAllData, getAndSetDataFromToken } from '../services/auth'

import config from '../../config'

import ipcRenderer from '../../ipcRenderer'

const { IS_WEB, IS_TEST_E2E } = config

export const loginThunk = (auth0Client, client, history) => async dispatch => {
  dispatch(Auth.loginRequest())
  try {
    if (IS_WEB && IS_TEST_E2E === false) {
      const userInfo = await loginService(auth0Client, client, false)
      if (userInfo) dispatch(Auth.loginSuccess(userInfo, false))
      Sentry.setContext()
      history.push('/welcome')
      return
    }
    const token = IS_TEST_E2E
      ? window.localStorage.getItem('cypressAccessToken')
      : await ipcRenderer.invoke('auth0Login')
    const { person, picture } = await getAndSetDataFromToken(token, client)
    const user = { ...person, picture }
    if (user) dispatch(Auth.loginSuccess(user, false))
    Sentry.setContext()
    history.push('/welcome')
  } catch (error) {
    logError(error, 'redux > thunks > auth > loginThunk > catch: ')
    dispatch(Auth.loginFail(error.message))
    history.push('/error')
  }
  return null
}

export const logoutThunk = () => async dispatch => {
  dispatch(Auth.logoutRequest())
  await deleteAllData()
  if (ipcRenderer) {
    await ipcRenderer.send('delete-offline-entries')
    await ipcRenderer.send('auth0-logout')
  }
  dispatch(Auth.logoutSuccess())
}
