import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'

import timeUtils from '../../utils/timeUtils'

import { getLastSyncUpdate } from '../../server/store'

import {
  Container,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  Date,
  OutsideBodyContainer,
  InnerBodyContainer,
  BodyTitle,
  BodyDescription,
  BodyParagraph,
  InformationIcon,
  IconContainer,
  FailedSync,
} from './style'

const OfflineModeMessage = () => {
  const [isBodyVisible, setBodyVisible] = useState(false)
  const [lastUpdate, setLastUpdate] = useState(null)

  useEffect(() => setLastUpdate(getLastSyncUpdate()), [])

  const toggleMessageVisibility = () => setBodyVisible(prev => !prev)
  const prettifyLastUpdate = dateString => timeUtils.fullPrettyDateFormat(dateString)

  return (
    <Container data-testid="offlineBanner">
      <Header
        onMouseOver={toggleMessageVisibility}
        onMouseLeave={toggleMessageVisibility}
        onFocus={toggleMessageVisibility}
        onBlur={toggleMessageVisibility}
        data-testid="offlineBannerHeader"
      >
        <IconContainer>
          <InformationIcon />
        </IconContainer>
        <HeaderTitle>No internet connection</HeaderTitle>
        {lastUpdate ? (
          <>
            <HeaderSubtitle>Last updated</HeaderSubtitle>
            <Date>{prettifyLastUpdate(lastUpdate)}</Date>
          </>
        ) : (
          <FailedSync>Sync has failed</FailedSync>
        )}
      </Header>
      <OutsideBodyContainer>
        <Collapse in={isBodyVisible}>
          <InnerBodyContainer data-testid="offlineBannerBody">
            <BodyTitle>Work Offline!</BodyTitle>
            <BodyDescription>
              <BodyParagraph>
                You can create, retrieve, update and delete time entries while offline for
                timesheets that have not been submitted yet.
              </BodyParagraph>
              <BodyParagraph>
                Time entries created at this time will be available to save and submit once the
                network is restored.
              </BodyParagraph>
            </BodyDescription>
          </InnerBodyContainer>
        </Collapse>
      </OutsideBodyContainer>
    </Container>
  )
}

export default OfflineModeMessage
