import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { GET_DELEGATE_ACCESS_GIVEN } from '../../entities/delegateAccess/service/query'
import config from '../../config'

const { RECURRINC_SYNC_SYNC_INTERVAL } = config

const useSurrogateAccess = () => {
  const { isUserLoggedIn } = useSelector(state => state.auth)
  const { data, error, loading, refetch } = useQuery(GET_DELEGATE_ACCESS_GIVEN, {
    pollInterval: RECURRINC_SYNC_SYNC_INTERVAL,
    skip: !isUserLoggedIn,
  })

  return { data: data && data.delegateAccess_getGiven, error, loading, refetch }
}

export default useSurrogateAccess
