import styled from 'styled-components'

export const Item = styled.div`
  cursor: pointer;
  padding: 10px 6px;
  display: flex;
  background-color: ${props => (props.isOpen ? '#2196f3' : '#eee')};
  color: ${props => (props.isOpen ? 'white' : '#2196f3')};
  &: hover {
    background-color: #2196f3;
    color: white;
    transition: background-color 1s ease-out;
  }
`

export const ItemTitle = styled.span`
  font-size: 14px;
  margin: 0 0 0 8px;
`

export const ItemContent = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  width: 100%;
  margin: 20px auto;
`
