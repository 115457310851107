import config from '../../../config'

import { SEND_ISSUE_REPORT } from './query'

const { VERSION, IS_WEB } = config

export const sendIssueReport = async ({ client, report, selectedDate, viewPeriod }) => {
  return client.mutate({
    mutation: SEND_ISSUE_REPORT,
    variables: {
      version: VERSION,
      isWeb: IS_WEB,
      report,
      selectedDateWhenReporting: selectedDate,
      viewPeriodWhenReporting: viewPeriod,
    },
  })
}
