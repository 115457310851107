import React from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from '../ConfirmationModal'

const FinishModal = props => {
  return (
    <ConfirmationModal
      title="Thanks for sharing the details"
      titleColor="primary"
      subtitle="Your issue has been reported to the support team."
      isOpen={props.isOpen}
      actionButtons={[
        {
          label: 'OK',
          handler: props.onFinish,
          color: 'primary',
        },
      ]}
    />
  )
}

FinishModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default FinishModal
