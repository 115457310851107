import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import moment from 'moment'

import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'

import { parseInnerTextFromElement } from '../../events/utils'

import {
  Event,
  EventDescription,
  Observation,
  ObservationUser,
  AvatarsWrapper,
  EventDates,
  ItalicSpan,
  EventSubtitle,
} from './style'

import { getNameInitials } from '../../../../App/utils'

const BROKEN_IMAGE_URL = '/broken-image.jpg'

const EventComment = ({ event, projects }) => {
  const { title, start, end, attendees, bodyPreview, subject } = event

  const getDescriptionFromHTML = () => {
    if (!bodyPreview) return null
    const bodyElements = ReactHtmlParser(bodyPreview)
    const elementInnerText = parseInnerTextFromElement(bodyElements)
    if (!elementInnerText) return null
    return (
      <>
        <br />
        <EventDescription>{elementInnerText}</EventDescription>
      </>
    )
  }

  const getFirstComment = () => {
    const areThereEventAttendees = attendees && attendees.length
    if (!areThereEventAttendees) return null

    const attendeesWithComments = attendees.filter(attendee => !_.isEmpty(attendee.comment))

    if (!attendeesWithComments.length)
      return (
        <>
          <br />
          <ItalicSpan>There are no comments.</ItalicSpan>
        </>
      )
    const { emailAddress, comment } = attendeesWithComments[0]
    const name = emailAddress.name
    return (
      <>
        <br />
        <Observation>
          <ObservationUser>{`${name}: `}</ObservationUser>
          {comment}
        </Observation>
      </>
    )
  }

  const getAvatars = () => {
    const areThereEventAttendees = attendees && attendees.length
    if (!areThereEventAttendees) return null

    return (
      <AvatarsWrapper>
        <AvatarGroup max={4}>
          {attendees.map(attendee => {
            const { emailAddress, src: imageUrl } = attendee
            const name = emailAddress.name
            return (
              <Avatar key={name} alt={name} src={imageUrl || BROKEN_IMAGE_URL}>
                {getNameInitials(name)}
              </Avatar>
            )
          })}
        </AvatarGroup>
      </AvatarsWrapper>
    )
  }

  const getProjectName = () => {
    const projectObject = _.find(projects, { _id: event.projectId })
    return _.get(projectObject, 'name', null)
  }

  const getSubtitle = projectName => {
    if (!projectName) return null
    return (
      <>
        <br />
        <EventSubtitle>{subject}</EventSubtitle>
      </>
    )
  }
  const projectName = getProjectName()
  const updatedTitle = projectName ? `${projectName}` : title
  return (
    <>
      <Event>{updatedTitle}</Event>
      <EventDates>{`${moment(start).format('LT')} - ${moment(end).format('LT')}`}</EventDates>
      <br />
      {getSubtitle(projectName)}
      {getDescriptionFromHTML()}
      {getFirstComment()}
      {getAvatars()}
    </>
  )
}

EventComment.propTypes = {
  event: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
}

export default EventComment
