import _ from 'lodash'
import { calcIsUserLoggedIn } from '../../../App/utils'
import { log, logError, logErrorMessage } from '../../../utils/logger'

import * as deltaService from '../../../entities/outlook/deltas/service'

import Email from '../../../server/models/email'
import Event from '../../../server/models/event'

import { logoutThunk } from '../../../redux/thunks/auth'
import store from '../../../redux/store'

import { getDefaultSyncDateRangeISOs } from '../../../server/utils/date'

export default class SocketIOActions {
  constructor(socket, apolloClient, auth0, socketHandlers) {
    this.socket = socket
    this.apolloClient = apolloClient
    this.socketHandlers = socketHandlers
    this.auth0 = auth0
    this.logoutThunk = logoutThunk
  }

  safeSync = async syncFn => {
    try {
      const isUserLoggedIn = await calcIsUserLoggedIn(this.auth0)
      if (!isUserLoggedIn) return
      const result = await syncFn()
      return result
    } catch (e) {
      logError(e, 'safeSyncSocket')
    }
  }

  async eventReceived() {
    const haveEventsChanged = await this.safeSync(async () => {
      return this.syncOutlookEventDeltas()
    })
    if (this.socketHandlers.eventsUpdated !== null) {
      await this.socketHandlers.eventsUpdated(haveEventsChanged)
    }
  }

  async messageReceived() {
    const haveEmailsChanged = await this.safeSync(async () => {
      return this.syncOutlookMessageDeltas()
    })
    if (this.socketHandlers.emailsUpdated !== null) {
      await this.socketHandlers.emailsUpdated(haveEmailsChanged)
    }
  }

  async logout() {
    await store.dispatch(this.logoutThunk())
  }

  syncOutlookEventDeltas = async () => {
    try {
      const { startISO, endISO } = getDefaultSyncDateRangeISOs()

      const events = await deltaService.getEventDeltas({
        client: this.apolloClient,
        from: startISO,
        to: endISO,
      })

      const eventsToCreateCount = _.size(events.create)
      const eventsToModifyCount = _.size(events.modify)
      const eventsToDeleteCount = _.size(events.delete)

      const haveCalendarEventsChanged =
        eventsToCreateCount !== 0 || eventsToModifyCount !== 0 || eventsToDeleteCount !== 0

      log(
        `ES → LS → Event → C: ${eventsToCreateCount} U: ${eventsToModifyCount} D: ${eventsToDeleteCount}`,
      )

      if (!haveCalendarEventsChanged) {
        return haveCalendarEventsChanged
      }

      try {
        await Event.bulkDelete(events.delete.map(e => e._id))
        await Event.bulkAdd(events.create)
        await Event.bulkPut(events.modify)
      } catch (e) {
        logErrorMessage(
          e.message,
          'components/SocketIO/actions/actions.js > syncOutlookEventDeltas > events e:',
        )
      }

      return haveCalendarEventsChanged
    } catch (e) {
      logErrorMessage(
        e.message,
        'components/SocketIO/actions/actions.js > syncOutlookEventDeltas > e:',
      )
      return true
    }
  }

  syncOutlookMessageDeltas = async () => {
    try {
      const emails = await deltaService.getMessageDeltas({
        client: this.apolloClient,
        folderType: 0,
        selectType: 0,
      })

      const emailsToCreateCount = _.size(emails.create)
      const emailsToModifyCount = _.size(emails.modify)
      const emailsToDeleteCount = _.size(emails.delete)

      const haveEmailsChanged =
        emailsToCreateCount !== 0 || emailsToModifyCount !== 0 || emailsToDeleteCount !== 0

      log(
        `ES → LS → Email → C: ${emailsToCreateCount} U: ${emailsToModifyCount} D: ${emailsToDeleteCount}`,
      )

      if (!haveEmailsChanged) {
        return haveEmailsChanged
      }

      try {
        await Email.bulkDelete(emails.delete.map(e => e._id))
        await Email.bulkAdd(emails.create)
        await Email.bulkPut(emails.modify)
      } catch (e) {
        logErrorMessage(
          e.message,
          'components/SocketIO/actions/actions.js > syncOutlookMessageDeltas > emails e:',
        )
      }

      return haveEmailsChanged
    } catch (e) {
      logErrorMessage(
        e.message,
        'components/SocketIO/actions/actions.js > syncOutlookMessageDeltas > e:',
      )
      return true
    }
  }
}
