import { useEffect } from 'react'
import { useHistory } from 'react-router'

export const useAuthRedirect = ({ isUserLoggedIn, hasUserGotStarted, isInitialSyncInProgress }) => {
  const history = useHistory()

  const shouldRenderWelcomeScreen =
    isUserLoggedIn && (isInitialSyncInProgress || !hasUserGotStarted)

  const shouldRenderCalendarPage = isUserLoggedIn && !isInitialSyncInProgress && hasUserGotStarted

  useEffect(() => {
    if (shouldRenderWelcomeScreen) history.push('/welcome')
    if (shouldRenderCalendarPage) history.push('/')
  })
}
