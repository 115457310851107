import React from 'react'
import PropTypes from 'prop-types'

import { NotificationBarWrapper, NotificationMessage, WarningIcon } from './style'
import { hasLocalOperations } from '../../../../models/entryOperation'

const NotificationBarText = props => {
  return (
    <NotificationBarWrapper>
      <WarningIcon />
      <NotificationMessage>{props.text}</NotificationMessage>
    </NotificationBarWrapper>
  )
}

NotificationBarText.propTypes = {
  text: PropTypes.string.isRequired,
}

const NotificationBar = props => {
  if (hasLocalOperations(props.uiRefreshTimePeriod, props.localEntries) && props.isConnected)
    return (
      <NotificationBarText text="You have created entries offline, please save them before submitting your timesheet." />
    )
  if (!props.hasTimesheetUnassignedEntries && props.isDayViewPeriodSelected) return null
  if (!props.hasWeekTimesheetsUnassignedEntries && !props.isDayViewPeriodSelected) return null
  return <NotificationBarText text="Please assign a project to all entries in order to submit." />
}

NotificationBar.propTypes = {
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  localEntries: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

export default NotificationBar
