import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'
import { withAuth0 } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import SocketIOWrapper from './actions'
import { mapStateToProps, mapDispatchToProps } from '../../views/Auth/store'

export const SocketIOContext = React.createContext('socketio')
let io
const SocketIO = props => {
  if (typeof io === 'undefined') io = new SocketIOWrapper(props.client, props.auth0)

  if (props.isUserLoggedIn === true && io.connected === false && io.connecting === false) {
    io.connect()
  } else if (
    props.isUserLoggedIn === false &&
    io.connected === true &&
    io.disconnecting === false
  ) {
    io.disconnect()
  }

  return <SocketIOContext.Provider value={io}>{props.children}</SocketIOContext.Provider>
}

SocketIO.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  auth0: PropTypes.object.isRequired,
}

export const SocketIOProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(withAuth0(SocketIO)))

export const withSocketIO = Component => {
  return props => {
    return (
      <SocketIOContext.Consumer>
        {ioObj => {
          return <Component {...props} io={ioObj} />
        }}
      </SocketIOContext.Consumer>
    )
  }
}
