export const CREATION_WAY = {
  TIMELINE: 'TIMELINE',
  LIST: 'LIST',
  TABLE: 'TABLE',
  CSV: 'CSV',
  TIMELINE_ADD_ALL_BUTTON: 'TIMELINE ADD ALL BUTTON',
  TIMELINE_ADD_ALL: 'TIMELINE ADD ALL',
  LIST_ADD_ALL: 'LIST ADD ALL',
  TABLE_ADD_ALL: 'TABLE ADD ALL',
}

export const REFERENCE_TYPE = {
  CALENDAR: 'CALENDAR',
  MAIL: 'MAIL',
}
