import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import config from '../../config'
import { setLastSyncUpdate } from '../../server/store'
import {
  loginByReinstallingService,
  calcIsUserLoggedIn as calcIsUserLoggedIn2,
} from '../../redux/services/auth'

// Utils
import { log, logError } from '../../utils/logger'
import {
  calcIsUserLoggedIn as calcIsUserLoggedIn1,
  replaceErrorMessageDynamicData,
} from '../../App/utils'
import {
  checkNotificationFeature,
  askNotificationPermission,
  PERMISSION_DEFAULT,
  PERMISSION_DENIED,
  PERMISSION_GRANTED,
} from '../../utils/Notifications'
import {
  convertDateToLocalTimezone,
  getDefaultSyncDateRangeISOs,
  getDiffDaysCount,
} from '../../server/utils/date'
import { shouldShowNotificationNow } from './utils'
import * as Sentry from '../../utils/sentry'
import history from '../../utils/history'

// Constants
import { ERRORS_TO_FILTER } from '../../App/constants'

// Entities
import * as resourceConsumptionService from '../../entities/resourceConsumption/service'

// Models
import EntryAction from '../../server/models/entryAction'

// Sync Functions
import {
  syncTimesheets,
  syncTimeEntries,
  syncTimeOffEntries,
  syncProjects,
  syncTimeOffCodes,
  syncReminderSettings,
  syncDelegateAccess,
  syncUserSettings,
  syncOutlookEvents,
  syncOutlookEmails,
  syncAssignments,
} from './syncFunc'

// Update Functions
import {
  updateViewOutlookEvents,
  updateViewOutlookEmails,
  updateViewTimesheets,
  updateViewTimesheetData,
  updateUnhandledEntries,
  updateViewTimeOffCodes,
  updateViewProjects,
  updateViewAssignments,
} from './updateFunc'

import ipcRenderer from '../../ipcRenderer'
import * as timesheetService from '../../server/actions/timesheetAction'
import db from '../../server/db'

const { RECURRINC_SYNC_SYNC_INTERVAL, IS_WEB } = config

class DataSync extends React.Component {
  recurrentSyncInterval = null

  reminderInterval = null

  componentDidMount = async () => {
    this.props.initializeHeartBeat()
    this.recurrentSyncInterval = setInterval(this.tick, RECURRINC_SYNC_SYNC_INTERVAL)
    this.reminderInterval = setInterval(this.reminderTimerFunction, 1 * 60 * 1000)

    Sentry.init(this.openIssueReportModalFromSentry)
    Sentry.setContext()

    if (this.props.io.socketHandlers.eventsUpdated === null) {
      this.props.io.socketHandlers.eventsUpdated = () => this.handleEventsUpdatedBySocket()
    }
    if (this.props.io.socketHandlers.emailsUpdated === null) {
      this.props.io.socketHandlers.emailsUpdated = () => this.handleEmailsUpdatedBySocket()
    }

    const reloadSession = async () => {
      if (!IS_WEB && (await ipcRenderer.invoke('getshouldExecuteInitialSyncOnStart'))) {
        console.log('DataSync > reloadSession > entró')
        await ipcRenderer.invoke('setshouldExecuteInitialSyncOnStart', false)
        const storedUserInfo = await loginByReinstallingService()
        console.log('storedUserInfo', storedUserInfo)
        if (storedUserInfo) {
          this.props.loginSuccess(storedUserInfo)
          return { isUserLoggedIn: true, isRehidrate: false }
        }
        return {}
      }
      try {
        const { isLoggedIn, userInfo } = await calcIsUserLoggedIn2(this.props.auth0)
        if (isLoggedIn) {
          this.props.loginSuccess(userInfo)
          return { isUserLoggedIn: true, isRehidrate: true }
        }
        return {}
      } catch (e) {
        // SHOUDL WE DO THE TRY CATCH?
        return {}
      }
    }

    const { isUserLoggedIn = false, isRehidrate } = await reloadSession()

    console.log('DataSync > componentDidMount', { isUserLoggedIn, isRehidrate })

    if (isUserLoggedIn) {
      if (isRehidrate) {
        this.props.setIsTimesheetLoading(true)

        try {
          const selectedDelegateId = window
            ? window.localStorage.getItem('selectedDelegateId')
            : _.get(this.props, 'selectedDelegateId', null)

          if (selectedDelegateId) {
            this.props.handleSelectedDelegateId(selectedDelegateId)
          }

          const syncData = {
            client: this.props.client,
            selectedDelegateId,
            getUIRefreshTimePeriod: this.props.getUIRefreshTimePeriod,
            userSettings: this.props.userSettings,
            localEntries: this.props.localEntries,
            handleAppState: this.props.handleAppState,
            handleUpdateTimesheetData: this.props.handleUpdateTimesheetData,
            handleUpdateEmailData: this.props.handleUpdateEmailData,
            handleUpdateEventsData: this.props.handleUpdateEventsData,
          }

          const {
            startISO: tSheetStartISO,
            endISO: tSheetEndISO,
          } = this.props.getUIRefreshTimePeriod(false)
          const tSheetStartLocal = convertDateToLocalTimezone(tSheetStartISO)
          const tSheetEndLocal = convertDateToLocalTimezone(tSheetEndISO)

          const diff = getDiffDaysCount(tSheetStartISO, tSheetEndISO)
          await timesheetService.assureTimesheetsExistLocally({
            client: this.props.client,
            startISO: tSheetStartISO,
            endISO: tSheetEndISO,
            startLocal: tSheetStartLocal,
            endLocal: tSheetEndLocal,
            selectedDelegateId,
            diff,
          })
          await updateViewTimesheets(syncData)
          await updateViewTimesheetData(syncData)
          await updateUnhandledEntries(syncData)
          await updateViewTimeOffCodes(syncData)
          await updateViewProjects(syncData)

          await syncUserSettings(syncData)
          this.props.setInitialCurrentEditableEntry()

          await updateViewAssignments(syncData)

          const { startISO, endISO } = getDefaultSyncDateRangeISOs()
          await syncOutlookEvents({
            ...syncData,
            startISO,
            endISO,
            isDelta: true,
            reSyncDelta: true,
          })
          await syncOutlookEmails({
            ...syncData,
            startISO,
            endISO,
            isDelta: true,
            reSyncDelta: true,
          })

          await updateViewOutlookEmails(syncData)
          await updateViewOutlookEvents(syncData)
          await this.props.startInitialDelegateAccessSync()
        } catch (e) {
          logError(e)
        } finally {
          this.props.setIsTimesheetLoading(false)
        }
      } else {
        // WELCOME REDIRECTION WAS NOT WORKING PROPERLY
        history.push('/welcome')
        history.replace('/welcome')
      }
    }

    if (IS_WEB) return

    ipcRenderer.on('app.resourceConsumptionUpdate', this.handleResourceConsumptionUpdate)
    ipcRenderer.on('app.loadLocalEntries', this.handleLoadLocalEntries)
  }

  componentWillUnmount = () => {
    clearInterval(this.recurrentSyncInterval)
    clearInterval(this.reminderInterval)

    if (IS_WEB) return

    ipcRenderer.off('app.resourceConsumptionUpdate', this.handleResourceConsumptionUpdate)
    ipcRenderer.off('app.loadLocalEntries', this.handleLoadLocalEntries)
  }

  componentDidUpdate = async prevProps => {
    const { isUserLoggedIn, isConnected } = this.props

    // LISTENS TO LOGOUT
    if (!isUserLoggedIn && prevProps.isUserLoggedIn) {
      clearInterval(this.recurrentSyncInterval)
      this.props.setToInitialState()
    }

    // LISTENS TO CONNECTION CHANGE
    if (isConnected && !prevProps.isConnected) {
      this.props.updateViewData()
    }
  }

  handleResourceConsumptionUpdate = (sender, args) => {
    if (!this.props.isUserLoggedIn) return
    return resourceConsumptionService.sendResourceConsuptionReport(this.props.client, args)
  }

  handleLoadLocalEntries = async (event, args) => {
    const linvoDbOperations = args

    // avoid duplicate indexDB ids
    const localEntries = await EntryAction.toArray()
    const operationsToAdd = linvoDbOperations.filter(operation =>
      localEntries.some(entry => entry._id === operation._id),
    )

    if (!operationsToAdd.length) return
    await EntryAction.bulkAdd(operationsToAdd)
  }

  async handleEventsUpdatedBySocket() {
    await updateViewOutlookEvents({
      getUIRefreshTimePeriod: this.props.getUIRefreshTimePeriod,
      handleUpdateEventsData: this.props.handleUpdateEventsData,
    })
  }

  async handleEmailsUpdatedBySocket() {
    await updateViewOutlookEmails({
      getUIRefreshTimePeriod: this.props.getUIRefreshTimePeriod,
      handleUpdateEmailData: this.props.handleUpdateEmailData,
    })
  }

  reminderTimerFunction = async () => {
    if (!shouldShowNotificationNow(this.props.reminderSettings)) return

    if (!IS_WEB) return window.displayNotification("Don't forget to track time!")

    if (!checkNotificationFeature() || Notification.permission === PERMISSION_DENIED) return

    if (Notification.permission === PERMISSION_DEFAULT) {
      await askNotificationPermission()
      if (Notification.permission !== PERMISSION_GRANTED) return
    }

    const text = "Don't forget to track time!"

    const notification = new Notification('Glimpse', { body: text })

    return notification
  }

  tick = async () => {
    if (!this.props.hasUserGotStarted) {
      log('did not tick 1')
      return
    }
    if (this.props.isRecurrentSyncInProgress) {
      log('did not tick 2')
      return
    }
    if (!this.props.isConnected) {
      log('did not tick 3')
      return
    }
    await this.sync()
  }

  openIssueReportModalFromSentry = sentryError => {
    const errorMessage = replaceErrorMessageDynamicData(Sentry.getSentryErrorMessage(sentryError))
    const shouldErrorBeFiltered = ERRORS_TO_FILTER.includes(errorMessage)
    if (shouldErrorBeFiltered) return
    this.props.handleAppState({
      isIssueReportModalFromSentryOpen: true,
      sentryError,
    })
  }

  safeSync = async syncFn => {
    try {
      const isUserLoggedIn = await calcIsUserLoggedIn1(this.props.auth0)
      if (!isUserLoggedIn || !db.isOpen()) return
      const result = await syncFn()
      return result
    } catch (e) {
      logError(e, 'safeSyncApp')
    }
  }

  sync = async () => {
    try {
      this.props.handleAppState({
        isRecurrentSyncInProgress: true,
      })

      const syncStartTime = moment()

      log('----------------Start-of-Sync----------------')

      const syncData = {
        selectedDelegateId: this.props.selectedDelegateId,
        userSettings: this.props.userSettings,
        localEntries: this.props.localEntries,
        handleAppState: this.props.handleAppState,
        client: this.props.client,
        getUIRefreshTimePeriod: this.props.getUIRefreshTimePeriod,
        handleUpdateTimesheetData: this.props.handleUpdateTimesheetData,
      }

      const haveTimesheetsChanged = await this.safeSync(async () => {
        return syncTimesheets(syncData)
      })

      const haveTimeEntriesChanged = await this.safeSync(async () => {
        return syncTimeEntries(syncData)
      })

      const haveTimeOffEntriesChanged = await this.safeSync(async () => {
        return syncTimeOffEntries(syncData)
      })

      const haveProjectsChanged = await this.safeSync(async () => {
        return syncProjects(syncData)
      })

      const haveTimeOffCodesChanged = await this.safeSync(async () => {
        return syncTimeOffCodes(syncData)
      })

      const haveAssignmentsChanged = await this.safeSync(async () => {
        return syncAssignments(syncData)
      })

      await this.safeSync(async () => {
        return syncReminderSettings(syncData)
      })

      await this.safeSync(async () => {
        return syncDelegateAccess({ ...syncData, shouldRefresh: false })
      })

      await this.safeSync(async () => {
        return syncUserSettings(syncData)
      })

      this.props.handleAppState({
        isRecurrentSyncInProgress: false,
      })

      const syncEndTime = moment()
      setLastSyncUpdate(syncEndTime.toISOString(true))
      log(
        '----------Sync Completed in ',
        `${syncEndTime.diff(syncStartTime, 'seconds')}s`,
        '-----------',
      )

      return {
        haveTimeEntriesChanged,
        haveTimeOffEntriesChanged,
        haveTimesheetsChanged,
        haveProjectsChanged,
        haveTimeOffCodesChanged,
        haveAssignmentsChanged,
      }
    } catch (e) {
      this.props.handleAppState({
        isRecurrentSyncInProgress: false,
      })
    }
  }

  render() {
    return null
  }
}

DataSync.propTypes = {
  initializeHeartBeat: PropTypes.func.isRequired,
  hasUserGotStarted: PropTypes.bool,
  isConnected: PropTypes.bool.isRequired,
  auth0: PropTypes.object.isRequired,
  handleAppState: PropTypes.func.isRequired,
  getUIRefreshTimePeriod: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  handleUpdateTimesheetData: PropTypes.func.isRequired,
  io: PropTypes.object.isRequired,
  loginSuccess: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  setToInitialState: PropTypes.func.isRequired,
  handleUpdateEmailData: PropTypes.func.isRequired,
  handleUpdateEventsData: PropTypes.func.isRequired,
  updateViewData: PropTypes.func.isRequired,
  selectedDelegateId: PropTypes.string,
  reminderSettings: PropTypes.object,
  isRecurrentSyncInProgress: PropTypes.bool,
  userSettings: PropTypes.object,
  localEntries: PropTypes.array,
  setInitialCurrentEditableEntry: PropTypes.func.isRequired,
  handleSelectedDelegateId: PropTypes.func.isRequired,
  startInitialDelegateAccessSync: PropTypes.func.isRequired,
  setIsTimesheetLoading: PropTypes.func.isRequired,
}

export default DataSync
