import Handsontable from 'handsontable'
import Fuse from 'fuse.js'
import _ from 'lodash'

const filterProjectNames = (query, projects) => {
  const openProjects = _.filter(projects, p => p.isOpenForTimeEntries)
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['projectCode', 'name'],
  }
  const fuse = new Fuse(openProjects, options)
  return fuse.search(query).map(project => project.name)
}

export const genProjectEditor = projects =>
  class extends Handsontable.editors.DropdownEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {
      // Invoke the original method...
      super.prepare(row, col, prop, td, originalValue, cellProperties)
      // ...and then do some stuff specific to your CustomEditor
      this.selectedRow = row
    }

    queryChoices(query) {
      this.query = query
      const source = this.cellProperties.source
      if (!query) {
        this.rawChoices = source
        this.updateChoicesList(source)
        return
      }
      const filteredProjects = filterProjectNames(query, projects)
      this.rawChoices = filteredProjects
      this.updateChoicesList(filteredProjects)
    }

    updateChoicesList(choicesList) {
      super.updateChoicesList(choicesList)
    }

    // eslint-disable-next-line class-methods-use-this
    limitDropdownIfNeeded() {}

    // eslint-disable-next-line class-methods-use-this
    flipDropdownIfNeeded() {}
  }

const getProjectsNameByIds = (projects, ids) => {
  return _.map(
    _.filter(projects, project => _.includes(ids, project._id)),
    p => p.name,
  )
}

export const genProjectsList = (
  projects,
  userSettings,
  mostRecentlyUsedProjectIds,
  assignments,
) => {
  const openProjects = _.filter(projects, p => p.isOpenForTimeEntries)
  const allProjects = _.map(openProjects, p => p.name)

  const defaultProjectId = userSettings.defaultProjectId ? [userSettings.defaultProjectId] : []

  const assignmentProjects = getProjectsNameByIds(projects, _.map(assignments, 'projectId'))

  const defaultProjects = getProjectsNameByIds(projects, defaultProjectId)

  const favoriteProjects = getProjectsNameByIds(projects, userSettings.favoriteProjectIds)

  const recentlyUsedProjects = getProjectsNameByIds(projects, mostRecentlyUsedProjectIds)

  return [
    '<strong>Default projects</strong>',
    ...defaultProjects,
    '<strong>My projects</strong>',
    ...assignmentProjects,
    '<strong>Favorite projects</strong>',
    ...favoriteProjects,
    '<strong>Recently Used Projects</strong>',
    ...recentlyUsedProjects,
    '<strong>All projects</strong>',
    ...allProjects,
  ]
}
