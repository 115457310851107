/* eslint-disable no-console */
import * as Sentry from './sentry'

export const logError = (err, trace) => {
  if (trace) console.error(trace, err)
  Sentry.captureException(err)
}

export const logErrorMessage = (message, trace) => {
  if (trace) console.error(trace, message)
  else console.error(message)
}

export const log = (...args) => console.log(...args)

export const logWarn = console.warn
