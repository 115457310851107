import { useState, useRef, useEffect, useCallback } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { roundUpEnd } from './utils'
import { setRunningStartTime, getRunningStartTime } from '../../../../server/store'

const noop = () => {}

const useTimer = (initialStartTime, startTimeSetter = noop, currentEditableEntry) => {
  const [milliseconds, setMilliSeconds] = useState(0)
  const intervalRef = useRef()
  const startTime = useRef()

  const play = useCallback(() => {
    if (intervalRef.current) return
    if (!startTime.current) {
      startTime.current = new Date().getTime()
      startTimeSetter(startTime.current)
    }

    intervalRef.current = setInterval(() => {
      const deltaTime = new Date().getTime() - startTime.current
      setMilliSeconds(deltaTime)
    }, 200)
  }, [startTimeSetter])

  const stop = useCallback(() => {
    if (!intervalRef.current) return
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }, [])

  // Safely initiates the timer
  useEffect(() => {
    if (startTime.current) return noop
    if (initialStartTime) {
      startTime.current = initialStartTime
      play()
    }
  }, [initialStartTime, play])

  useEffect(() => {
    if (milliseconds !== 0 && _.isNil(intervalRef.current)) {
      startTime.current = null
      startTimeSetter(null)
      setMilliSeconds(0)
    }
  }, [milliseconds, startTimeSetter])

  return { milliseconds, play, stop, startTime: startTime.current }
}

const useThreeHoursAutoSaving = ({ entryCreationHandler, setInitialStartTime, isRunning }) => {
  useEffect(() => {
    if (isRunning) return noop
    const runningStartTime = getRunningStartTime()

    if (!runningStartTime) return noop

    const threeHoursInMs = moment.duration(3, 'hours').asMilliseconds()
    const nowAsMs = new Date().getTime()
    const currentDuration = nowAsMs - runningStartTime

    if (currentDuration > threeHoursInMs) {
      const threeHoursOverStartTime = runningStartTime + threeHoursInMs
      const selectedSlot = {
        start: new Date(runningStartTime),
        end: new Date(threeHoursOverStartTime),
      }
      setRunningStartTime(null)
      entryCreationHandler(selectedSlot)
      return noop
    }
    setInitialStartTime(runningStartTime)
  }, [entryCreationHandler, setInitialStartTime, isRunning])
}

export const useTimerWithEntryCreation = (entryCreationHandler, currentEditableEntry) => {
  const [initialStartTime, setInitialStartTime] = useState(null)
  const setLocalAndStateInitialTime = time => {
    setInitialStartTime(time)
    setRunningStartTime(time)
  }

  const { milliseconds, play, stop, startTime } = useTimer(
    initialStartTime,
    setLocalAndStateInitialTime,
    currentEditableEntry,
  )
  const isRunning = Boolean(milliseconds)

  useThreeHoursAutoSaving({
    entryCreationHandler,
    setInitialStartTime: setLocalAndStateInitialTime,
    isRunning,
  })

  const handleClick = () => {
    if (!isRunning) {
      play()
      return
    }
    const selectedSlot = {
      start: new Date(startTime),
      end: roundUpEnd(startTime),
    }
    entryCreationHandler(selectedSlot)
    stop()
  }
  return { milliseconds, isRunning, handleClick }
}
