import gql from 'graphql-tag'

export const SET_REMINDER_SETTINGS = gql`
  mutation(
    $shouldRemind: Boolean!
    $weekDays: WeekdayInput!
    $timeFrom: String!
    $timeTo: String!
    $periodInMinutes: Int!
  ) {
    setReminderSettings(
      shouldRemind: $shouldRemind
      weekDays: $weekDays
      timeFrom: $timeFrom
      timeTo: $timeTo
      periodInMinutes: $periodInMinutes
    )
  }
`
