import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
  },
  loaderBarContainer: {
    width: '100%',
  },
  text: {
    color: 'white',
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
}))
