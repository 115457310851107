import _ from 'lodash'

import Timesheet from '../models/timesheet'
import { convertTimesheetToLocalDate } from '../funnel/funnelUtils'
import { OPEN, APPROVED } from '../../constants/timesheetStatus'
import { logError } from '../../utils/logger'

import * as timesheetService from '../../entities/timesheet/service'

export const submitTimesheet = async ({ client, timesheet, selectedDelegateId }) => {
  try {
    const updatedTimesheet = await timesheetService.updateTimesheetApprovalStatus({
      client,
      repliconId: timesheet.repliconId,
      approvalStatus: APPROVED,
      selectedDelegateId,
    })

    const [transformedTimesheet] = convertTimesheetToLocalDate([updatedTimesheet])

    await Timesheet.put(transformedTimesheet)
    return transformedTimesheet
  } catch (e) {
    logError(e, 'actions > timesheetAction > submitTimesheet > e: ')
  }
}

export const reopenTimesheet = async ({ client, timesheet, selectedDelegateId }) => {
  try {
    const updatedTimesheet = await timesheetService.updateTimesheetApprovalStatus({
      client,
      repliconId: timesheet.repliconId,
      approvalStatus: OPEN,
      selectedDelegateId,
    })

    const [transformedTimesheet] = convertTimesheetToLocalDate([updatedTimesheet])
    await Timesheet.put(transformedTimesheet)
    return transformedTimesheet
  } catch (e) {
    logError(e, 'actions > timesheetAction > reopenTimesheet > e: ')
  }
}

export const assureTimesheetsExistLocally = async ({
  client,
  startISO,
  endISO,
  startLocal,
  endLocal,
  selectedDelegateId,
  diff,
}) => {
  try {
    const localTimesheets = await Timesheet.where('startDate')
      .between(startLocal, endLocal, true, true)
      .toArray()

    if (localTimesheets.length >= diff) return

    const timesheets = await timesheetService.fetchTimesheets({
      client,
      startDate: startISO,
      endDate: endISO,
      shouldCreateIfNotExist: true,
      selectedDelegateId,
    })
    const newTimesheetsToSave = convertTimesheetToLocalDate(timesheets)
    await Timesheet.bulkDelete(_.map(timesheets, timesheet => _.get(timesheet, '_id')))
    await Timesheet.bulkAdd(newTimesheetsToSave)
  } catch (e) {
    logError(e, 'actions/timesheetAction > assureTimesheetsExistLocally > e:')
  }
}
