import gql from 'graphql-tag'

const GET_EVENTS = gql`
  query(
    $startDateTime: String
    $endDateTime: String
    $top: Int
    $skip: Int
    $isCancelled: Boolean
    $isDelta: Boolean
    $deltaToken: String
    $nextDeltaLink: String
    $selectedDelegateId: String
  ) {
    getEvents(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      top: $top
      skip: $skip
      isCancelled: $isCancelled
      isDelta: $isDelta
      deltaToken: $deltaToken
      nextDeltaLink: $nextDeltaLink
      selectedDelegateId: $selectedDelegateId
    ) {
      deltaToken
      nextDeltaLink
      deltaEvents {
        id
        deltaType
        event {
          id
          calendar {
            id
          }
          projectId
          tagId
          isCancelled
          isAllDay
          bodyPreview
          body {
            contentType
            content
          }
          seriesMasterId
          attendees {
            status {
              response
            }
            emailAddress {
              name
              address
            }
          }
          responseStatus {
            response
            time
          }
          subject
          type
          start {
            dateTime
          }
          end {
            dateTime
          }
        }
      }
    }
  }
`

export default GET_EVENTS
