import gql from 'graphql-tag'

export const GET_PROJECTS = gql`
  query {
    getProjects {
      billable
      client
      name
      projectCode
      repliconId
      taskIds
      isOpenForTimeEntries
      _id
      StageName
    }
  }
`
