import React from 'react'
import PropTypes from 'prop-types'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import CustomTooltip from '../../../../../components/CustomTooltip'
import { SuggestionSectionContainer, TextContainer, Text, Favorite } from './styles'

import { isSuggestionPTO } from '../../../../../utils/appUtils'

class SuggestionSection extends React.Component {
  renderFavoriteIcon = isFavorite => {
    const style = { color: '#747475', fontSize: 18 }
    return isFavorite ? <StarIcon style={style} /> : <StarBorderIcon style={style} />
  }

  suggestionContent = () => {
    const formattedSuggestionName = this.props.suggestion.name
      ? this.props.suggestion.name.replace(/\s+/g, '-').toLowerCase()
      : ''
    const className = `suggestion-${formattedSuggestionName}`
    return (
      <TextContainer>
        <Text
          className={className}
          disabled={isSuggestionPTO(this.props.suggestion) && !this.props.isConnected}
          onMouseDown={() => this.props.onClickProject(this.props.suggestion)}
        >
          {this.props.suggestion.name}
        </Text>
        <Favorite
          id="favorite-icon"
          onMouseDown={() => {
            this.props.onClickFavorite(this.props.suggestion)
          }}
        >
          {this.renderFavoriteIcon(this.props.isSuggestionInFavorite(this.props.suggestion))}
        </Favorite>
      </TextContainer>
    )
  }

  render() {
    return (
      <SuggestionSectionContainer
        onFocus={this.props.onFocus}
        onKeyDown={this.props.onSuggestionKeyDown}
        tabIndex={this.props.tabIndex}
        highlighted={this.props.highlighted}
      >
        {isSuggestionPTO(this.props.suggestion) && !this.props.isConnected ? (
          <CustomTooltip title="PTO can not be selected while offline">
            {this.suggestionContent()}
          </CustomTooltip>
        ) : (
          this.suggestionContent()
        )}
      </SuggestionSectionContainer>
    )
  }
}

SuggestionSection.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onClickProject: PropTypes.func.isRequired,
  onClickFavorite: PropTypes.func.isRequired,
  isSuggestionInFavorite: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onSuggestionKeyDown: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  highlighted: PropTypes.bool,
  isConnected: PropTypes.bool.isRequired,
}

SuggestionSection.defaultProps = {
  highlighted: false,
}

export default SuggestionSection
