import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import styled from 'styled-components'
import blue from '@material-ui/core/colors/blue'

export const PeriodSwitcherContainer = styled.div`
  grid-area: period;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ViewSwitcherContainer = styled.div`
  grid-area: views;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const DatePickerWrapper = styled.div`
  position: relative;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  padding: 2px 4px;
  z-index: 9;
`
export const DateRange = styled.div`
  font-size: 1rem;
  color: ${blue[500]};
  text-align: center;
  flex-basis: 100%;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }
`

export const CustomToggle = withStyles({
  root: {
    height: '28px',
    color: blue[500],
    backgroundColor: 'transparent',
    textTransform: 'none',
    borderRadius: '5px',
    border: '2px solid #c7c7c7',
    width: '60px',
  },
  selected: {
    color: '#fff !important',
    backgroundColor: `${blue[500]} !important`,
    borderRadius: '5px',
    border: 'none',
  },
})(({ classes, ...rest }) => <ToggleButton classes={classes} {...rest} />)

export const styles = () => ({
  toggleButtonGroup: {},
  popover: {
    marginTop: '6px',
  },
})

export const DatePickerContainer = styled.div`
  grid-area: calendar;

  display: flex;
  justify-content: center;
  align-items: center;
`
