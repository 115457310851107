import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:first-child': {
      display: 'none',
    },
  },
})

function DayCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}

export default DayCheckbox
