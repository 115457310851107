import styled from 'styled-components'

export const ReleaseDate = styled.span`
  font-size: 14px;
  color: rgba(1, 1, 1, 0.5);
`

export const NewTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: #2196f3;
`

export const NewSubtitle = styled.h2`
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 600;
  color: rgba(1, 1, 1, 0.8);
`

export const NewParagraph = styled.p`
  font-size: 14px;
  color: rgba(1, 1, 1, 0.8);
  margin: 0 0 20px 0;
  max-width: 560px;
  text-align: justify;
`
export const NewRelease = styled.div`
  margin-bottom: 30px;
`

export const NewUL = styled.ul`
  margin: 5px 0;
  padding: 0 0 0 20px;
  font-size: 14px;
`
