import { requestEvents } from './utils/requestEvents'
import getTransformedEvents from './utils/eventsTransformations'

export const getEvents = async ({ client, params }) => {
  const { from, to, isDelta, reSyncDelta, selectedDelegateId } = params

  const eventDeltas = await requestEvents({
    client,
    params: { from, to, isDelta, reSyncDelta, selectedDelegateId },
  })

  const events = eventDeltas
    .filter(eventDelta => eventDelta.deltaType !== 'delete')
    .map(eventDelta => eventDelta.event)

  const modifiedEvents = getTransformedEvents({ client, events })
  return modifiedEvents
}
