import moment from 'moment'
import { get } from 'lodash'

export const FUNNEL_ENTRIES_RESOURCE_ID = 1
export const EXCHANGE_EVENTS_RESOURCE_ID = 2
export const EXCHANGE_EMAILS_RESOURCE_ID = 3

export const placeholderEventMock = {
  _id: 'placeholder',
  resourceId: 1,
}

export const createOneHourPlaceholderAfterLastestTimeEntry = editableEntry => {
  const { end: currentEventEnd } = editableEntry
  const start = currentEventEnd.toISOString()
  const end = moment(start)
    .add(1, 'hours')
    .toISOString()

  return { start, end }
}

export const getLongLastingEvent = events => {
  if (events.length === 1) return events[0]
  const eventsDurationInMs = events.map(event => {
    return {
      durationInMs: moment(event.end).diff(moment(event.start)),
      event,
    }
  })

  return get(
    eventsDurationInMs.sort(function(a, b) {
      return b.durationInMs - a.durationInMs
    })[0],
    'event',
    [],
  )
}

export const getNearestEntryBoxElement = (currentActiveNode, noTimeEntriesElements) => {
  const timeEntriesAfterCurrentNode = noTimeEntriesElements.filter(
    element => currentActiveNode.tabIndex < element.tabIndex,
  )
  const timeEntriesBeforeCurrentNode = noTimeEntriesElements.filter(
    element => currentActiveNode.tabIndex > element.tabIndex,
  )

  if (timeEntriesAfterCurrentNode.length > 0) return timeEntriesAfterCurrentNode[0]
  return timeEntriesBeforeCurrentNode[0]
}

export const getNoTimeEntriesDOMElements = () => {
  const noTimeEntries = document.querySelectorAll('div[id^="emptyEntryBoxPreview_tabindex_"]')
  return Array.from(noTimeEntries)
}

export const getTimeEntriesDOMElements = () => {
  const timeEntries = document.querySelectorAll('div[id^="entryBoxPreview_tabindex_"]')
  return Array.from(timeEntries)
}
