import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'

export const StyledGrid = withStyles({
  root: {
    borderBottom: '1px solid black',
  },
})(Grid)

export const StyledMenuHeading = styled.li`
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
`

export const PermissionText = styled.p`
  color: green;
  font-size: 12px;
`
export const SideText = styled.span`
  vertical-align: middle;
  padding-left: 6px;
  font-weight: bold;
`

export const ClearIcon = withStyles({
  root: {
    cursor: 'pointer',
    marginRight: 8,
  },
})(CloseIcon)

export const StyledMenuItem = withStyles({
  root: {
    '&:hover, &:focus': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
})(MenuItem)
