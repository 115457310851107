import React from 'react'
import styled from 'styled-components'

import Warning from '@material-ui/icons/Warning'

export const NotificationBarWrapper = styled.div`
  grid-area: notifications;

  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const NotificationMessage = styled.h1`
  font-size: 12px;
  margin: 0 4px;
  font-weight: 500;
  color: #b1b1b1;
  display: inline-block;
`

export const WarningIcon = styled(({ ...others }) => <Warning {...others} />)`
  font-size: 20px;
  margin: 0 4px;
  color: red;
`
