import { GET_TIME_OFF_CODES } from './query'

export const fetchTimeOffCodes = async client => {
  const response = await client.query({
    query: GET_TIME_OFF_CODES,
  })

  const timeOffCodes = response.data.getTimeOffCodes
  return timeOffCodes
}
