import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import * as analyticsService from '../../entities/analytics/service'
import { ReportsPage, ReportsPageHeader, GoBackContainer, ReportPageTitle } from './style'
import { useClient } from '../../graphql/provider'

const Reports = () => {
  const history = useHistory()
  const [reportSrc, setReportSrc] = useState(null)

  const client = useClient()

  useEffect(() => {
    const fetchIframeSrc = async () => {
      const reportsSrc = await analyticsService.getUserReportsSrc(client)
      setReportSrc(reportsSrc)
    }
    fetchIframeSrc()
  }, [client])

  return (
    <ReportsPage style={{ height: '100%' }}>
      <ReportsPageHeader>
        <GoBackContainer onClick={() => history.push('/')}>
          <ChevronLeftIcon style={{ fontSize: 40 }} />
        </GoBackContainer>
        <ReportPageTitle>Reports</ReportPageTitle>
      </ReportsPageHeader>

      <div className="iframe-container">
        {reportSrc !== null ? (
          <iframe
            src={reportSrc}
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
            title="user report"
          />
        ) : null}
      </div>
    </ReportsPage>
  )
}

export default Reports
