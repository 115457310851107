import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

class BasicTable extends React.PureComponent {
  handleEdit = assignment => {
    const { setCurrentEditableAssignment } = this.props
    setCurrentEditableAssignment(assignment)
  }

  render() {
    const { rows, columns, shouldBeAbleToEdit, currentEditableAssignment, isConnected } = this.props
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {_.map(columns, column => (
                <TableCell align="center" key={column}>
                  <b>{column}</b>
                </TableCell>
              ))}
              {shouldBeAbleToEdit && (
                <TableCell align="center" key="Options">
                  Options
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(rows, row => {
              const isCurrentEditing = currentEditableAssignment
                ? row._id === currentEditableAssignment._id
                : false
              return (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  selected={isCurrentEditing}
                >
                  {_.map(_.keys(row), key => {
                    if (key === '_id') return null
                    return (
                      <TableCell align="center" key={`${row._id} - ${key}`}>
                        {row[key]}
                      </TableCell>
                    )
                  })}
                  {shouldBeAbleToEdit && (
                    <TableCell align="center" key={`${row._id} - Options`}>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.handleEdit(row)
                        }}
                        disabled={!isConnected}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

BasicTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  shouldBeAbleToEdit: PropTypes.bool.isRequired,
  setCurrentEditableAssignment: PropTypes.func.isRequired,
  currentEditableAssignment: PropTypes.object,
  isConnected: PropTypes.bool.isRequired,
}

export default BasicTable
