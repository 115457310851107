import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const AuthFormContainer = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AuthPageNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const useStyles = makeStyles({
  button: {
    fontSize: '1.1rem',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textField: {
    width: 300,
    margin: '10px 0',
  },
})
