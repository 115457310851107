import styled from 'styled-components'

export const Event = styled.h1`
  font-size: 14px;
  margin: 0;
  display: inline-block;
  width: 65%;
`
export const EventSubtitle = styled.span`
  font-weight: bold;
  font-size: 12px;
  width: 100%;
`

export const EventDescription = styled.div`
  text-align: justify;
  padding-right: 5px;
`

export const EventDates = styled.div`
  font-size: 10px;
  display: inline-block;
  width: 35%;
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
`

export const Observation = styled.p`
  margin: 15px 0 25px 0;
`

export const ObservationUser = styled.span`
  font-weight: 600;
`

export const AvatarsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ItalicSpan = styled.span`
  font-style: italic;
  font-weight: bold;
`
