import { createTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: `"Arial Regular", "Arial Medium", sans-serif`,
  },
  palette: {
    primary: blue,
    disabled: {
      main: grey[300],
      dark: '#A6A6A6',
    },
  },
})
