import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import ExpansionPanel from '../Header/ExpansionPanel'
import { ProjectTableWrapper } from './styles'
import BasicTable from '../Table'

const types = { 1: 'Project Lead', 2: 'Partner Lead', 3: 'Invoice Lead', 4: 'Invoice Team' }

class SelfView extends React.PureComponent {
  populateAssignments = assignments => {
    const { projects } = this.props
    return _.map(assignments, assignment => {
      const findProject = _.find(projects, project => project._id === assignment.projectId)
      return { ...assignment, project: findProject }
    })
  }

  createObject = (populatedSelf, populatedLead) => {
    return { 'Self Assignments': populatedSelf, 'Lead Assignments': populatedLead }
  }

  getWeekdaysWeek = startWeek => {
    const start = moment.utc(startWeek).startOf('week')
    const monday = start.clone().day(1)
    const tuesday = start.clone().day(2)
    const wednesday = start.clone().day(3)
    const thursday = start.clone().day(4)
    const friday = start.clone().day(5)
    return [monday, tuesday, wednesday, thursday, friday]
  }

  calculateTotals = assignments => {
    const { currentStartNextWeek } = this.props
    const weekDays = this.getWeekdaysWeek(currentStartNextWeek)

    // We can use this later to improve the totals calculation (maybe showing the total for each day)
    const percentsByDay = _.map(weekDays, day => {
      const percents = _.reduce(
        assignments,
        (accum, assignment) => {
          const { startDate, endDate, percent } = assignment
          const isAssingmentForTheDay = moment.utc(day).isBetween(startDate, endDate, null, '[]')
          if (isAssingmentForTheDay) {
            const num = Number(percent.slice(0, percent.indexOf('%')))
            return [...accum, num]
          }
          return accum
        },
        [],
      )
      return { day: day.format('MM/DD/YYYY'), total: _.sum(percents) }
    })

    return _.sumBy(percentsByDay, 'total') / _.keys(percentsByDay).length
  }

  renderMultipleTables = () => {
    const { selfAssignments, leadAssignments, shouldBeAbleToEdit } = this.props
    const populatedSelf = this.populateAssignments(selfAssignments)
    const populatedLead = this.populateAssignments(leadAssignments)
    const groupedAssignments = this.createObject(populatedSelf, populatedLead)
    const assignmentType = Object.keys(groupedAssignments)
    const total = this.calculateTotals(selfAssignments)
    return (
      <ProjectTableWrapper>
        <ExpansionPanel
          data={_.map(assignmentType, type => {
            const ableToEdit = type === 'Self Assignments' ? shouldBeAbleToEdit : false
            return {
              index: type,
              title: `${type} (${groupedAssignments[type].length})`,
              content: this.renderAssignmentTable(type, groupedAssignments[type], ableToEdit),
            }
          })}
          totals={total}
        />
      </ProjectTableWrapper>
    )
  }

  renderAssignmentTable = (title, assignments, shouldBeAbleToEdit) => {
    const { setCurrentEditableAssignment, currentEditableAssignment, isConnected } = this.props
    if (assignments.length === 0) {
      return <span>You do not have {title} for the selected week. </span>
    }
    const basicColumns = ['Project Code', 'Start Date', 'End Date']
    const columns =
      title === 'Self Assignments'
        ? [...basicColumns, 'Staffing Category', 'Percent']
        : [...basicColumns, 'Lead Type']
    const basicFields = ['_id', 'project.projectCode', 'startDate', 'endDate']
    const fields =
      title === 'Self Assignments'
        ? [...basicFields, 'investment', 'percent']
        : [...basicFields, 'type']
    const rows = _.map(assignments, assignment => {
      return _.reduce(
        fields,
        (accum, field) => {
          const value = _.get(assignment, field)
          if (field === 'type') {
            return { ...accum, [field]: types[value] }
          }
          if (field === 'startDate' || field === 'endDate') {
            return { ...accum, [field]: moment.utc(value).format('MM/DD/YYYY') }
          }
          return { ...accum, [field]: value }
        },
        {},
      )
    })
    return (
      <>
        <BasicTable
          rows={rows}
          columns={columns}
          shouldBeAbleToEdit={shouldBeAbleToEdit}
          setCurrentEditableAssignment={setCurrentEditableAssignment}
          currentEditableAssignment={currentEditableAssignment}
          isConnected={isConnected}
        />
      </>
    )
  }

  render() {
    return <>{this.renderMultipleTables()}</>
  }
}

SelfView.propTypes = {
  selfAssignments: PropTypes.array.isRequired,
  leadAssignments: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  shouldBeAbleToEdit: PropTypes.bool.isRequired,
  setCurrentEditableAssignment: PropTypes.func.isRequired,
  currentEditableAssignment: PropTypes.object,
  isConnected: PropTypes.bool.isRequired,
  currentStartNextWeek: PropTypes.object.isRequired,
}

export default SelfView
