import _ from 'lodash'
import moment from 'moment'
import md5 from 'md5'
import {
  EXCHANGE_EVENTS_RESOURCE_ID,
  EXCHANGE_EMAILS_RESOURCE_ID,
  FUNNEL_ENTRIES_RESOURCE_ID,
} from '../constants/events'
import timeUtils from './timeUtils'

import { timesheet } from '../constants/billingOptions'
import { UNASSIGNED_PROJECT_ID } from '../constants/projects'
import { getBillableByProject, isProjectInternal, isPTOCodeProject } from './projectUtils'
import { isAnOperation, getEntryOrOperation } from '../models/entryOperation'

const parseDate = date => moment(date).format('YYYY-MM-DD')

export default {
  isEventFromRightCalendarColumn(resourceId) {
    return resourceId === EXCHANGE_EVENTS_RESOURCE_ID || resourceId === EXCHANGE_EMAILS_RESOURCE_ID
  },

  isCalendarEvent(event) {
    return event.resourceId === EXCHANGE_EVENTS_RESOURCE_ID
  },

  isEmailEvent(event) {
    return event.resourceId === EXCHANGE_EMAILS_RESOURCE_ID
  },

  isFunnelEntry(event) {
    return event.resourceId === FUNNEL_ENTRIES_RESOURCE_ID
  },

  isCalendarOrEmailEvent(event) {
    return (
      event.resourceId === EXCHANGE_EMAILS_RESOURCE_ID ||
      event.resourceId === EXCHANGE_EVENTS_RESOURCE_ID
    )
  },

  isTimeOffEvent(event) {
    return _.get(event, 'type') === 'timeOffEntry'
  },

  isUnassignedEntryProject(entry) {
    return entry.originalProjectId === UNASSIGNED_PROJECT_ID
  },

  getTimesheetForDay(date, timesheets) {
    // TODO: Fix this issue of having to set hours to 0
    const formattedDate = date.setHours(0, 0, 0, 0)
    const timesheetForDay = _.find(timesheets, t => {
      const start = moment(t.startDate).startOf('day')
      const end = moment(t.endDate).endOf('day')
      return timeUtils.isBetween(formattedDate, start, end)
    })
    return timesheetForDay || {}
  },

  getWeekTimesheets(date, timesheets) {
    const formattedDate = date.setHours(0, 0, 0, 0)
    const weekTimesheets = _.filter(timesheets, t => {
      const start = moment(t.startDate).startOf('week')
      const end = moment(t.startDate).endOf('week')
      return timeUtils.isBetween(formattedDate, start, end)
    })
    return weekTimesheets
  },

  getEventsForDay(date, events) {
    const todayEvents = events.filter(e => {
      if (_.isNil(e)) return true
      const eventStart = this.getEventStartDate(e)
      const eventEnd = this.getEventEndDate(e)
      const goodStart = moment(date)
        .startOf('day')
        .isSameOrBefore(moment(eventStart))

      const goodEnd = moment(date)
        .add(1, 'day')
        .startOf('day')
        .isSameOrAfter(moment(eventEnd))

      const isBetween = goodStart && goodEnd
      return isBetween
    })
    return todayEvents
  },

  getDatesForEvent(e) {
    const start = this.getEventStartDate(e)
    const end = this.getEventEndDate(e)
    start.setHours(0, 0, 0, 0)
    end.setHours(0, 0, 0, 0)
    const count = timeUtils.daysBetween(end, start)

    const dates = []
    for (let i = 0; i <= count; i += 1) {
      const d = new Date(start)
      d.setDate(d.getDate() + i)
      dates.push(d)
    }

    return dates
  },

  getEventStartDate(dataObject) {
    const data = isAnOperation(dataObject) ? dataObject.data : dataObject
    const { startDate, start } = data
    if (start) return new Date(start)
    if (startDate) return moment(startDate)
    return new Date()
  },

  getEventEndDate(dataObject) {
    const data = isAnOperation(dataObject) ? dataObject.data : dataObject
    const { endDate, end } = data
    if (end) return new Date(end)
    if (endDate) return moment(endDate)
    return new Date()
  },

  getHoursForEventOnDate(e, date) {
    const start = this.getEventStartDate(e)
    const end = this.getEventEndDate(e)
    if (timeUtils.areSameDay(start, date)) {
      if (!timeUtils.areSameDay(start, end)) {
        return 24 - start.getHours()
      }
      return end.getHours() - start.getHours()
    }
    if (timeUtils.areSameDay(end, date)) {
      return end.getHours()
    }
    if (timeUtils.isBetween(date, start, end)) {
      return 24
    }

    return 0
  },

  groupEventsByDate(events, today = new Date()) {
    today.setHours(0, 0, 0, 0)
    const todayFormat = today.toISOString().substr(0, 10)
    const groups = {
      [todayFormat]: [],
    }

    events.forEach(e => {
      const dates = this.getDatesForEvent(e)

      dates.forEach(d => {
        const format = d.toISOString().substr(0, 10)
        if (!groups[format]) {
          groups[format] = [e]
        } else {
          groups[format].push(e)
        }
      })
    })

    const sortedGroups = {}
    let keys = Object.keys(groups)
    keys = keys.sort()
    for (const k of keys) {
      const split = k.split('-')
      sortedGroups[timeUtils.prettyFormatDate(new Date(split[0], split[1] - 1, split[2]), today)] =
        groups[k]
    }
    return sortedGroups
  },

  getEventsByDate(events, date = new Date()) {
    const dateFormat = timeUtils.prettyFormatDate(date)
    const groupedTodayEvents = this.groupEventsByConversation(events)
    return {
      [dateFormat]: groupedTodayEvents,
    }
  },

  getEventsBySelectedDate(events, date) {
    return _.reduce(
      events,
      (acc, event) => {
        const dates = this.getDatesForEvent(event)
        dates.forEach(day => {
          if (timeUtils.areSameDay(date, day)) acc.push(event)
        })
        return acc
      },
      [],
    )
  },

  getSelectedWeekEventsByDate(events, date) {
    const weekDays = timeUtils.getSelectedDayWeekDays(date)
    const weekEvents = _.map(weekDays, day => {
      return this.getEventsBySelectedDate(events, day)
    })
    return _.flatten(weekEvents)
  },

  groupEventsByConversation(eventsList) {
    const groupedEvents = _.reduce(
      eventsList,
      (acc, event) => {
        const { events, conversations } = acc
        const conversationId = this.getConversationId(event)
        if (conversationId) {
          if (_.has(conversations, conversationId)) {
            conversations[conversationId] = _.concat(conversations[conversationId], event)
          } else {
            conversations[conversationId] = event
            events.push(conversationId)
          }
        } else {
          events.push(event)
        }
        return acc
      },
      {
        events: [],
        conversations: {},
      },
    )

    return _.map(groupedEvents.events, item => {
      if (_.has(groupedEvents, ['conversations', item])) {
        return _.get(groupedEvents, ['conversations', item])
      }
      return item
    })
  },

  getConversationId(event) {
    if (event.conversationId && event.resourceId === EXCHANGE_EMAILS_RESOURCE_ID) {
      return event.conversationId
    }
    return null
  },

  getEventId(projectId, comment, start, end) {
    const curUnixTime = Date.now()
    return md5(`${projectId}${comment}${curUnixTime}${start}${end}`)
  },

  sortEventsByStartTime(events) {
    return _.sortBy(events, event => new Date(event.start || event.data.start))
  },

  findProjectObject(entryOrAction, projects, defaultProjectId) {
    const entry = isAnOperation(entryOrAction) ? entryOrAction.data : entryOrAction
    const isPTO = this.isTimeOffEvent(entry)

    if (entry.originalProjectId || entry.repliconTypeId) {
      const projectFound = _.find(projects, project => {
        const hasProjectTypeProp = _.has(project, 'projectType')

        if (isPTO) {
          const doesMatchRepliconId =
            project._id === (entry.originalProjectId || entry.repliconTypeId)
          return doesMatchRepliconId && project.projectType === 'pto'
        }

        return project._id === entry.originalProjectId && !hasProjectTypeProp
      })

      return projectFound || {}
    }

    if (!defaultProjectId) return {}
    return _.find(projects, { _id: defaultProjectId }) || {}
  },
  groupEventsByProjectType(events, projects) {
    return _.groupBy(events, event => {
      const isTimeOffEntry = this.isTimeOffEvent(event)
      if (isTimeOffEntry) return 'PTO'

      const projectObject = this.findProjectObject(event, projects)
      if (projectObject) {
        if (isProjectInternal(projectObject)) return 'Internal'
        if (isPTOCodeProject(projectObject)) return 'PTO'
        return 'Project'
      }
    })
  },

  isEntryInvoiced(entry) {
    return _.get(entry, 'quickbooksInvoiceId', null) != null
  },

  isPlaceholderEntry(entry) {
    return entry._id === 'placeholder' && !entry.sourceId
  },

  getBillableStatus(project) {
    return _.isEmpty(project) ? timesheet.NONBILLABLE : getBillableByProject(project)
  },

  getTotalDurationInHours(events) {
    const number = _.reduce(
      events,
      (acc, entryOrOp) => {
        const entry = getEntryOrOperation(entryOrOp)
        const durationToAdd = entry.originalDurationInHours || entry.durationInHours || 0
        return acc + parseFloat(durationToAdd)
      },
      0,
    )
    return Math.round(number * 100) / 100
  },

  getTimesheetDictionaryByDate(timesheets) {
    return _.reduce(
      timesheets,
      (accum, { totalHours, startDate, approvalStatus }) => {
        const parsedStartDate = parseDate(startDate)

        return {
          ...accum,
          [parsedStartDate]: {
            approvalStatus,
            totalHours,
          },
        }
      },
      {},
    )
  },

  parseEventsDates(events) {
    return _.map(events, event => {
      return {
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }
    })
  },

  parseEmailDates(events) {
    return _.map(events, event => {
      const startDate = new Date(event.start)
      const endDate = new Date(startDate.getTime() + 30 * 60000)
      return {
        ...event,
        start: startDate,
        end: endDate,
      }
    })
  },

  parseLocalEntryDatesAndSource(event) {
    return {
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }
  },

  parseUnhandledEntries(entries) {
    return _.map(entries, entry => {
      return {
        ...entry,
        start: new Date(entry.start),
        end: new Date(entry.end),
      }
    })
  },

  parseUnhandledEntriesInfo(entries) {
    return _.groupBy(entries, entry => {
      return moment
        .utc(entry.start)
        .startOf('day')
        .toLocalString()
    })
  },

  excludeEntriesFoundedIn: _.curry((entriesToFind, entriesToExclude) => {
    const localEntries = _.groupBy(entriesToFind, 'sourceId')
    return _.filter(entriesToExclude, entry => !_.hasIn(localEntries, entry.sourceId))
  }),

  getClearedEntriesByLocalEntries(entriesToFind, entriesToExclude) {
    return _.flow([this.parseEventsDates, this.excludeEntriesFoundedIn(entriesToFind)])(
      entriesToExclude,
    )
  },

  groupEmailsForTimeline(emails) {
    const secondsInHalfHour = 1800
    const milliSecondsInSec = 1000
    const getNearestHalfHour = email =>
      Math.floor(email.start / milliSecondsInSec / secondsInHalfHour)
    const groupedEmails = _.groupBy(emails, getNearestHalfHour)
    const transformEmail = (value, key) => {
      const startDate = new Date(key * milliSecondsInSec * secondsInHalfHour)
      const endDate = timeUtils.setMinutes(startDate, 30)
      return {
        start: startDate,
        end: endDate,
        resourceId: EXCHANGE_EMAILS_RESOURCE_ID,
        emails: value,
      }
    }
    return _.map(groupedEmails, transformEmail)
  },

  buildUsedExchangeEvents(entries) {
    return _.reduce(
      entries,
      (acc, entry) => {
        const { referenceId } = entry
        if (referenceId) acc[referenceId] = true
        return acc
      },
      {},
    )
  },

  getFirstStartTimeDate(entries) {
    const sortedByStartDate = _.sortBy(entries, 'start')
    return _.get(sortedByStartDate, '0.start', null)
  },

  sortEventsChronologically(events) {
    return _.sortBy(events, event => new Date(event.start))
  },

  /**
   * @typedef {Object} gap
   * @property {Date} start
   * @property {Date} end
   */

  /**
   * @typedef {Object} timeEntry
   * @property {Date} start
   * @property {Date} end
   * ...and other props
   */

  /**
   * Returns an array of objects with keys start and end which
   * specify busy day periods
   * @param {timeEntry[]} dayTimeEntries
   * @returns {gap[]} busy time
   */

  getDayBusyTime(dayTimeEntries) {
    const ordered = this.sortEventsByStartTime(dayTimeEntries)
    return ordered.reduce((acc, cur) => {
      const currentStart = cur.start || cur.data.start
      const currentEnd = cur.end || cur.data.end

      if (timeUtils.momentIsSame(currentStart, currentEnd)) return acc
      if (acc.length === 0) return [{ start: currentStart, end: currentEnd }]

      const { start: previousStart, end: previousEnd } = acc[acc.length - 1]

      if (
        timeUtils.momentInclusiveIsBetween(currentStart, previousStart, previousEnd) &&
        timeUtils.momentIsAfter(previousEnd, currentEnd)
      ) {
        acc[acc.length - 1] = { start: previousStart, end: currentEnd }
        return acc
      }

      if (
        timeUtils.momentInclusiveIsBetween(currentStart, previousStart, previousEnd) &&
        !timeUtils.momentIsAfter(previousEnd, currentEnd)
      ) {
        return acc
      }

      if (!timeUtils.momentInclusiveIsBetween(currentStart, previousStart, previousEnd)) {
        return [...acc, { start: currentStart, end: currentEnd }]
      }

      return acc
    }, [])
  },

  getDayFreeTime(dayTimeEntries, selectedDate) {
    const busyTime = this.getDayBusyTime(dayTimeEntries)
    const today = moment(selectedDate)
    const startOfToday = moment(today).startOf('day')
    const endOfToday = moment(today).endOf('day')

    const buildFreeTime = () => {
      if (_.isEmpty(busyTime)) return [{ start: new Date(startOfToday), end: new Date(endOfToday) }]

      const isThereAMorningGap = moment(busyTime[0].start).isAfter(startOfToday)
      const isThereANightGap = moment(busyTime[busyTime.length - 1].end).isBefore(endOfToday)

      const morningFreeTime = isThereAMorningGap
        ? { start: new Date(startOfToday), end: busyTime[0].start }
        : {}
      const nightFreeTime = isThereANightGap
        ? { start: busyTime[busyTime.length - 1].end, end: new Date(endOfToday) }
        : {}

      const partialFreeTime = busyTime.reduce((acc, cur, idx, src) => {
        if (idx === src.length - 1) return acc
        return [
          ...acc,
          {
            start: cur.end,
            end: src[idx + 1].start,
          },
        ]
      }, [])

      return [morningFreeTime, ...partialFreeTime, nightFreeTime].filter(gap => !_.isEmpty(gap))
    }

    const freeTime = buildFreeTime()
    return freeTime
  },

  getSelectedDayEntries(selectedTimesheet, timeEntries) {
    const selectedTimesheetRepliconId = _.get(selectedTimesheet, 'repliconId')
    return timeEntries.filter(e => e.repliconTimesheetId === selectedTimesheetRepliconId)
  },

  getSelectedDayEntriesByDate(selectedTimesheet, timeEntries) {
    const selectedStartDate = moment(_.get(selectedTimesheet, 'startDate')).format('L')
    return timeEntries.filter(e => moment(e.start).format('L') === selectedStartDate)
  },

  getTimeEntryDurationUnits(entry) {
    // >> get dates for operational or regular entries
    const start = entry.start || entry.data.start
    const end = entry.end || entry.data.end

    const { hours, minutes, seconds } = timeUtils.getTimeUnitsBetweenDates(start, end)

    return {
      hours,
      minutes,
      seconds,
    }
  },
}
