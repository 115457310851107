import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import HistoryIcon from '@material-ui/icons/History'
import StarsIcon from '@material-ui/icons/Stars'
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed'
import SuggestionSectionTitle from './SuggestionSectionTitle'
import SuggestionSection from './SuggestionSection'
import { SuggestionContainer } from './styles'
import { keysCodes } from '../../../../../utils/keyboard'

import { isSuggestionPTO } from '../../../../../utils/appUtils'

const genSuggestions = ({ userSettings, mostRecentlyUsedProjectIds, assignments }) => {
  return [
    {
      title: 'Default Project',
      icon: GpsNotFixedIcon,
      description: 'This project is used by default for all your newly added time entries',
      suggestionIds: userSettings.defaultProjectId ? [userSettings.defaultProjectId] : [],
    },
    {
      title: 'My Projects',
      icon: AccountCircleIcon,
      description: 'Projects you are assigned to',
      suggestionIds: _.map(assignments, 'projectId'),
    },
    {
      title: 'Favorite Projects',
      icon: StarsIcon,
      description: 'Your favorite projects',
      suggestionIds: userSettings.favoriteProjectIds,
    },
    {
      title: 'Recently Used Projects',
      icon: HistoryIcon,
      description: 'Your most recently used projects',
      suggestionIds: mostRecentlyUsedProjectIds,
      recent: true,
    },
    {
      title: 'All Projects',
      icon: MenuIcon,
      description: 'All Keystone Projects',
    },
  ]
}

class SuggestionList extends PureComponent {
  getNextTabIndexNode = currentNode => {
    const nextNode = currentNode.nextSibling
    if (_.isNull(nextNode)) return null
    if (!nextNode.hasAttribute('tabIndex')) {
      return this.getNextTabIndexNode(nextNode)
    }
    return nextNode
  }

  getPrevTabIndexNode = currentNode => {
    const prevNode = currentNode.previousSibling
    if (_.isNull(prevNode)) return null
    if (!prevNode.hasAttribute('tabIndex')) {
      return this.getPrevTabIndexNode(prevNode)
    }
    return prevNode
  }

  getRecentSuggestions = ids => {
    return _.map(ids, id => this.props.suggestions.find(e => e.repliconId === id))
  }

  renderGroupedSuggestions = () => {
    const { suggestions, userSettings, mostRecentlyUsedProjectIds, assignments } = this.props
    const groupedSuggestions = genSuggestions({
      userSettings,
      mostRecentlyUsedProjectIds,
      assignments,
    })
    return _.map(groupedSuggestions, suggestionGroup => {
      const { suggestionIds, title, recent } = suggestionGroup

      const suggestionGetter = () => {
        if (!suggestionIds) return suggestions
        if (recent) return this.getRecentSuggestions(suggestionIds)
        return this.getSuggestionsObjectByIds(suggestionIds)
      }

      return (
        <Fragment key={title}>
          {this.renderSectionTitle(suggestionGroup)}
          {this.renderSuggestions(suggestionGetter())}
        </Fragment>
      )
    })
  }

  renderSuggestion = (suggestion, index) => {
    if (!suggestion) return null
    const highlighted = !this.props.shouldRenderGroupedSuggestions && index === 0
    return (
      <SuggestionSection
        key={index}
        highlighted={highlighted}
        tabIndex={-1}
        suggestion={suggestion}
        onClickProject={this.props.handleSuggestionSelected}
        onClickFavorite={this.props.onFavoriteSuggestionSelected}
        isSuggestionInFavorite={this.isSuggestionInFavoriteProjectIds}
        onSuggestionKeyDown={this.handleSuggestionKeyDown(suggestion)}
        onFocus={this.props.handleSuggestionFocus}
        isConnected={this.props.isConnected}
      />
    )
  }

  handleSuggestionKeyDown = suggestion => ({ nativeEvent: { code } }) => {
    const { setFocusOnInput, handleSuggestionSelected } = this.props
    const { activeElement } = document
    if (code === keysCodes.down) {
      const nextSuggestionNode = this.getNextTabIndexNode(activeElement)
      if (_.isNull(nextSuggestionNode)) {
        setFocusOnInput()
        return
      }
      nextSuggestionNode.focus()
    }
    if (code === keysCodes.up) {
      const prevSuggestionNode = this.getPrevTabIndexNode(activeElement)
      if (_.isNull(prevSuggestionNode)) {
        setFocusOnInput()
        return
      }
      prevSuggestionNode.focus()
    }
    if (code === keysCodes.enter && activeElement) {
      if (isSuggestionPTO(suggestion) && !this.props.isConnected) return
      handleSuggestionSelected(suggestion)
    }
  }

  renderSectionTitle = section => (
    <SuggestionSectionTitle
      icon={section.icon}
      title={section.title}
      description={section.description}
    />
  )

  getSuggestionsObjectByIds = ids => {
    return _.filter(this.props.suggestions, project => {
      return _.includes(ids, project._id) && !project.isPTO
    })
  }

  isSuggestionInFavoriteProjectIds = project => {
    return _.includes(this.props.userSettings.favoriteProjectIds, project._id)
  }

  renderSuggestions(suggestions) {
    if (_.isEmpty(suggestions)) return null
    return _.map(suggestions, this.renderSuggestion)
  }

  render() {
    if (!this.props.visible) return null
    const { width, top, height, bottom } = this.props.suggestionContainerPosition
    return (
      <SuggestionContainer
        id="suggestion-list"
        ref={this.props.suggestionRef}
        width={width}
        top={top}
        bottom={bottom}
        height={height}
      >
        {this.props.shouldRenderGroupedSuggestions
          ? this.renderGroupedSuggestions()
          : this.renderSuggestions(this.props.suggestions)}
      </SuggestionContainer>
    )
  }
}

SuggestionList.propTypes = {
  visible: PropTypes.bool.isRequired,
  shouldRenderGroupedSuggestions: PropTypes.bool.isRequired,
  suggestionContainerPosition: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFavoriteSuggestionSelected: PropTypes.func.isRequired,
  setFocusOnInput: PropTypes.func.isRequired,
  handleSuggestionSelected: PropTypes.func.isRequired,
  suggestionRef: PropTypes.func.isRequired,
  handleSuggestionFocus: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  assignments: PropTypes.array.isRequired,
}

export default SuggestionList
