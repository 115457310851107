import Store from './Store'

const store = new Store()
const versionStore = new Store('version')

export const getLastAppVersion = () => {
  return versionStore.get('info') || {}
}

export const setAppLastVersion = curVersion => {
  return versionStore.set('info', curVersion)
}

export const getFunnelUser = () => {
  return store.get('funnelPerson') || {}
}

export const setFunnelUser = person => {
  return store.set('funnelPerson', person)
}

export const setGlimpseApiToken = token => {
  return store.set('glimpseApiToken', token)
}

export const getGlimpseApiToken = () => {
  return store.get('glimpseApiToken') || ''
}

export const updateOutlookEventDeltaToken = deltaToken => {
  store.set('lastOutlookEventDeltaSync', new Date().toISOString())
  return store.set('eventDeltaToken', deltaToken)
}

export const getOutlookEventDeltaToken = () => {
  return store.get('eventDeltaToken') || ''
}

export const getLastOutlookEventSyncDate = () => {
  return store.get('lastOutlookEventDeltaSync') || null
}

export const updateOutlookMessageDeltaToken = deltaToken => {
  store.set('lastOutlookMessageDeltaSync', new Date().toISOString())
  return store.set('messageDeltaToken', deltaToken)
}

export const getOutlookMessageDeltaToken = () => {
  return store.get('messageDeltaToken') || ''
}

export const getLastOutlookMessageSyncDate = () => {
  return store.get('lastOutlookMessageDeltaSync') || null
}

export const setPicture = base64 => {
  return store.set('userPicture', base64)
}

export const getPicture = () => {
  return store.get('userPicture')
}

export const setLastSyncUpdate = lastUpdate => {
  return store.set('lastSyncUpdate', lastUpdate)
}

export const getLastSyncUpdate = () => {
  return store.get('lastSyncUpdate')
}

export const setRunningStartTime = newRunningStartTime => {
  return store.set('runningStartTime', newRunningStartTime || '')
}

export const getRunningStartTime = () => {
  const runningStartTime = store.get('runningStartTime')
  if (!runningStartTime) return null
  return Number(runningStartTime)
}

export const clearStore = () => {
  store.clear()
}
