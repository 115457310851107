import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DelegateAccessProfileList from './components/DelegateAccessProfileList'
import { SideText } from './styles'

const DelegateAccess = props => {
  const [anchorMenu, setAnchorMenu] = useState(null)

  const handleMenuClick = event => {
    setAnchorMenu(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorMenu(null)
  }
  const isOpen = Boolean(anchorMenu)
  const getUserName = () => {
    const result = props.delegateAccessList.find(delegateAccess => {
      return delegateAccess._id === props.selectedDelegateId
    })
    return result ? result.fromFullName : null // or undefined
  }

  if (props.delegateAccessList.length === 0) return null
  return (
    <div>
      <IconButton
        aria-label="Menu"
        aria-owns={isOpen ? 'delegate-Access-profile' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="medium"
        style={{ backgroundColor: '#66cf64', padding: '10px' }}
      >
        <VisibilityIcon style={{ color: 'white' }} />
      </IconButton>

      {props.selectedDelegateId && <SideText>View as: {getUserName()}</SideText>}

      <DelegateAccessProfileList
        delegateAccessList={props.delegateAccessList}
        setAnchorMenu={setAnchorMenu}
        anchorMenu={anchorMenu}
        anchorMenuClose={handleClose}
        selectedDelegateId={props.selectedDelegateId}
        handleSelectedDelegateId={props.handleSelectedDelegateId}
      />
    </div>
  )
}

DelegateAccess.propTypes = {
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func,
  delegateAccessList: PropTypes.array.isRequired,
}

export default DelegateAccess
