import React from 'react'
import PropTypes from 'prop-types'
import { EventCommentBox, EventContainer, TimelineViewEventTitle } from './TimelineViewEventStyle'
import TextLinesClamp from './TextLinesClamp'
import CustomTooltip from '../../../components/CustomTooltip'
import { getTooltipTitle } from './utils'
import { getHeaderTitle } from '../../../utils/entriesUtils'
import { isAnOperation } from '../../../models/entryOperation'

const TimelineViewTitle = ({
  entry,
  projects,
  timeOffCodes,
  handleEntryKeyDown,
  onDoubleClick,
}) => {
  const entryToUse = isAnOperation(entry) ? entry.data : entry
  const entryHeaderTitle = getHeaderTitle(entryToUse, projects, timeOffCodes)
  return (
    <CustomTooltip textAlign="left" title={getTooltipTitle(entryToUse)}>
      <EventContainer
        data-testid={`timeline.weekTimeEntry.${entryHeaderTitle}`}
        onDoubleClick={onDoubleClick}
        tabIndex={0}
        onKeyDown={handleEntryKeyDown}
      >
        {entryToUse.title ? (
          <EventCommentBox>
            <TextLinesClamp lineHeight={12} offsetBottom={10} offsetTop={0}>
              <span>{entryToUse.title}</span>
            </TextLinesClamp>
          </EventCommentBox>
        ) : (
          <TimelineViewEventTitle>{entryHeaderTitle}</TimelineViewEventTitle>
        )}
      </EventContainer>
    </CustomTooltip>
  )
}

TimelineViewTitle.propTypes = {
  entry: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  timeOffCodes: PropTypes.array.isRequired,
  handleEntryKeyDown: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
}

export default TimelineViewTitle
