import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmationModal from '../ConfirmationModal'
import DelegateAccessModelContent from './DelegateAccessModelContent'
import useSurrogateAccess from '../../hooks/delegateAccess/useSurrogateAccess'

const title = 'Delegate Access'
const subtitle =
  'This option will provide access to your account for another user of your choosing. You will be able to allow access to Calendar Events & Emails.'

const DelegateAccessFromMenu = props => {
  const { data: surrogateAccessList, loading, refetch } = useSurrogateAccess()

  return (
    <ConfirmationModal
      title={title}
      titleColor="dark"
      subtitle={subtitle}
      isOpen={props.isOpen}
      actionButtons={[
        {
          label: 'CLOSE',
          handler: props.handleSwitchIsDelegateAccessModalOpen,
          color: 'secondary',
        },
      ]}
    >
      {loading && <CircularProgress />}
      {!loading && (
        <DelegateAccessModelContent
          delegateUsersList={surrogateAccessList}
          refetchSurrogateAccess={refetch}
        />
      )}
    </ConfirmationModal>
  )
}

DelegateAccessFromMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
}

export default DelegateAccessFromMenu
