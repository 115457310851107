import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import ConfirmationModal from '../ConfirmationModal'

import { IN_PROGRESS } from '../IssueReportFromMenu/reportStatuses'

import styles from './style'

const FormModal = props => {
  const isIssueReportInProgress = props.reportStatus === IN_PROGRESS

  const isSubmitButtonDisabled = isIssueReportInProgress || props.report.trim().length === 0
  return (
    <ConfirmationModal
      title="Report Issue"
      titleColor="primary"
      subtitle={props.subtitle}
      isOpen={props.isOpen}
      actionButtons={[
        {
          label: 'CLOSE',
          handler: props.handleSwitchIsIssueReportModalOpen,
          color: 'secondary',
          isDisabled: isIssueReportInProgress,
        },
        {
          label: 'SUBMIT',
          handler: props.onSubmitIssueReport,
          color: 'primary',
          isDisabled: isSubmitButtonDisabled,
        },
      ]}
    >
      <TextField
        placeholder="Type your issue..."
        multiline
        rows="9"
        rowsMax="13"
        margin="normal"
        variant="outlined"
        value={props.report}
        onChange={props.onReportChange}
        className={props.classes.textField}
      />
    </ConfirmationModal>
  )
}

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  onSubmitIssueReport: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    textField: PropTypes.string.isRequired,
  }).isRequired,
  report: PropTypes.string.isRequired,
  onReportChange: PropTypes.func.isRequired,
  reportStatus: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default withStyles(styles)(FormModal)
