import gql from 'graphql-tag'

export const SEND_RESOURCE_CONSUMPTION = gql`
  mutation(
    $appVersion: String!
    $systemVersion: String!
    $processResidentSetMemory: Int
    $processPrivateMemory: Int!
    $processSharedMemory: Int!
    $systemTotalMemory: Int!
    $systemFreeMemory: Int!
    $systemSwapTotalMemory: Int!
    $systemSwapFreeMemory: Int!
  ) {
    sendResourceConsumption(
      appVersion: $appVersion
      systemVersion: $systemVersion
      processResidentSetMemory: $processResidentSetMemory
      processPrivateMemory: $processPrivateMemory
      processSharedMemory: $processSharedMemory
      systemTotalMemory: $systemTotalMemory
      systemFreeMemory: $systemFreeMemory
      systemSwapTotalMemory: $systemSwapTotalMemory
      systemSwapFreeMemory: $systemSwapFreeMemory
    )
  }
`
