const updates = [
  {
    date: '2020-09-08',
    news: [
      {
        title: 'Timer',
        text:
          'We know that one of the hardest things about keeping track of time is recording the exact amount of time spent on a task! That is why we decided to incorporate a timer within Glimpse (so you don’t miss Timebot too much). Click the start button, stop anytime you want, and save your time with your next time entry!. Pro tip: if you have a “default project” enabled the project will be pre-assigned and you would have saved more time.',
      },
      {
        title: 'New sections in Glimpse',
        text:
          'We’ve added 2 new options in our main menu: shortcuts and what’s new. Learn about our current shortcuts to make your interaction faster and also keep up-to-date with our latest feature releases and bug fixes.',
      },
      {
        title: 'Optimizations in Table View',
        text:
          'We’ve included an optimized project selection dropdown in Table view. Now, you will be able to select your default, favorite or recently used project from the top of the dropdown.',
      },
      {
        title: 'Improvements for offline mode and general bug fixes',
        subtitle: 'Coming Soon:',
        text:
          'New API connection to Outlook to ensure Calendar sync integrity and that all event updates are available in Glimpse.',
      },
    ],
  },
  {
    date: '2021-03-05',
    news: [
      {
        title: 'API connection to Outlook',
        text:
          'Fear no more! All your calendar events and emails are synced 24/7 thanks to this new integration.',
      },
      {
        title: 'Reminders',
        text:
          'To enable reminders to track hours on a customed interval: Go to Menu → Settings → Reminders and select the days; start and end time and interval between reminders.',
      },
      {
        title: 'UI improvements',
        text:
          'We know reading the content of your events and emails on timeline-view wasn’t always easy! So, we have improved the UI by increasing contrast and changing the format of the tooltip for easier comprehension.',
      },
      {
        title: 'PTO entries are now shown as read-only in Glimpse',
        text:
          'Namely has become the official PTO manager so now you can only review your booked PTOs in Glimpse.',
      },
      {
        subtitle: 'Coming soon:',
        items: [
          {
            text: 'Plugin in Outlook to tag projects in your calendar events',
          },
          {
            text: 'Log in as a different user',
          },
          {
            text: 'Chatbot on slack to fill in your hours in Glimpse ',
          },
          {
            text: 'Full app redesign ',
          },
        ],
      },
    ],
  },
]

module.exports = updates
