import React from 'react'
import PropTypes from 'prop-types'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { isNextMonthOutOfRange, isPreviousMonthOutOfRange } from '../../utils'
import { DayPickerArrow } from '../styles'
import timeUtils from '../../../../utils/timeUtils'

const DayPickerNavbar = ({
  month,
  onPreviousClick,
  onNextClick,
  previousMonth,
  nextMonth,
  updateCurrentMonth,
  firstMonthRange,
  lastMonthRange,
  userStartDate,
}) => {
  const minFirstMonthRange = Math.min(timeUtils.getMonthDifference(userStartDate), firstMonthRange)
  const handlePreviousClick = () => {
    if (isPreviousMonthOutOfRange(month, minFirstMonthRange)) return
    onPreviousClick()
    updateCurrentMonth(previousMonth)
  }
  const handleNextClick = () => {
    if (isNextMonthOutOfRange(month, lastMonthRange)) return
    onNextClick()
    updateCurrentMonth(nextMonth)
  }
  const fullMonth = timeUtils.getFullMonth(month)
  const fullYear = timeUtils.getFullYear(month)
  return (
    <div className="DayPicker-Custom-NavButton">
      <DayPickerArrow
        data-testid="dateSelector.previousMonth"
        hide={isPreviousMonthOutOfRange(month, minFirstMonthRange)}
        onClick={handlePreviousClick}
      >
        <ChevronLeftIcon style={{ fontSize: 24, color: '#8D8C8C' }} />
      </DayPickerArrow>
      <div className="DayPicker-Custom-Caption">
        {fullMonth} {fullYear}
      </div>
      <DayPickerArrow
        data-testid="dateSelector.nextMonth"
        hide={isNextMonthOutOfRange(month, lastMonthRange)}
        onClick={handleNextClick}
      >
        <ChevronRightIcon style={{ fontSize: 24, color: '#8D8C8C' }} />
      </DayPickerArrow>
    </div>
  )
}

DayPickerNavbar.defautlProps = {
  isAdmin: false,
  firstMonthRange: 2,
  lastMonthRange: 1,
}

DayPickerNavbar.propTypes = {
  month: PropTypes.object.isRequired,
  previousMonth: PropTypes.object.isRequired,
  nextMonth: PropTypes.object.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  updateCurrentMonth: PropTypes.func.isRequired,
  firstMonthRange: PropTypes.number,
  lastMonthRange: PropTypes.number,
  userStartDate: PropTypes.string,
}

export default DayPickerNavbar
