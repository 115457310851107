import styled from 'styled-components'

export const EntryBoxTimeRangeContainer = styled.div`
  font-size: 1.1rem;
  color: #636363;
  margin-bottom: 4px;
  width: 180px;
`
export const EmptyEntryBoxTimeRangeContainer = styled.div`
  font-size: 1.1rem;
  color: #636363;
  width: 180px;
`

export const EntryBoxDurationContainer = styled.div`
  display: inline-block;
  color: #636363;
  margin-right: 5px;
  font-size: 1rem;
`

export const EntryBoxProjectContainer = styled.div`
  font-size: 1rem;
  color: #2182f6;
  display: inline-block;
  line-height: 18px;
  font-weight: ${props => (props.isPlaceholder ? 'lighter' : 'normal')};
`

export const BillableStatusContainer = styled.span`
  font-size: 1rem;
`

export const EntryBoxPTOTitle = styled.div`
  display: inline-block;
  font-size: 13px;
  color: #2182f6;
`

export const EntryBoxCommentContainer = styled.div`
  word-wrap: break-word;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${props => (props.isPlaceholder ? 'lighter' : 'normal')};
`

export const EntryBoxIconContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 6px;
  right: 8px;

  &:hover {
    cursor: pointer;
  }
`

export const EntryBoxContainer = styled.div`
  margin: 8px 0;
  padding: 8px 16px;
  border: 1px solid #c3c3c3;
  position: relative;
`

const colors = {
  placeholderHasProject:
    'repeating-linear-gradient(45deg, #bcddf0, #bcddf0 15px, #e3f1f8 15px, #e3f1f8 30px)',
  placeholder: 'white',
  unassigned: '#f6bebc',
  timeOff: '#faf8df',
  internal: '#cfe3f9',
  default: '#eaf7e8',
}
const offlineColors = {
  placeholderHasProject:
    'repeating-linear-gradient(45deg, #f6bebc, #f6bebc 15px, #fce4e3 15px, #fce4e3 30px)',
  placeholder: 'white',
  unassigned: 'repeating-linear-gradient(45deg, #f6bebc, #f6bebc 15px, #fce4e3 15px, #fce4e3 30px)',
  internal: 'repeating-linear-gradient(45deg, #bcddf0, #bcddf0 15px, #e3f1f8 15px, #e3f1f8 30px)',
  deleted: 'repeating-linear-gradient(45deg, #cccccc, #cccccc 15px, #eeeeee 15px, #eeeeee 30px)',
  default: 'repeating-linear-gradient(45deg, #eef7ed, #eef7ed 15px, #dbf0d7 15px, #dbf0d7 30px)',
}

const selectBackground = props => {
  const background = props.isOffline ? offlineColors[props.entryType] : colors[props.entryType]
  return background
}

export const ColoredEntryBoxContainer = styled(EntryBoxContainer)`
  background: ${selectBackground};
  border: ${props => (props.isEntryBeingEdited ? '2px solid #2182f6;' : ';')};
`

export const EmptyEntryBoxTextContainer = styled.div`
  font-size: 1.1rem;
  color: #e16b8a;
  display: inline-block;
  line-height: 18px;
`
