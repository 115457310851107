import styled from 'styled-components'

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border: ${props => (props.isPlaceholder ? '2px solid #2182f6;' : ';')};
`

export const TimelineViewEventHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EventCommentBox = styled.div`
  font-size: 11px;
  color: #717171;
  margin-bottom: 1px;
`

export const EventDuration = styled.span`
  font-size: 10px;
  color: #717171;
`

export const TimelineViewEventTitle = styled.h1`
  font-size: 11px;
  color: #467fd7;
  margin-bottom: 1px;
  font-weight: ${props => (props.isPlaceholder && !props.hasTitle ? 'lighter' : 'normal')};
`
export const EmailSubject = styled.div`
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  display: inline-block;
  width: 80%;
`
export const EmailHour = styled.div`
  font-size: 10px;
  display: inline-block;
  width: 20%;
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
`
export const EmailBody = styled.div`
  text-align: justify;
  padding-right: 5px;
  padding-top: 10px;
`
