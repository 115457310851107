import gql from 'graphql-tag'

export const GET_ASSIGNMENTS = gql`
  query($startISO: String!, $endISO: String!) {
    getAssignments(startISO: $startISO, endISO: $endISO) {
      _id
      projectId
      startDate
      endDate
      type
      percent
      investment
    }
  }
`

export const GET_ASSIGNMENTS_AS_DELEGATE = gql`
  query($startISO: String!, $endISO: String!, $delegateId: String!) {
    getAssignments_asDelegate(startISO: $startISO, endISO: $endISO, delegateId: $delegateId) {
      _id
      projectId
      startDate
      endDate
      type
      percent
      investment
    }
  }
`

export const GET_ASSIGNMENTS_BY_PROJECTS = gql`
  query($projectIds: String!, $startISO: String!, $endISO: String!) {
    getAssignmentsByProjects(projectIds: $projectIds, startISO: $startISO, endISO: $endISO) {
      _id
      projectId
      personId
      startDate
      endDate
      type
      percent
      investment
    }
  }
`

export const DELETE_ASSIGNMENT = gql`
  mutation($_id: String!) {
    deleteAssignment(_id: $_id)
  }
`

export const CREATE_ASSIGNMENT = gql`
  mutation(
    $projectId: String!
    $personId: String!
    $startDate: String!
    $endDate: String!
    $percent: String!
    $investment: String!
  ) {
    createAssignment(
      projectId: $projectId
      personId: $personId
      startDate: $startDate
      endDate: $endDate
      percent: $percent
      investment: $investment
    ) {
      _id
      projectId
      personId
      startDate
      endDate
      type
      percent
      investment
    }
  }
`

export const UPDATE_ASSIGNMENT = gql`
  mutation(
    $_id: String!
    $projectId: String!
    $personId: String!
    $startDate: String!
    $endDate: String!
    $percent: String!
    $investment: String!
  ) {
    updateAssignment(
      _id: $_id
      projectId: $projectId
      personId: $personId
      startDate: $startDate
      endDate: $endDate
      percent: $percent
      investment: $investment
    ) {
      _id
      projectId
      personId
      startDate
      endDate
      type
      percent
      investment
    }
  }
`
