import moment from 'moment'
import _ from 'lodash'
import { logError } from '../../../utils/logger'

import { EXCHANGE_EMAILS_RESOURCE_ID, EXCHANGE_EVENTS_RESOURCE_ID } from '../../../constants/events'
import { getDurationInHours, convertDateToNonLocalString } from '../../../server/utils/date'

export const convertMessageToGlimpseEmail = message => {
  const dateTimeSent = convertDateToNonLocalString(message.receivedDateTime)

  return {
    _id: message.id,
    id: message.id,
    subject: message.subject,
    recipients: message.toRecipients,
    ccRecipients: _.get(message, 'ccRecipients', []),
    sender: message.from,
    bodyPreview: message.bodyPreview,
    body: message.body,
    conversationId: message.conversationId,
    conversationTopic: message.subject,
    dateTimeSent,
    start: dateTimeSent,
    end: dateTimeSent,
    resourceId: EXCHANGE_EMAILS_RESOURCE_ID,
  }
}

export const convertEventToGlimpseEvent = event => {
  let startTime
  let endTime
  if (event.isAllDay) {
    startTime = moment(`${event.start.dateTime}`).toLocalString()
    endTime = moment(`${event.end.dateTime}`).toLocalString()
  } else {
    startTime = moment(`${event.start.dateTime}Z`).toLocalString()
    endTime = moment(`${event.end.dateTime}Z`).toLocalString()
  }

  try {
    return {
      _id: event.id,
      id: event.id,
      subject: event.subject,
      title: event.subject,
      bodyPreview: event.bodyPreview,
      body: event.body,
      start: startTime,
      end: endTime,
      duration: getDurationInHours(startTime, endTime),
      attendees: event.attendees,
      type: event.type,
      isAllDay: event.isAllDay,
      seriesMasterId: event.seriesMasterId,
      isCancelled: event.isCancelled,
      calendarId: event.calendar ? event.calendar.id : '',
      projectId: event.projectId,
      resourceId: EXCHANGE_EVENTS_RESOURCE_ID,
      tagId: event.tagId,
    }
  } catch (e) {
    logError(e, 'convertEventToGlimpseEvent > e: ')
    return null
  }
}
