import gql from 'graphql-tag'

export const GET_REMINDER_SETTINGS = gql`
  query {
    getReminderSettings {
      shouldRemind
      weekDays {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
      }
      timeFrom
      timeTo
      periodInMinutes
    }
  }
`
