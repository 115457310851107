import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Tooltip from '@material-ui/core/Tooltip'

import eventUtils from '../../../utils/eventUtils'
import timeUtils from '../../../utils/timeUtils'

import {
  shouldRenderButton,
  parseInnerTextFromElement,
  getTimeRange,
  removeTagsFromBody,
  bodyParserOptions,
} from './utils'
import CardEntryBtn from './CardEntryBtn'

class EventCard extends React.Component {
  state = {
    isExpanded: false,
  }

  toggleExpand = () => {
    this.setState(prevstate => ({
      isExpanded: !prevstate.isExpanded,
    }))
  }

  _renderEventAgenda = event => {
    const currentBody =
      this.state.isExpanded && _.has(event.body, 'content')
        ? removeTagsFromBody(event.body.content)
        : event.bodyPreview

    if (_.isEmpty(currentBody)) {
      return (
        <div>
          {this.props.isEmail
            ? `The email does not contain message body!`
            : `The event does not contain message body!`}
        </div>
      )
    }

    const bodyElements = ReactHtmlParser(currentBody, bodyParserOptions)
    const elementInnerText = parseInnerTextFromElement(bodyElements)

    if (!elementInnerText) return null

    return <div className="event-agenda">{bodyElements}</div>
  }

  renderCardEntryButton = () => {
    const shouldRenderCreateButton = _.isEmpty(this.props.currentEditableEntry)
      ? true
      : !!this.props.currentEditableEntry.hideOnWeekPeriod

    const action = shouldRenderCreateButton ? this.props.onCreateEntry : this.props.onAddToEntry
    const label = shouldRenderCreateButton ? 'Create entry' : 'Add to entry'
    return <CardEntryBtn action={action} label={label} />
  }

  renderToggleButton = () => {
    const expandMessage = this.props.isEmail
      ? { hide: 'Hide Message', show: 'Show Message' }
      : { hide: 'Close', show: 'Expand' }
    return (
      <button type="button" onMouseDown={this.toggleExpand} className="event-toggle-message-btn">
        {this.state.isExpanded ? expandMessage.hide : expandMessage.show}
      </button>
    )
  }

  render() {
    const { event, isEmail } = this.props
    const timeRange = isEmail
      ? timeUtils.standardTimeFormat(event.dateTimeSent)
      : getTimeRange(
          eventUtils.getEventStartDate(event),
          eventUtils.getEventEndDate(event),
          this.props.date,
        )
    const doesMessageExist = !!event.bodyPreview
    const eventClasses = classNames({
      selected: this.props.isSelected,
      highlighted: this.props.isHighlighted,
    })
    const _shouldRenderButton = shouldRenderButton(
      [...this.props.entries, ...this.props.localEntries.map(e => e.data)],
      event,
    )

    const participants = isEmail ? event.recipients : event.attendees
    const participantNames = _.map(participants, 'emailAddress.name')

    const firstParticipants = participantNames.slice(0, 7).join(', ')
    const moreParticipants = participantNames.slice(7).join(', ')

    const eventSubject = isEmail ? event.subject : event.title

    return (
      <div
        role="menuitem"
        tabIndex={0}
        className={`event ${eventClasses}`}
        onMouseDown={this.props.onSelect}
      >
        <div className="time">{timeRange}</div>
        <div className="event-content">
          <div className="event-subject truncated">
            <span>{eventSubject || '(No title)'}</span>
          </div>
          <div className="event-attendees">
            <span>{isEmail ? `Recipient(s)` : `Attendees`}:</span>
            <span className="names">{firstParticipants}</span>
            {participantNames.length > 7 && (
              <Tooltip title={moreParticipants}>
                <AddCircleOutlineIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          {this._renderEventAgenda(event)}
          {doesMessageExist ? this.renderToggleButton() : null}
        </div>
        {_shouldRenderButton && this.renderCardEntryButton()}
      </div>
    )
  }
}

EventCard.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onAddToEntry: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCreateEntry: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  localEntries: PropTypes.array.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool,
}

EventCard.defaultProps = {
  isEmail: false,
}

export default EventCard
