import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../common/Modal'
import { ShortcutTitle, ShortcutItem } from './style'

const KeyboardShortcuts = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="KEYBOARD SHORTCUTS">
      <ShortcutTitle>Create Time Entries</ShortcutTitle>
      <ShortcutItem>Start new entry input box (Ctrl + B)</ShortcutItem>
      <ShortcutItem>Create a new time entry (Ctrl + enter)</ShortcutItem>
    </Modal>
  )
}

KeyboardShortcuts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default KeyboardShortcuts
