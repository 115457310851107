import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'

import CustomTooltip from '../../../../components/CustomTooltip'

import { getNameInitials } from '../../../../App/utils'
import { getPersonFullName, getNumberOfMissing } from './utils'

import { Container, Picture, UserName } from './style'

const UserProfile = ({ person, timesheets }) => {
  const numberOfMissingTimesheets = getNumberOfMissing({ timesheets, startDate: person.startDate })
  const userName = getPersonFullName(person)
  return (
    <Container>
      <CustomTooltip
        title={`You have ${numberOfMissingTimesheets} missing timesheets`}
        placement="right"
      >
        <Badge
          badgeContent={numberOfMissingTimesheets}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          overlap="circular"
          color="error"
        >
          <Picture src={person.picture}>{getNameInitials(userName)}</Picture>
        </Badge>
      </CustomTooltip>
      <UserName>{userName}</UserName>
    </Container>
  )
}

UserProfile.propTypes = {
  person: PropTypes.object.isRequired,
  timesheets: PropTypes.array.isRequired,
}

export default UserProfile
