import styled from 'styled-components'

export const ProjectTableWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`
export const ProjectContainer = styled.div`
  padding: 10px 20px 20px;
  width: 100%;
  position: relative;
`
