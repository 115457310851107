import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import { Fade } from '@material-ui/core'
import { EventCommentBox, EventContainer } from './TimelineViewEventStyle'
import TextLinesClamp from './TextLinesClamp'
import CustomTooltip from '../../../components/CustomTooltip'
import EventComment from './EventComment'

class CalendarEvent extends React.Component {
  containerRef = React.createRef()

  state = {
    containerHeight: 0,
  }

  constructor(props) {
    super(props)
    this.throttleHandleEventClick = _.debounce(this.throttleHandleEventClick.bind(this), 500)
  }

  componentDidMount() {
    if (this.isContainerHeightAvailable()) {
      this.updateContainerHeight()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isContainerHeightAvailable()) {
      if (this.containerRef.current.clientHeight !== prevState.containerHeight) {
        this.updateContainerHeight()
      }
    }
  }

  throttleHandleEventClick = event => {
    this.props.setCurrentSelectedEvent(event._id)
    if (event.projectId) this.props.handleCreateEntry(event.projectId)
  }

  isContainerHeightAvailable = () => {
    return !!this.containerRef.current
  }

  getFirstTwoAttendees = attendees => {
    return _.slice(attendees, 0, 2)
  }

  getHeaderTitle = event => {
    const projectObject = _.find(this.props.projects, { repliconId: event.exchangeProjectId })
    return _.get(projectObject, 'name', null)
  }

  updateContainerHeight() {
    this.setState({
      containerHeight: this.containerRef.current.clientHeight,
    })
  }

  renderRemainingAttendees = event => {
    if (event.attendees.length > 2) {
      const numberOfRemainingAttendees = `+${event.attendees.length - 2}`
      return <Avatar key={2} value={numberOfRemainingAttendees} size={14} color="#b1b1b1" />
    }
  }

  renderFistTwoAttendees = event => {
    const attendees = this.getFirstTwoAttendees(event.attendees)
    return _.map(attendees, (attendee, index) => {
      if (attendee) {
        return <Avatar key={index} name={attendee.emailAddress.name} size={14} color="#b1b1b1" />
      }
    })
  }

  renderAttendees = event => {
    if (event.attendees) {
      return _.concat(this.renderFistTwoAttendees(event), this.renderRemainingAttendees(event))
    }
  }

  render() {
    const { event, projects } = this.props
    return (
      <CustomTooltip
        title={<EventComment event={event} projects={projects} />}
        textAlign="left"
        padding="15px 10px"
        width="37vw"
        isTimeline
        transition={Fade}
      >
        <EventContainer
          data-testid={`timeline.calendarEvent.${event._id}`}
          ref={this.containerRef}
          onClick={() => this.throttleHandleEventClick(event)}
        >
          <EventCommentBox>
            <TextLinesClamp
              containerHeight={this.state.containerHeight}
              lineHeight={11}
              offsetBottom={12}
              offsetTop={0}
            >
              {event.subject}
            </TextLinesClamp>
          </EventCommentBox>
        </EventContainer>
      </CustomTooltip>
    )
  }
}

CalendarEvent.propTypes = {
  event: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  handleCreateEntry: PropTypes.func.isRequired,
  setCurrentSelectedEvent: PropTypes.func.isRequired,
}

export default CalendarEvent
