import _ from 'lodash'
import {
  getNumberOfMissingTimeSheets,
  getMissingNavigationStartDate,
} from '../../../../utils/timesheetUtils'

export const getPersonFullName = person => _.get(person, 'fullName', '')

export const getNumberOfMissing = ({ timesheets, startDate }) => {
  const missingNavigationStartDate = getMissingNavigationStartDate(startDate)
  const missingTimesheets = getNumberOfMissingTimeSheets(timesheets, missingNavigationStartDate)
  return `${missingTimesheets > 99 ? '99+' : missingTimesheets}`
}
