import _ from 'lodash'

import { convertEventToGlimpseEvent } from '../../../utils/events'

import Event from '../../../../../server/models/event'

export default async ({ events, ignoreFiltration }) => {
  const processedEvents = _.flatten(await getProcessedEvents(events))
  let filteredUniqueEvents
  if (!ignoreFiltration) {
    const filteredEvents = processedEvents.filter(filterEvent)
    filteredUniqueEvents = _.uniqBy(filteredEvents, event => event._id)
  } else {
    filteredUniqueEvents = _.uniqBy(processedEvents, event => event._id)
  }
  return filteredUniqueEvents
}

const getProcessedEvents = async events => {
  const eventsFilledWithData = await fillReoccurringEvents(events)
  return eventsFilledWithData.map(event => convertEventToGlimpseEvent(event))
}

const fillReoccurringEvents = async events => {
  const eventsToFetch = {}
  for (let index = 0; index < events.length; index += 1) {
    const event = events[index]
    if (event.type && event.type === 'occurrence' && event.seriesMasterId) {
      if (eventsToFetch[event.seriesMasterId]) {
        eventsToFetch[event.seriesMasterId].push(index)
      } else {
        eventsToFetch[event.seriesMasterId] = [index]
      }
    }
  }

  const eventsFromDB = await Event.where('_id')
    .anyOf(Object.keys(eventsToFetch))
    .toArray()

  eventsFromDB.push(...events.filter(event => event.type === 'seriesMaster'))

  const eventsToReturn = events

  for (const eventFromDB of eventsFromDB) {
    const eventsToUpdate = eventsToFetch[eventFromDB.id]
    if (eventsToUpdate) {
      for (const eventToUpdateIndex of eventsToUpdate) {
        const recurringEvent = events[eventToUpdateIndex]
        eventsToReturn[eventToUpdateIndex] = {
          ...eventFromDB,
          id: recurringEvent.id,
          type: recurringEvent.type,
          seriesMasterId: recurringEvent.seriesMasterId,
          start: recurringEvent.start,
          end: recurringEvent.end,
        }
      }
    }
  }

  return eventsToReturn
}

const filterEvent = event => {
  if (event.isCancelled) return false
  return true
}
