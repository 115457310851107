import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { ErrorPageContainer, DraggableContainer, ErrorText, SyncNavigation } from './styles'
import { mapStateToProps, mapDispatchToProps } from './store'

const ErrorScreen = props => {
  const renderLogoutButton = () => {
    return (
      <Button
        id="get-started"
        variant="contained"
        color="secondary"
        size="large"
        onClick={props.logout}
      >
        Reload
      </Button>
    )
  }

  return (
    <DraggableContainer>
      <ErrorPageContainer>
        <ErrorText>
          <div>
            <h1>There was an error loading Glimpse</h1>
          </div>
          <div>
            <h2 id="sync-message">Please try again later.</h2>
          </div>
        </ErrorText>
        <SyncNavigation>{renderLogoutButton()}</SyncNavigation>
      </ErrorPageContainer>
    </DraggableContainer>
  )
}

ErrorScreen.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen)
