import {
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_AS_DELEGATE,
  GET_ASSIGNMENTS_BY_PROJECTS,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
} from './query'

export const fetchAssignmentsInDateRange = async ({
  client,
  startISO,
  endISO,
  selectedDelegateId,
}) => {
  const { data } = await client.query({
    query: selectedDelegateId ? GET_ASSIGNMENTS_AS_DELEGATE : GET_ASSIGNMENTS,
    variables: {
      startISO,
      endISO,
      delegateId: selectedDelegateId,
    },
  })
  return selectedDelegateId ? data.getAssignments_asDelegate : data.getAssignments
}

export const fetchAssignmentsByProjectIdsInDateRange = async ({
  client,
  projectIds,
  startISO,
  endISO,
}) => {
  const { data } = await client.query({
    query: GET_ASSIGNMENTS_BY_PROJECTS,
    variables: {
      projectIds,
      startISO,
      endISO,
    },
  })
  return data.getAssignmentsByProjects
}

export const createAssignment = async ({ client, assignment }) => {
  const { data } = await client.mutate({
    mutation: CREATE_ASSIGNMENT,
    variables: {
      personId: assignment.personId,
      projectId: assignment.projectId,
      startDate: assignment.startDate,
      endDate: assignment.endDate,
      percent: assignment.percent,
      investment: assignment.investment,
    },
  })
  return data.createAssignment
}

export const updateAssignment = async ({ client, assignmentId, assignment }) => {
  const { data } = await client.mutate({
    mutation: UPDATE_ASSIGNMENT,
    variables: {
      _id: assignmentId,
      personId: assignment.personId,
      projectId: assignment.projectId,
      startDate: assignment.startDate,
      endDate: assignment.endDate,
      percent: assignment.percent,
      investment: assignment.investment,
    },
  })
  return data.updateAssignment
}

export const deleteAssignment = async ({ client, assignmentId }) => {
  const { data } = await client.mutate({
    mutation: DELETE_ASSIGNMENT,
    variables: {
      _id: assignmentId,
    },
  })
  return data.deleteAssignment
}
