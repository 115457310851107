import React from 'react'

import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: props => props.fontSize || '12px',
    textAlign: props => props.textAlign || 'center',
    width: props => props.width || 'auto',
    maxWidth: props => props.maxWidth || 'none',
    minWidth: props => props.minWidth || 'none',
    minHeight: props => props.minHeight || 'none',
    padding: props => props.padding || '5px 15px',
    borderRadius: props => {
      if (props.isEmail) return '15px 0 0 15px'
      if (props.isTimeline) return '0 15px 15px 0'
      return '5px'
    },
    backgroundColor: props =>
      props.isTimeline || props.isEmail ? `rgba(83, 83, 83, 0.8)` : `default`,
  },
  popper: {
    zIndex: props => (props.isTimeline || props.isEmail ? 200 : 'default'),
  },
})(({ transition, transitionTimeout, classes, title, children, isTimeline, isEmail }) => {
  const placement = isTimeline || isEmail ? 'bottom-start' : 'bottom'
  const offsetTooltip = isEmail ? '0px, -10px' : '-1px, -13px'
  const paddingOverflow = isEmail ? 0 : 5

  return (
    <Tooltip
      TransitionComponent={transition || Zoom}
      TransitionProps={{ timeout: transitionTimeout || 300 }}
      classes={classes}
      title={title}
      placement={placement}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              order: 200,
              enabled: isTimeline || isEmail,
              offset: offsetTooltip,
            },
            flip: {
              order: 100,
              enabled: isTimeline || isEmail,
            },
            preventOverflow: {
              enabled: isTimeline || isEmail,
              padding: paddingOverflow,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
})

export default CustomTooltip
