import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { FlexColumn, FlexRow } from '../../../../styledComponents/layout'

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    minWidth: 85,
    maxWidth: 220,
    fontSize: '1rem',
    padding: '5px 3px',
    border: '2px solid #4794eb',
  },
  arrow: {
    color: '#4794eb',
  },
  tooltipPlacementBottom: {
    margin: '6px 0',
  },
}))(Tooltip)

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Column = styled(FlexColumn)`
  align-items: center;
`

export const ApprovalStatus = styled(Column)`
  color: ${props => (props.isSubmitted ? green[500] : red[500])};
`

export const Row = styled(FlexRow)`
  justify-content: space-around;
  align-items: center;
`
