import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { listItemsString } from '../../utils/prettyString'

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: '1em',
  },
  title: {
    color: '#FF8C00',
    fontSize: 14,
    fontWeight: 900,
  },
  contentText: {
    color: '#C0C0C0',
    fontSize: 14,
  },
  buttonContainer: {
    justifyContent: 'center',
  },
})

const ContinueWithoutSync = props => {
  const styles = useStyles()
  const failedSyncs = Object.keys(props.initialSync.syncItems).filter(syncItemKey => {
    const syncItem = props.initialSync.syncItems[syncItemKey]
    return syncItem.error
  })

  const failedSyncsString = failedSyncs.reduce(listItemsString)
  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography className={styles.title} gutterBottom>
          Oops! We failed to sync {failedSyncsString}
        </Typography>
        <Typography className={styles.contentText}>
          You may continue without {failedSyncs.length === 1 ? 'that item' : 'those items'} and try
          to sync later. All other functionalities will work as expected.
          <br />
          An automated notification will be sent to the Glimpse team.
        </Typography>
      </CardContent>
      <CardActions className={styles.buttonContainer}>
        <Button size="medium" color="primary" onClick={props.handleContinueWithoutSync}>
          Continue without sync
        </Button>
      </CardActions>
    </Card>
  )
}

ContinueWithoutSync.propTypes = {
  handleContinueWithoutSync: PropTypes.func.isRequired,
  initialSync: PropTypes.object.isRequired,
}

export default ContinueWithoutSync
