import styled from 'styled-components'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'

export const InitialSyncContainer = styled.div`
  padding-top: 30px;
  height: 200px;
  display: flex;
  flex-direction: column;
`

export const SyncingElement = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
  margin: 5px 0;

  > div {
    margin: 0 5px;
  }
`

export const SyncElementIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
`

export const SyncNavigation = styled.div`
  display: flex;
  justify-content: center;
`

export const FailedSyncNavigation = styled.div`
  margin-top: 45px;
`

export const styles = () => ({
  syncIconDone: {
    color: ``,
  },
  syncIconError: {
    color: orange[700],
  },
  syncProgress: {
    color: green[700],
  },
  buttonContainer: {
    textAlign: 'center',
  },
})
