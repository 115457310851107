import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import ConfirmationModal from '../../ConfirmationModal'
import timeUtils from '../../../utils/timeUtils'
import { useCheckboxState } from '../hooks'
import {
  getUnhandledEntriesInfoByTimesheet,
  getUnhandledTotalInfo,
  getResolveSubtitle,
} from '../utils'

const ResolveModal = ({
  classes,
  isOpen,
  setShowed,
  unhandledEntriesInfo,
  onAdd,
  onDismiss,
  onReplace,
  csvActionButtonsBlocked,
  failedCsvInfoTotal,
}) => {
  const unhandledEntriesInfoByTimesheet = getUnhandledEntriesInfoByTimesheet(unhandledEntriesInfo)
  const unhandledDates = unhandledEntriesInfoByTimesheet.map(e => e.date)
  const unhandledTotalInfo = getUnhandledTotalInfo(unhandledEntriesInfoByTimesheet)
  const [selectedCheckboxes, handleCheck, clearCheckboxState, handleSelectAll] = useCheckboxState(
    unhandledDates,
  )

  const subtitle = getResolveSubtitle(unhandledTotalInfo)

  const handleNextModal = () => {
    const failedTimesheetsCount = _.get(failedCsvInfoTotal, 'timesheetsCount', 0)
    clearCheckboxState()
    if (failedTimesheetsCount > 0) {
      setShowed(2)
    }
  }

  const handleDismiss = async () => {
    await onDismiss(selectedCheckboxes)
    handleNextModal()
  }

  const handleAdd = () => {
    onAdd(selectedCheckboxes)
    handleNextModal()
  }

  const handleReplace = () => {
    onReplace(selectedCheckboxes)
    handleNextModal()
  }

  const isDisabledButtons = csvActionButtonsBlocked || selectedCheckboxes.length === 0

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="ATTENTION!"
      titleColor="secondary"
      subtitle={subtitle}
      actionButtons={[
        {
          handler: handleDismiss,
          label: 'DISMISS',
          color: 'secondary',
          isDisabled: isDisabledButtons,
        },
        { handler: handleReplace, label: 'REPLACE', isDisabled: isDisabledButtons },
        {
          handler: handleAdd,
          label: 'ADD',
          color: 'primary',
          isDisabled: isDisabledButtons,
        },
      ]}
    >
      <TableContainer className={classes.container}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedCheckboxes.length === unhandledDates.length}
                  value="selected"
                  onChange={handleSelectAll}
                  color="primary"
                />
              </TableCell>
              <TableCell align="right">Select all</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
            {_.map(unhandledEntriesInfoByTimesheet, e => (
              <TableRow key={e.start}>
                <TableCell>
                  <Checkbox
                    checked={selectedCheckboxes.includes(e.date.toString())}
                    onChange={handleCheck}
                    value={e.date}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">{e.start}</TableCell>
                <TableCell align="right">
                  {e.count} {e.count > 1 ? 'entries' : 'entry'}
                </TableCell>
                <TableCell align="right">
                  {timeUtils.getPrettyDuration(e.durationInHours, 'hour')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfirmationModal>
  )
}

ResolveModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setShowed: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  unhandledEntriesInfo: PropTypes.object.isRequired,
  failedCsvInfoTotal: PropTypes.object.isRequired,
  csvActionButtonsBlocked: PropTypes.bool.isRequired,
}

export default ResolveModal
