import styled from 'styled-components'
import Info from '@material-ui/icons/Info'

export const Container = styled.div``

export const Header = styled.div`
  background-color: #d94138;
  color: white;
  text-align: center;
  padding: 5px 2px;
`

export const HeaderTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  pointer-events: none;
`

export const HeaderSubtitle = styled.span`
  font-size: 14px;
  pointer-events: none;
  letter-spacing: 0.4px;
`

export const Date = styled.span`
  font-size: 14px;
  pointer-events: none;
  letter-spacing: 0.4px;
  margin: 0 0 0 4px;
`

export const OutsideBodyContainer = styled.div`
  position: absolute;
  z-index: 10000;
  width: 100%;
`

export const InnerBodyContainer = styled.div`
  padding: 20px 20px 32px 20px;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  @media (max-width: 768px) {
    & > p {
      margin: 0 0 20px 0;
    }
  }
`

export const BodyTitle = styled.h1`
  font-size: 16px;
`

export const BodyDescription = styled.div`
  margin: 15px 0 0 0;
  @media (max-width: 768px) {
    margin: 20px 0 0 0;
  }
`

export const BodyParagraph = styled.p`
  font-size: 14px;
  margin: 0px;
  @media (max-width: 768px) {
    text-align: justify;
    margin: 10px 0 0 0;
  }
`

export const FailedSync = styled.span`
  font-size: 14px;
  letter-spacing: 0.4px;
  pointer-events: none;
`

export const InformationIcon = styled(Info)`
  color: white;
  margin-right: 25;
`

export const IconContainer = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
`
