import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import timeUtils from '../../../utils/timeUtils'
import ConfirmationModal from '../../ConfirmationModal'
import { getSuccessSubtitle } from '../utils'

const SuccessModal = ({ classes, csvInfoTotal, handler, isOpen }) => {
  const successTimesheetTotal = _.get(csvInfoTotal, 'csvInfoByTimesheetTotal', [])
  const subtitle = getSuccessSubtitle(csvInfoTotal)
  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="WELL DONE!"
      titleColor="primary"
      subtitle={subtitle}
      actionButtons={[{ handler, label: 'OK', color: 'primary' }]}
    >
      <TableContainer className={classes.container}>
        <Table>
          <TableBody>
            {_.map(successTimesheetTotal, e => (
              <TableRow key={e.start}>
                <TableCell align="right">{e.start}</TableCell>
                <TableCell align="right">
                  {e.count} {e.count > 1 ? 'entries' : 'entry'}
                </TableCell>
                <TableCell align="right">
                  {timeUtils.getPrettyDuration(e.durationInMiliseconds)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfirmationModal>
  )
}

SuccessModal.propTypes = {
  classes: PropTypes.object.isRequired,
  csvInfoTotal: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default SuccessModal
