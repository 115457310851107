import _ from 'lodash'

export const getCreationDate = e => new Date(e.createdAt)

export const getLastUsedProjectIds = (timeEntries = [], count = 3, favoriteProjectIds = []) => {
  const sortedProjectEventsByDate = _.orderBy(timeEntries, [getCreationDate], ['desc'])
  const uniqueTimeEntriesProjects = _.uniqBy(sortedProjectEventsByDate, 'repliconProjectId')

  const isNotInFavorites = e => !_.includes(favoriteProjectIds, e.repliconProjectId)

  const filteredProjects = _.filter(uniqueTimeEntriesProjects, isNotInFavorites)
  const first3 = _.take(filteredProjects, count)
  const repliconIds = _.map(first3, 'repliconProjectId')

  return repliconIds
}
