export const APPROVED = 'Approved'
export const OPEN = 'Open'

export const TIMESHEET_STATUS = {
  ERROR: 'There is no timesheet',
  FETCHING: 'Fetching data…',
  REOPEN: 'Reopening timesheet...',
  SUBMITTED: 'Submitting...',
  CONNECTION: 'There is no connection',
  EARLIER: 'Can not reopen a timesheet earlier than two months ago',
  UNASSIGNED: 'There are unassigned project entries.',
  WEEK_VIEW: 'Not all week timesheets have entries',
}
