import styled from 'styled-components'
import Box from '@material-ui/core/Box'

export const ContentContainer = styled(Box)`
  height: calc(100vh - 455px);
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`

export const AssignmentContainer = styled(Box)`
  width: calc(100% - 32px);
  z-index: 1000;
  margin: 16px;
  position: relative;
  bottom: 0;
  right: 0;
`

export const CircularContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
  text-align: center;
`
