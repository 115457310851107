import _ from 'lodash'
import { billingOptions } from '../../../../constants/billingOptions'

export const getCheckTooltipText = projectObject => {
  const billable = _.get(projectObject, 'billable')
  const checkTooltipTextOptions = {
    [billingOptions.NONBILLABLE]: 'This project is set to accept non-billable entries only.',
    [billingOptions.BILLABLE]: 'This project is set to accept billable entries only.',
    [billingOptions.BOTH]: 'This project is set to accept billable & non-billable entries.',
  }

  return checkTooltipTextOptions[billable] || ''
}
