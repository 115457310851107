import React, { useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { MasterButton, Expandables } from './style'
import ExpandableItem from './ExpandableItem'

const ExpansionPanel = ({ data }) => {
  const initialToggleStatus = data.map(el => {
    return {
      index: el.index,
      isOpen: false,
    }
  })

  const [toggleStatus, setToggleStatus] = useState(initialToggleStatus)
  const [prevIndexes, setPrevIndexes] = useState(null)

  const dataIndexes = data.map(d => d.index)
  const shouldResetToggles = !_.isEqual(dataIndexes, prevIndexes)
  if (shouldResetToggles) {
    setToggleStatus(
      data.map(e => {
        return {
          index: e.index,
          isOpen: false,
        }
      }),
    )
    setPrevIndexes(dataIndexes)
  }

  const onToggleAll = () => {
    const shouldExpand = toggleStatus.some(el => el.isOpen === false)
    const newToggleStatus = data.map(el => {
      return {
        index: el.index,
        isOpen: shouldExpand,
      }
    })
    setToggleStatus(newToggleStatus)
  }

  const onToggleItem = index => {
    const newToggleStatus = toggleStatus.map(el => {
      if (el.index !== index) return el
      const newValue = !el.isOpen
      return {
        ...el,
        isOpen: newValue,
      }
    })
    setToggleStatus(newToggleStatus)
  }

  const getToggleStatus = index => {
    const status = toggleStatus.find(el => el.index === index)
    if (status === undefined) return false
    return status.isOpen
  }

  const atLeastOneCollapsed = toggleStatus.some(el => el.isOpen === false)

  return (
    <>
      <MasterButton onClick={onToggleAll} color="primary">
        {atLeastOneCollapsed ? 'Expand all' : 'Collapse all'}
      </MasterButton>
      <Expandables>
        {data.map(el => {
          return (
            <ExpandableItem
              key={el.index}
              title={el.title}
              toggle={() => onToggleItem(el.index)}
              isOpen={getToggleStatus(el.index)}
            >
              {el.content}
            </ExpandableItem>
          )
        })}
      </Expandables>
    </>
  )
}

export default ExpansionPanel

ExpansionPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
}
